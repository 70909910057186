import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { TypedUserMeta } from '../interface/user.meta.model';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import helpers from 'helpers';

export const initialState = {
  loading: false,
  entity: {} as TypedUserMeta,
  entities: {} as TypedUserMeta[],
  errorMessage: null as any, // Errors returned from server side
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'user_meta';

export const getEntity = createAsyncThunk('user-meta/fetch-entity', async (meta_key: string) => {
  return await axios.get<any>(`${apiUrl}/${meta_key}`);
},
  { serializeError: serializeAxiosError }
);


export const updateEntity = createAsyncThunk(
  'user-meta/update-entity',
  async (entity: TypedUserMeta, thunkAPI) => {
    let meta_key = String(entity.meta_key);
    delete entity.meta_key;
    let data = await axios.patch<TypedUserMeta>(`${apiUrl}/${meta_key}`, helpers.cleanEntity(entity));
    return data;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'user-meta/delete-entity',
  async (meta_key: string) => {
    return await axios.delete<any>(`${apiUrl}/${meta_key}`);
  },
  { serializeError: serializeAxiosError }
);




export const USER_META = createEntitySlice({
  name: 'user_meta',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    },
    reset: (state) => {
      return { ...state, ...initialState };
    }
  },

  extraReducers(builder) {
    builder

      .addMatcher(isFulfilled(getEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          entity: action.payload.data,
        };
      })
      .addMatcher(isRejected(getEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
          entity: null,
        };
      })
      .addMatcher(isPending(getEntity), (state, action) => {
        return {
          ...state,
          loading: true,
          errorMessage: null,
          entity: null,
        };
      })

      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          updating: false,
          entity: action.payload.data,
        };
      })
      .addMatcher(isRejected(updateEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          updating: false,
          errorMessage: action.payload,
        };
      })
      .addMatcher(isPending(updateEntity), (state, action) => {
        return {
          ...state,
          loading: true,
          updating: true,
          errorMessage: null,
          entity: null,
        };
      })

      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          updating: false,
          entity: action.payload.data,
        };
      })
      .addMatcher(isRejected(deleteEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          updating: false,
          errorMessage: action.payload,
        };
      })
      .addMatcher(isPending(deleteEntity), (state, action) => {
        return {
          ...state,
          loading: true,
          updating: true,
          errorMessage: null,
          entity: null,
        };
      });

  },
});
export const { clearError, reset } = USER_META.actions;
// Reducer
export default USER_META.reducer;