import { FormLayout, Modal, TextField, Text, Form, InlineStack, DatePicker, Card, Popover, Icon, Autocomplete, InlineGrid } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'config/store';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import { CalendarIcon, SearchIcon } from '@shopify/polaris-icons';
import { createEntity, updateEntity, clearError } from 'store/customer_campaign.store.reducer';
import { useGetProducts } from 'queries/product.query';
import __ from 'languages/index';

/**
 *   Create upload Modal for Notification
 */

// eslint-disable-next-line import/no-anonymous-default-export

export default function CustomerCampaignModal(props: any) {
  const {
    onClose,
    mode,
    show
  } = props;
  const dispatch = useAppDispatch();
  let entity = useAppSelector(state => state.customer_campaign.entity);
  const updating = useAppSelector(state => state.customer_campaign.updating);
  const updateSuccess = useAppSelector(state => state.customer_campaign.updateSuccess);
  const [productQuery, setProductQuery] = useState({
    sort: 'createdAt:desc',
    limit: 5,
    query: ''
  });
  const {
    refetch: findProduct,
    data: productList,
    isLoading: loadingProduct
  } = useGetProducts(productQuery);
  const [cloneEntity, setCloneEntity] = useState(null);

  /**
   * Chức năng tìm nhanh ở dưới ...
   */
  const [productPNRSelected, setProductPNRSelected] = useState<string[]>([]);
  const [productPNRValue, setProductPNRValue] = useState('');
  const [productListForQuickOption, setProductListForQuickOption] = useState([]);
  const history = useNavigate();
  let {
    customer_campaign_id
  } = useParams();
  const queryAndCloneAll = useCallback(() => {
    if (entity && mode === 'edit') {
      setCloneEntity(entity);
      setProductPNRValue(entity?.product?.product_sku);
    }
  }, [entity]);

  /**
   * Start from here ...
   */
  useEffect(() => {
    if (show === 0) return;
    if (show === false) return;
    queryAndCloneAll();
    if (mode !== 'edit') {
      setCloneEntity(null);
      return;
    }
    if (!customer_campaign_id) {
      reset();
    }
  }, [show]);
  const useFields = {
    campaign_name: useField<string>({
      value: cloneEntity?.campaign_name ?? '',
      validates: [lengthLessThan(150, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(1, 'Tên phải dài hơn 1 ký tự.')]
    }),
    campaign_target: useField<string>({
      value: cloneEntity?.campaign_target ?? '',
      validates: [inputValue => {
        if (!helpers.isNumber(inputValue)) return 'Mục tiêu phải là một số.';
      }]
    }),
    campaign_deadline: useField<string>({
      value: cloneEntity?.campaign_deadline ?? '',
      validates: []
    }),
    campaign_description: useField<string>({
      value: cloneEntity?.campaign_description ?? '',
      validates: [lengthLessThan(255, 'No more than 255 characters.')]
    }),
    product_sku: useField<string>({
      value: cloneEntity?.product?.product_sku ?? '',
      validates: [lengthLessThan(50, 'No more than 50 characters.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'Giá trị không hợp lệ, mã ID không gồm các ký tự UTF8';
        }
        if (helpers.parseMetaKey(inputValue) !== inputValue) {
          return 'Giá trị không hợp lệ, hoặc đang chứa dấu cách';
        }
      }]
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        if (mode !== 'edit') {
          dispatch(createEntity({
            campaign_name: values.campaign_name,
            campaign_target: Number(values.campaign_target || 0),
            campaign_description: values.campaign_description,
            product_sku: values.product_sku,
            campaign_deadline: String(values.campaign_deadline)
          }));
        } else {
          dispatch(updateEntity({
            campaign_id: BigInt(customer_campaign_id),
            campaign_name: values.campaign_name,
            campaign_target: Number(values.campaign_target || 0),
            campaign_description: values.campaign_description,
            product_sku: values.product_sku,
            campaign_deadline: String(values.campaign_deadline)
          }));
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * Date ...
   */
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    if (cloneEntity?.campaign_deadline) {
      setSelectedDate(new Date(Number(cloneEntity?.campaign_deadline)));
    }
  }, [cloneEntity]);
  const [{
    month,
    year
  }, setDate] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear()
  });
  function handleMonthChange(month, year) {
    setDate({
      month,
      year
    });
  }
  function handleDateSelection({
    end: newSelectedDate
  }) {
    setSelectedDate(newSelectedDate);
    setVisible(false);
  }
  useEffect(() => {
    useFields.campaign_deadline.onChange(`` + selectedDate.getTime());
  }, [selectedDate]);
  const formattedValue = dateandtime.format(selectedDate, 'DD-MM-YYYY');
  /**
   * End date
   */

  /* End form */

  useEffect(() => {
    reset();
  }, []);

  /**
   * Saving it ...
   */
  useEffect(() => {
    if (updateSuccess) {
      let campaign_id = entity.campaign_id;
      history('/customer_campaign/view/' + campaign_id);
      onClose();
      dispatch(clearError());
    }
  }, [updateSuccess]);

  /**
   *
   * HAHA HAHA HAHA HAHA HAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHA
   */

  useEffect(() => {
    let innerProduct = [];
    if (productList && productList?.body) for (let product of productList.body) {
      innerProduct.push({
        value: product.product_sku,
        label: product.product_name
      });
    }
    setProductListForQuickOption(innerProduct);
  }, [productList]);
  const onQueryToServer = useCallback((keyword: string) => {
    setProductQuery({
      query: keyword,
      sort: 'createdAt:desc',
      limit: 10
    });
    findProduct();
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateText = useCallback((value: string) => {
    setProductPNRValue(value);
    onChangeMainQueryCallback(value);
  }, []);
  const textField = <Autocomplete.TextField onChange={updateText} label="SKU sản phẩm mặc định cho chiến dịch" value={productPNRValue} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Search" autoComplete="off" />;
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = productListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.value;
    });
    setProductPNRSelected(selected);
    useFields.product_sku.onChange(selectedValue[0]);
    setProductPNRValue(selectedValue[0] || '');
  }, [productListForQuickOption]);
  return <Modal
  // large
  open={show} onClose={() => onClose()} title={mode !== 'edit' ? 'Thêm chiến dịch' : 'Chỉnh sửa thông tin'} primaryAction={{
    content: mode === 'edit' ? __('update_label_button') : 'Tạo & đóng',
    disabled: !dirty || mode === 'edit' || entity?.campaign_status === 0,
    loading: updating,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    onAction: onClose
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <TextField autoFocus autoComplete="off" requiredIndicator label="Tên chiến dịch" {...fields.campaign_name} />

            <TextField label="Mô tả" autoComplete="off" multiline={2} maxLength={255} showCharacterCount {...fields.campaign_description} />

            <InlineGrid columns={'2'} gap={'400'}>
              <TextField type="integer" autoComplete="off" label="Mục tiêu khách hàng (số lượng)" {...fields.campaign_target} />

              <Popover active={visible} autofocusTarget="none" preferredAlignment="left"
            // fullWidth
            preferInputActivator={false} preferredPosition="below" preventCloseOnChildOverlayClick onClose={() => setVisible(false)} activator={<TextField role="combobox" label={'Ngày hết hạn'} prefix={<Icon source={CalendarIcon} />} value={formattedValue} onFocus={() => setVisible(true)}
            // onBlur={() => setVisible(false)}
            onChange={null} autoComplete="off" />}>
                <Card>
                  <DatePicker month={month} year={year} selected={selectedDate} onMonthChange={handleMonthChange} onChange={handleDateSelection} disableDatesBefore={new Date()} />
                </Card>
              </Popover>
            </InlineGrid>

            <Autocomplete options={productListForQuickOption} selected={productPNRSelected} onSelect={updateSelection} textField={textField} loading={loadingProduct} />
          </FormLayout>
        </Form>
        <br />
        <InlineStack gap={'500'}>
          <Text as="p" variant="bodyMd">
            {' '}
          </Text>
        </InlineStack>
      </Modal.Section>
    </Modal>;
}