import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedPostCategory } from "./posts.query";



interface IQuery extends IQueryParams, TypedPostCategory { };

/*
* List of all app
*/
export function useGetPostCategories(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["post_category/fetch_entity_list"],
        queryFn: () => axios.get<TypedPostCategory[]>(`/post_category/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetPostCategory(app_id: string) {
    return useQuery({
        queryKey: ['post_category/fetch_entity'],
        queryFn: () => axios.get<TypedPostCategory>(`/post_category/${app_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdatePostCategory() {
    return useMutation({
        mutationKey: ['post_category/update_entity'],
        mutationFn: ({ category_id, ...rest }: TypedPostCategory) => axios.patch<TypedPostCategory>(`post_category/${category_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['post_category/fetch_entity_list'] });
        }
    });
}


export function useCreatePostCategory() {
    return useMutation({
        mutationKey: ['post_category/create_entity'],
        mutationFn: (entity: TypedPostCategory) => axios.post<TypedPostCategory>(`post_category/`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['post_category/fetch_entity_list'] });
        }
    });
}


export function useDeletePostCategory() {
    return useMutation({
        mutationKey: ['post_category/delete_entity'],
        mutationFn: (category_id: string) => axios.delete<any>(`post_category/${category_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['post_category/fetch_entity_list'] });
        }
    });
}
