import React, { useEffect, useRef, useState } from 'react';
import { Badge, Text, LegacyCard, DataTable, ExceptionList } from '@shopify/polaris';
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import { useAppDispatch, useAppSelector } from 'config/store';
import { PaymentIcon, AlertDiamondIcon } from '@shopify/polaris-icons';
import { getEntities } from 'store/transaction_to_project.store.reducer';
export default function AccountantLog({
  project_id
}: {
  project_id: string;
}) {
  /**
   * Danh sách giao dịch
   */
  const transactionsEntities = useAppSelector(state => state.transaction_to_project.entities);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getEntities(project_id));
  }, []);

  /**
   * Tính tổng đã thanh toán ...
   */
  const [totalPaid, setTotalPaid] = useState(0);
  useEffect(() => {
    let paid = 0;
    if (!transactionsEntities) return;
    for (let A of transactionsEntities) {
      paid += Number(A.transactions.amount);
    }
    setTotalPaid(paid);
  }, [transactionsEntities]);
  return <>
      <div className="small-icon">
        <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
          <PaymentIcon /> Giao dịch
        </Text>
      </div>

      {transactionsEntities && transactionsEntities.length > 0 ? <LegacyCard>
          <DataTable hasZebraStripingOnData increasedTableDensity hideScrollIndicator truncate columnContentTypes={['text', 'text', 'text', 'text', 'text']} headings={['ID', 'Giá trị', 'Phương thức', 'Loại giao dịch', 'Ngày thêm']} totalsName={{
        singular: 'Tổng',
        plural: 'Tổng'
      }} showTotalsInFooter totals={['', helpers.formatNumber(totalPaid, ','), '', '', '']} rows={transactionsEntities?.map(({
        transactions
      }) => {
        return [String(transactions.transaction_id), helpers.formatNumber(transactions.amount, ','), <Badge tone="info">{transactions.transaction_type}</Badge>, <Badge tone="info">{transactions.transaction_category}</Badge>, dateandtime.format(new Date(Number(transactions.createdAt)), 'DD-MM-YYYY HH:mm')];
      })} />
        </LegacyCard> : <ExceptionList items={[{
      icon: AlertDiamondIcon,
      description: 'Chưa có một giao dịch nào.'
    }]} />}
    </>;
}