import { Autocomplete, Card, Icon } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getEntities } from 'store/email_template.store.reducer';
import { SearchIcon } from "@shopify/polaris-icons";
import helpers from "helpers/index";
export default function QuickSearchEmailTemplate({
  current_setting,
  onClose,
  title
}: {
  current_setting?: string | bigint;
  title?: string;
  onClose: (val: string) => void;
}) {
  /**!SECTION
   * Select người handler
   */
  const dispatch = useAppDispatch();

  /**
   * Campaign ...
   */
  const loading = useAppSelector(state => state.email_template.loading);
  const entities = useAppSelector(state => state.email_template.entities);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    dispatch(getEntities({
      sort: 'createdAt:desc',
      limit: 5
    }));
  }, []);
  useEffect(() => {
    let innerCampaign = [];
    if (Array.isArray(entities) && entities.length > 0) for (let camp of entities) {
      innerCampaign.push({
        value: camp.id,
        label: camp.template_name
      });
      if (current_setting) {
        if (camp.id === current_setting) {
          setUserQuickSearchText(camp.template_name);
        }
      }
    }
    setUserListForQuickOption(innerCampaign);
  }, [entities]);
  const onQueryToServer = useCallback((keyword: string) => {
    dispatch(getEntities({
      query: keyword,
      sort: 'createdAt:desc',
      limit: 10
    }));
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    onClose(selected[0]);
  }, [userListForQuickOption]);
  const textFieldSelectUser = <Autocomplete.TextField onChange={updateUserText} label={title || ''} clearButton onClearButtonClick={() => {
    setUserQuickSearchText('');
    updateSelection([]);
  }} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm mẫu email" autoComplete="off" />;
  return <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={loading} />;
}