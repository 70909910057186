import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import { useAppDispatch, useAppSelector } from 'config/store';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateEntity, clearError, createEntity } from '../../store/task.store.reducer';
import { Form, FormLayout, InlineGrid, InlineStack, Modal, Select, TextField, Text } from '@shopify/polaris';
import QuickSearchUser from 'components/quickSearchUser';
export default function NewtaskModal({
  show,
  onClose,
  project_id
}: {
  show: boolean;
  onClose: Function;
  project_id: string;
}) {
  const entity = useAppSelector(state => state.task.entity);
  const updating = useAppSelector(state => state.task.updating);
  const loading = useAppSelector(state => state.task.loading);
  const updateSuccess = useAppSelector(state => state.task.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  useEffect(() => {
    reset();
    dispatch(clearError());
  }, []);
  const useFields = {
    task_title: useField<string>({
      value: '',
      validates: [lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(1, 'Tựa đề phải dài hơn 1 ký tự.')]
    }),
    task_description: useField<string>({
      value: '',
      validates: [lengthLessThan(550, 'Không được dài hơn 550 ký tự.'), lengthMoreThan(1, 'Mô tả phải dài hơn 1 ký tự.')]
    }),
    createdBy: useField<string>({
      value: '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        dispatch(createEntity({
          task_title: values.task_title,
          createdBy: values.createdBy,
          task_description: values.task_description,
          project_id: project_id
        }));
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!';
        const field = e?.response?.data?.errorKey ?? 'base';
        return {
          status: 'fail',
          errors: [{
            field,
            message
          }]
        };
      }
      return {
        status: 'success'
      };
    }
  });
  const closeMeAndCallback = useCallback(() => {
    return onClose(entity?.task_id);
  }, [entity]);
  useEffect(() => {
    if (updateSuccess) {
      closeMeAndCallback();
      reset();
      dispatch(clearError());
    }
  }, [updateSuccess]);
  return <Modal open={show} onClose={() => onClose()} title={'Task mới'} primaryAction={{
    content: 'Tạo & đóng',
    disabled: !dirty,
    loading: updating,
    onAction: () => submit()
  }} secondaryActions={[{
    content: 'Đóng',
    onAction: () => onClose()
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <TextField autoFocus autoComplete="off" requiredIndicator label="Tên task" {...fields.task_title} />

            <TextField label="Mô tả" autoComplete="off" multiline={2} {...fields.task_description} />

            <Text as="p">Giao cho:</Text>
            <QuickSearchUser title="" user_role="user,sale,sale_admin" current_user_id={useFields.createdBy.value} onClose={(u: any) => useFields.createdBy.onChange(u.user_id)} />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;
}