import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedComment } from '../interface/comment.model';

/**
 *   Reducer used for front-end, with comment.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using comment.model.ts
 */

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null,
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};
const apiUrl = 'comment';

// Actions

export const getEntities = createAsyncThunk('comment/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
});
export const getEntity = createAsyncThunk('comment/fetch_entity', async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const createEntity = createAsyncThunk('comment/create_entity', async (entity: TypedComment) => {
  return await axios.post<TypedComment>(`${apiUrl}`, helpers.cleanEntity(entity));
}, {
  serializeError: serializeAxiosError
});
export const updateEntity = createAsyncThunk('comment/update_entity', async (entity: TypedComment, thunkAPI) => {
  // patch
  const result = await axios.patch<TypedComment>(`${apiUrl}/${entity.comment_id}`, helpers.cleanEntity(entity));
  thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});
export const partialUpdateEntity = createAsyncThunk('comment/partial_update_entity', async (entity: TypedComment, thunkAPI) => {
  const result = await axios.patch<TypedComment>(`${apiUrl}/${entity.comment_id}`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});
export const updateManyEntities = createAsyncThunk('comment/update_many_entities', async (entity: TypedComment, thunkAPI) => {
  const result = await axios.patch<TypedComment>(`${apiUrl}/`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});
export const deleteEntity = createAsyncThunk('comment/delete_entity', async (id: string | number, thunkAPI) => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = axios.delete<any>(requestUrl);
  // thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});

// slice

export const Reducer_Comment = createEntitySlice({
  name: 'comment',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null;
      state.updateSuccess = false;
    },
    reset: state => {
      return {
        ...state,
        ...initialState
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(createEntity.fulfilled, (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
      state.entity = action.payload.data;
    }).addCase(createEntity.pending, (state, action) => {
      state.updating = true;
      state.updateSuccess = false;
    }).addCase(createEntity.rejected, (state, action) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    }).addCase(getEntity.fulfilled, (state, action) => {
      state.loading = false;
      state.entity = action.payload.data;
    }).addCase(getEntity.rejected, (state, action) => {
      state.loading = false;
      state.entity = null;
    }).addMatcher(isFulfilled(getEntities), (state, action) => {
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }).addMatcher(isRejected(updateEntity, updateManyEntities, deleteEntity), (state, action) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    }).addMatcher(isFulfilled(updateEntity, updateManyEntities), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = action.payload.data;
    }).addMatcher(isFulfilled(deleteEntity), (state, action) => {
      state.updating = false;
      //state.loading = false;
      state.updateSuccess = true;
      state.entity = null;
    }).addMatcher(isPending(deleteEntity), state => {
      state.updating = true;
      //state.loading = true;
      state.updateSuccess = false;
      state.entity = null;
    }).addMatcher(isPending(getEntities, getEntity), state => {
      state.errorMessage = null;
      state.loading = true;
    }).addMatcher(isPending(updateEntity, updateManyEntities), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.updating = true;
    });
  }
});
export const {
  clearError,
  reset
} = Reducer_Comment.actions;

// Reducer
export default Reducer_Comment.reducer;