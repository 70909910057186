import { Page, BlockStack, Text, LegacyCard, LegacyStack, Divider, Checkbox, TextField, Card, Form } from '@shopify/polaris';
import QuickUploadFile from 'components/oneclick-upload-file';
import { Toast, ContextualSaveBar } from '@shopify/polaris';
import { useState, useCallback, useEffect } from 'react';
import { useField, useForm } from '@shopify/react-form';
import { useGetSettings, useUpdateSetting } from 'queries/setting.query';
import { useNotification } from 'NotificationContext';
import __ from 'languages/index';
import __helpers from 'helpers/index';
export default function OrderSetting() {
  const {
    addNotification
  } = useNotification();
  const {
    data: entities,
    isLoading: loading
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `order_automatic_cancel_time,order_created_notification,order_created_notification_via_email,order_packingslip_template,order_invoice_template`
  });
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess,
    isPending
  } = useUpdateSetting();
  const useFields = {
    order_automatic_cancel_time: useField<string>({
      value: entities?.order_automatic_cancel_time ?? '0',
      validates: [inputVal => {
        if (inputVal && !__helpers.isNumeric(inputVal)) return 'Phải là một số hợp lệ.';
      }]
    }),
    order_created_notification: useField<string>({
      value: entities?.order_created_notification ?? '0',
      validates: []
    }),
    order_created_notification_via_email: useField<string>({
      value: entities?.order_created_notification_via_email ?? '0',
      validates: []
    }),
    order_packingslip_template: useField<string>({
      value: entities?.order_packingslip_template ?? '',
      validates: []
    }),
    order_invoice_template: useField<string>({
      value: entities?.order_invoice_template ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          order_automatic_cancel_time: Number(values.order_automatic_cancel_time),
          order_created_notification: Number(values.order_created_notification),
          order_created_notification_via_email: Number(values.order_created_notification_via_email),
          order_packingslip_template: values.order_packingslip_template,
          order_invoice_template: values.order_invoice_template
        });
      } catch (e: any) {
        console.error(`Submit error`, e);
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  useEffect(() => {
    if (isSuccess) addNotification('info', __('update_successfully'));
  }, [isSuccess]);
  useEffect(() => {
    if (uploadError) addNotification('error', __(uploadError));
  }, [uploadError]);
  return <>
      <Page title="Cài đặt cho đơn hàng" narrowWidth>
        <Form onSubmit={submit}>
          <Card>
            <BlockStack gap="400">
              <TextField autoComplete="off" label="Thời gian tự động hủy đơn" helpText={'Nếu đơn hàng không được xử lý và để quá thời gian này, nó sẽ bị hủy bởi hệ thống.'} suffix={<>Đơn vị tính: Giờ</>} {...useFields.order_automatic_cancel_time} />
              <Checkbox label="Nhận thông báo khi đơn hàng mới được tạo." checked={useFields.order_created_notification.value === '1'} onChange={() => useFields.order_created_notification.onChange(useFields.order_created_notification.value === '0' ? '1' : '0')} />

              <Checkbox label="Gửi email thông báo khi đơn hàng được tạo." checked={useFields.order_created_notification_via_email.value === '1'} onChange={() => useFields.order_created_notification_via_email.onChange(useFields.order_created_notification_via_email.value === '0' ? '1' : '0')} />

              <br />
              <Divider />
              <br />
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Template cho packing-slip
                </Text>
                <Text as="p" tone="subdued" variant="bodySm">
                  Đây là file mẫu để in packing slip. Một số doanh nghiệp không cần cài đặt này.
                </Text>
                <br />
                <Text as="p" tone="critical">
                  {useFields.order_packingslip_template.value}
                </Text>
                <br />
                <QuickUploadFile onSuccess={e => {
                useFields.order_packingslip_template.onChange(e.media_filename);
              }} onError={e => setUploadError(e)} children={<Text as="span">Tải lên</Text>} acceptMimeType={['text/html', 'text/htm', 'text/plain']} />
              </BlockStack>

              <br />
              <Divider />
              <br />
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Template cho hoá đơn
                </Text>
                <Text as="p" tone="subdued" variant="bodySm">
                  Đây là file mẫu để in hóa đơn hoặc gửi hóa đơn qua email cho khách hàng.
                </Text>
                <br />
                <Text as="p" tone="critical">
                  {useFields.order_invoice_template.value || 'Chưa có'}
                </Text>
                <br />
                <QuickUploadFile onSuccess={e => {
                useFields.order_invoice_template.onChange(e.media_filename);
              }} onError={e => setUploadError(e)} children={<Text as="span">Tải lên</Text>} acceptMimeType={['text/html', 'text/htm', 'text/plain']} />
              </BlockStack>
            </BlockStack>
          </Card>
        </Form>
        {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
        onAction: () => submit(),
        loading: isPending
      }} discardAction={{
        onAction: () => resetForm()
      }} />}
      </Page>
    </>;
}