import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
const ProtectedRoute = ({
  children
}: any) => {
  const {
    isAuthenticated
  } = useAuth();
  if (!isAuthenticated) {
    let current_url = encodeURIComponent(window.location.href);
    return <Navigate to={`/login?redirect_to=${current_url}`} replace />;
  } else {
    return children;
  }
};
export default ProtectedRoute;