import { compiledBlock as _compiledBlock } from "million/react";
const ProductGallery_1 = _compiledBlock(_props => <div>
      {_props.v0}
      <div id="product_gallery">
        <div className="product_gallery_wrap">
          {_props.v1}
        </div>
      </div>
      <br />
      {_props.v2}
      <br />
      {_props.v3}
    </div>, {
  name: "ProductGallery_1",
  portals: ["v0", "v1", "v2", "v3"]
});
const ProductGallery = function ProductGallery({
  product_id
}: {
  product_id: string;
}) {
  const {
    mutateAsync: createProductToMedia
  } = useCreateProduct_to_media();
  const {
    mutate: deleteMedia
  } = useDeleteProduct_to_media();
  const {
    refetch: getEntities,
    data: mediaList,
    isSuccess: loadProductToMediaSuccess,
    isError
  } = useGetProduct_to_medias({
    limit: 100,
    sort: 'media_order:asc',
    product_id: product_id || 'null'
  });
  const [entities, setEntities] = useState<TypedProduct_to_media[]>([]);
  useEffect(() => {
    if (mediaList) {
      let {
        body,
        totalItems
      } = mediaList;
      setEntities(body);
    }
  }, [mediaList]);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const mediaModal = useCallback(() => {
    setShowMediaModal(true);
  }, []);
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
  const validVideoTypes = ['video/mp4'];
  const removeThisElement = useCallback(async (id: string) => {
    try {
      setEntities(previousTags => previousTags.filter(previousTag => previousTag.id !== id));
      deleteMedia(id);
    } catch (e) {}
  }, []);
  const setTheseElement = useCallback(async (selectedMedia: string[]) => {
    selectedMedia.forEach(async (mi, index) => {
      let B = {
        product_id: product_id,
        media_id: mi,
        media_order: index
      };
      try {
        let mediaAfterCreate = await createProductToMedia(B);
        // bổ sung vào entities
        setEntities(oldData => [...oldData, ...[mediaAfterCreate]]);
      } catch (e) {}
    });
  }, []);
  return product_id ? /*@million jsx-skip*/<ProductGallery_1 v0={<MediaCenter show={showMediaModal} onClose={(selectedMedia: string[]) => {
    setShowMediaModal(false);
    setTheseElement(selectedMedia);
  }} />} v1={entities.length < 1 ? <Text as="p" tone="subdued" alignment="center">
              Chưa có gì ở đây cả!
            </Text> : entities.map((file, index) => {
    return <div className="product_gallery_element" key={`product_gallery_index_` + index}>
                  <img className="product_gallery_thumbnail" alt={file.media.media_filename} src={helpers.getMediaLink(file.media?.media_thumbnail ? file.media?.media_thumbnail['128x128'] : '')} />
                  <Button icon={XIcon} variant="plain" onClick={() => removeThisElement(file.id)}></Button>
                </div>;
  })} v2={<Divider />} v3={<Text as="p" alignment="center">
        <Button variant="plain" icon={UploadIcon} onClick={mediaModal}>
          Thêm ảnh và video
        </Button>
      </Text>} /> : null;
};
import { block as _block } from "million/react";
import React, { Suspense, forwardRef, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Divider, FooterHelp, Link, Text } from '@shopify/polaris';
import { DeleteIcon, NoteIcon, UploadIcon, SaveIcon } from '@shopify/polaris-icons';
import { XIcon } from '@shopify/polaris-icons';
import 'media/css/product_gallery.scss';
import MediaCenter from 'components/mediaCenter';
import helpers from 'helpers/index';
import placeholder_image_vector from 'media/images/placeholder_image_vector.svg';
import placeholder_video from 'media/images/placeholder_video.jpg';
import { TypedProduct_to_media, useCreateProduct_to_media, useDeleteProduct_to_media, useGetProduct_to_medias } from 'queries/product_to_media.query';
export default ProductGallery;