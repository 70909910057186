import { Page } from '@shopify/polaris';
import __, { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import ForLoggedInPeople from './forLoggedInPeople';

/************************************************************ *
 * MAINN
 * Private route
 ************************************************************ */

export default function Dashboard() {
  return <>
      <Helmet prioritizeSeoTags>
        <title>Dashboard</title>
      </Helmet>

      <Page>
        <ForLoggedInPeople />
      </Page>

      <br />
    </>;
}