import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import { TypedUser } from "interface/user.model";
import queryClient from 'queries';


export interface TypedUser_verified {
    id?: string;
    user_id?: string;
    cccd_front_image?: string;
    cccd_back_image?: string;
    user_face_image?: string;
    verified_status?: number;
    cccd_id?: string;
    user_note?: string;
    createdAt?: string;
    updatedAt?: string;
    updatedBy?: string;

    user?: TypedUser;
    confirmer?: TypedUser;
}



type IQuery = TypedUser_verified & IQueryParams;



/*
* List of all user verified
*/
export function useGetUserVerifiedRequest(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["user_verified/fetch_entities_list"],
        queryFn: () => axios.get<TypedUser_verified[]>(`/user_verified${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useGetUserSingleVerified(request_id: string) {
    return useQuery({
        queryKey: ['user_verified/fetch_entity'],
        queryFn: () => axios.get<TypedUser_verified>(`/user_verified/${request_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: false,
    });
}


export function useGetSingleVerifiedViaUserID(user_id: string) {
    return useMutation({
        mutationKey: ['user_verified/fetch_entity_via_user_id'],
        mutationFn: () => axios.get<TypedUser_verified>(`/user_verified/admin/${user_id}`).then((res) => res.data),
        retry: 1,
    });
}


export function useUpdateUserVerified() {
    return useMutation({
        mutationKey: ['user_verified/update_entity'],
        mutationFn: ({ id, ...rest }: TypedUser_verified) => axios.patch<any>(`user_verified/${id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_verified/fetch_entity_list'] });
        }
    });
}



export function useDeleteUserVerifiedRequest() {
    return useMutation({
        mutationKey: ['user_verified/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`user_verified/${id}`)
    });
}

