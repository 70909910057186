import { Card, DatePicker, Form, FormLayout, InlineGrid, Icon, Modal, Popover, TextField, Text, BlockStack } from "@shopify/polaris";
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from "@shopify/react-form";
import { useAppDispatch, useAppSelector } from "config/store";
import { useCallback, useEffect, useRef, useState } from "react";
import { createEntity } from "store/project.store.reducer";
import dateandtime from 'date-and-time';
import { CalendarIcon } from '@shopify/polaris-icons';
import QuickSearchUser from "components/quickSearchUser";
import helpers from 'helpers/index';
export default function ProjectNew({
  show,
  onClose
}: {
  show: boolean;
  onClose: Function;
}) {
  const loading = useAppSelector(state => state.project.loading);
  const updateSuccess = useAppSelector(state => state.project.updateSuccess);
  const dispatch = useAppDispatch();
  const onCloseModal = useCallback(() => {
    onClose?.call(this, null);
  }, []);
  const currentCustomer = useRef<any>(0);
  const useFields = {
    project_title: useField<string>({
      value: '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.'), inputValue => {
        if (inputValue.length < 2) {
          return 'Your title is too short, or it is empty.';
        }
      }]
    }),
    project_description: useField<string>({
      value: '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.'), inputValue => {
        if (inputValue.length < 2) {
          return 'Your description is too short, or it is empty.';
        }
      }]
    }),
    project_price: useField<string>({
      value: '',
      validates: [inputVal => {
        if (helpers.parseNumeric(inputVal) > 9000000000) {
          return 'Số tiền quá lớn.';
        }
      }, notEmpty('Trường giá được yêu cầu.')]
    }),
    project_deadline: useField<string>({
      value: '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      // create new 
      dispatch(createEntity({
        project_title: values.project_title,
        project_description: values.project_description,
        project_deadline: values.project_deadline,
        project_price: helpers.parseNumeric(values.project_price),
        customer_id: currentCustomer.current
      }));
      return {
        status: 'success'
      };
    }
  });

  /**
   * Date ...
   */
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [{
    month,
    year
  }, setDate] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear()
  });
  function handleMonthChange(month, year) {
    setDate({
      month,
      year
    });
  }
  function handleDateSelection({
    end: newSelectedDate
  }) {
    setSelectedDate(newSelectedDate);
    setVisible(false);
  }
  useEffect(() => {
    useFields.project_deadline.onChange(`` + selectedDate.getTime());
  }, [selectedDate]);
  const formattedValue = dateandtime.format(selectedDate, 'DD-MM-YYYY');

  /**
   * End date
   */

  useEffect(() => {
    if (updateSuccess) {
      Userreset();
      onCloseModal();
    }
  }, [updateSuccess]);
  useEffect(() => {
    Userreset();
  }, [show]);
  return <Modal open={show} title={"Thêm dự án mới"} onClose={onCloseModal} primaryAction={{
    content: 'Thêm mới',
    disabled: !dirty,
    loading: loading,
    onAction: () => submit()
  }}>
            <Modal.Section>
                <Form onSubmit={submit}>
                    <FormLayout>
                    <BlockStack gap={"500"}>
                        <TextField autoComplete="off" autoFocus label="Tên dự án" {...fields.project_title} />
                            
                        <TextField autoComplete="off" maxLength={250} label="Mô tả" {...fields.project_description} showCharacterCount={true} multiline={2} />

                        <InlineGrid gap={"300"} columns={{
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 2
            }}>
                            <TextField autoComplete="off" label="Giá trị dự án" value={helpers.formatNumberWithCommas(useFields.project_price.value)} onChange={e => useFields.project_price.onChange(e)} error={useFields.project_price.error} />

                            <Popover active={visible} autofocusTarget="none" preferredAlignment="left"
              // fullWidth
              preferInputActivator={false} preferredPosition="below" preventCloseOnChildOverlayClick onClose={() => setVisible(false)} activator={<TextField role="combobox" label={"Ngày hết hạn"} prefix={<Icon source={CalendarIcon} />} value={formattedValue} onFocus={() => setVisible(true)} onChange={null} autoComplete="off" />}>
                                <Card>
                                    <DatePicker month={month} year={year} selected={selectedDate} onMonthChange={handleMonthChange} onChange={handleDateSelection} disableDatesBefore={new Date()} />
                                </Card>
                            </Popover>

                        </InlineGrid>

                        <BlockStack gap={"200"}>
                            <Text as="p">Khách hàng</Text>
                            <QuickSearchUser title="" user_role="customer" current_user_id={null} onClose={_current_customer => currentCustomer.current = _current_customer.user_id} />
                        </BlockStack>


                    </BlockStack>
                    </FormLayout>
                </Form>
            </Modal.Section>
        </Modal>;
}