import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import { TypedUser } from "interface/user.model";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export interface TypedPosts {
    post_id?: number;
    createdBy?: number;
    post_name?: string;
    post_title?: string;
    post_excerpt?: string;
    post_content?: string;
    post_status?: "publish" | "draft" | "deleted" | "lock" | "future" | any;
    comment_status?: string | number;
    post_type?: string;
    post_parent?: number;
    comment_count?: number;
    post_tag?: string;
    post_thumbnail?: string;

    author?: TypedUser | null,
    createdAt?: any;
    updatedAt?: any;

    post_category?: any;
    post_to_category?: any;
    post_thumbnail_to_media?: TypedMedia;
}


export interface TypedPostCategory {
    category_id?: bigint;
    category_name?: string;
    category_description?: string;
    category_slug?: string;
    category_thumbnail?: string;
    category_order?: number;
    category_status?: number;
    createdAt?: string;

    category_thumbnail_to_media?: TypedMedia,

    _count?: {
        post_to_category: number
    }
}


type IQuery = TypedPosts & IQueryParams;


/*
* List of all app
*/
export function useGetPosts(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["posts/fetch_entity_list"],
        queryFn: () => axios.get<TypedPosts[]>(`/posts${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetPost(post_id: string) {
    return useQuery({
        queryKey: ['posts/fetch_entity'],
        queryFn: () => axios.get<TypedPosts>(`/posts/${post_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}
export function useMutateGetPost() {
    return useMutation({
        mutationKey: ['posts/fetch_entity'],
        mutationFn: (post_id: string) => axios.get<TypedPosts>(`/posts/${post_id}`).then((res) => res.data),
        retry: 1,
        // enabled: false,
    });
}


export function useUpdatePost() {
    return useMutation({
        mutationKey: ['posts/update_entity'],
        mutationFn: ({ post_id, ...rest }: TypedPosts) => axios.patch<any>(`/posts/${post_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['posts/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['posts/fetch_entity'] });
        }
    });
}


export function useCreatePost() {
    return useMutation({
        mutationKey: ['posts/create_entity'],
        mutationFn: (entity: TypedPosts) => axios.post<any>(`/posts`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['posts/fetch_entity_list'] });
        }
    });
}


export function useDeletePost() {
    return useMutation({
        mutationKey: ['posts/delete_entity'],
        mutationFn: (post_id: string) => axios.delete<any>(`/posts/${post_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['posts/fetch_entity_list'] });
        }

    });
}


export function useGetPostType() {
    return useQuery({
        queryKey: ['post_type/fetch_entity'],
        queryFn: () => axios.get(`/post_type/`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}