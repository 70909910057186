import { Autocomplete, Card, Icon } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getEntities } from 'store/email_setting.store.reducer';
import { SearchIcon } from "@shopify/polaris-icons";
import helpers from "helpers/index";
export default function QuickSearchEmailSetting({
  current_setting,
  title,
  error,
  onClose
}: {
  current_setting?: string | bigint;
  title?: string;
  error?: any;
  onClose: (val: string) => void;
}) {
  /**!SECTION
   * Select người handler
   */
  const dispatch = useAppDispatch();

  /**
   * Campaign ...
   */
  const loading = useAppSelector(state => state.email_setting.loading);
  const entities = useAppSelector(state => state.email_setting.entities);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    dispatch(getEntities({
      sort: 'createdAt:desc',
      server_status: 1,
      setting_type: 'smtp',
      limit: 5
    }));
  }, []);
  useEffect(() => {
    let innerCampaign = [];
    if (entities) for (let camp of entities) {
      innerCampaign.push({
        value: camp.id,
        label: camp.server_name
      });
      if (current_setting) if (camp.id === current_setting) {
        setUserQuickSearchText(camp.server_name);
      }
    }
    setUserListForQuickOption(innerCampaign);
  }, [entities]);
  const onQueryToServer = useCallback((keyword: string) => {
    dispatch(getEntities({
      query: keyword,
      sort: 'createdAt:desc',
      server_status: 1,
      setting_type: 'smtp',
      limit: 10
    }));
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    onClose?.call(this, selected[0]);
  }, [userListForQuickOption]);
  const textFieldSelectUser = <>
    <Autocomplete.TextField onChange={updateUserText} label={title ?? "Tài khoản gửi email"} labelHidden={!title} clearButton onClearButtonClick={() => {
      setUserQuickSearchText('');
      updateSelection([]);
    }} error={error} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm kiếm tài khoản Email" autoComplete="off" />
  </>;
  return <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={loading} />;
}