import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedCustomer_campaign_relationship } from '../interface/customer.model';

/**
*   Reducer used for front-end, with customer.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using customer.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null as TypedCustomer_campaign_relationship[],
  userEntities: null as any[],
  loadingUserEntities: false,
  entity: null as TypedCustomer_campaign_relationship,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};
const apiUrl = 'customer_campaign_relationship';

// Actions

export const getEntities = createAsyncThunk('customer_campaign_relationship/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});
export const reloadEntities = createAsyncThunk('customer_campaign_relationship/reloadEntities', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});
export const getUserEntities = createAsyncThunk('customer_campaign_relationship/fetch_user_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});
export const getEntity = createAsyncThunk('customer_campaign_relationship/fetch_entity', async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.get<TypedCustomer_campaign_relationship>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const createEntity = createAsyncThunk('customer_campaign_relationship/create_entity', async (entity: TypedCustomer_campaign_relationship, thunkAPI) => {
  return await axios.post<TypedCustomer_campaign_relationship>(`${apiUrl}`, helpers.cleanEntity(entity));
}, {
  serializeError: serializeAxiosError
});
export const updateEntity = createAsyncThunk('customer_campaign_relationship/update_entity', async (entity: TypedCustomer_campaign_relationship, thunkAPI) => {
  // patch
  let customerCampaignID = String(entity.customer_campaign_id);
  delete entity.customer_campaign_id;
  return await axios.put<TypedCustomer_campaign_relationship>(`${apiUrl}/${customerCampaignID}`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
}, {
  serializeError: serializeAxiosError
});
export const bulkUpdate = createAsyncThunk('customer_campaign_relationship/bulk_update', async (entity: {
  customer_campaign_id: String;
  color: String;
  pin: string;
}, thunkAPI) => {
  return await axios.post<TypedCustomer_campaign_relationship>(`${apiUrl}/bulk_update/`, helpers.cleanEntity(entity));
}, {
  serializeError: serializeAxiosError
});
export const partialUpdateEntity = createAsyncThunk('customer_campaign_relationship/partial_update_entity', async (entity: TypedCustomer_campaign_relationship, thunkAPI) => {
  let customerCampaignID = String(entity.customer_campaign_id);
  delete entity.customer_campaign_id;
  return await axios.patch<TypedCustomer_campaign_relationship>(`${apiUrl}/${customerCampaignID}`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
}, {
  serializeError: serializeAxiosError
});
export const updateManyEntities = createAsyncThunk('customer_campaign_relationship/update_many_entities', async (entity: TypedCustomer_campaign_relationship, thunkAPI) => {
  return await axios.patch<TypedCustomer_campaign_relationship>(`${apiUrl}/`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
}, {
  serializeError: serializeAxiosError
});
export const deleteEntity = createAsyncThunk('customer_campaign_relationship/delete_entity', async (id: string | number, thunkAPI) => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = axios.delete<any>(requestUrl);
  // thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});

// slice

export const Reducer_customer_campaign_relationship = createEntitySlice({
  name: 'customer_campaign_relationship',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null;
      state.updateSuccess = false;
    },
    clearEntity: state => {
      state.entity = null;
    },
    reset: state => {
      return {
        ...state,
        ...initialState
      };
    },
    resetUserEntities: state => {
      state.userEntities = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getEntity.fulfilled, (state, action) => {
      state.loading = false;
      state.entity = action.payload.data;
    }).addCase(getEntity.rejected, (state, action) => {
      state.loading = false;
      state.entity = null;
    }).addCase(createEntity.rejected, (state, action) => {
      state.loading = false;
      state.entity = null;
    }).addCase(bulkUpdate.fulfilled, (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
    }).addCase(bulkUpdate.pending, (state, action) => {
      state.updating = true;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    }).addCase(bulkUpdate.rejected, (state, action) => {
      state.updating = false;
      state.updateSuccess = false;
    }).addMatcher(isFulfilled(getEntities), (state, action) => {
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }).addMatcher(isFulfilled(reloadEntities), (state, action) => {
      return {
        ...state,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }).addMatcher(isFulfilled(getUserEntities), (state, action) => {
      return {
        ...state,
        loadingUserEntities: false,
        userEntities: action.payload.data
      };
    }).addMatcher(isRejected(getUserEntities), (state, action) => {
      state.loadingUserEntities = false;
      state.errorMessage = action.error.message;
    }).addMatcher(isRejected(createEntity, updateEntity, updateManyEntities, partialUpdateEntity, deleteEntity), (state, action) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.error.message;
    }).addMatcher(isFulfilled(partialUpdateEntity), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
    }).addMatcher(isFulfilled(createEntity, updateEntity, updateManyEntities), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = action.payload.data;
    }).addMatcher(isFulfilled(deleteEntity), (state, action) => {
      state.updating = false;
      //state.loading = false;
      state.updateSuccess = true;
      state.entity = null;
    }).addMatcher(isPending(deleteEntity), state => {
      state.updating = true;
      //state.loading = true;
      state.updateSuccess = false;
      state.entity = null;
    }).addMatcher(isPending(getEntity), state => {
      state.entity = null;
    }).addMatcher(isPending(getUserEntities), state => {
      state.userEntities = null;
      state.loadingUserEntities = true;
    }).addMatcher(isPending(getEntities, getEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loading = true;
    }).addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, updateManyEntities), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.updating = true;
    });
  }
});
export const {
  clearError,
  clearEntity,
  reset,
  resetUserEntities
} = Reducer_customer_campaign_relationship.actions;

// Reducer
export default Reducer_customer_campaign_relationship.reducer;