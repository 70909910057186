import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';

type TypeEmailOutgoing = {
  id?: string,
  title?: string,
  subject?: string,
  content?: string,
  email_template?: string,
  email_setting?: string,
  to_email?: string,
  cc?: string,
  bcc?: string,
  createdAt?: string,
  email_sent_schedule?: any;
  email_sent_status?: number;

}

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as TypeEmailOutgoing[],
  entity: null as TypeEmailOutgoing,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'email_outgoing';

// Actions

export const getEntities = createAsyncThunk('email_outgoing/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);


export const getEntity = createAsyncThunk('email_outgoing/fetch_entity_single', async (email_id: string) => {
  const requestUrl = `${apiUrl}/${email_id}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'email_outgoing/update_entity',
  async (entity: TypeEmailOutgoing) => {
    // patch
    entity = helpers.filterEmptyObject(entity);
    let email_id = String(entity.id);
    delete entity.id;
    return await axios.put<any>(`${apiUrl}/${email_id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateStatus = createAsyncThunk(
  'email_outgoing/update_status',
  async (entity: { id: string, email_sent_status: number }) => {
    let id = String(entity.id);
    delete entity.id;
    return await axios.patch<any>(`${apiUrl}/status/${id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk('email_outgoing/create_entity', async (entity: TypeEmailOutgoing) => {
  // patch
  entity = helpers.filterEmptyObject(entity);
  delete entity.id;
  return await axios.post<any>(`${apiUrl}`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'email_outgoing/delete_entity',
  async (file_name: string | number) => {
    const requestUrl = `${apiUrl}/${file_name}`;
    return await axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_System_email_outgoing = createEntitySlice({
  name: 'email_outgoing',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.totalItems = 0;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })

      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
        state.entity = null;
      })

      .addCase(updateStatus.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(updateStatus.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })

      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })


      .addCase(createEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(createEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })



      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })
  },
});

export const { clearError, reset } = Reducer_System_email_outgoing.actions;

// Reducer
export default Reducer_System_email_outgoing.reducer;
