import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedCustomer_campaign } from '../interface/customer.model';


/**
*   Reducer used for front-end, with customer.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using customer.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null as TypedCustomer_campaign[],
  entity: null as TypedCustomer_campaign,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  analytics: null as any,

  assignLoading: false,
  addAssigneeLoading: false,
  assignList: [] as any,

};

const apiUrl = 'customer_campaign';

// Actions

export const getEntities = createAsyncThunk('customer_campaign/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'customer_campaign/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<TypedCustomer_campaign>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'customer_campaign/create_entity',
  async (entity: TypedCustomer_campaign, thunkAPI) => {
    const result = await axios.post<TypedCustomer_campaign>(`${apiUrl}`, helpers.cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'customer_campaign/update_entity',
  async (entity: TypedCustomer_campaign, thunkAPI) => {
    // patch
    let customerCampaignID = String(entity.campaign_id);
    delete entity.campaign_id;

    const result = await axios.put<TypedCustomer_campaign>(`${apiUrl}/${customerCampaignID}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'customer_campaign/partial_update_entity',
  async (entity: TypedCustomer_campaign, thunkAPI) => {
    let customerCampaignID = String(entity.campaign_id);
    delete entity.campaign_id;
    const result = await axios.patch<TypedCustomer_campaign>(`${apiUrl}/${customerCampaignID}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'customer_campaign/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = axios.delete<any>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


export const getAnalytics = createAsyncThunk(
  'customer_campaign/getAnalytics',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/analytics/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);




/**
 * Assign
 */
export const getAssignList = createAsyncThunk('customer_campaign/assign/fetch_entity_list', async (campaign_id: string | bigint) => {
  const requestUrl = `${apiUrl}/assign/${campaign_id}`;
  return await axios.get<any>(requestUrl);
});

// add
export const addAssignee = createAsyncThunk('customer_campaign/assign/create_entity', async (entity: { user_id: any, campaign_id: any }) => {
  return await axios.post<any>(`${apiUrl}/assign/`, helpers.cleanEntity(entity));
});

// deleteAssign
export const deleteAssign = createAsyncThunk('customer_campaign/assign/delete_entity', async (assign_id: string | bigint) => {
  return await axios.delete<any>(`${apiUrl}/assign/${assign_id}`);
});




// slice

export const Reducer_customer_campaign_relationship = createEntitySlice({
  name: 'customer_campaign',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
    clearEntity: (state) => {
      state.entity = null
    },
    reset: (state) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })



      .addCase(getAssignList.fulfilled, (state, action) => {
        state.assignLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(getAssignList.pending, (state, action) => {
        state.assignLoading = true;
      })
      .addCase(getAssignList.rejected, (state, action) => {
        state.assignLoading = false;
        state.assignList = [];
        state.errorMessage = action.payload;
      })

      .addCase(addAssignee.fulfilled, (state, action) => {
        state.addAssigneeLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(addAssignee.pending, (state, action) => {
        state.addAssigneeLoading = true;
      })
      .addCase(addAssignee.rejected, (state, action) => {
        state.addAssigneeLoading = false;
        state.errorMessage = action.payload;
      })



      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.analytics = action.payload.data;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.analytics = [];
      })


      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })

      .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })
      .addMatcher(isFulfilled(partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        //state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        //state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities), state => {
        state.loading = null;
      })
      .addMatcher(isPending(getEntity), state => {
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })

  },
});

export const { clearError, clearEntity, reset } = Reducer_customer_campaign_relationship.actions;

// Reducer
export default Reducer_customer_campaign_relationship.reducer;
