import { DataTable, Icon, LegacyCard, Page, Toast, Text, Link, Popover, Button, TextField, FormLayout, Card } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import dateandtime from 'date-and-time';
import { ArchiveIcon } from '@shopify/polaris-icons';
import { createEntity, getEntities, clearError, reset, deleteEntity } from 'store/backup.store.reducer';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from 'helpers/index';
import { ___ } from 'languages/index';
export default function BackUp() {
  const entities = useAppSelector(state => state.backup.entities);
  const loading = useAppSelector(state => state.backup.loading);
  const updating = useAppSelector(state => state.backup.updating);
  const updateSuccess = useAppSelector(state => state.backup.updateSuccess);
  const totalItems = useAppSelector(state => state.backup.totalItems);
  const dispatch = useAppDispatch();
  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);
  useEffect(() => {
    dispatch(getEntities());
  }, []);
  const deleteFile = useCallback(file_name => {
    dispatch(deleteEntity(file_name));
  }, []);

  /**
   * Toast here meet an error ...
   */

  const toastSuccessMarkup = updateSuccess ? <Toast content={'Thành công!'} onDismiss={toggleActive} /> : null;
  const [textInLog, setTextInLog] = useState<string>('');
  const [showA, setShowA] = useState<string>(null);
  function showLog(file_name: string, logContent: string) {
    setShowA(file_name);
    setTextInLog(logContent);
  }
  function closeLog() {
    setShowA(null);
    setTextInLog('');
  }
  const items = item => {
    const {
      file_name,
      birthtime,
      size,
      file_log
    } = item;
    return [file_log ? <Popover active={showA === file_name} activator={<Button variant="plain" onClick={() => showLog(file_name, file_log)}>
              {file_name}
            </Button>} onClose={() => closeLog()} sectioned>
          <TextField value={textInLog} label="Log: " multiline={3} onChange={null} autoComplete="off" />
        </Popover> : file_name, helpers.bytesToSize(size / 1028), dateandtime.format(new Date(birthtime), 'DD/MM/YYYY hh:mm'), <Link url={process.env.REACT_APP_BACKEND_URL + '/utils/backup/' + file_name} target="_blank">
        {' '}
        Tải xuống
      </Link>, <Link onClick={() => deleteFile(file_name)}> Xóa bỏ</Link>];
  };
  return <>
      {toastSuccessMarkup}
      <Page title="Backup hệ thống" subtitle="Để đảm bảo an toàn, bạn không nên giao một bản backup cho người khác" secondaryActions={[{
      content: 'Bắt đầu backup',
      icon: ArchiveIcon,
      loading: updating,
      onAction: () => dispatch(createEntity({}))
    }]}>
        <LegacyCard>
          <DataTable columnContentTypes={['text', 'numeric', 'text', 'text', 'text']} headings={['Tên phiên bản', 'kích thước', 'Thời gian tạo', '', '']} rows={entities?.map(items)} hasZebraStripingOnData increasedTableDensity footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{entities?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </LegacyCard>
      </Page>
    </>;
}