import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedBlacklistDeviceUUID } from 'interface/blacklist.model';
import helpers from 'helpers/index';

export const initialState = {
    loading: false,
    entity: null as TypedBlacklistDeviceUUID,
    entities: null as TypedBlacklistDeviceUUID[],
    errorMessage: null as any, // Errors returned from server side
    updateSuccess: false,
    totalItems: 0,
};

export const getEntities = createAsyncThunk('blacklist_device_uuid/get_entities', async (object: IQueryParams) => {
    const EndURL = helpers.buildEndUrl(object);
    return await axios.get<any>('/blacklist_device_uuid' + EndURL);
},
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk('blacklist_device_uuid/delete_entity', async (ID: any) => {
    return await axios.delete<any>(`/blacklist_device_uuid/${ID}`);
},
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk('blacklist_device_uuid/create_entity', async (data: any) => {
    return await axios.post<TypedBlacklistDeviceUUID>(`/blacklist_device_uuid/`, data);
},
    { serializeError: serializeAxiosError }
);




export const blacklist_device_uuid = createEntitySlice({
    name: 'blacklist_device_uuid',
    initialState: initialState,
    reducers: {
        clearError: (state) => {
            state.errorMessage = null;
            state.updateSuccess = false;
        },
        reset: (state) => {
            return { ...state, ...initialState };
        }
    },

    extraReducers(builder) {
        builder

            .addCase(getEntities.fulfilled, (state, action) => {
                state.loading = false;
                state.entities = action.payload.data;
                state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
            })
            .addCase(getEntities.pending, (state, action) => {
                state.loading = true;
                // state.totalItems = 0;
            })
            .addCase(getEntities.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = action.payload;
                state.totalItems = 0
            })

            .addCase(deleteEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addCase(deleteEntity.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteEntity.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = action.payload;
            })

    },
});
export const { clearError, reset } = blacklist_device_uuid.actions;
// Reducer
export default blacklist_device_uuid.reducer;