import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';


/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

export interface TypedActivity {
  activity_id?: string; 
  device_id?: string; 
  activity_referrer?: string; 
  activity_target?: string; 
  activity_ip?: string; 
  device?: any;
  createdAt?: string; 
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as TypedActivity[],
  entity: null as TypedActivity,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'activity';

// Actions

export const getEntities = createAsyncThunk('activity/fetch_entity_list', async (object: any) => {
      const EndURL = helpers.buildEndUrl(object);
      const requestUrl = `${apiUrl}${EndURL}`;
      return await axios.get<any>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
    'activity/delete_entity',
    async (file_name: string | number, thunkAPI) => {
      const requestUrl = `${apiUrl}/${file_name}`;
      return await axios.delete<any>(requestUrl);
    },
    { serializeError: serializeAxiosError }
  );

// slice

export const Reducer_System_Activity = createEntitySlice({
  name: 'activity',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder
    
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.totalItems = 0;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })
    
      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })
  },
});

export const { clearError, reset } = Reducer_System_Activity.actions;

// Reducer
export default Reducer_System_Activity.reducer;
