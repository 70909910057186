import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice } from '../config/reducer.utils';
import { TypedUser } from 'interface/user.model';


export interface TypedUser_activity {
  activity_id?: string;
  user_id?: string;
  activity_type?: string;
  activity_source?: string;
  createdAt?: string;
  user?: TypedUser
}

type iQuery = TypedUser_activity & IQueryParams;

/**
*   Reducer used for front-end, with comment.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using comment.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null as TypedUser_activity[],
  totalItems: 0
};

const apiUrl = 'user_activity';

// Actions

export const getEntities = createAsyncThunk('user_activity/fetch_entity_list', async (object: iQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
});

// slice

export const Reducer_user_activity = createEntitySlice({
  name: 'user_activity',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.entities = [];
        state.errorMessage = action.payload
      })

  },
});

export const { clearError, reset } = Reducer_user_activity.actions;

// Reducer
export default Reducer_user_activity.reducer;
