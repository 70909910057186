import { Button, InlineStack, Modal, Text } from "@shopify/polaris";
import __ from "languages/index";
import { memo, useCallback, useEffect, useState } from "react";

/**
 * Show dialog and ask people ...
 */
const DialogConfirm = memo(function DialogConfirmModal({
  show,
  onClose,
  title,
  content,
  tone,
  ...args
}: {
  show: boolean;
  onClose: Function;
  title: string;
  content: string;
  tone?: "critical" | "success";
  args?: any;
}) {
  const [showS, setShowS] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setShowS(show);
  }, [show]);
  const closeOnly = useCallback(() => {
    setShowS(false);
    onClose?.call(this, false, args);
  }, []);
  const closeAndAgree = useCallback(async () => {
    setLoading(true);
    await onClose?.call(this, true, args);
    setLoading(false);
    // setShowS(false);
  }, []);
  return <Modal activator={null} open={showS} onClose={closeOnly} title={title}>
        <Modal.Section>
          {content}
        </Modal.Section>
        <Modal.Section>
          <InlineStack gap={'200'} align="end" blockAlign="center">
            <Button size="medium" onClick={closeOnly}>{__('cancel')}</Button>
            <Button variant="primary" onClick={closeAndAgree} size="medium" tone={tone} loading={loading}>{__('agree')}</Button>
          </InlineStack>
        </Modal.Section>
      </Modal>;
});
export default DialogConfirm;