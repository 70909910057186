import { compiledBlock as _compiledBlock } from "million/react";
const CustomerReport_1 = _compiledBlock(_props => <div>
        <div style={_props.v0}>

            {_props.v1}
            {_props.v2}
            <br />


            {_props.v3}
        </div>



        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {/** END MAINNNNN */}

      </div>, {
  name: "CustomerReport_1",
  portals: ["v1", "v2", "v3"]
});
const CustomerReport = function CustomerReport() {
  const customerGeneralAnalytics = useAppSelector(state => state.analytics.customerGeneralAnalytics);
  const loadingCustomerGeneralAnalytics = useAppSelector(state => state.analytics.loadingGetCustomerGeneralAnalytics);
  const dispatch = useAppDispatch();
  const [defaultDate, setDefaultDate] = useState<string>(`gte:${new Date(dateandtime.format(dateandtime.addDays(new Date(), -7), 'YYYY-MM-DD 00:00:00')).getTime()},
        lte:${new Date(dateandtime.format(new Date(), 'YYYY-MM-DD 23:59:59')).getTime()}`);
  useEffect(() => {
    dispatch(getCustomerGeneralAnalytics({
      createdAt: defaultDate
    }));
  }, [defaultDate]);
  const dateChangeCallback = useCallback(_e => {
    let {
      since,
      until
    } = _e.period;
    let FNS = [];
    if (since) {
      FNS.push(`gte:${new Date(dateandtime.format(since, 'YYYY-MM-DD 00:00:00')).getTime()}`);
    }
    if (until) {
      FNS.push(`lte:${new Date(dateandtime.format(until, 'YYYY-MM-DD 23:59:59')).getTime()}`);
    }
    setDefaultDate(FNS.join(','));
  }, []);
  const totalCustomersOption: ReactEChartsProps["option"] = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      show: false
    },
    grid: {
      left: 50,
      top: 20,
      right: 50,
      bottom: 50
    },
    xAxis: [{
      type: 'category',
      data: customerGeneralAnalytics?.customers?.data || [],
      axisPointer: {
        type: 'shadow'
      }
    }],
    yAxis: [{
      type: 'value',
      name: '',
      alignTicks: true,
      axisLabel: {
        formatter: '{value}'
      }
    }],
    series: [{
      name: 'Số khách',
      type: 'bar',
      // prettier-ignore
      data: customerGeneralAnalytics?.customers?.series || []
    }]
  };
  const SourceAddCustomer: ReactEChartsProps["option"] = {
    title: {
      text: 'Nguồn khách',
      top: 0
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0',
      left: 'center'
    },
    series: [{
      name: 'Truy cập từ',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: customerGeneralAnalytics.customer_source || []
    }]
  };
  const customerActivityAnalyticsChart: ReactEChartsProps["option"] = {
    title: {
      text: 'Tương tác',
      subtext: '',
      top: 0
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0%',
      left: 'center',
      selectedMode: false
    },
    series: [{
      name: 'Nguồn',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '70%'],
      // adjust the start angle
      startAngle: 180,
      label: {
        show: true,
        formatter(param) {
          // correct the percentage
          return param.name + ' (' + param.percent * 2 + '%)';
        }
      },
      data: customerGeneralAnalytics?.customer_activity || []
    }]
  };
  const geoNameConvert = customerGeneralAnalytics?.geo?.map(el => {
    return {
      name: diachinh[el.name]?.name ?? 'unknown',
      value: el.value
    };
  });
  const customerGeoChart: ReactEChartsProps["option"] = {
    title: {
      text: 'Vị trí địa lý',
      subtext: '',
      top: 0
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0%',
      left: 'center',
      selectedMode: false
    },
    series: [{
      name: 'Tỉnh/thành phố',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '50%'],
      roseType: 'area',
      itemStyle: {
        borderRadius: 8
      },
      label: {
        show: true,
        formatter(param) {
          // correct the percentage
          return param.name + ' (' + param.percent * 2 + '%)';
        }
      },
      data: geoNameConvert || []
    }]
  };
  const customerBuyInterval: ReactEChartsProps["option"] = {
    title: {
      text: 'Tần suất mua hàng',
      subtext: '',
      top: 0
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0%',
      left: 'center',
      selectedMode: false
    },
    series: [{
      name: 'Tần suất',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '50%'],
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: true,
        formatter(param) {
          // correct the percentage
          return param.name + ' (' + param.percent * 2 + '%)';
        }
      },
      data: customerGeneralAnalytics?.customerBuyInterval || []
    }]
  };
  return /*@million jsx-skip*/<CustomerReport_1 v0={{
    padding: '25px'
  }} v1={<Text as="h1" variant="heading2xl">Khách hàng</Text>} v2={<Text as="h3" variant="headingMd">Toàn cảnh chân dung khách hàng</Text>} v3={<InlineGrid gap="400" columns={{
    xs: 1,
    sm: 1,
    md: 1,
    lg: ['oneHalf', 'oneHalf'],
    xl: ['oneHalf', 'oneHalf']
  }}>

                <div className="dashboard_column_1_wrap">
                    <div style={{
        padding: '15px'
      }}>
                    <InlineStack wrap={false} gap={"200"} align="space-between" blockAlign="start">
                      
                      <InlineGrid columns={['oneThird', 'twoThirds']} alignItems="center" gap={"200"}>
                        <img src={require('media/images/4l.png')} width={"50px"} alt="" />
                        <BlockStack>
                        {loadingCustomerGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                            <Text as="h3" fontWeight="regular" variant="heading2xl">{customerGeneralAnalytics?.today} khách</Text>
                            <Text as="p">(hôm nay)</Text>
                          </>}

                        </BlockStack>
                      </InlineGrid>

                      <InlineGrid columns={['oneThird', 'twoThirds']} alignItems="center" gap={"200"}>
                        <img src={require('media/images/4l.png')} width={"50px"} alt="" />
                        <BlockStack>
                        {loadingCustomerGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                            <Text as="h3" fontWeight="regular" variant="heading2xl">{customerGeneralAnalytics?.customerConversionToday} đơn</Text>
                            <Text as="p">(hôm nay)</Text>
                          </>}
                        </BlockStack>
                      </InlineGrid>

                      <InlineGrid columns={['oneThird', 'twoThirds']} alignItems="center" gap={"200"}>
                        <img src={require('media/images/4l.png')} width={"50px"} alt="" />
                        <BlockStack>
                        {loadingCustomerGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                            <Text as="h3" fontWeight="regular" variant="heading2xl">{customerGeneralAnalytics?.lifetimeCount} khách</Text>
                            <Text as="p">(Lifetime)</Text>
                          </>}
                        </BlockStack>
                      </InlineGrid>

                    </InlineStack>
                    <br />
                    </div>

                    <Divider />
                    <div>
                        <br />
                        <InlineStack wrap={false} align="space-between" blockAlign="center">
                            <BlockStack>
                                <Text as="h3" variant="headingLg">Số khách</Text>
                                <Text as="p" variant="bodySm">Có tổng số {customerGeneralAnalytics?.totalCustomer || 0} khách đã hiển thị trong bảng thống kê.</Text>
                            </BlockStack>
                            <DateRangePicker onChange={dateChangeCallback} Alignment="right" />
                        </InlineStack>
                        <br /><br />

                        <ReactECharts settings={{
          notMerge: true
        }} option={totalCustomersOption} loading={loadingCustomerGeneralAnalytics} style={{
          minHeight: '400px'
        }} graphic={undefined} />

                    </div>


                </div>{/** dashboard_column_1_wrap */}

                <div className="dashboard_column_2_wrap">
                    <InlineGrid gap={"200"} columns={2}>
                      <div style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '1px 1px 1px #CCC'
        }}>
                        <ReactECharts settings={{
            notMerge: true
          }} option={SourceAddCustomer} loading={loadingCustomerGeneralAnalytics} style={{
            minHeight: '300px',
            padding: '15px'
          }} />
                      </div>
                      
                      <div style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '1px 1px 1px #CCC'
        }}>
                        {<ReactECharts settings={{
            notMerge: true
          }} loading={loadingCustomerGeneralAnalytics} option={customerActivityAnalyticsChart} style={{
            minHeight: '300px',
            padding: '15px'
          }} />}
                      </div>

                      <div style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '1px 1px 1px #CCC'
        }}>
                        {<ReactECharts settings={{
            notMerge: true
          }} loading={loadingCustomerGeneralAnalytics} option={customerGeoChart} style={{
            minHeight: '300px',
            padding: '15px'
          }} />}
                      </div>

                      <div style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '1px 1px 1px #CCC'
        }}>
                        {<ReactECharts settings={{
            notMerge: true
          }} loading={loadingCustomerGeneralAnalytics} option={customerBuyInterval} style={{
            minHeight: '300px',
            padding: '15px'
          }} />}
                      </div>
                    </InlineGrid>
                </div>{/** dashboard_column_2_wrap */}


            </InlineGrid>} />;
};
import { block as _block } from "million/react";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { Divider, Grid, InlineGrid, InlineStack, Select, SkeletonDisplayText, Text, BlockStack } from "@shopify/polaris";
import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import helpers from "../../helpers";
import { clearError, getCustomerGeneralAnalytics } from "../../store/analytics.store.reducer";
import { useAppDispatch, useAppSelector } from '../../config/store';
import DateRangePicker from "../../components/dateRangePicker";
import dateandtime from 'date-and-time';
import diachinh from '../../config/diachinh.json';
export default CustomerReport;