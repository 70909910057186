import { Card, Text, Link, Page, Toast, DataTable, Loading, Tooltip, Badge, Avatar, InlineGrid, InlineStack } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clearError, getEntities } from 'store/customer.store.reducer';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from '../../helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'components/pagination';
import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
import UserNewModel from '../customer/customer.new';
import Hotkeys from 'react-hot-keys';
import CustomerUploadModal from '../customer/customer.upload.modal';
import FilterAdvance from './filter';
import { CircleChevronUpIcon, PlusIcon } from '@shopify/polaris-icons';
import dateandtime from 'date-and-time';
import { TypedCustomer } from 'interface/customer.model';
import { Helmet } from 'react-helmet-async';
import __helpers from '../../helpers';
export default function CustomerList() {
  const entities = useAppSelector(state => state.customer.entities);
  const loading = useAppSelector(state => state.customer.loading);
  const totalItems = useAppSelector(state => state.customer.totalItems);
  const updating = useAppSelector(state => state.customer.updating);
  const updateSuccess = useAppSelector(state => state.customer.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const rootSearchPath = window.location.search;

  /**
   * New model
   */

  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);

  /**
   * Upload Modal
   */

  const [uploadModal, setUploadModal] = useState(false);
  const toggleShowUploadModal = useCallback(() => setUploadModal(true), []);
  const toggleClearError = useCallback(() => {
    dispatch(clearError());
  }, []);
  useEffect(() => {
    toggleClearError();
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  const reduceRequest = useCallback(mainQuery => {
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product', '/customer' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);
  const renderItem = (customerData: TypedCustomer) => {
    if (!customerData) return;
    const {
      user_id,
      display_name,
      bio,
      customer_to_user,
      user_phonenumber,
      user_email,
      user_avatar,
      createdAt,
      user_verified_phone,
      user_verified_email
    } = customerData;
    const customer_display_name = display_name || user_email || user_phonenumber || 'noname';
    return [<Link key={user_id} removeUnderline url={'/customer/view/' + user_id}>
        <div className={customer_to_user?.is_vip ? 'has_vip' : ''}>
          <InlineStack gap="200" align="start" blockAlign="center">
            <Avatar source={user_avatar ? __helpers.getMediaLink(user_avatar) : null} name={customer_display_name} initials={customer_display_name.charAt(0)} size="sm" />
            <Text fontWeight="bold" as="p">
              {helpers.trimMiddleString(customer_display_name)}{' '}
            </Text>
          </InlineStack>
        </div>
      </Link>, user_phonenumber ? user_phonenumber : '-', user_email ? user_email : <Badge></Badge>, customer_to_user?.customer_order_count, helpers.formatNumber(customer_to_user?.customer_moneyspent_count, ','), createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm') : '-'];
  };
  const toastSuccessMarkup = updateSuccess ? <Toast content={__('updated_successfully')} onDismiss={toggleClearError} /> : null;
  return <>
      <Helmet>
        <title>Danh sách khách hàng</title>
      </Helmet>
      {entities === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title="Khách hàng" compactTitle primaryAction={{
      content: 'Thêm khách hàng',
      icon: PlusIcon,
      onAction: toggleNewActive
    }} secondaryActions={[{
      content: 'Tải lên',
      icon: CircleChevronUpIcon,
      onAction: toggleShowUploadModal
    }]}>
          <Card padding="0">
            <FilterAdvance loading={loading} onCallback={filterCallback} />
            <DataTable sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric', 'text']} headings={['Tên khách hàng', 'Điện thoại', 'Email', 'Số đơn', 'Đã chi tiêu', 'Tạo lúc']} rows={entities?.map(renderItem)} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
          </Card>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
        </Page>}

      {toastSuccessMarkup}

      <UserNewModel onClose={() => {
      setNewModelactive(false);
      dispatch(getEntities(mainQuery));
    }} show={newModelactive} />

      {uploadModal ? <CustomerUploadModal onClose={() => setUploadModal(false)} /> : null}

      <Hotkeys keyName="shift+enter" onKeyDown={(keyname: any, event: any, _handler) => {
      event.preventDefault();
      toggleNewActive();
    }} />
    </>;
}