import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedLogistic_service {
    logistic_id?: string;
    logistic_name?: string;
    module?: string;
    logistic_description?: string;
    logistic_count?: number;
    logistic_delivery_method?: string;
    logistic_fee?: number;
    logistic_api_require_field?: string;
    logistic_api_require_field_array?: string[];
    logistic_status?: number;
    createdAt?: string;
}

export const logistic_api_require_fields = ['logistic_token', 'logistic_username', 'logistic_password', 'logistic_phonenumber', 'logistic_shop_id'];



type IQuery = TypedLogistic_service & IQueryParams;


/*
* List of all logistics
*/
export function useGetLogistics(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["logistic_service/fetch_entity_list"],
        queryFn: () => axios.get<TypedLogistic_service[]>(`/logistic_service${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}

/*
* List of all logistics
*/
export function useMutateGetLogistics() {
    return useMutation({
        mutationKey: ["logistic_service/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedLogistic_service[]>(`/logistic_service${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}



export function useGetLogistic(logistic_id: string) {
    return useQuery({
        queryKey: ['logistic_service/fetch_entity'],
        queryFn: () => axios.get<TypedLogistic_service>(`/logistic_service/${logistic_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useMutateGetLogistic() {
    return useMutation({
        mutationKey: ['logistic_service/fetch_entity'],
        mutationFn: (logistic_id: string) => axios.get<TypedLogistic_service>(`/logistic_service/${logistic_id}`).then((res) => res.data),
        retry: 1,
        // enabled: false,
    });
}


export function useUpdateLogistic() {
    return useMutation({
        mutationKey: ['logistic_service/update_entity'],
        mutationFn: ({ logistic_id, ...rest }: TypedLogistic_service) => axios.patch<any>(`/logistic_service/${logistic_id}`, rest).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['logistic_service/fetch_entity_list'] });
        }
    });
}


export function useCreateLogisticService() {
    return useMutation({
        mutationKey: ['logistic_service/create_entity'],
        mutationFn: (entity: TypedLogistic_service) => axios.post<any>(`/logistic_service`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['logistic_service/fetch_entity_list'] });
        }
    });
}


export function useDeleteLogistic() {
    return useMutation({
        mutationKey: ['logistic_service/delete_entity'],
        mutationFn: (logistic_id: string) => axios.delete<any>(`/logistic_service/${logistic_id}`)
    });
}