import { Form, Modal, Select, TextField } from '@shopify/polaris';
import helpers from 'helpers/index';
import { useCreateOrder_fee, useGetOrderFeeList } from 'queries/order_fee.query';
import { useCallback, useEffect, useState } from 'react';
export default function ShowCustomFee({
  order_id,
  onClose,
  show
}) {
  /**
   * Danh sách phí ...
   */

  const {
    data: orderFeeList
  } = useGetOrderFeeList();
  const {
    mutateAsync: createEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useCreateOrder_fee();
  useEffect(() => {
    if (updateSuccess === true) {
      onClose();
    }
  }, [updateSuccess]);
  const [selected, setSelected] = useState<string>('');
  const handleSelectChange = useCallback((value: string) => setSelected(value), []);
  const [selectlist, setSelectlist] = useState(null);
  const options = [];
  useEffect(() => {
    if (orderFeeList) {
      for (let e in orderFeeList) {
        options.push({
          label: orderFeeList[e] + ` (${e})`,
          value: e
        });
      }
      setSelectlist(options);
      setSelected(options[0].value);
    }
  }, [orderFeeList]);
  const [inputFee, setInputFee] = useState('1000');
  const handleFeeInputChange = useCallback((newValue: string) => setInputFee(helpers.formatNumberWithCommas(newValue)), []);
  const handleSaveChange = useCallback(() => {
    createEntity({
      order_fee_name: String(selected),
      order_fee_value: helpers.parseNumeric(inputFee),
      order_id: order_id
    });
  }, [createEntity, selected, inputFee, order_id]);
  return <Modal activator={null} open={show} onClose={onClose} title="Thêm chi phí cho đơn hàng này" primaryAction={{
    content: 'Lưu lại',
    loading: updating,
    disabled: updating,
    onAction: handleSaveChange
  }} secondaryActions={[{
    content: 'Đóng',
    onAction: onClose
  }]}>
      <Modal.Section>
        <Form onSubmit={handleSaveChange}>
          <Select label="Vui lòng chọn: " labelInline options={selectlist} onChange={handleSelectChange} value={selected} />

          <br />

          <TextField label="Tiền phí" autoFocus value={inputFee} onChange={handleFeeInputChange} autoComplete="off" error={helpers.parseNumeric(inputFee, 0) > 100000000 && 'Chú ý phí quá cao'} />
        </Form>
      </Modal.Section>
    </Modal>;
}