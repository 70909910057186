import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppliedFilterInterface, FilterInterface, Filters } from '@shopify/polaris';
import helpers from 'helpers/index';
export interface IPostsFiltersProps {
  queryValue?: string | null;
  onChange(query: string): void;
}

/**
 * Will be remove
 * @deprecated - use other library ...
 * @param props 
 * @returns 
 */
export default function QuickSearchFilter(props: IPostsFiltersProps) {
  const {
    onChange,
    queryValue
  } = props;
  const ready = useRef(false);
  const startCallback = useCallback(() => {
    ready.current = true;
  }, []);
  const [queryString, setQueryString] = useState<string | null>(queryValue ?? '');
  const setQueryValueCallback = useCallback((_value: string) => setQueryString(_value), []);
  const onChangeCallback = useMemo(() => helpers.debounce(_value => onChange?.call(this, _value), 800), []);
  useEffect(() => {
    if (ready.current) onChangeCallback(queryString);
  }, [queryString]);
  function clearAll() {
    setQueryString('');
    onChangeCallback('');
  }
  const filters = [] as FilterInterface[];
  const appliedFilters = [] as AppliedFilterInterface[];
  return <Filters onQueryFocus={startCallback} queryPlaceholder="Search" queryValue={queryString} onQueryChange={setQueryValueCallback} onQueryClear={() => clearAll()} filters={filters} appliedFilters={appliedFilters} onClearAll={() => clearAll()} />;
}
;