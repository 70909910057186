import { DataTable, EmptyState, LegacyCard, Link, Page } from '@shopify/polaris';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import { TypedShopping_cart, useGetShoppingCarts } from 'queries/shopping_cart.query';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import emptyIMG from '../../media/images/empty.png';
import dateandtime from 'date-and-time';
import SimpleFilter from 'components/simpleFilter';
export default function ShoppingCartList() {
  let history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'lastUpdated:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/shopping_cart' + buildURLSearch);
  }, [mainQuery]);
  const {
    data: all_entities,
    isLoading
  } = useGetShoppingCarts(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntities] = useState<TypedShopping_cart[] | null>(null);
  useEffect(() => {
    if (all_entities) {
      let {
        body,
        totalItems
      } = all_entities;
      setTotalItems(totalItems);
      setEntities(body);
    }
  }, [all_entities]);
  const EmptyData = () => <EmptyState heading="No shopping cart here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  return <>
      <Helmet>
        <title>Giỏ hàng</title>
      </Helmet>
      {helpers.isEmpty(entities) ? <EmptyData /> : <Page title="Quản lý giỏ hàng" subtitle="Quản lý tất cả giỏ hàng của người dùng">
          <LegacyCard>
            <SimpleFilter loading={isLoading} onCallback={filterCallback} options={[]} sortField={[{
          label: 'Giá trị',
          field: 'total_value'
        }, {
          label: 'Số món',
          field: 'total_quantity'
        }]} />

            <DataTable verticalAlign="middle" columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['User', 'Tổng giá', 'Tổng số món', 'Created At', 'last Update']} rows={entities.map((el, index) => {
          return [<Link url={`/shopping_cart/${el.user_id}`}>{el.user.display_name}</Link>, helpers.formatNumber(el.total_value), helpers.formatNumber(el.total_quantity), dateandtime.format(new Date(Number(el.createdAt)), 'DD-MM-YYYY'), dateandtime.format(new Date(Number(el.lastUpdated)), 'DD-MM-YYYY')];
        })} />
          </LegacyCard>
        </Page>}
      <br />
      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
    </>;
}