import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";



/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedProduct_to_media {
    id?: string,
    product_id?: string,
    media_id?: string,
    media_order?: number,
    media?: TypedMedia
}


type IQuery = TypedProduct_to_media & IQueryParams;


/*
* List of all app
*/
export function useGetProduct_to_medias(object: IQuery) {
    let { product_id, ...rest } = object;
    const EndURL = helpers.buildEndUrl(rest);
    return useQuery({
        queryKey: ["product_to_media/fetch_entity_list"],
        queryFn: () => axios.get<TypedProduct_to_media[]>(`/product_to_media/${product_id}/${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}


export function useCreateProduct_to_media() {
    return useMutation({
        mutationKey: ['product_to_media/create_entity'],
        mutationFn: (entity: TypedProduct_to_media | TypedProduct_to_media[]) => axios.post<any>(`product_to_media/`, (entity)).then(res => res.data),
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: ['product_to_media/fetch_entity_list'] });
        }
    });
}


export function useDeleteProduct_to_media() {
    return useMutation({
        mutationKey: ['product_to_media/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`product_to_media/${id}`),
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: ['product_to_media/fetch_entity_list'] });
        }
    });
}


