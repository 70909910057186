import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { useGetCurrentUserData } from './queries/user.query';
import { TypedUser } from 'interface/user.model';
import __helpers from './helpers';
interface AuthContextType {
  user: TypedUser | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);
const AuthProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [user, setUser] = useState<TypedUser | null>(null);
  const [start_display_children, setStart_display_children] = useState(false);
  const {
    data,
    error,
    isLoading,
    isFetched
  } = useGetCurrentUserData();
  const startSetData = useCallback(async () => {
    if (data) {
      setUser(data);
    }
    if (error) {
      setUser(null);
    }
    await __helpers.sleep(1000);
    setStart_display_children(true);
  }, [data, error]);
  useEffect(() => {
    if (isFetched) startSetData();
  }, [isFetched]);

  /** Trường hợp bị log out ? OEE */
  // useEffect(() => {
  //   if (isRefetchError) {
  //     console.log('User has been log out ...');
  //   }
  // }, [isRefetchError]);

  return <AuthContext.Provider value={{
    user,
    isAuthenticated: !!user,
    isAuthenticating: isLoading
  }}>
      {start_display_children && children}
    </AuthContext.Provider>;
};

/** return {user: TypedUser | null, isAuthenticated: boolean, isAuthenticating: boolean} */
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
export { AuthProvider, useAuth };