import React, { useEffect, useRef } from 'react';

/**
 * Function like UseEffect, but not RUN at initial load, it is so important to avoid duplicated loading ...
 * @param func 
 * @param deps 
 */
export default function useDidMountEffect(func, deps) {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}
