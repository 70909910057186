import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
// import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import { TypedProduct } from "interface/product.model";
import queryClient from 'queries';
import { TypedProduct_variant } from "./product_variant.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/


/**
*   Interface/model file auto generate for Order_product
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedOrder_product {
    order_product_id?: string;
    order_id?: string;
    quantity?: number;
    product_id?: string;
    variant_id?: string;
    price?: number;
    original_price?: number;

    product?: TypedProduct;
    product_variant?: TypedProduct_variant

}


/*
* List of all product in order
*/
export function useGetOrderProducts(order_id: string) {
    return useQuery({
        queryKey: ["order_product/fetch_entity_list"],
        queryFn: () => axios.get<TypedOrder_product[]>(`/order_product/${order_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useUpdateOrderProduct() {
    return useMutation({
        mutationKey: ['order_product/update_entity'],
        mutationFn: ({ order_product_id, ...rest }: TypedOrder_product) => axios.patch<TypedOrder_product>(`/order_product/${order_product_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_product/fetch_entity_list'] });
        }
    });
}


export function useCreateOrderProduct() {
    return useMutation({
        mutationKey: ['order_product/create_entity'],
        mutationFn: ({ order_id, ...rest }: TypedOrder_product) => axios.post<TypedOrder_product>(`/order_product/${order_id}`, helpers.cleanEntity(rest)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_product/fetch_entity_list'] });
        }
    });
}


export function useDeleteOrderProduct() {
    return useMutation({
        mutationKey: ['order_product/delete_entity'],
        mutationFn: ({ order_product_id }: TypedOrder_product) => axios.delete<any>(`/order_product/${order_product_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_product/fetch_entity_list'] });
        }

    });
}
