import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Card, EmptyState, IndexTable, LegacyCard, Link, Popover, SkeletonTabs, Spinner, Text, BlockStack } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import emptyIMG from 'media/images/empty.png';
import helpers from 'helpers';
import Pagination from 'components/pagination';
import CustomerDetailPopup from '../../../components/customer.detail.popup';
import { useLocation } from 'react-router-dom';
import { getAnalytics } from 'store/customer_campaign.store.reducer';
import { useAppDispatch, useAppSelector } from 'config/store';
import { getEntities as customerCampaignGetEntities, reloadEntities, reset as customerCampaignReset } from 'store/customer_campaign_relationship.store.reducer';
import CustomerCampaignFilter from './filter';
import { getEntities, bulkUpdate, updateEntity } from 'store/customer_campaign_relationship.store.reducer';
import { TypedCustomer_campaign_relationship } from 'interface/customer.model';
import QuickSearchUser from 'components/quickSearchUser';
import NoteComponent from 'components/noteComponent';
import { useCreateOrder, useMutateGetOrderDetail, useUpdateOrder } from 'queries/orders.query';
import { useCreateOrderProduct } from 'queries/order_product.query';

/**
 * ĐẶC BIỆT CHÚ Ý:
 * @Note component này để lại một chút code vớ vẩn như đánh dấu màu cho ticket, di chuyển vị trí ...
 * Cái đó sẽ được sử dụng cho bản kanban ...
 * @param param0 D
 * @returns
 */

export default function CampaignCustomerList({
  campaign_id
}) {
  const {
    mutate: addProductToOrder
  } = useCreateOrderProduct();
  const {
    mutateAsync: orderCreateEntity,
    data: newOrderData
  } = useCreateOrder();
  const {
    mutateAsync: updateOrder
  } = useUpdateOrder();
  const {
    data: entityOrder
  } = useMutateGetOrderDetail();
  const rootData = useAppSelector(state => state.customer_campaign_relationship.entities);
  const entity = useAppSelector(state => state.customer_campaign_relationship.entity);
  const loading = useAppSelector(state => state.customer_campaign_relationship.loading);
  // const errorMessage = useAppSelector((state) => state.customer_campaign_relationship.errorMessage);
  const totalItems = useAppSelector(state => state.customer_campaign_relationship.totalItems);
  const updating = useAppSelector(state => state.customer_campaign_relationship.updating);
  const updateSuccess = useAppSelector(state => state.customer_campaign_relationship.updateSuccess);
  const dispatch = useAppDispatch();

  /**
   * Clone ra làm việc cho dế!
   */
  const [entities, setEntities] = useState<any[]>([]);
  useEffect(() => {
    setEntities(rootData);
  }, [rootData]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  useEffect(() => {
    dispatch(getAnalytics(campaign_id));
  }, []);

  /**
   * Khi update một record, chúng ta có thể update vào thẳng trong data, đừng gọi lại thêm kẻo tốn băng thông!
   */

  useEffect(() => {
    if (!updateSuccess) return;
    if (!entity) return;
    let cloneDataInternal = entities.map(el => {
      if (el.customer_campaign_id === entity.customer_campaign_id) {
        return {
          ...el,
          ...entity
        };
      }
      return el;
    });
    mergeData(cloneDataInternal);
  }, [updateSuccess, entity]);

  /**
   * For other update function merge data to main entity
   */
  const mergeData = useCallback((data: any[]) => {
    setEntities(data);
  }, []);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    campaign_id: campaign_id,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Lấy thông tin Data của một
   */
  useEffect(() => {
    if (campaign_id) {
      dispatch(customerCampaignReset());
      dispatch(customerCampaignGetEntities(mainQuery));
    }
  }, [mainQuery]);

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  const reduceRequest = useCallback(mainQuery => {
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  const emptyData = <EmptyState heading="Chưa có khách hàng!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;

  /** Convert to status */

  /** Convert to status */
  /**
   * Case 0: pending: nháp
   * Case 1: inprocess: hẹn gọi lại
   * Case 4: lost: Lost
   * Case 5: spam: SPAM
   * Case 9: OK
   * @param statusNum
   * @returns
   */
  function showBadgeCustomerCampaignStatus(statusNum: number) {
    switch (statusNum) {
      case 1:
        return <Badge progress="partiallyComplete" tone="warning">
            Hẹn lại
          </Badge>;
      case 4:
        return <Badge progress="incomplete">Không Gọi được</Badge>;
      case 5:
        return <Badge progress="incomplete" tone="attention">
            SPAM
          </Badge>;
      case 9:
        return <Badge tone="success" progress="complete">
            OK
          </Badge>;
      default:
        return <>
            <Badge></Badge>
            <Badge></Badge>
          </>;
    }
  }
  function getOrderStatusBadge(_status: number): any {
    switch (_status) {
      case 0:
        return <Badge tone="new">Bản nháp</Badge>;
      case 1:
        return <Badge tone="warning">Hủy đơn</Badge>;
      case 2:
        return <Badge tone="success">Chốt đơn</Badge>;
      default:
        return;
    }
  }
  function getPaymentStatusBadge(status: number) {
    switch (status) {
      case 0:
        return <Badge>Chưa thanh toán</Badge>;
      case 1:
        return <Badge tone={'warning'} progress={'partiallyComplete'}>
            T.Toán một phần
          </Badge>;
      case 2:
        return <Badge tone={'success'} progress={'complete'}>
            Đã thanh toán
          </Badge>;
      case 3:
        return <Badge tone="critical" progress="incomplete">
            Chờ hoàn tiền
          </Badge>;
      case 4:
        return <Badge tone="critical" progress="incomplete">
            Hoàn tiền
          </Badge>;
      default:
        return <Badge></Badge>;
    }
  }

  /**
   * Creat order in a quick
   */
  const [creatingOrder, setCreatingOrder] = useState(false);
  const createOrderWithProduct = useCallback(async (user_id: string | any, customer_campaign_id: any, customer_campaign_relationship_id: any, product_id: any) => {
    setCreatingOrder(customer_campaign_relationship_id);
    let createOrderData: any = await orderCreateEntity({
      user_id: user_id,
      customer_campaign: customer_campaign_id,
      customer_campaign_relationship_id: customer_campaign_relationship_id
    }).then(async () => {
      if (product_id) addProductToOrder({
        order_id: createOrderData.payload.data.order_id,
        product_id: product_id
      });
    }).catch(e => {});
    setCreatingOrder(false);
  }, []);

  // newOrderData

  useEffect(() => {
    if (!newOrderData) return;
    let cloneDataInternal = entities.map(el => {
      if (el.customer_campaign_id === newOrderData.customer_campaign_relationship_id) {
        return {
          ...el,
          orders: [newOrderData]
        };
      }
      return el;
    });
    mergeData(cloneDataInternal);
  }, [newOrderData]);

  /**!SECTION
   * Quick update, update for many function
   */

  const quickUpdateCallback = useCallback(async (_val: TypedCustomer_campaign_relationship) => {
    return await dispatch(updateEntity(_val));
  }, []);

  /**
   * Change Note ...
   */

  const [notyValue, setNotyValue] = useState(''); /** Dùng chung ... */
  const [showPophoverNote, setShowPophoverNote] = useState(null);
  const onClosePophoverCallback = useCallback((customer_campaign_id: any) => {
    setShowPophoverNote(!customer_campaign_id);
    setNotyValue('');
  }, []);
  const togglePophoverCallback = useCallback((_s: any, order_note: string) => {
    setShowPophoverNote(showPophoverNote === _s ? false : _s);
    setNotyValue(order_note);
  }, [showPophoverNote]);
  const submitNoteCallback = useCallback(async (value: string) => {
    let R: any = await quickUpdateCallback({
      customer_campaign_id: showPophoverNote,
      customer_campaign_note: value
    });
    if (typeof R.error === 'undefined') {
      setShowPophoverNote(null);
      setNotyValue('');
    }
  }, [showPophoverNote]);

  /**
   * Note for orders ...
   */

  const [showPophoverOrderNote, setShowPophoverOrderNote] = useState(null);
  const onClosePophoverOderCallback = useCallback((customer_campaign_id: any) => {
    setShowPophoverOrderNote(!customer_campaign_id);
    setNotyValue('');
  }, []);
  const togglePophoverOrderCallback = useCallback((_s: any, order_note: string) => {
    setShowPophoverOrderNote(showPophoverNote === _s ? false : _s);
    setNotyValue(order_note);
  }, [showPophoverNote]);
  const submitNoteOrderCallback = useCallback(async (inputvalue: string, order_id: any) => {
    await updateOrderAndPullDownToData(order_id, {
      order_note: String(inputvalue)
    });
    setShowPophoverOrderNote(null);
    setNotyValue('');
  }, []);

  /**
   * Change Assignee
   */
  const [showPophoverAssign, setShowPophoverAssign] = useState(null);
  const [updatingAssign, setUpdatingAssign] = useState<any>(null);
  const onClosePophoverAssignCallback = useCallback((customer_campaign_id: any) => {
    setShowPophoverAssign(!customer_campaign_id);
  }, []);
  const togglePophoverAssignCallback = useCallback((_s: any) => {
    setShowPophoverAssign(showPophoverAssign === _s ? false : _s);
  }, [showPophoverAssign]);

  /**
   * For button only ...
   */
  const [showPophoverStatus, setShowPophoverStatus] = useState(null);
  const togglePophoverStatusCallback = useCallback(async (customer_campaign_id: any, field_name: string) => {
    setShowPophoverStatus(customer_campaign_id + `` + field_name);
  }, []);
  const onClosePophoverStatusCallback = useCallback(() => {
    setShowPophoverStatus(null);
  }, []);
  const changeCampaignStatus = useCallback(async (customer_campaign_id: any, new_status: number, field_name: string) => {
    onClosePophoverStatusCallback();
    await quickUpdateCallback({
      ...{
        customer_campaign_id: customer_campaign_id
      },
      ...{
        [field_name]: new_status
      }
    });
  }, []);

  /**
   * Note for order ...
   */

  useEffect(() => {
    if (!entityOrder) return;
    let cloneDataInternal = entities.map(el => {
      return {
        ...el,
        orders: el.orders?.map((o: any) => {
          if (o.order_id === entityOrder.order_id) {
            return entityOrder;
          }
          return o;
        })
      };
    });
    mergeData(cloneDataInternal);
  }, [entityOrder]);
  const updateOrderAndPullDownToData = useCallback(async (order_id: string | number, _other: {
    [key: string]: string | number | boolean;
  }) => {
    updateOrder({
      ...{
        order_id: order_id
      },
      ..._other
    }).catch(e => {});
  }, []);

  /**
   * Order status
   */

  const [showPopupForChangeOrderStatus, setShowPopupForChangeOrderStatus] = useState('');
  const changeOrderStatus = useCallback((order_id: any, new_status: number) => {
    setShowPopupForChangeOrderStatus('');
    updateOrderAndPullDownToData(order_id, {
      order_status: Number(new_status)
    });
  }, []);

  /**
   * END order Status
   */

  const [showCustomerPopupEdit, setShowCustomerPopupEdit] = useState(0);
  const toggleQuickCustomerModelEdit = useCallback((customerCampaignID: number) => setShowCustomerPopupEdit(customerCampaignID), []);

  /**
   * Add to callback
   */
  const buttonOptionChangeStatusCallback = (customer_campaign_id: any, field_name: string, current_value: number) => {
    return current_value > 0 ? showBadgeCustomerCampaignStatus(current_value) : <Popover key={customer_campaign_id + `` + field_name} active={showPophoverStatus === customer_campaign_id + `` + field_name} activator={<Link onClick={() => togglePophoverStatusCallback(customer_campaign_id, field_name)}>{showBadgeCustomerCampaignStatus(current_value)}</Link>} onClose={() => onClosePophoverStatusCallback()} ariaHaspopup={false} sectioned>
        <BlockStack gap={'200'}>
          <Link removeUnderline onClick={() => changeCampaignStatus(customer_campaign_id, 1, field_name)}>
            {showBadgeCustomerCampaignStatus(1)}
          </Link>
          <Link removeUnderline onClick={() => changeCampaignStatus(customer_campaign_id, 4, field_name)}>
            {showBadgeCustomerCampaignStatus(4)}
          </Link>
          <Link removeUnderline onClick={() => changeCampaignStatus(customer_campaign_id, 5, field_name)}>
            {showBadgeCustomerCampaignStatus(5)}
          </Link>
          <Link removeUnderline onClick={() => changeCampaignStatus(customer_campaign_id, 9, field_name)}>
            {showBadgeCustomerCampaignStatus(9)}
          </Link>
        </BlockStack>
      </Popover>;
  };

  // hold ctr / command for multiselect
  const multiselect = useRef(false);

  // click to select
  const [rowStatus, setRowStatus] = useState([]);
  useEffect(() => {
    let rowStatus = localStorage.getItem('rowStatus');
    if (helpers.isJson(rowStatus)) setRowStatus(JSON.parse(rowStatus));
  }, []);
  const toggleRowStatus = useCallback((rowID: any) => {
    if (multiselect.current === false) {
      setRowStatus([rowID]);
    } else {
      setRowStatus(rowStatus => {
        if (rowStatus.includes(rowID)) {
          return rowStatus.filter(x => x !== rowID);
        }
        return [...rowStatus, ...[rowID]];
      });
    }
  }, [multiselect]);

  // test
  const a = useRef([]);
  useEffect(() => {
    localStorage.setItem('rowStatus', JSON.stringify(rowStatus));
    a.current = rowStatus;
  }, [rowStatus]);
  // end test

  /**
   * Nút action nhanh
   */

  const actionOnContextMenu = useCallback(async (detail: any) => {
    dispatch(bulkUpdate({
      customer_campaign_id: a.current.join(','),
      color: detail.color || undefined,
      pin: detail.pin || undefined
    }));
    reduceRequestMemo(mainQuery);
  }, [a]);
  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Control' || event.key === 'Meta') {
        multiselect.current = true;
      }
    });
    document.addEventListener('keyup', function (event) {
      if (event.key === 'Control' || event.key === 'Meta') {
        multiselect.current = false;
      }
    });
    function C(event: CustomEvent) {
      actionOnContextMenu(event.detail);
    }
    window.removeEventListener('contextmenu_in_action', C);
    window.addEventListener('contextmenu_in_action', C);
  }, []);
  const rowMarkup = entities && entities.map((element, index) => {
    let customer_campaign_id = element.customer_campaign_id,
      user_id = element.user_id || '',
      campaign_id = element.campaign_id || '',
      customer_campaign_status = element.customer_campaign_status || 0,
      customer_campaign_status_1 = element.customer_campaign_status_1 || 0,
      customer_campaign_status_2 = element.customer_campaign_status_2 || 0,
      customer_campaign_note = element.customer_campaign_note || '',
      assign_for = element.assign_for || '',
      color = element.color || '',
      createdAt = element.createdAt || '',
      customer = element.user_customer_campaign_relationship_user_idTouser;
    let colorText = (color: string) => {
      return ['black', 'red', 'green', 'blue'].indexOf(color) > -1 ? 'Polaris_table_tr_light_text' : 'Polaris_table_tr_dark_text';
    };
    return <IndexTable.Row id={customer_campaign_id} key={customer_campaign_id + '_fullscreen_tab'} position={index} tone={rowStatus.includes(customer_campaign_id) ? 'subdued' : null} onClick={() => toggleRowStatus(customer_campaign_id)}
    // selected={selectedResources.includes(customer_campaign_id)}
    >
          <IndexTable.Cell>
            <Text as="span">{customer_campaign_id}</Text>
          </IndexTable.Cell>
          {/**Số thứ tự */}
          <IndexTable.Cell>{createdAt && dateandtime.format(new Date(Number(createdAt)), 'DD-MM')}</IndexTable.Cell>
          {/**Ngày */}
          <IndexTable.Cell>{createdAt && dateandtime.format(new Date(Number(createdAt)), 'hh:mm')}</IndexTable.Cell>
          {/**Giờ */}
          <IndexTable.Cell>
            <Link url="" removeUnderline onClick={() => toggleQuickCustomerModelEdit(user_id)}>
              {customer.display_name}
            </Link>
          </IndexTable.Cell>
          {/** Khach hang */}
          <IndexTable.Cell>{customer ? customer.user_phonenumber : ''}</IndexTable.Cell>
          {/** Số điện thoại */}
          <IndexTable.Cell>{element?.customer_source}</IndexTable.Cell>
          {/** Nguồn */}
          <IndexTable.Cell>
            {customer_campaign_status_2 !== 0 ? <Text as="span">{element?.user?.user_login || 'Chưa giao'}</Text> : <Popover active={showPophoverAssign === customer_campaign_id} activator={<Button variant="plain" disclosure={'down'} loading={updatingAssign === customer_campaign_id} onClick={() => togglePophoverAssignCallback(customer_campaign_id)}>
                    {element?.user?.user_login || 'Chưa giao'}
                  </Button>} autofocusTarget="first-node" onClose={onClosePophoverAssignCallback}>
                <Card>
                  <QuickSearchUser title="" user_role="user" current_user_id={element?.user?.user_id} onClose={async (userData: any) => {
              let __newUser = userData.user_id;
              onClosePophoverAssignCallback(customer_campaign_id);
              setUpdatingAssign(customer_campaign_id);
              await quickUpdateCallback({
                customer_campaign_id: customer_campaign_id,
                assign_for: String(__newUser)
              });
              setUpdatingAssign(null);
            }} />
                </Card>
              </Popover>}
          </IndexTable.Cell>
          {/** Giao choa */}
          <IndexTable.Cell>
            {buttonOptionChangeStatusCallback(customer_campaign_id, 'customer_campaign_status', customer_campaign_status)}
          </IndexTable.Cell>
          {/** Tình trạng tư vấn lần 1 */}
          <IndexTable.Cell>
            {buttonOptionChangeStatusCallback(customer_campaign_id, 'customer_campaign_status_1', customer_campaign_status_1)}
          </IndexTable.Cell>
          {/** Tình trạng tư vấn lần 2 */}
          <IndexTable.Cell>
            {buttonOptionChangeStatusCallback(customer_campaign_id, 'customer_campaign_status_2', customer_campaign_status_2)}
          </IndexTable.Cell>
          {/** Tình trạng tư vấn lần 3 */}
          <IndexTable.Cell className="sale_order_note">
            {<Popover active={showPophoverNote === customer_campaign_id} activator={<Link removeUnderline onClick={() => togglePophoverCallback(customer_campaign_id, customer_campaign_note)}>
                    {customer_campaign_note || <>
                        <Badge></Badge>
                        <Badge></Badge>
                      </>}
                  </Link>} onClose={() => onClosePophoverCallback(customer_campaign_id)} ariaHaspopup={false} sectioned>
                <NoteComponent label="Ghi chú" default_string={notyValue} onSave={submitNoteCallback} args />
              </Popover>}
          </IndexTable.Cell>
          {/** Ghi chú */}
          <IndexTable.Cell>{element?.customer_campaign?.product?.product_name}</IndexTable.Cell>
          {/** Sản phẩm */}
          <IndexTable.Cell>{element?.customer_campaign?.campaign_name}</IndexTable.Cell>
          {/** Chiến dịch */}

          <IndexTable.Cell>{element?.orders[0]?.order_pnr ?? <Badge></Badge>}</IndexTable.Cell>
          {/** Mã đơn hàng */}
          <IndexTable.Cell>
            {element?.orders[0]?.order_status > 0 ? getOrderStatusBadge(element?.orders[0]?.order_status) : <Popover key={customer_campaign_id + `order_` + customer_campaign_id} active={showPopupForChangeOrderStatus === customer_campaign_id} activator={<Link onClick={() => setShowPopupForChangeOrderStatus(customer_campaign_id)}>
                      {getOrderStatusBadge(element?.orders[0]?.order_status)}
                    </Link>} onClose={() => setShowPopupForChangeOrderStatus('')} ariaHaspopup={false} sectioned>
                  <BlockStack gap={'200'}>
                    <Link removeUnderline onClick={() => changeOrderStatus(element?.orders[0]?.order_id, 1)}>
                      {getOrderStatusBadge(1)}
                    </Link>
                    <Link removeUnderline onClick={() => changeOrderStatus(element?.orders[0]?.order_id, 2)}>
                      {getOrderStatusBadge(2)}
                    </Link>
                  </BlockStack>
                </Popover>
        // getOrderStatusBadge(element?.orders[0]?.order_status)
        }
          </IndexTable.Cell>
          {/** Trạng thái đơn */}
          <IndexTable.Cell>{getPaymentStatusBadge(element?.orders[0]?.payment_status)}</IndexTable.Cell>
          {/** Thanh toán */}
          <IndexTable.Cell className="sale_order_note">
            {element?.orders[0]?.order_id ? <Popover active={showPophoverOrderNote === customer_campaign_id} activator={<Link removeUnderline onClick={() => togglePophoverOrderCallback(customer_campaign_id, element?.orders[0]?.order_note || '')}>
                    {element?.orders[0]?.order_note || <>
                        <Badge></Badge>
                        <Badge></Badge>
                      </>}
                  </Link>} onClose={() => onClosePophoverOderCallback(customer_campaign_id)} ariaHaspopup={false} sectioned>
                <NoteComponent label="Ghi chú đơn hàng" default_string={notyValue} onSave={submitNoteOrderCallback} args={element?.orders[0]?.order_id} />
              </Popover> : <Text as="span">
                <Badge></Badge>
                <Badge></Badge>
              </Text>}
          </IndexTable.Cell>
          {/** Ghi chú */}
          <IndexTable.Cell>
            {element?.orders[0]?.order_pnr ? <Link target="_blank" removeUnderline url={'/order/view/' + element?.orders[0]?.order_id}>
                {element?.orders[0]?.order_pnr}
              </Link> : <Link removeUnderline monochrome onClick={() => createOrderWithProduct(customer.user_id, element.campaign_id, customer_campaign_id, element?.customer_campaign?.product?.product_id)}>
                {creatingOrder === customer_campaign_id ? <Spinner accessibilityLabel="Loading" size="small" /> : <>
                    <Badge></Badge>
                    <Badge></Badge>
                  </>}
              </Link>}
          </IndexTable.Cell>
          {/** Mã đơn */}
        </IndexTable.Row>;
  });
  const PagesList = entities && entities.length > 0 ? <>
        <IndexTable resourceName={{
      singular: 'đơn hàng',
      plural: 'đơn hàng'
    }} loading={loading} emptyState={<Text as="p">CHƯA CÓ DỮ LIỆU...</Text>} itemCount={entities?.length || 0} headings={[{
      title: 'STT'
    }, {
      title: 'Ngày'
    }, {
      title: 'Giờ'
    }, {
      title: 'Khách hàng'
    }, {
      title: 'Số điện thoại'
    }, {
      title: 'Nguồn'
    }, {
      title: 'Giao cho'
    }, {
      title: 'Gọi lần 1'
    }, {
      title: 'Gọi lần 2'
    }, {
      title: 'Gọi lần 3'
    }, {
      title: 'Ghi chú'
    }, {
      title: 'Sản phẩm'
    }, {
      title: 'Chiến dịch'
    }, {
      title: 'Mã Đơn hàng'
    }, {
      title: 'Tình trạng đơn'
    }, {
      title: 'Thanh toán'
    }, {
      title: 'Ghi chú đơn hàng'
    }, {
      title: 'Mã đơn'
    }]} lastColumnSticky selectable={false}>
          {rowMarkup || []}
        </IndexTable>
      </> : entities === null ? <SkeletonTabs /> : emptyData;
  return <>
      <LegacyCard>
        <CustomerCampaignFilter loading={loading || updating} onCallback={filterCallback} />
        {PagesList}
      </LegacyCard>
      <br />
      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePageNumber} /> : null}

      <CustomerDetailPopup onClose={() => {
      setShowCustomerPopupEdit(0);
      dispatch(reloadEntities(mainQuery));
    }} show={showCustomerPopupEdit} />
    </>;
}