import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'config/store';
import { getAssignList, deleteAssign, addAssignee } from 'store/customer_campaign.store.reducer';
import { BlockStack, Text, InlineStack, Tag, Icon, Popover, Card, SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris';
import { PlusIcon } from '@shopify/polaris-icons';
import QuickSearchUser from 'components/quickSearchUser';
import Capacity from 'components/Capacity';
export default function UserAssignToCustomerCampaign() {
  const entity = useAppSelector(state => state.customer_campaign.entity);
  const assignLoading = useAppSelector(state => state.customer_campaign.assignLoading);
  const addAssigneeLoading = useAppSelector(state => state.customer_campaign.addAssigneeLoading);
  const assignList = useAppSelector(state => state.customer_campaign.assignList);
  const dispatch = useAppDispatch();
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    setSelectedTags(assignList);
  }, [assignList]);
  useEffect(() => {
    if (!entity) return;
    dispatch(getAssignList(entity?.campaign_id));
  }, [entity]);
  const removeTag = useCallback((tag: any) => () => {
    setSelectedTags(previousTags => previousTags.filter(previousTag => previousTag.id !== tag.id));
    dispatch(deleteAssign(tag.id));
  }, []);
  const [showAddnewUser, setShowAddnewUser] = useState(false);
  const addNewAssignee = useCallback(async user_id => {
    dispatch(addAssignee({
      campaign_id: entity?.campaign_id,
      user_id: user_id
    }));
  }, [entity]);
  return <>
      {assignLoading ? <SkeletonBodyText /> : <BlockStack gap={'200'}>
          <Text as="h3" variant="headingMd" key="CustomerAssign_title">
            Thành viên
          </Text>
          <InlineStack gap={'200'} align="start" blockAlign="center">
            {/* {
                        entity?.author_data && (
                            <Tag>{entity?.author_data?.display_name || entity?.author_data?.user_email }</Tag>
                        )
                    } */}
            {Array.isArray(selectedTags) && selectedTags.length > 0 && selectedTags.map((e, index) => {
          return <Tag key={index + 'taglist'} onRemove={removeTag(e)}>
                    {e?.assignee?.display_name}
                  </Tag>;
        })}

            {addAssigneeLoading && <SkeletonThumbnail size="extraSmall" />}
            <Capacity current_user_can={'sale_admin'}>
              <Popover active={showAddnewUser} activator={<Tag onClick={() => setShowAddnewUser(true)}>
                    <InlineStack align="start" blockAlign="center" gap="200">
                      <span key="add_new_assignee">Thêm nhân viên</span>
                      <Icon source={PlusIcon} />
                    </InlineStack>
                  </Tag>} autofocusTarget="first-node" onClose={() => setShowAddnewUser(false)}>
                <Card>
                  <QuickSearchUser title="" support_filter_user_role={true} onClose={(userData: any) => {
                let __newUser = userData.user_id;
                addNewAssignee(__newUser);
                setShowAddnewUser(false);
              }} />
                </Card>
              </Popover>
            </Capacity>
          </InlineStack>
          <Text as="p" variant="bodySm" tone="subdued" key="CustomerAssign_subtitle">
            Những người trong danh sách phân quyền này sẽ có thể xem và chỉnh sửa dữ liệu.
          </Text>
        </BlockStack>}
    </>;
}