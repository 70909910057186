export default function errorMiddlewareHandle() {

  return next => action => {
    /**
       *
       * The error middleware serves to log error messages from dispatch
       * It need not run in production
       */
    if (process.env.NODE_ENV === 'development') {
      const { error } = action;
      if (error) {
        console.error(`${action.type} caught at middleware with reason: ${JSON.stringify(error, null, 4)}.`);
      }
    }

    // Dispatch initial action
    return next(action);
  }
}