import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import { TypedNotification } from "interface/notification.model";
import queryClient from 'queries';

type IQuery = TypedNotification & IQueryParams;


/*
* List of all notification
*/
export function useGetNotifications(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["notification"],
        queryFn: () => axios.get<TypedNotification[]>(`/notification${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


export function useGetNotifitcation() {
    return useMutation({
        mutationKey: ['notification/fetch_entity'],
        mutationFn: (notification_id: string) => axios.get<TypedNotification>(`/notification/${notification_id}`).then((res) => res.data),
    });
}

export function useCreateNotification() {
    return useMutation({
        mutationKey: ['notification/create_entity'],
        mutationFn: (entity: TypedNotification) => axios.post<TypedNotification>(`/notification/`, helpers.cleanEntity(entity)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notification'] });
        }
    });
}