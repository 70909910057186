import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export interface TypedUser_address_book {
    user_address_book_id?: string | bigint;
    user_id?: string;
    user_fullname?: string;
    user_phonenumber?: string;
    user_address?: string;
    user_address_city?: string;
    user_address_district?: string;
    user_address_ward?: string;
    user_address_type?: string;
    is_default?: number;
    createdAt?: string | bigint;
}


type IQuery = TypedUser_address_book & IQueryParams;


/*
* List of all app
*/
export function useGetUserAddressBooks(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["user_address_book/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser_address_book[]>(`/user_address_book/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useDeleteUserAddressBooks() {
    return useMutation({
        mutationKey: ['user_address_book/delete_entity'],
        mutationFn: (user_address_book_id: string) => axios.delete<any>(`/user_address_book/admin/${user_address_book_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_address_book/fetch_entity_list'] });
        }

    });
}