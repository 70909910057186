import { Badge, Button, Card, DataTable, Text } from '@shopify/polaris';
import { TypedUser_address_book, useDeleteUserAddressBooks, useGetUserAddressBooks } from 'queries/user_address_book';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PhoneIcon, DeleteIcon } from '@shopify/polaris-icons';
import DeleteConfirmModal from 'components/deleteConfirm';
import helpers from 'helpers/index';
export default function CustomerAddressBook({
  user_id
}) {
  const {
    data
  } = useGetUserAddressBooks({
    limit: 20,
    user_id: user_id,
    sort: 'createdAt:desc'
  });
  const [entities, setEntities] = useState<TypedUser_address_book[]>(null);
  useEffect(() => {
    if (!data) return;
    let {
      totalItems,
      body
    } = data;
    setEntities(body);
  }, [data]);

  // delete ...
  const {
    mutateAsync: deleteUserAddressBook
  } = useDeleteUserAddressBooks();
  const [showModalDeleteConfirm, setShowModalDeleteConfirm] = useState(false);
  const current_delete_id = useRef(null);
  const deleteCallback = useCallback(async id => {
    current_delete_id.current = id;
  }, [current_delete_id.current]);
  return <>
      <DeleteConfirmModal show={showModalDeleteConfirm} onClose={async (agreeOrnot: boolean) => {
      if (agreeOrnot) {
        await deleteUserAddressBook(current_delete_id.current);
        current_delete_id.current = null;
      }
      setShowModalDeleteConfirm(false);
    }} title={'Bạn có chắc muốn xóa địa chỉ này?'} />
      <Card padding={'0'}>
        <DataTable stickyHeader verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']} headings={['-', 'Tên liên hệ', 'Địa chỉ', 'Xã', 'Huyện', 'Tỉnh']} rows={entities?.map(element => {
        return [<Button variant="plain" icon={DeleteIcon} onClick={() => deleteCallback(element.user_address_book_id)}></Button>, <>
                  <Text fontWeight="bold" as="p">
                    {element.user_fullname}
                  </Text>
                  {element.user_phonenumber && <Button variant="plain" icon={PhoneIcon}>
                      {element.user_phonenumber}
                    </Button>}
                </>, <>
                  <Badge>{element.user_address_type}</Badge> {element.user_address}
                </>, helpers.getDiaChinhAsync(element.user_address_ward)?.name_with_type, helpers.getDiaChinhAsync(element.user_address_district)?.name_with_type, helpers.getDiaChinhAsync(element.user_address_city)?.name_with_type];
      }) || []} />
      </Card>
    </>;
}