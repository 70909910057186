import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { BlockStack, Button, Card, Checkbox, DataTable, Form, Grid, Link, Page, Tabs, Text, TextField } from '@shopify/polaris';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useField, useForm } from '@shopify/react-form';
import { SaveIcon, DeleteIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import DeleteConfirmModal from 'components/deleteConfirm';
import TextFieldRichText from 'components/TextFieldRichText';
import { useClearGameCheckinSession, useGetGame, useGetGameCheckinRank, useUpdateGame } from 'queries/game.query';
import __ from 'languages/index';
export default function GameViewCheckin() {
  const {
    mutateAsync: updateEntity
  } = useUpdateGame();
  const {
    data: entity
  } = useGetGame('checkin');
  const {
    mutateAsync: clearGameCheckinSession
  } = useClearGameCheckinSession();
  const {
    mutateAsync: getGameCheckinRank
  } = useGetGameCheckinRank();
  const QuickSearchWallet = React.lazy(() => import('components/quickSearchWallet'));
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    if (entity) {
      let {
        game_setting
      } = entity;
      setSettings(game_setting);
    }
  }, [entity]);
  const history = useNavigate();
  let {
    hash
  } = useLocation();
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    window.location.hash = '#tab-' + selectedTabIndex;
  }, []);
  const tabs = useMemo(() => [{
    id: 'tab_0',
    content: 'Bảng xếp hạng',
    panelID: 'top_10'
  }, {
    id: 'tab_1',
    content: 'Cài đặt',
    panelID: 'setting'
  }, {
    id: 'tab_2',
    content: 'Mã nhúng',
    panelID: 'embed_code'
  }], []); // Empty dependency array means this will only run once on mount

  useEffect(() => {
    if (hash) {
      let tabb = Number(String(hash || ' ').replace('#tab-', ''));
      if (tabs[tabb] === undefined) tabb = 0;
      setTabselected(tabb);
    } else {
      setTabselected(0);
    }
  }, [hash, tabs]);
  const [topTenData, setTopTenData] = useState([]);
  const loadTopTenData = useCallback(async () => {
    try {
      let data = await getGameCheckinRank();
      setTopTenData(data);
    } catch (e) {}
  }, []);
  useEffect(() => {
    loadTopTenData();
  }, []);
  const TopTen = () => {
    return <DataTable columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['Người chơi', 'Điểm', 'Rank']} rows={Array.isArray(topTenData) && topTenData.length > 0 ? topTenData.map((element, index) => {
      return [<Link url={'/users/view/' + element.user_id}>{element.user.display_name}</Link>, element.count, index + 1];
    }) : []} />;
  };
  const EmbedCode = useCallback(() => {
    return <Card>
        <BlockStack gap="400">
          <Text as="p" fontWeight="bold">
            Yêu cầu chung khi tham gia game này: Người dùng phải đăng nhập.
          </Text>

          <Text as="p">Trước tiên, kiểm tra xem người dùng đã join hay chưa:</Text>
          <TextField value="POST {{backend_url}}/game/check_in/check_join" selectTextOnFocus label="" readOnly autoComplete="off" />

          <Text as="p">Sau đó, người dùng tham gia chương trình, để tham gia:</Text>
          <TextField value="POST {{backend_url}}/game/check_in/join" selectTextOnFocus label="" readOnly autoComplete="off" />

          <Text as="p">Để lấy danh sách checkin của một người hoặc kiểm tra xem người nào đó có checkin hay chưa, dùng endpoint sau đây:</Text>
          <TextField value="GET {{backend_url}}/game/check_in/my_checkin" selectTextOnFocus label="" readOnly autoComplete="off" />

          <Text as="p">Để check-in, truy vấn như sau: </Text>
          <TextField value="POST {{backend_url}}/game/check_in/do_checkin" selectTextOnFocus label="" readOnly autoComplete="off" />
          <Text as="p">Kết quả trả về: Mã thành công 2xx hoặc mã lỗi, nếu người đó đã check-in rồi. </Text>
          <Text as="p">Để lấy bảng xếp hạng, truy vấn như sau:</Text>
          <TextField value="GET {{backend_url}}/game/check_in/rank" selectTextOnFocus label="" readOnly autoComplete="off" />
        </BlockStack>
      </Card>;
  }, []);
  const GameSetting = () => {
    const useFields = {
      game_status: useField<number>({
        value: entity?.game_status || 0,
        validates: []
      }),
      game_title: useField<string>({
        value: entity?.game_title ?? '',
        validates: []
      }),
      game_excerpt: useField<string>({
        value: entity?.game_excerpt ?? '',
        validates: []
      }),
      game_description: useField<string>({
        value: entity?.game_description ?? '',
        validates: []
      }),
      checkin_time_from: useField<string>({
        value: String(settings?.checkin_time_from ?? '8'),
        validates: [inputVal => {
          if (!helpers.isNumeric(inputVal)) return 'Số không hợp lệ';
        }]
      }),
      checkin_time_to: useField<string>({
        value: String(settings?.checkin_time_to ?? '21'),
        validates: [inputVal => {
          if (!helpers.isNumeric(inputVal)) return 'Số không hợp lệ';
        }]
      }),
      checkin_winner_value: useField<string>({
        value: String(settings?.checkin_winner_value ?? 25),
        validates: [inputValue => {
          if (!helpers.isNumeric(inputValue)) return 'Phải là một số hợp lệ!';
        }]
      }),
      checkin_winner_wallet: useField<string>({
        value: settings?.checkin_winner_wallet ?? '',
        validates: []
      })
    };
    const {
      fields,
      submit,
      submitting,
      dirty
    } = useForm({
      fields: useFields,
      async onSubmit(values) {
        try {
          await updateEntity({
            id: entity?.id,
            game_status: values.game_status,
            // game_title: values.game_title,
            game_excerpt: values.game_excerpt,
            game_description: values.game_description,
            game_setting: {
              checkin_time_from: String(values.checkin_time_from),
              checkin_time_to: String(values.checkin_time_to),
              checkin_winner_value: String(values.checkin_winner_value),
              checkin_winner_wallet: values.checkin_winner_wallet
            }
          });
          await helpers.sleep(2000);
        } catch (e: any) {
          if (e.params !== undefined) {
            if (e.params.field !== undefined) {
              useFields[e.params.field].setError(__('check_this_field_again'));
            }
          }
        }
        return {
          status: 'success'
        };
      }
    });
    return <Form onSubmit={submit}>
        <Card>
          <BlockStack gap="400">
            <Checkbox label="Cho phép chạy game" helpText="Nếu tắt, toàn bộ hoạt động cũng dừng theo" checked={fields.game_status.value === 1} onChange={e => fields.game_status.onChange(Number(e))} />

            <TextField readOnly label="Tên của Game này" autoComplete="off" {...fields.game_title} />
            <TextField label="Mô tả ngắn" autoComplete="off" {...fields.game_excerpt} />
            <TextFieldRichText label={'Nội dung / hướng dẫn'} initialData={entity?.game_description} onCallback={html => fields.game_description.onChange(html)} />

            <Text as="h2" variant="headingSm">
              Khoảng thời gian cho phép
            </Text>
            <Grid>
              <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                <TextField label="Thời gian cho phép từ" suffix="(24 giờ)" type="number" autoComplete="off" {...fields.checkin_time_from} />
              </Grid.Cell>
              <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                <TextField label="Đến mấy giờ?" suffix="(24 giờ)" autoComplete="off" type="number" {...fields.checkin_time_to} />
              </Grid.Cell>
            </Grid>

            <Grid>
              <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                <TextField label="Khi tham gia, họ sẽ được?" suffix="Điểm" autoComplete="off" {...fields.checkin_winner_value} />
              </Grid.Cell>

              <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                <Suspense fallback={<Text as="span">Đợi một chút</Text>}>
                  <QuickSearchWallet title="Ví đựng điểm" current_wallet_id={settings?.checkin_winner_wallet} onClose={checkin_winner_wallet_id => useFields.checkin_winner_wallet.onChange(checkin_winner_wallet_id)} />
                </Suspense>
              </Grid.Cell>
            </Grid>
          </BlockStack>
        </Card>

        <br />
        <Button onClick={submit} disabled={!dirty} loading={submitting} icon={SaveIcon}>
          Cập nhật
        </Button>
      </Form>;
  };
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const clearSessionCallback = useCallback(() => {
    setShowDeleteConfirm(p => !p);
  }, []);
  const [clearingData, setClearingData] = useState(false);
  const clearSessionDispatch = useCallback(async () => {
    setClearingData(true);
    await clearGameCheckinSession();
    await helpers.sleep(1000);
    setClearingData(false);
  }, []);
  return <>
      <Helmet>
        <title>Điểm danh nhận quà</title>
      </Helmet>
      <DeleteConfirmModal show={showDeleteConfirm} onClose={(condition: boolean) => {
      clearSessionCallback();
      if (condition === true) clearSessionDispatch();
    }} title={'Xóa toàn bộ dữ liệu mùa'} />
      <Page narrowWidth title={entity?.game_title} backAction={{
      content: 'Quay lại',
      onAction: () => history('/game')
    }} primaryAction={{
      loading: clearingData,
      icon: DeleteIcon,
      content: 'Clear session Data',
      onAction: clearSessionCallback
    }}>
        <div className="custom_tabs" style={{
        margin: '0 -15px'
      }}>
          <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>
          <div style={{
          margin: '15px'
        }}>
            {tabs[tabselected].panelID === 'top_10' && <TopTen />}
            {tabs[tabselected].panelID === 'setting' && <GameSetting />}
            {tabs[tabselected].panelID === 'embed_code' && <EmbedCode />}
          </div>
        </div>
        <br />
        <br />
        <br />
      </Page>
    </>;
}