import { compiledBlock as _compiledBlock } from "million/react";
const PopoverWithSearchableListbox_1 = _compiledBlock(_props => <div>
      {_props.v0}
      <br />
      {_props.v1}
      <br />
      {_props.v2}
      <br />
      {_props.v3}
      <br />
    </div>, {
  name: "PopoverWithSearchableListbox_1",
  portals: ["v0", "v1", "v2", "v3"]
});
const PopoverWithSearchableListbox = function PopoverWithSearchableListbox({
  current_product_id
}: {
  current_product_id: string;
}) {
  /** Gán một nhóm variant vào trong product */
  const {
    mutate: assignVariantGroupToProduct,
    isPending: isAssigning
  } = useCreateVariantGroup();
  /** Lấy danh sách variant group đã được gán */
  const {
    data: listOfProductVariantAssigned,
    isPending: loadingEntities
  } = useGetVariantGroups({
    product_id: current_product_id
  });
  /** Xóa danh sách  */
  const {
    mutate: deleteAssignVariantID
  } = useDeleteVariantGroup();
  /** Tạo hàng loạt */
  const {
    mutate: createBulkVariant,
    isPending: bulkCreatingProductVariant
  } = useBulkCreateProductVariant();
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);
  const deleteVariantAssigned = useCallback((variant_assigned_id: string) => {
    deleteAssignVariantID(variant_assigned_id);
  }, []);
  const startAutoCreateVariant = useCallback(() => {
    createBulkVariant(current_product_id);
  }, [current_product_id]);
  const useFields = {
    variant_group_name: useField<string>({
      value: '',
      validates: []
    }),
    variant_group_value: useField<string>({
      value: '',
      validates: []
    })
  };
  const setProductVariantGroupToProduct = useCallback(() => {
    assignVariantGroupToProduct({
      product_id: current_product_id,
      variant_group_name: useFields.variant_group_name.value,
      variant_group_value: useFields.variant_group_value.value
    });
  }, [current_product_id, useFields]);
  return /*@million jsx-skip*/<PopoverWithSearchableListbox_1 v0={<BlockStack gap="400">
        {__helpers.isEmpty(listOfProductVariantAssigned) && <Text as="p" tone="subdued">
            Chưa có một nhóm biến thể nào.
          </Text>}
        {listOfProductVariantAssigned?.body?.map((el, index) => {
      return <InlineStack gap="400" align="space-between" key={index + `_r_32`}>
              <Text as="h2" variant="headingMd">
                {el?.variant_group_name}: {el?.variant_group_value}
              </Text>
              <Button variant="plain" onClick={() => deleteVariantAssigned(el.id)}>
                XÓA
              </Button>
            </InlineStack>;
    })}
      </BlockStack>} v1={<Divider />} v2={<InlineStack gap="400" align="space-between" blockAlign="center">
        <Popover active={popoverActive} activator={<Button icon={PlusIcon} onClick={togglePopoverActive}>
              Thêm nhóm biến thể
            </Button>} autofocusTarget="first-node" onClose={togglePopoverActive}>
          <Box padding={'400'}>
            <Form onSubmit={() => setProductVariantGroupToProduct()}>
              <BlockStack gap="400">
                <TextField label="Tên nhóm biến thể" placeholder="Ví dụ: Màu sắc" autoComplete="off" {...useFields.variant_group_name} />
                <TextField label="Giá trị (phân cách bằng dấu phẩy)" placeholder="Ví dụ: Xanh, Đỏ, Trắng, Vàng" autoComplete="off" {...useFields.variant_group_value} />
                <Button loading={isAssigning} onClick={() => setProductVariantGroupToProduct()} variant="primary">
                  Submit
                </Button>
              </BlockStack>
            </Form>
          </Box>
        </Popover>
        {listOfProductVariantAssigned?.body?.length > 0 && <Button icon={DomainNewIcon} loading={bulkCreatingProductVariant} onClick={() => startAutoCreateVariant()}>
            Tạo biến thể từ nhóm
          </Button>}
      </InlineStack>} v3={<Divider />} />;
};
import { block as _block } from "million/react";
import { Button, Popover, ActionList, Text, BlockStack, Divider, InlineStack, Form, TextField, Box } from '@shopify/polaris';
import { useState, useCallback, useEffect } from 'react';
import { PlusIcon, DomainNewIcon } from '@shopify/polaris-icons';
import { useCreateVariantGroup, useDeleteVariantGroup, useGetVariantGroups } from 'queries/product_variant_group.query';
import __helpers from 'helpers/index';
import { useBulkCreateProductVariant } from 'queries/product_variant.query';
import { useField } from '@shopify/react-form';
export default PopoverWithSearchableListbox;