import { Autocomplete, Card, Icon } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getEntities } from 'store/customer_campaign.store.reducer';
import { SearchIcon } from "@shopify/polaris-icons";
import helpers from "helpers/index";
export default function QuickSearchCampaign({
  current_campaign,
  onClose
}: {
  current_campaign?: string | bigint;
  onClose: (val: string) => void;
}) {
  /**!SECTION
   * Select người handler
   */
  const dispatch = useAppDispatch();

  /**
   * Campaign ...
   */
  const campaignLoading = useAppSelector(state => state.customer_campaign.loading);
  const campaignList = useAppSelector(state => state.customer_campaign.entities);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    dispatch(getEntities({
      sort: 'createdAt:desc',
      campaign_status: 1,
      limit: 5
    }));
  }, []);
  useEffect(() => {
    let innerCampaign = [];
    if (campaignList) for (let camp of campaignList) {
      innerCampaign.push({
        value: camp.campaign_id,
        label: camp.campaign_name
      });
      if (current_campaign) if (camp.campaign_id === current_campaign) {
        setUserQuickSearchText(camp.campaign_name);
      }
    }
    setUserListForQuickOption(innerCampaign);
  }, [campaignList]);
  const onQueryToServer = useCallback((keyword: string) => {
    dispatch(getEntities({
      query: keyword,
      sort: 'createdAt:desc',
      limit: 10
    }));
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    onClose?.call(this, selected[0]);
  }, [userListForQuickOption]);
  const textFieldSelectUser = <>
    <Autocomplete.TextField onChange={updateUserText} label="Giao cho" labelHidden value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Search" autoComplete="off" />
  </>;
  return <>
            <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={campaignLoading} />
        </>;
}