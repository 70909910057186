const USER_CAPACITY_LIST = ['visitor', 'customer', 'user', 'sale', 'sale_admin', 'stock_manager', 'accountant', 'admin', 'super_admin'];

/** Tình trạng thanh toán */
const PAYMENT_STATUS_CONST = ['order_payment_status_unpaid', 'order_payment_status_partialpaid', 'order_payment_status_paid', 'order_payment_status_refunding', 'order_payment_status_refunded'];
const ORDER_PAYMENT_STATUS = {
  '0': 'order_payment_status_unpaid',
  '1': 'order_payment_status_partialpaid',
  '2': 'order_payment_status_paid',
  '3': 'order_payment_status_refunding',
  '4': 'order_payment_status_refunded'
};
const enum ENUM_PAYMENT_TYPE {
  Bank = 'bank',
  Cash = 'cash',
  Cod = 'cod',
}
const PAYMENT_TYPE_CONST = ['bank', 'cash', 'cod'];
export { USER_CAPACITY_LIST, PAYMENT_STATUS_CONST, ORDER_PAYMENT_STATUS, ENUM_PAYMENT_TYPE, PAYMENT_TYPE_CONST };