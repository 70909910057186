import { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InlineStack, Badge, Button, DataTable, Divider, Icon, Layout, Link, Page, ResourceList, Select, Text, TextField, BlockStack, Thumbnail, Card, Box, Tabs, Tooltip } from '@shopify/polaris';
import orderStatusJson from 'config/order.status.json';
import TransactionNewModal from '../transactions/transaction.new';
import { XSmallIcon, ReceiptIcon, MenuHorizontalIcon, CheckSmallIcon, ProductIcon, ProductCostIcon, NoteIcon, PlusIcon, QuestionCircleIcon, DeleteIcon } from '@shopify/polaris-icons';
import helpers from '../../helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import ShowCustomFee from './order_fee.modal';
import __ from 'languages/index';
import Comments from 'components/CommentsComponent';
import OrderAssign from './order_detail/order.assign';
import OrderTransaction from './order_detail/order.transaction';
import { TypedOrder, useMutateGetOrderDetail, useUpdateOrder } from 'queries/orders.query';
import { Helmet } from 'react-helmet-async';
import { useGetOrder_fees, useDeleteOrder_fee } from 'queries/order_fee.query';
import { useCreateOrderProduct, useDeleteOrderProduct, useGetOrderProducts, useUpdateOrderProduct } from 'queries/order_product.query';
import OrderTransportLog from './order_detail/order_transport_log';
import QuickSearchProductAndVariant from 'components/quickSearchProductAndVariant.modal';
import { PAYMENT_STATUS_CONST, PAYMENT_TYPE_CONST } from 'constant/index';
import { useNotification } from 'NotificationContext';
import DeleteConfirmModal from 'components/deleteConfirm';
import { getBadgeOfPaymentMethod, getOrderStatusBadge } from './order.component';
import { useGetStocks } from 'queries/stock.query';
import dateandtime from 'date-and-time';
const OrderTransportInformation = lazy(() => import('./order_detail/order_transport_information'));
const OrderCustomerInformation = lazy(() => import('./order_detail/order_customer_information'));
export default function OrderView() {
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  let {
    order_id
  } = useParams();
  const {
    addNotification
  } = useNotification();
  const {
    data: orderFeeEntities
  } = useGetOrder_fees(order_id);
  const {
    mutateAsync: deleteOrderFee
  } = useDeleteOrder_fee();

  /** Hỏi xóa chi phí */
  const current_order_fee_id = useRef(null);
  const [showModalDeleteOrderFee, setShowModalDeleteOrderFee] = useState(false);
  const deleteOrderFeeCallback = useCallback(async () => {
    await deleteOrderFee(current_order_fee_id.current);
    current_order_fee_id.current = null;
  }, [current_order_fee_id.current]);
  const [entity, setEntity] = useState<TypedOrder>(null);
  const {
    data,
    isPending: loading,
    mutateAsync: getEntity
  } = useMutateGetOrderDetail();
  useEffect(() => {
    getEntity(order_id);
  }, [order_id]);
  useEffect(() => {
    if (data) setEntity(data);
  }, [data]);
  const {
    mutateAsync: updateOrder,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateOrder();
  const orderStatusArray = [];
  for (let orderStatus in orderStatusJson) {
    let orderStatusName = orderStatusJson[orderStatus];
    orderStatusArray.push({
      label: orderStatusName,
      value: orderStatus
    });
  }

  /**
   * order_product
   */
  const {
    data: entitiesOrderProduct
  } = useGetOrderProducts(order_id);
  const {
    mutate: updateOrderProduct,
    isPending: updatingOrderProduct,
    isSuccess: updateSuccessOrderProduct
  } = useUpdateOrderProduct();
  const {
    mutate: createOrderProduct
  } = useCreateOrderProduct();
  const {
    mutateAsync: deleteOrderProduct
  } = useDeleteOrderProduct();
  /**
   * Khoá hay không nhỉ?
   */
  const [orderIsLock, setOrderIsLock] = useState<boolean>(true);
  useEffect(() => {
    if (updateSuccessOrderProduct) {
      addNotification('info', __('update_successfully'));
    }
    // reset order detail
    getEntity(order_id);
  }, [updateSuccessOrderProduct]);

  /**
   * Số tiền thanh toán ....
   */

  const paymentTypeChangeCallback = useCallback(async (new_payment_type: string) => {
    if (!new_payment_type) return;
    try {
      await updateOrder({
        order_id: order_id,
        payment_type: new_payment_type
      });
      let new_entity = {
        ...entity,
        ...{
          payment_type: new_payment_type
        }
      };
      setEntity(new_entity);
    } catch (e) {}
  }, [order_id, entity]);
  const paymentStatusChangeCallback = useCallback(async (new_payment_status: string) => {
    if (!new_payment_status) return;
    try {
      await updateOrder({
        order_id: order_id,
        payment_status: Number(new_payment_status)
      });
      let new_entity = {
        ...entity,
        ...{
          payment_status: Number(new_payment_status)
        }
      };
      setEntity(new_entity);
    } catch (e) {}
  }, [order_id, entity]);

  /**
   * Handle selected order status change ...
   */
  const handleOrderStatusChange = useCallback(async (value: string) => {
    try {
      await updateOrder({
        order_id: order_id,
        order_status: Number(value)
      });
      let new_entity = {
        ...entity,
        ...{
          order_status: Number(value)
        }
      };
      setEntity(new_entity);
    } catch (e) {}
  }, [entity]);

  /**
   * Quick note
   */
  const [showQuickNote, setShowQuickNote] = useState(false);
  const [valuetext, setValuetext] = useState('');
  const inputInitialData = useCallback(() => {
    setValuetext(entity.order_note || '');
    setOrderIsLock(entity.order_checked === 1 || entity.order_status > 11);
  }, [entity]);
  useEffect(() => {
    if (!entity) return;
    inputInitialData();
  }, [entity]);
  useEffect(() => {
    if (updateSuccess === true) {
      setShowQuickNote(false);
      getEntity(order_id);
    }
  }, [updateSuccess, order_id]);
  const saveQuickNoteButtonCallback = async () => {
    try {
      await updateOrder({
        order_id: entity?.order_id,
        order_note: valuetext
      });
    } catch (e) {}
  };
  const handleQuickNoteChange = useCallback((newValue: string) => {
    setValuetext(newValue);
  }, []);

  /**!SECTION
   * Show product form search
   */

  const [showModelProductSearchForm, setShowModelProductSearchForm] = useState(false);
  const onCloseshowModelProductSearchForm = useCallback((data: {
    product_id: string;
    variant_id: string;
  } | null) => {
    setShowModelProductSearchForm(false);
    if (!data) return;
    if (!data.product_id) return;
    // save data and close Modal...
    createOrderProduct({
      order_id: entity?.order_id,
      product_id: data.product_id,
      variant_id: data.variant_id,
      quantity: 1
    });
  }, [entity]);
  /**
   * Thêm một giao dịch
   */

  const [showTransactionModal, setShowTransactionModal] = useState(false);
  function afterCloseTransactionModal() {
    setShowTransactionModal(false);
  }

  /**
   * Thêm chi phí cho một đơn hàng ...
   */
  const [showModelCustomFeeForm, setShowModelCustomFeeForm] = useState(false);

  /**
   * Product list
   */
  function deleteOrderProductCallback(order_product_id: any) {
    deleteOrderProduct({
      order_product_id: order_product_id,
      order_id: order_id
    });
  }

  /**
   * Khối xử lý click thay đổi số lượng hàng nhanh...
   */
  const [quantityValue, setQuantityValue] = useState({});
  useEffect(() => {
    let quantityAll = {};
    if (!entitiesOrderProduct) return;
    for (let product of entitiesOrderProduct) {
      quantityAll = {
        ...quantityAll,
        ...{
          [product.order_product_id]: product.quantity
        }
      };
    }
    setQuantityValue(quantityAll);
  }, [entitiesOrderProduct]);
  function updateOrderProductQuantity(newValue: any, order_product_id: any) {
    // quantityValue[order_product_id] = newValue;
    if (isNaN(newValue)) return;
    let newString = {
      ...quantityValue,
      ...{
        [order_product_id]: newValue
      }
    };
    setQuantityValue(newString);
  }
  /**
   * Lưu số lượng ngay khi click ra ngoài!
   * @param order_product_id
   */
  function savingQuantityThisIndex(order_product_id: any) {
    let newVal = quantityValue[order_product_id];
    updateOrderProduct({
      order_product_id: order_product_id,
      quantity: Number(newVal)
    });
  }

  /**
   * Khối xử lý click thay đổi giá trong từng đơn hàng nhanh ...
   */
  const [pricyValue, setPricyValue] = useState({});
  useEffect(() => {
    let quantityAll = {};
    if (!entitiesOrderProduct) return;
    for (let product of entitiesOrderProduct) {
      quantityAll = {
        ...quantityAll,
        ...{
          [product.order_product_id]: product.price
        }
      };
    }
    setPricyValue(quantityAll);
  }, [entitiesOrderProduct]);
  function updateOrderProductPrice(newValue: any, order_product_id: any) {
    if (isNaN(newValue)) return;
    let newString = {
      ...pricyValue,
      ...{
        [order_product_id]: newValue
      }
    };
    setPricyValue(newString);
  }
  /**
   * Lưu số lượng ngay khi click ra ngoài!
   * @param order_product_id
   */
  const savingPriceForThisIndex = (order_product_id: any) => {
    let newVal = pricyValue[order_product_id];
    updateOrderProduct({
      order_product_id: order_product_id,
      price: Number(newVal)
    });
  };
  const markThisOrderPrintPackingSlip = () => {
    updateOrder({
      order_id: entity?.order_id,
      order_print_packing_slip: 1
    }).catch(e => {});
    window.open(process.env.REACT_APP_BACKEND_URL + '/orders/packing_slip/print/' + entity?.order_id, '_blank');
  };
  const productRows = [];
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [totalProductUnit, setTotalProductUnit] = useState(0);

  /**
   * Cập nhật tổng giá, realtime
   */
  useEffect(() => {
    if (!entitiesOrderProduct) return;
    let tPrice = 0,
      tProductUnit = 0;
    for (let product of entitiesOrderProduct) {
      tPrice += product.quantity * product.price;
      tProductUnit += product.quantity;
    }
    setTotalPrice(tPrice);
    setTotalProductUnit(tProductUnit);
  }, [entitiesOrderProduct]);

  /**
   * Cập nhật tổng phí. realtime
   */
  useEffect(() => {
    let tPrice = 0;
    if (!orderFeeEntities) return;
    for (let productFee of orderFeeEntities) {
      tPrice += productFee.order_fee_value;
    }
    setTotalFee(tPrice);
  }, [orderFeeEntities]);
  if (entitiesOrderProduct) {
    for (let orderProductData of entitiesOrderProduct) {
      // tính total ...
      productRows.push([<div style={{
        width: '250px'
      }}>
          <BlockStack gap="400">
            <InlineStack gap="400" align="start" blockAlign="center">
              <Thumbnail size="small" source={helpers.getMediaLink(orderProductData.product.product_thumbnail_to_media ? orderProductData.product.product_thumbnail_to_media.media_thumbnail ? orderProductData.product.product_thumbnail_to_media.media_thumbnail['128x128'] : '' : '')} alt="" />
              <BlockStack>
                <Text as="p" fontWeight="bold">
                  {orderProductData.product.product_name}
                </Text>
                <Text as="p" tone="subdued">
                  SKU: {orderProductData.product.product_sku ?? '-'}
                </Text>
              </BlockStack>
            </InlineStack>
            {orderProductData.product_variant && <div style={{
            marginLeft: '15px'
          }}>
                <InlineStack align="start" blockAlign="center" gap="400">
                  <Thumbnail size="extraSmall" source={helpers.getMediaLink(orderProductData.product_variant.variant_thumbnail_to_media ? orderProductData.product_variant.variant_thumbnail_to_media.media_thumbnail ? orderProductData.product_variant.variant_thumbnail_to_media.media_thumbnail['128x128'] : '' : '')} alt="" />
                  <BlockStack>
                    <Text as="p">
                      <strong>{orderProductData.product_variant.variant_name}</strong>{' '}
                      <span>SKU: {orderProductData.product_variant.variant_sku ?? '-'}</span>
                    </Text>
                  </BlockStack>
                </InlineStack>
              </div>}
          </BlockStack>
        </div>,
      // product.product_price,
      <div style={{
        width: '80px'
      }}>
          <TextField label="" max={99} min={0} disabled={updatingOrderProduct || orderIsLock} value={pricyValue[orderProductData.order_product_id]} onBlur={() => {
          savingPriceForThisIndex(orderProductData.order_product_id);
        }} onChange={(newValue: any) => {
          updateOrderProductPrice(newValue, orderProductData.order_product_id);
        }} autoComplete="off" />
        </div>,
      // product.order_quantity,
      <div style={{
        width: '80px'
      }}>
          <TextField label="" max={99} min={1} disabled={updatingOrderProduct || orderIsLock} value={quantityValue[orderProductData.order_product_id]} onBlur={() => {
          savingQuantityThisIndex(orderProductData.order_product_id);
        }} onChange={(newValue: any) => {
          updateOrderProductQuantity(newValue, orderProductData.order_product_id);
        }} autoComplete="off" />
        </div>, entity?.order_status < 11 ? <Link onClick={() => {
        deleteOrderProductCallback(orderProductData.order_product_id);
      }}>
            <Icon source={XSmallIcon} tone="base" />
          </Link> : null]);
    }
  }
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelectedTab(selectedTabIndex), []);

  /** change stock information */

  const {
    isLoading: loadingStockList,
    data: stockList
  } = useGetStocks({
    limit: 5,
    sort: 'createdAt:desc'
  });
  const updateStockCallback = useCallback((stock_id: string) => {
    updateOrder({
      order_id: order_id,
      stock_id: stock_id
    });
  }, [order_id]);
  const ActualPage = <>
      <Helmet>
        <title>Thông tin đơn hàng</title>
      </Helmet>
      <Page title={entity?.order_pnr} subtitle={'Tạo bởi ' + entity?.user?.user_login + ' / ' + String(entity ? helpers.subtractDate(entity.createdAt) : '1') + ' ngày trước'} backAction={{
      content: 'Danh sách đơn hàng',
      url: '/order'
    }} titleMetadata={<>
            {getBadgeOfPaymentMethod(entity?.payment_status)} {getOrderStatusBadge(entity?.order_status)}{' '}
            {entity?.order_checked ? <Badge tone="attention">Đã đối soát</Badge> : null}{' '}
          </>}
    // titleMetadata={ getBadgeOfPaymentMethod(entity?.payment_status) +''+ <Badge></Badge> }
    secondaryActions={[{
      content: 'Invoice',
      icon: ReceiptIcon,
      onAction: () => {
        window.open(process.env.REACT_APP_BACKEND_URL + '/orders/invoice/print/' + entity?.order_id, '_blank');
      }
    }, {
      content: 'Packing slip',
      icon: entity?.order_print_packing_slip ? CheckSmallIcon : MenuHorizontalIcon,
      // CheckSmallIcon
      onAction: () => {
        markThisOrderPrintPackingSlip();
      }
    }]} pagination={{
      label: <>{dateandtime.format(new Date(Number(entity?.createdAt)), 'DD/MM')}</>,
      hasPrevious: entity?.pagination?.prev ? true : false,
      hasNext: entity?.pagination?.next ? true : false,
      nextURL: '/order/view/' + entity?.pagination?.next,
      previousURL: '/order/view/' + entity?.pagination?.prev
    }}>
        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap="400">
                <InlineStack align="space-between" blockAlign="center">
                  <div className="small-icon">
                    <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                      <ProductIcon /> Danh sách sản phẩm
                    </Text>
                  </div>
                  <Button icon={PlusIcon} variant="plain" disabled={orderIsLock} onClick={() => setShowModelProductSearchForm(true)}>
                    Thêm sản phẩm
                  </Button>
                </InlineStack>

                {entitiesOrderProduct?.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'text']} headings={['Sản phẩm', 'Giá', 'S.lượng', '']} rows={productRows} /> : <Text as="p" tone="critical">
                    Chưa có sản phẩm nào trong đơn này! Click "thêm sản phẩm" để thêm!
                  </Text>}
                <Divider />
              </BlockStack>

              <br />
              <Box padding={'0'}>
                <Text fontWeight="bold" as="p">
                  Tóm tắt
                </Text>
                <InlineStack gap="400">
                  <InlineStack>Số hàng</InlineStack>
                  <InlineStack>
                    <strong>{totalProductUnit} đơn vị</strong>
                  </InlineStack>
                  <InlineStack>Tổng tiền hàng</InlineStack>
                  <InlineStack>
                    <strong> {helpers.formatNumber(totalPrice, ',')} vnd</strong>
                  </InlineStack>
                </InlineStack>
              </Box>
            </Card>

            <br />

            <Card>
              <BlockStack gap="400">
                <InlineStack align="space-between" blockAlign="center">
                  <div className="small-icon">
                    <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                      Các chi phí{' '}
                      <Tooltip content="Bạn có thể lên phí tùy chỉnh tùy theo thỏa thuận giữa sale và khách hàng.">
                        <Icon source={QuestionCircleIcon} />
                      </Tooltip>
                    </Text>
                  </div>
                  <Button icon={PlusIcon} variant="plain" disabled={orderIsLock} onClick={() => setShowModelCustomFeeForm(true)}>
                    Thêm chi phí tùy chỉnh
                  </Button>
                </InlineStack>

                <Divider />

                {!helpers.isEmpty(orderFeeEntities) ? <ResourceList resourceName={{
                singular: 'fee',
                plural: 'fees'
              }} items={orderFeeEntities} renderItem={item => {
                const {
                  order_fee_name,
                  order_fee_value,
                  order_fee_id
                } = item;
                return <ResourceList.Item id={order_fee_name.toLocaleLowerCase()} url={'#'} key={`` + order_fee_id}>
                          <InlineStack align="space-between">
                            <Text as="p">{order_fee_name}</Text>
                            <Text as="p">{helpers.formatNumber(order_fee_value, ',')}</Text>
                            <Text as="p">
                              <Button variant="plain" disabled={orderIsLock} icon={DeleteIcon} onClick={() => {
                        setShowModalDeleteOrderFee(true);
                        current_order_fee_id.current = order_fee_id;
                      }}></Button>
                            </Text>
                          </InlineStack>
                        </ResourceList.Item>;
              }} /> : <Text as="p" tone="subdued">
                    Chưa có phí thêm nào.
                  </Text>}
              </BlockStack>
            </Card>

            <br />

            <Card padding="0">
              <Box padding={'400'}>
                <BlockStack gap="400">
                  <InlineStack align="space-between" blockAlign="center">
                    <div className="small-icon">
                      <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                        <ProductCostIcon /> Giao dịch
                      </Text>
                    </div>
                    <Button icon={PlusIcon} variant="plain" disabled={orderIsLock} onClick={() => setShowTransactionModal(true)}>
                      Thêm giao dịch
                    </Button>
                  </InlineStack>

                  <div>
                    <OrderTransaction order_id={order_id} />
                  </div>
                </BlockStack>
              </Box>

              <Box background="bg" padding={'400'}>
                <BlockStack gap="400">
                  <Text as="h3" fontWeight="bold" variant="headingMd">
                    Tổng phải thanh toán
                  </Text>
                  <ResourceList resourceName={{
                  singular: 'fee',
                  plural: 'fees'
                }} items={[{
                  id: 'totalPrice',
                  name: 'Tổng tiền hàng',
                  value: helpers.formatNumber(totalPrice, ',')
                }, {
                  id: 'totalFee',
                  name: 'Tổng tiền phí',
                  value: helpers.formatNumber(totalFee, ',')
                }, {
                  id: 'totalMustPay',
                  name: 'Phải thanh toán',
                  value: helpers.formatNumber(entity?.order_total_mustpay, ',')
                }]} renderItem={item => {
                  const {
                    id,
                    name,
                    value
                  } = item;
                  return <ResourceList.Item id={id} url={'#'} key={`kakak_ANNAOOO_` + id}>
                          <InlineStack align="space-between">
                            <Text as="p">{name}</Text>
                            <Text as="p">{value}</Text>
                          </InlineStack>
                        </ResourceList.Item>;
                }} />
                </BlockStack>
              </Box>
            </Card>

            <br />
            <Card>
              <OrderAssign entity={entity} />
            </Card>
            <br />

            <div style={{
            margin: '0 -15px 15px -15px'
          }}>
              <Tabs tabs={[{
              id: 'comments_tab',
              content: 'Thảo luận nội bộ',
              panelID: 'commentstab'
            }, {
              id: 'transportation_tab',
              content: 'Vận chuyển',
              panelID: 'transpotationTab'
            }]} selected={selectedTab} onSelect={handleTabChange}></Tabs>
              <Divider />
            </div>
            {selectedTab === 0 && <Comments objectId={order_id} commentType="order_comment" title="Nhận xét"></Comments>}
            {selectedTab === 1 && <OrderTransportLog order_id={order_id} />}
          </Layout.Section>

          <Layout.Section variant="oneThird">
            <BlockStack gap="400">
              <Card>
                <BlockStack gap="400">
                  <Select disabled={entity?.order_checked ? true : false} label={'Tình trạng đơn hàng'} options={orderStatusArray} value={`${entity?.order_status}`} onChange={val => handleOrderStatusChange(val)} />

                  <Select disabled={orderIsLock} label={'Kho xử lý đơn hàng'} options={stockList?.body?.map(stock => {
                  return {
                    label: stock.stock_name,
                    value: stock.stock_id
                  };
                }) || []} value={`${entity?.order_to_stock?.stock?.stock_id}`} onChange={val => updateStockCallback(val)} />
                </BlockStack>
              </Card>

              <Card>
                <BlockStack gap="400">
                  <Text as="h4" variant="headingMd">
                    Thanh toán
                  </Text>
                  <Select label={'Cách thanh toán'} disabled={orderIsLock} options={PAYMENT_TYPE_CONST.map((el, index) => {
                  return {
                    label: __(`order_payment_type_via_` + el),
                    value: `${el}`
                  };
                })} onChange={val => paymentTypeChangeCallback(val)} value={`` + entity?.payment_type} />

                  <Select label={'Tình trạng thanh toán'} disabled={orderIsLock} options={PAYMENT_STATUS_CONST.map((el, index) => {
                  return {
                    label: __(el),
                    value: `${index}`
                  };
                })} onChange={val => paymentStatusChangeCallback(val)} value={`` + entity?.payment_status} />
                </BlockStack>
              </Card>

              <Suspense fallback={<>Loading...</>}>
                <OrderCustomerInformation entity={entity} />
              </Suspense>

              <Suspense fallback={<>Loading...</>}>
                <OrderTransportInformation entity={entity} />
              </Suspense>

              <Card>
                <Box>
                  <InlineStack align="space-between" blockAlign="center">
                    <div className="small-icon">
                      <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                        <NoteIcon /> Ghi chú cuả khách hàng
                      </Text>
                    </div>

                    <Button variant="plain" disabled={entity?.order_checked === 1} onClick={() => setShowQuickNote(true)}>
                      sửa
                    </Button>
                  </InlineStack>
                </Box>

                <Box>
                  {showQuickNote ? <>
                      <TextField label="" multiline={2} value={valuetext} onChange={handleQuickNoteChange} autoComplete="off" maxLength={250} showCharacterCount />
                      <Button variant="plain" onClick={saveQuickNoteButtonCallback} loading={updating}>
                        Save
                      </Button>
                      &nbsp; or &nbsp;
                      <Button variant="plain" onClick={() => {
                    setShowQuickNote(false);
                  }}>
                        Cancel
                      </Button>
                    </> : entity?.order_note ?? 'chưa có ghi chú'}
                </Box>
              </Card>
            </BlockStack>
          </Layout.Section>
        </Layout>
      </Page>

      <br />
      <br />

      <QuickSearchProductAndVariant show={showModelProductSearchForm} onClose={onCloseshowModelProductSearchForm} />
    </>;
  return <>
      {entity ? ActualPage : <SkeletonLoading />}
      <ShowCustomFee show={showModelCustomFeeForm} order_id={order_id} onClose={() => setShowModelCustomFeeForm(false)} />
      {!orderIsLock && <DeleteConfirmModal show={showModalDeleteOrderFee} onClose={(agree: boolean) => {
      setShowModalDeleteOrderFee(false);
      if (agree) {
        deleteOrderFeeCallback();
      }
    }} title={'Bạn có chắc chắn muốn xóa chi phí này?'} />}
      <TransactionNewModal show={showTransactionModal} order_id={entity?.order_id} default_customer_id={entity?.user_id} default_money_quantity={entity?.order_total_price + entity?.order_total_fee} onClose={afterCloseTransactionModal} />
    </>;
}