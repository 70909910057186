import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InlineGrid, Box, Form, Text, Toast, SkeletonBodyText } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import { TypedActivity, clearError, deleteEntity, getEntities, reset } from "store/activity.store.reducer";
import dateandtime from "date-and-time";
import __ from "languages/index";
import React from "react";
import helpers from "helpers/index";
import Pagination from "components/pagination";
import { TypedUser_activity } from "store/user_activity.store.reducer";
import For from 'components/For';
export default function ActivityTracing({
  recordPerPage = 50,
  deviceID = '',
  userID = ''
}: {
  recordPerPage?: number;
  deviceID?: string;
  userID?: string;
}) {
  const entities = useAppSelector(state => state.activity.entities);
  const totalItems = useAppSelector(state => state.activity.totalItems);
  const loading = useAppSelector(state => state.activity.loading);
  const dispatch = useAppDispatch();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const [mainQuery, setMainQuery] = useState({
    query: "",
    page: 1,
    device_uuid: deviceID,
    user_id: userID,
    limit: recordPerPage,
    sort: "createdAt:desc"
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  const queryCallback = useCallback(async () => {
    await dispatch(reset());
    await dispatch(getEntities(mainQuery));
  }, [mainQuery]);
  useEffect(() => {
    queryCallback();
  }, [mainQuery]);
  const groups = useRef('');
  const commentBodyComponent = (element: TypedActivity, index: number) => {
    let datetimeCreate = new Date(Number(element.createdAt));
    let dateMonthYear = dateandtime.format(datetimeCreate, 'ddd, DD, MMM, YYYY'); // Fri, 28, Apr, 2023
    let {
      activity_id,
      device_id,
      activity_referrer,
      activity_target,
      activity_ip,
      device
    } = element;
    let {
      device_uuid,
      user_agent,
      system_type,
      status
    } = device;
    let A = false;
    if (dateMonthYear !== groups.current) {
      groups.current = dateMonthYear;
      A = true;
    }
    let N = dateMonthYear.toLowerCase().split(',');
    let M = __(N[0]) + ', ' + N[1] + ' ' + __(N[2]) + ', ' + N[3];
    return <React.Fragment key={`${index}_activity_body`}>
                {A && <div className="step-item">{M}</div>}
                <div className="card" id={'comment_' + activity_id}>
                    <div className="timeline_handler"></div>
                    <div className="time">
                        <span className="hour">{dateandtime.format(datetimeCreate, 'HH:mm')}</span>
                        <div className="author">{system_type}</div>
                    </div>
                    <div className="info">
                        <h3 className="title">Trang đích: {activity_target}</h3>
                        <div>
                            {activity_referrer && <Text as="p" variant="bodyMd">
                                    Referrer: {activity_referrer}
                                </Text>}
                            <Text as="p" variant="bodyMd">
                                Agent: {user_agent}
                            </Text>
                            <Text as="p" variant="bodyMd">
                                From IP: {activity_ip}
                            </Text>
                        </div>
                        
                    </div>
                </div>    
            </React.Fragment>;
  };
  return <>
            {loading ? <SkeletonBodyText /> : <InlineGrid gap="400">
                    <Box padding="400">
                    <Text variant="headingMd" as="h6">
                        Activity tracing
                    </Text>
                        
                    {deviceID && <Text variant="bodySm" as="p" tone="subdued">
                                Device ID: {deviceID}
                            </Text>}

                    <div className="timeline">
                    <div className="outer">
                        {Array.isArray(entities) && (entities.length > 0 ? <For each={entities}>
                                        {commentBodyComponent}
                                    </For> : <div style={{
              padding: '15px'
            }}><Text as="p">Chưa có một hoạt động nào.</Text></div>)}
                    </div>
                    </div>

                    <Text variant="headingMd" as="h6">
                        Activity tracing
                    </Text>

                    </Box>
                </InlineGrid>}

            {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
            <br /><br />
        </>;
}