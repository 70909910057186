import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';
import project_list from './project.list';
import project_detail from './project.detail';
export default function Pagidex() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'list';
  let ActualPage: any;
  switch (Param) {
    case 'list':
      ActualPage = project_list;
      break;
    case 'view':
      ActualPage = project_detail;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>
            {<ActualPage />}
        </>;
}