import { useAppDispatch, useAppSelector } from 'config/store';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from 'helpers/index';
// import __ from "languages/index";
import { useCallback, useEffect, useState } from 'react';
import { clearError, updateEntity, getEntity } from 'store/project.store.reducer';
import { PlusIcon } from '@shopify/polaris-icons';
import dateandtime from 'date-and-time';
// import ProjectNew from "./project.new";
import { Badge, Divider, InlineGrid, Loading, Page, Toast, Text, BlockStack, InlineStack, DescriptionList, Avatar, Link, Popover, ActionList } from '@shopify/polaris';
import Comments from 'components/CommentsComponent';
import UserAssign from './user.assign';
import AccountantLog from './accountantLog';
import NewtaskModal from './task.new';
import TransactionNewModal from 'entities/transactions/transaction.new';
import TaskList from './task.list';
import { getEntities as getManyTransactionToProject } from 'store/transaction_to_project.store.reducer';
export default function ProjectDetail() {
  const entity = useAppSelector(state => state.project.entity);
  const loading = useAppSelector(state => state.project.loading);
  // const totalItems = useAppSelector((state) => state.project.totalItems);
  const updating = useAppSelector(state => state.project.updating);
  const updateSuccess = useAppSelector(state => state.project.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [showNewTask, setShowNewTask] = useState(false);
  function project_status(_status: number) {
    switch (_status) {
      case 0:
        return <>
            <Badge></Badge>
            <Badge></Badge>
          </>;
      case 1:
        return <Badge tone="attention">Đang diễn ra</Badge>;
      case 2:
        return <Badge tone="success">Đã hoàn thành</Badge>;
      case 9:
        return <Badge tone="critical-strong">Hủy</Badge>;
    }
  }
  function payment_status(_TTTT: string | number) {
    switch (_TTTT) {
      case 0:
        return <Badge progress="incomplete" tone="attention">
            Chưa thanh toán
          </Badge>;
      case 1:
        return <Badge progress="partiallyComplete" tone="warning">
            Thanh toán một phần
          </Badge>;
      case 2:
        return <Badge tone="success" progress="complete">
            Đã thanh toán
          </Badge>;
      case 3:
        return <Badge tone="critical" progress="incomplete">
            Chờ hoàn tiền
          </Badge>;
      case 4:
        return <Badge tone="critical" progress="incomplete">
            Đã hoàn tiền
          </Badge>;
      default:
        return <Badge></Badge>;
    }
  }

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    project_id
  } = useParams();
  useEffect(() => {
    dispatch(getEntity(project_id));
  }, [project_id]);
  useEffect(() => {
    if (updateSuccess) dispatch(getEntity(project_id));
  }, [updateSuccess]);

  /**
   * Notification
   */
  const toggleToastActive = useCallback(() => {
    dispatch(clearError());
  }, []);
  const toastMarkup = updateSuccess ? <Toast content={'Thành công!'} onDismiss={toggleToastActive} duration={4500} /> : null;
  const [showPopupForChangeStatus, setShowPopupForChangeStatus] = useState(false);
  const changeProjectStatus = useCallback((project_id: string, project_new_status: number) => {
    dispatch(updateEntity({
      project_id: project_id,
      project_status: Number(project_new_status)
    }));
    setShowPopupForChangeStatus(false);
  }, []);
  const [showPopupForChangePaymentStatus, setShowPopupForChangePaymentStatus] = useState(false);
  const changeProjectPaymentStatus = useCallback((project_id: string, project_new_payment_status: number) => {
    dispatch(updateEntity({
      project_id: project_id,
      project_payment_status: Number(project_new_payment_status)
    }));
    setShowPopupForChangePaymentStatus(false);
  }, []);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const afterCloseTransactionModal = useCallback(() => {
    setShowTransactionModal(false);
    dispatch(getManyTransactionToProject(entity?.project_id));
  }, [entity]);
  return <>
      {toastMarkup}
      {loading || updating ? <Loading /> : null}

      <TransactionNewModal show={showTransactionModal} project_id={entity?.project_id} default_customer_id={`` + entity?.customer_id} default_money_quantity={entity?.project_price} onClose={afterCloseTransactionModal} />

      <NewtaskModal show={showNewTask} onClose={() => {
      setShowNewTask(false);
      dispatch(getEntity(project_id));
    }} project_id={project_id} />

      <Page fullWidth title={entity?.project_title} backAction={{
      content: 'Back',
      onAction: () => history('/project/')
    }} secondaryActions={[{
      content: 'Thêm task',
      onAction: () => setShowNewTask(true),
      icon: PlusIcon
    }, {
      content: 'Thêm giao dịch',
      onAction: () => setShowTransactionModal(true),
      icon: PlusIcon
    }]}>
        <InlineGrid columns={{
        xs: '1',
        sm: '1',
        md: '1',
        lg: ['oneThird', 'twoThirds']
      }} gap="400">
          <div>
            <InlineStack gap={'400'} align="start" blockAlign="center">
              <InlineStack gap={'200'} blockAlign="center">
                <Avatar size="sm" source={entity?.author_data?.user_avatar} initials={(entity?.author_data?.display_name || 'u').charAt(0)} />
                <BlockStack>
                  <Text as="span" fontWeight="bold">
                    {entity?.author_data?.display_name || entity?.author_data?.user_login}
                  </Text>
                  <Text as="span" variant="bodySm" tone="subdued">
                    {`Tạo ` + String(entity ? helpers.subtractDate(entity?.createdAt) : '1') + ' ngày trước'}
                  </Text>
                </BlockStack>
              </InlineStack>

              <InlineStack gap={'200'} blockAlign="center">
                <Avatar size="sm" source={entity?.customer_data?.user_avatar} initials={(entity?.customer_data?.display_name || 'u').charAt(0)} />
                <BlockStack>
                  <Link url={'/customer/view/' + entity?.customer_id}>
                    {entity?.customer_data?.display_name || entity?.customer_data?.user_login}
                  </Link>
                  <Text as="span" variant="bodySm" tone="subdued">
                    Khách hàng
                  </Text>
                </BlockStack>
              </InlineStack>
            </InlineStack>

            <br />
            <DescriptionList gap="tight" items={[{
            term: 'Tình trạng triển khai',
            description: <Popover active={showPopupForChangeStatus} activator={<>
                          <Link onClick={() => setShowPopupForChangeStatus(true)}>{project_status(entity?.project_status)}</Link>
                        </>} autofocusTarget="first-node" onClose={() => setShowPopupForChangeStatus(false)}>
                      <ActionList actionRole="menuitem" items={[{
                content: 'Đang diễn ra',
                onAction: () => changeProjectStatus(entity?.project_id, 1)
              }, {
                content: 'Hoàn thành',
                onAction: () => changeProjectStatus(entity?.project_id, 2)
              }, {
                content: 'Hủy dự án',
                onAction: () => changeProjectStatus(entity?.project_id, 9)
              }]} />
                    </Popover>
          }, {
            term: 'Tình trạng thanh toán',
            description: <Popover active={showPopupForChangePaymentStatus} activator={<>
                          <Link onClick={() => setShowPopupForChangePaymentStatus(true)}>{payment_status(entity?.project_payment_status)}</Link>
                        </>} autofocusTarget="first-node" onClose={() => setShowPopupForChangePaymentStatus(false)}>
                      <ActionList actionRole="menuitem" items={[{
                content: 'Thanh toán một phần',
                onAction: () => changeProjectPaymentStatus(entity?.project_id, 1)
              }, {
                content: 'Đã thanh toán',
                onAction: () => changeProjectPaymentStatus(entity?.project_id, 2)
              }, {
                content: 'Đã refund',
                onAction: () => changeProjectPaymentStatus(entity?.project_id, 9)
              }]} />
                    </Popover> //payment_status(entity?.project_payment_status),
          }, {
            term: 'ID dự án',
            description: entity?.project_pnr
          }, {
            term: 'Bắt đầu',
            description: dateandtime.format(new Date(Number(entity?.createdAt)), 'DD-MM-YYYY')
          }, {
            term: 'Kết thúc dự kiến',
            description: dateandtime.format(new Date(Number(entity?.project_deadline)), 'DD-MM-YYYY')
          }, {
            term: 'Số ngày còn lại',
            description: entity?.project_deadline && (Number(helpers.subtractDate(entity.project_deadline)) <= 0 ? <span>{Math.abs(Number(helpers.subtractDate(entity.project_deadline))) + ' ngày'}</span> : <span>Hết hạn</span>)
          }, {
            term: 'Giá trị dự án',
            description: helpers.formatNumber(entity?.project_price, ',') + ' VND'
          }]} />
            <br />
            <div>
              <UserAssign />
            </div>

            <br />

            <br />
            <Text as="h6" variant="headingMd">
              Mô tả
            </Text>
            <Text as="p">{entity?.project_description}</Text>
          </div>

          <BlockStack gap="400">
            <TaskList project_id={project_id} />

            <AccountantLog project_id={project_id} />

            <br />
            <Divider />
            <br />
            <Comments objectId={project_id} commentType={'project_comment'} title={'Thảo luận'} />
          </BlockStack>
        </InlineGrid>
      </Page>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>;
}