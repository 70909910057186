
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export interface TypedProduct_variant_group {
    id?: string;
    product_id?: string;
    variant_group_name?: string;
    variant_group_value?: string;
    createdAt?: string | bigint;
}


type IQuery = TypedProduct_variant_group & IQueryParams;


/*
* List of all app
*/
export function useGetVariantGroups(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product_variant_group/fetch_entity_list"],
        queryFn: () => axios.get<TypedProduct_variant_group[]>(`/product_variant_group${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetVariantGroup(variant_id: string) {
    return useQuery({
        queryKey: ['product_variant_group/fetch_entity'],
        queryFn: () => axios.get<TypedProduct_variant_group>(`/product_variant_group/${variant_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateVariantGroup() {
    return useMutation({
        mutationKey: ['product_variant_group/update_entity'],
        mutationFn: ({ id, ...rest }: TypedProduct_variant_group) => axios.patch<any>(`product_variant_group/${id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant_group/fetch_entity_list'] });
        }
    });
}


export function useCreateVariantGroup() {
    return useMutation({
        mutationKey: ['product_variant_group/create_entity'],
        mutationFn: (entity: TypedProduct_variant_group) => axios.post<any>(`product_variant_group/`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant_group/fetch_entity_list'] });
        }
    });
}


export function useDeleteVariantGroup() {
    return useMutation({
        mutationKey: ['product_variant_group/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`product_variant_group/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant_group/fetch_entity_list'] });
        }
    });
}