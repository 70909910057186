import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedIncoming_webhook } from '../interface/incoming_webhook.interface';

/**
 *   Reducer used for front-end, with incoming_webhook.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using incoming_webhook.model.ts
 */

const initialState = {
  loading: false,
  loadingLog: false,
  errorMessage: null as any,
  entities: null,
  entity: null,
  entities_log: [],
  updating: false,
  totalItems: 0,
  totalLogItems: 0,
  updateSuccess: false
};
const apiUrl = 'webhook';

// Actions

export const getEntities = createAsyncThunk('incoming_webhook/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});
export const getEntity = createAsyncThunk('incoming_webhook/fetch_entity', async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const createEntity = createAsyncThunk('incoming_webhook/create_entity', async (entity: TypedIncoming_webhook, thunkAPI) => {
  const result = await axios.post<TypedIncoming_webhook>(`${apiUrl}`, helpers.cleanEntity(entity));
  thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});
export const updateEntity = createAsyncThunk('incoming_webhook/update_entity', async (entity: TypedIncoming_webhook) => {
  let apiID = String(entity.api_id);
  delete entity.api_id;
  return await axios.patch<TypedIncoming_webhook>(`${apiUrl}/${apiID}`, helpers.cleanEntity(entity));
}, {
  serializeError: serializeAxiosError
});
export const resetToken = createAsyncThunk('incoming_webhook/reset_api_access_token', async (api_id: string) => {
  return await axios.patch<TypedIncoming_webhook>(`${apiUrl}/reset_api_access_token/${api_id}`);
}, {
  serializeError: serializeAxiosError
});
export const partialUpdateEntity = createAsyncThunk('incoming_webhook/partial_update_entity', async (entity: TypedIncoming_webhook) => {
  let apiID = String(entity.api_id);
  delete entity.api_id;
  return await axios.patch<TypedIncoming_webhook>(`${apiUrl}/${apiID}`, helpers.cleanEntity(entity));
}, {
  serializeError: serializeAxiosError
});
export const deleteEntity = createAsyncThunk('incoming_webhook/delete_entity', async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.delete<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});

/**
 * Get log ...
 */
export const getIncomingLog = createAsyncThunk('incoming_webhook_log/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `webhook_incoming_log/${object.incoming_webhook_id}${EndURL}`;
  return axios.get<any>(requestUrl);
});

// slice

export const Reducer_Incoming_webhook = createEntitySlice({
  name: 'incoming_webhook',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getEntity.fulfilled, (state, action) => {
      state.loading = false;
      state.entity = action.payload.data;
    }).addCase(getEntity.rejected, (state, action) => {
      state.loading = false;
      state.entity = null;
    }).addCase(createEntity.rejected, (state, action) => {
      state.loading = false;
      state.entity = null;
      state.errorMessage = action.payload;
    }).addMatcher(isFulfilled(getEntities), (state, action) => {
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }).addMatcher(isFulfilled(getIncomingLog), (state, action) => {
      return {
        ...state,
        loadingLog: false,
        entities_log: action.payload.data,
        totalLogItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }).addMatcher(isRejected(createEntity, updateEntity, resetToken, partialUpdateEntity, deleteEntity), (state, action) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    }).addMatcher(isFulfilled(createEntity, updateEntity, resetToken, partialUpdateEntity), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = action.payload.data;
    }).addMatcher(isFulfilled(deleteEntity), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = null;
    }).addMatcher(isPending(getIncomingLog), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loadingLog = true;
    }).addMatcher(isPending(deleteEntity), state => {
      state.updating = true;
      state.loading = true;
      state.updateSuccess = false;
      state.entity = null;
    }).addMatcher(isPending(getEntities, getEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loading = true;
    }).addMatcher(isPending(createEntity, updateEntity, resetToken, partialUpdateEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.updating = true;
    });
  }
});
export const {
  clearError
} = Reducer_Incoming_webhook.actions;

// Reducer
export default Reducer_Incoming_webhook.reducer;