import React, { useCallback, useEffect, useRef, useState } from "react";
import { InlineGrid, Box, Text, Loading } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import dateandtime from "date-and-time";
import __ from "languages/index";
import { getEntities, reset } from 'store/user_activity.store.reducer';
import Pagination from "./pagination";
import For from 'components/For';
type TypedTimeline = {
  objectId: string;
  title: string;
};
export interface TypedUser_activity {
  activity_id?: string;
  user_id?: string;
  activity_type?: string;
  activity_source?: string;
  createdAt?: string;
}
export default function UserActivity({
  objectId,
  title
}: TypedTimeline) {
  // user_activity
  const customerActivityLoading = useAppSelector(state => state.user_activity.loading);
  const entities = useAppSelector(state => state.user_activity.entities);
  const totalItems = useAppSelector(state => state.user_activity.totalItems);
  const dispatch = useAppDispatch();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const [mainQuery, setMainQuery] = useState({
    query: "",
    page: 1,
    limit: 50,
    sort: "createdAt:desc",
    user_id: objectId
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  const queryCallback = useCallback(async () => {
    await dispatch(getEntities(mainQuery));
  }, [mainQuery]);
  useEffect(() => {
    queryCallback();
  }, [mainQuery]);
  const groups = useRef('');
  const commentBodyComponent = useCallback((element, index) => {
    let datetimeCreate = new Date(Number(element.createdAt));
    let {
      activity_type,
      activity_source
    } = element;
    let dateMonthYear = dateandtime.format(datetimeCreate, 'ddd, DD, MMM, YYYY'); // Fri, 28, Apr, 2023

    let A = false;
    if (dateMonthYear !== groups.current) {
      groups.current = dateMonthYear;
      A = true;
    }
    let N = dateMonthYear.toLowerCase().split(',');
    let M = __(N[0]) + ', ' + N[1] + ' ' + __(N[2]) + ', ' + N[3];
    return <React.Fragment key={`user_activity_${index}`}>
            {A && <div className="step-item">{M}</div>}
            <div className="card">
                <div className="timeline_handler"></div>
                <div className="time">
                    <span className="hour">{dateandtime.format(datetimeCreate, 'HH:mm')}</span>
                    <div className="author">{activity_type} - {activity_source}</div>
                </div>
            </div>    
        </React.Fragment>;
  }, []);
  return <>
            <InlineGrid gap="400">
                <Box padding="400">
                    <Text variant="headingMd" as="h6">
                        {title}
                    </Text>

                    <div className="timeline">
                        <div className="outer">
                            <For each={entities} fallback={<div style={{
              padding: '15px'
            }}><Text as="p">Chưa có một hoạt động nào.</Text></div>}>
                                {commentBodyComponent}
                            </For>
                        </div>
                    </div>

                    <Text variant="headingMd" as="h6">
                        {title}
                    </Text>
                </Box>
            </InlineGrid>
            {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} key="user_activity" /> : null}
            <br /><br />
        </>;
}