import React, { useCallback, useState } from 'react';
import { Text, Thumbnail, Link, Button, IndexTable, useBreakpoints } from '@shopify/polaris';
import { useDeleteProductVariant, useGetProductVariants } from 'queries/product_variant.query';
import __helpers from 'helpers/index';
import PopoverWithSearchableListbox from './productVariantGroupSearchBox';
import ProductVariantModalEdit from './product_variant_modal';
export default function ProductVariants({
  current_product_id
}: {
  current_product_id: string;
}) {
  const {
    data: entities
  } = useGetProductVariants(current_product_id);
  const {
    mutate: mutateDeleteProductVariant
  } = useDeleteProductVariant();
  const [showModal, setShowModal] = useState<any>(null);
  const deleteProductVariant = useCallback((id: string) => {
    mutateDeleteProductVariant(id);
  }, []);
  const breakPoint = useBreakpoints();
  const MainData = useCallback(() => {
    if (!Array.isArray(entities) || __helpers.isEmpty(entities)) return <Text as="p" tone="subdued">
          Chưa có một phiên bản khác nào!
        </Text>;
    return <IndexTable headings={[{
      title: ''
    }, {
      title: 'Tên'
    }, {
      title: 'Giá'
    }, {
      title: 'SKU'
    }, {
      title: 'Barcode'
    }, {
      title: '-'
    }]} selectable={false} sortable={[]} itemCount={entities?.length ?? 0} condensed={breakPoint.smDown}>
        {entities.map((el, index) => {
        return [<IndexTable.Row disabled={el.variant_status === 0} id={`product_variant_` + index} key={`product_variant_key_` + index} position={index}>
              <IndexTable.Cell>
                <Thumbnail source={__helpers.getMediaLink(el.variant_thumbnail_to_media ? el.variant_thumbnail_to_media.media_thumbnail['128x128'] : undefined)} alt="" size="small" />
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div style={{
              width: '120px'
            }}>
                  <Link onClick={() => setShowModal(el.variant_id)}>{el.variant_name}</Link>
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text as="h3" fontWeight="bold" tone="caution">
                  {__helpers.formatNumber(el.variant_price)} đ
                </Text>
                <Text as="p" variant="bodyXs" tone="disabled">
                  <del>{__helpers.formatNumber(el.variant_original_price)} đ</del>
                </Text>
              </IndexTable.Cell>
              <IndexTable.Cell>{el.variant_sku ?? '-'}</IndexTable.Cell>
              <IndexTable.Cell>{el.variant_barcode ?? '-'}</IndexTable.Cell>
              <IndexTable.Cell>
                <Button onClick={() => deleteProductVariant(el.variant_id)}>Xóa</Button>
              </IndexTable.Cell>
            </IndexTable.Row>];
      })}
      </IndexTable>;
  }, [entities]);
  return <>
      <ProductVariantModalEdit variant_id={showModal} show={!!showModal} onClose={() => {
      setShowModal(null);
    }} />
      {current_product_id ? <>
          <PopoverWithSearchableListbox current_product_id={current_product_id} />
          <MainData />
        </> : <>
          <Text as="p" tone="subdued">
            Lưu bản nháp trước, sau đó sẽ thêm được các phiên bản của sản phẩm...
          </Text>
        </>}
    </>;
}