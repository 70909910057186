import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedProduct_to_collection } from 'queries/product_collections.query';


interface getQuery extends IQueryParams, TypedProduct_to_collection { };

const initialState = {
    loading: false,
    errorMessage: null,
    entities: null as TypedProduct_to_collection[],
    entity: null as TypedProduct_to_collection,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};
const apiUrl = 'product_to_collection';

// Actions

export const getEntities = createAsyncThunk('product_to_collection/fetch_entity_list', async (object: getQuery) => {
    const EndURL = helpers.buildEndUrl(object);
    return axios.get<TypedProduct_to_collection>(`${apiUrl}${EndURL}`);
});

export const createEntity = createAsyncThunk(
    'product_to_collection/create_entity',
    async (entity: any) => {
        return await axios.post<TypedProduct_to_collection>(`${apiUrl}`, helpers.cleanEntity(entity));
    },
    { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
    'product_to_collection/delete_entity',
    async ({ collection_id, product_id }: { collection_id: string, product_id: string }) => {
        return await axios.delete<any>(`${apiUrl}/bulk_delete`, {
            data: { collection_id, product_id }
        });
    },
    { serializeError: serializeAxiosError }
);

// slice

export const reducer_product_to_collection = createEntitySlice({
    name: 'product_to_collection',
    initialState,
    reducers: {
        clearError: (state) => {
            state.errorMessage = null;
            state.updateSuccess = false;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(deleteEntity.rejected, (state, action) => {
                state.loading = false;
                state.entity = null;
                state.errorMessage = "Can not delete this Entity"
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: (action.payload as any).data,
                    entity: null,
                    totalItems: parseInt(action.payload.headers['x-total-count'], 10),
                };
            })
            .addMatcher(isFulfilled(createEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload.data;
            })
            .addMatcher(isFulfilled(deleteEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = null;
            })
            .addMatcher(isPending(deleteEntity), state => {
                state.updating = true;
                state.loading = true;
                state.updateSuccess = false;
                state.entity = null;
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            })

    },
});

export const { clearError, reset } = reducer_product_to_collection.actions;

// Reducer
export default reducer_product_to_collection.reducer;
