import { BlockStack, Card, DescriptionList, InlineGrid, Page, Tabs, TextField, Text, Checkbox, ContextualSaveBar, Select, Grid } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import __ from 'languages/index';
import { useGetSettings, useUpdateSetting } from 'queries/setting.query';
import React, { Suspense, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
export default function AffiliateSetup() {
  const QuickSearchWallet = React.lazy(() => import('components/quickSearchWallet'));
  const {
    data: entities,
    isLoading: loading
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `must_add_referrer,referrer_general_setting_user_condition,referrer_general_setting_direct_user_receive_value,referrer_general_setting_direct_user_receive_wallet`
  });
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const useFields = {
    must_add_referrer: useField<number>({
      value: Number(entities?.must_add_referrer ?? 0),
      validates: []
    }),
    referrer_general_setting_user_condition: useField<string>({
      value: entities?.referrer_general_setting_user_condition ?? 'after_active_account',
      validates: []
    }),
    referrer_general_setting_direct_user_receive_value: useField<string>({
      value: entities?.referrer_general_setting_direct_user_receive_value ?? '',
      validates: []
    }),
    referrer_general_setting_direct_user_receive_wallet: useField<string>({
      value: entities?.referrer_general_setting_direct_user_receive_wallet ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          must_add_referrer: values.must_add_referrer,
          referrer_general_setting_user_condition: values.referrer_general_setting_user_condition,
          referrer_general_setting_direct_user_receive_value: values.referrer_general_setting_direct_user_receive_value,
          referrer_general_setting_direct_user_receive_wallet: values.referrer_general_setting_direct_user_receive_wallet
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return <>
      <Helmet>
        <title>Cài đặt quy tắc Affiliate</title>
      </Helmet>
      <Page title="Cài đặt Affiliate">
        <DescriptionList items={[{
        term: 'Cài đặt chung',
        description: <Card>
                  <BlockStack gap="400">
                    <Checkbox label="Bắt buộc thêm mã giới thiệu khi đăng ký" helpText="Khi đăng ký mới tài khoản bằng hình thức nào, đều sẽ yêu cầu thêm mã giới thiệu." checked={fields.must_add_referrer.value === 1} onChange={e => fields.must_add_referrer.onChange(Number(e))} />
                    <Text as="h3" variant="headingMd">
                      Thưởng cho người giới thiệu trực tiếp
                    </Text>
                    <Select label="Điều kiện người đăng ký mới" options={[{
              label: 'Chỉ cần gia nhập',
              value: 'after_active_account'
            }, {
              label: 'Sau khi xác minh profile',
              value: 'after_profile_verified'
            }]} helpText="Khi người đăng ký mới thêm mã giới thiệu, nếu đạt tiêu chí này, người giới thiệu trực tiếp sẽ nhận được phần thưởng." onChange={v => fields.referrer_general_setting_user_condition.onChange(v)} value={String(fields.referrer_general_setting_user_condition.value)} />

                    <Grid>
                      <Grid.Cell columnSpan={{
                xs: 6,
                sm: 3,
                md: 3,
                lg: 6,
                xl: 6
              }}>
                        <TextField label="Họ sẽ được?" autoComplete="off" {...fields.referrer_general_setting_direct_user_receive_value} />
                      </Grid.Cell>

                      <Grid.Cell columnSpan={{
                xs: 6,
                sm: 3,
                md: 3,
                lg: 6,
                xl: 6
              }}>
                        <Suspense fallback={<Text as="span">Đợi một chút</Text>}>
                          <QuickSearchWallet title="Ví" current_wallet_id={entities?.referrer_general_setting_direct_user_receive_wallet} onClose={wallet_id => useFields.referrer_general_setting_direct_user_receive_wallet.onChange(wallet_id)} />
                        </Suspense>
                      </Grid.Cell>
                    </Grid>
                  </BlockStack>
                </Card>
      }, {
        term: 'Cài đặt nâng cao',
        description: <> </>
      }]} />
      </Page>

      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      onAction: () => submit(),
      loading: updating,
      disabled: updating
    }} discardAction={{
      onAction: () => resetForm()
    }} />}
    </>;
}