import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../config/store';
import Sidebar from '../config/sidebar';
import Logo from 'media/images/logo.svg';
import { ActionList, Frame, Toast, Icon, TopBar } from '@shopify/polaris';
import { ExitIcon, ProfileIcon, PhoneInIcon, ChatIcon, NotificationIcon, SettingsFilledIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import SidebarPopup from 'components/sidebarPopup';
import PhoneCallLog from './phoneCall';
import SMSLog from './smsLog';
import NotificationLog from './notificationLog';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useUserLogout } from 'queries/user.query';
import { useAuth } from 'AuthContext';
import { useNavigate } from 'react-router-dom';
import { useNotification } from 'NotificationContext';
import __helpers from 'helpers/index';

// rootReducer.js

export default function AppFrame({
  children,
  hasSidebar = true
}: {
  children: any;
  hasSidebar?: boolean;
}) {
  const history = useNavigate();
  /**
   * Check login ...
   */

  const skipToContentRef = useRef(null);
  const {
    isAuthenticated,
    user: account
  } = useAuth();
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);
  const handleSearchFieldChange = useCallback(value => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const toggleUserMenuActive = useCallback(() => setUserMenuActive(_userMenuActive => !_userMenuActive), []);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive(_mobileNavigationActive => !_mobileNavigationActive), []);
  const {
    mutateAsync: logMeOut,
    isSuccess: isLogoutSuccess
  } = useUserLogout();
  useEffect(() => {
    if (isLogoutSuccess) {
      history('/login');
    }
  }, [isLogoutSuccess]);
  const logOutCallback = useCallback(async () => {
    helpers.cookie_delete('AT');
    helpers.cookie_delete('RT');
    helpers.cookie_delete('EA');
    /** xóa app id để không dính lỗi SSO */
    localStorage.setItem('redirect', window.location.href);
    // load lại để log out ra ngoài ...
    await logMeOut();
  }, []);
  const userMenuMarkup = !helpers.isEmpty(account) ? <TopBar.UserMenu customWidth="250px" actions={[{
    items: [{
      content: __('my_profile'),
      url: '/profile',
      icon: ProfileIcon
    }, {
      content: __('edit_my_profile'),
      url: '/edit-my-profile',
      icon: SettingsFilledIcon
    }]
  }, {
    items: [{
      content: __('logout'),
      icon: ExitIcon,
      onAction: () => logOutCallback()
    }]
  }]} name={account?.display_name || account?.user_email || account?.user_login} detail={account?.user_email} initials={String(account?.display_name || account?.user_email || account?.user_login || 'unknown').charAt(0)} open={userMenuActive} avatar={account.user_avatar ? __helpers.getMediaLink(account.user_avatar) : null} onToggle={toggleUserMenuActive} /> : <TopBar.UserMenu actions={[{
    items: [{
      content: __('login'),
      url: '/login',
      icon: ExitIcon
    }, {
      content: __('register'),
      url: '/register',
      icon: ProfileIcon
    }]
  }]} name={__('welcome')} detail={__('customer')} initials="U" open={userMenuActive} onToggle={toggleUserMenuActive} />;
  const searchResultsMarkup = <ActionList items={[{
    content: 'Shopify help center'
  }, {
    content: 'Community forums'
  }]} />;
  const searchFieldMarkup = <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search" />;

  /**
   * Nút show bẹn website
   */
  const [showPhoneCallLog, setShowPhoneCallLog] = useState(false);
  const [showSMSLog, setShowSMSLog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const secondaryMenuMarkup = <TopBar.Menu key="OpenPhoneBook" activatorContent={<span>
          <Icon source={PhoneInIcon} />
        </span>} open={false} actions={[]} onOpen={() => setShowPhoneCallLog(true)} onClose={() => {}} />;
  const smsSidebarMenuActivator = <TopBar.Menu key="OpenSMS" activatorContent={<span>
          <Icon source={ChatIcon} />
        </span>} open={false} actions={[]} onOpen={() => {
    setShowSMSLog(true);
  }} onClose={() => {}} />;
  const notificationSidebarMenuActivator = <TopBar.Menu key="OpenNotification" activatorContent={<span>
          <Icon source={NotificationIcon} />
        </span>} open={false} actions={[]} onOpen={() => setShowNotification(true)} onClose={() => alert('close notification')} />;
  const topBarMarkup = <TopBar showNavigationToggle userMenu={userMenuMarkup} searchResultsVisible={searchActive} searchField={isAuthenticated && searchFieldMarkup} searchResults={searchResultsMarkup} onSearchResultsDismiss={handleSearchResultsDismiss} onNavigationToggle={toggleMobileNavigationActive} secondaryMenu={isAuthenticated ? [secondaryMenuMarkup, smsSidebarMenuActivator, notificationSidebarMenuActivator] : []} />;
  const logo = {
    width: 150,
    topBarSource: Logo,
    url: '/',
    accessibilityLabel: 'nBee'
  };

  /** Global notification */
  const {
    notification,
    clearNotification
  } = useNotification();
  return <>
      <Frame topBar={topBarMarkup} logo={logo} navigation={hasSidebar && isAuthenticated && <Sidebar />} showMobileNavigation={mobileNavigationActive} onNavigationDismiss={toggleMobileNavigationActive} skipToContentTarget={skipToContentRef.current}>
        {notification && <Toast content={notification.message} duration={5000} onDismiss={() => clearNotification(notification.id)} error={notification.type === 'error'} />}
        {isAuthenticated && <>
            <SidebarPopup title="Phone call" show={showPhoneCallLog} onClose={() => setShowPhoneCallLog(false)}>
              <PhoneCallLog show={showPhoneCallLog} />
            </SidebarPopup>

            <SidebarPopup title="SMS" show={showSMSLog} onClose={() => setShowSMSLog(false)}>
              <SMSLog show={showSMSLog} />
            </SidebarPopup>

            <SidebarPopup title="Thông báo" show={showNotification} onClose={() => setShowNotification(false)}>
              <NotificationLog show={showNotification} />
            </SidebarPopup>
          </>}

        {children}
      </Frame>
    </>;
}