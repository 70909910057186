import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BlockStack, Button, Card, DataTable, Form, Link, Modal, TextField } from '@shopify/polaris';
import { SaveIcon, DeleteIcon } from '@shopify/polaris-icons';
import DeleteConfirmModal from 'components/deleteConfirm';
import { useField, useForm } from '@shopify/react-form';
import __ from 'languages/index';
import helpers from 'helpers/index';
import { useCreatePresent, useDeletePresent, useGetGameLuckyListPresent } from 'queries/game.query';
export function PresentList() {
  const {
    data: presents
  } = useGetGameLuckyListPresent({
    limit: 20
  });
  const {
    mutateAsync: createPresent
  } = useCreatePresent();
  const {
    mutateAsync: deletePresent
  } = useDeletePresent();
  const [presentListing, setPresentListing] = useState([]);
  useEffect(() => {
    if (!presents) return;
    setPresentListing(presents);
  }, [presents]);
  const [loadingCreatePresent, setLoadingCreatePresent] = useState(false);
  const useFields = {
    present_name: useField<string>({
      value: '',
      validates: []
    }),
    present_slug: useField<string>({
      value: '',
      validates: []
    })
  };
  const {
    submit,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      setLoadingCreatePresent(true);
      await helpers.sleep(1000);
      try {
        await createPresent({
          present_name: values.present_name,
          present_slug: values.present_slug
        });
        setShowCreateModal(false);
        resetForm();
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      setLoadingCreatePresent(false);
      return {
        status: 'success'
      };
    }
  });

  /** Present */

  const deletePresentCallback = useCallback(async (present_id: any) => {
    await deletePresent(present_id);
  }, []);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const delete_id = useRef('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  useEffect(() => {
    useFields.present_slug.onChange(helpers.slugify(useFields.present_name.value));
  }, [useFields.present_name.value]);
  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [showCreateModal]);
  return <>
      <Modal open={showCreateModal} title={'Tạo mới một quà tặng'} onClose={() => {
      setShowCreateModal(false);
    }} primaryAction={{
      content: __('save'),
      icon: SaveIcon,
      disabled: !dirty,
      loading: loadingCreatePresent,
      onAction: submit
    }}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <BlockStack gap="500">
              <TextField label="Tên của quà tặng" autoComplete="off" {...useFields.present_name} />
              <TextField label="Present slug" autoComplete="off" {...useFields.present_slug} />
            </BlockStack>
          </Form>
        </Modal.Section>
      </Modal>

      <DeleteConfirmModal show={showDeleteModal} onClose={(agree: boolean) => {
      setShowDeleteModal(false);
      if (agree) deletePresentCallback(delete_id.current);
    }} title={'Xóa quà tặng?'} content="Một khi quà tặng bị xóa, những người nhận được quà tặng trong danh sách cũng bị xóa." />

      <Link onClick={() => setShowCreateModal(true)}>Thêm quà tặng</Link>
      <br />
      <br />

      <DataTable columnContentTypes={['text', 'numeric', 'numeric', 'numeric']} headings={['Tên quà', 'Slug', '-']} rows={Array.isArray(presentListing) && presentListing.length > 0 ? presentListing.map((element, index) => {
      return [element.present_name, element?.present_slug, <Button variant="plain" icon={DeleteIcon} onClick={() => {
        setShowDeleteModal(true);
        delete_id.current = element.present_id;
      }}>
                    Xóa
                  </Button>];
    }) : []} />
    </>;
}