import { useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Text } from '@shopify/polaris';
import helpers from 'helpers/index';
import 'media/css/RichTextEditor.scss';
class MyUploadAdapter {
  loader: any;
  xhr: any;
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(file => new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject, file);
      this._sendRequest(file);
    }));
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open('POST', process.env.REACT_APP_AJAX_UPLOAD_URL, true);
    xhr.setRequestHeader('X-Authorization', helpers.cookie_get('AT'));
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;
    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: helpers.getMediaLink(response.media_url)
        // default: helpers.getMediaLink(response.media_filename),
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();
    data.append('files', file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}
export default function TextFieldRichText({
  label,
  initialData,
  onCallback
}: {
  label: string;
  initialData: string;
  onCallback: (content: string) => void;
}) {
  const callbackToMainEditor = useMemo(() => {
    return helpers.debounce((_value: string) => {
      onCallback?.call(this, _value);
    }, 1500);
  }, [onCallback]);
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    // Data chính là HTML out luôn đấy!
    // console.log({ event, editor, data });
    callbackToMainEditor(data);
  };
  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }
  return <div>
      {label && <Text as="h3">{label}</Text>}
      <CKEditor editor={ClassicEditor} onReady={editor => {
      // Set the min-height on editor ready
      const editableElement = editor.ui.view.editable.element;
      editableElement.style.minHeight = '200px';
      editableElement.style.overflowY = 'auto'; // Add this line
      // console.log('Editor is ready to use!', editor);
    }} data={initialData ?? ''} onChange={handleEditorChange} config={{
      language: 'vi',
      extraPlugins: [MyCustomUploadAdapterPlugin],
      placeholder: 'Nhập nội dung tại đây!'
    }} />
    </div>;
}