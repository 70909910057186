import { Card, Badge, Text, Link, Page, Button } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import __, { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import ProductCategoryModalAddAndEdit from './product_collection.edit.modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TypedProductCollection, useBatchReorder, useDeleteProductCollection, useGetProductCollections, useUpdateProductCollection } from 'queries/product_collections.query';
import DeleteConfirmModal from 'components/deleteConfirm';
import { DeleteIcon } from '@shopify/polaris-icons';
export default function ProductCollectionList() {
  const {
    mutateAsync: deleteProductCollection
  } = useDeleteProductCollection();
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'collection_order:asc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((page: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      ...{
        page,
        limit
      }
    });
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const {
    data: collectionDT,
    refetch: getEntities
  } = useGetProductCollections(mainQuery);
  const {
    isSuccess: updateSuccess,
    isPending: updating,
    mutate: updateEntity
  } = useUpdateProductCollection();
  const [entities, setEntities] = useState<TypedProductCollection[]>(null);
  const [totalItems, setTotalItems] = useState(0);
  const {
    mutate: batchReorder
  } = useBatchReorder();
  useEffect(() => {
    if (!collectionDT) return;
    let {
      body,
      totalItems
    } = collectionDT;
    setEntities(body);
    setTotalItems(totalItems);
  }, [collectionDT]);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product Collection', '/product_collection' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  useEffect(() => {
    if (updateSuccess === true) {
      reduceRequestMemo(mainQuery);
    }
  }, [updateSuccess]);
  const [showModal, setShowModal] = useState(false);
  const handleOnDragEnd = useCallback(result => {
    if (!result.destination) return;
    let cloneEntities = [...entities, ...[]];
    const [reorderedItem] = cloneEntities.splice(result.source.index, 1);
    cloneEntities.splice(result.destination.index, 0, reorderedItem);
    setEntities(cloneEntities);
    let reorderData = {};
    let index = 0;
    for (let d of cloneEntities) {
      index++;
      reorderData = {
        ...reorderData,
        ...{
          [d.collection_id]: index
        }
      };
    }
    batchReorder(reorderData);
  }, [entities]);

  // delete ?
  const currentDeleteID = useRef<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteCallback = useCallback((id: any) => {
    currentDeleteID.current = id;
    setShowDeleteModal(true);
  }, []);
  const deleteProductCategoryQueryCallback = useCallback(async () => {
    try {
      await deleteProductCollection(currentDeleteID.current);
    } catch (e) {}
    setShowDeleteModal(false);
  }, [currentDeleteID.current]);
  return <>
      <Helmet>
        <title>Quản lý bộ sưu tập sản phẩm</title>
      </Helmet>

      <DeleteConfirmModal show={showDeleteModal} onClose={async agree_or_not => {
      if (agree_or_not) {
        await deleteProductCategoryQueryCallback();
      } else {
        setShowDeleteModal(false);
      }
    }} title={'Xóa chuyên mục bài đăng'} content="Chỉ các chuyên mục không có bài đăng nào mới có thể xóa bỏ. Nếu chuyên mục còn bài đăng, vui lòng di chuyển sang chuyên mục khác trước." />

      <ProductCategoryModalAddAndEdit show={showModal} onClose={() => {
      setShowModal(false);
    }} current_collection_id={null} />

      <Page title="Bộ sưu tập sản phẩm" primaryAction={{
      content: 'Tạo mới bộ sưu tập',
      onAction: () => setShowModal(true)
    }}>
        <Card padding={'0'}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="Polaris-DataTable__ScrollContainer">
              <Droppable droppableId="table">
                {provided => <table {...provided.droppableProps} ref={provided.innerRef} className="Polaris-DataTable__Table">
                    <thead>
                      <tr>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">STT</th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Tên collection
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Mô tả ngắn
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Hiển thị
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--header">
                          Sản phẩm
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Ngày tạo
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric">
                          -
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entities?.map((collectionData, index) => <Draggable key={collectionData.collection_id} draggableId={collectionData.collection_id} index={index}>
                          {provided => <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{index + 1}</td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                <Link removeUnderline url={'view/' + collectionData.collection_id} key={index}>
                                  <Text as="span">{collectionData.collection_name}</Text>
                                </Link>
                              </td>

                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                {collectionData.collection_excerpt}
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                {collectionData.collection_status === 1 ? <Badge tone="success">Có</Badge> : <Badge tone="warning">Không</Badge>}
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                                {helpers.formatNumber(collectionData._count.product_to_collection)}
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                {dateandtime.format(new Date(Number(collectionData.createdAt)), 'DD-MM-YYYY')}
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                                <Button variant="plain" onClick={() => deleteCallback(collectionData.collection_id)} icon={DeleteIcon}></Button>
                              </td>
                            </tr>}
                        </Draggable>)}
                      {provided.placeholder}
                    </tbody>
                  </table>}
              </Droppable>
            </div>
          </DragDropContext>
        </Card>
        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}