import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import { TypedProduct } from "interface/product.model";
import queryClient from 'queries';

export interface TypedProductCollection {
    collection_id?: string;
    collection_status?: number;
    collection_order?: number;
    collection_name?: string;
    collection_slug?: string;
    collection_excerpt?: string;
    collection_description?: string;
    createdAt?: bigint;

    _count?: {
        product_to_collection: number
    }
}



export interface TypedProduct_to_collection {
    ID?: bigint;
    product_id?: bigint;
    collection_id?: bigint;
    createdAt?: bigint;

    product?: TypedProduct
}

interface IQuery extends IQueryParams, TypedProductCollection { };


/*
* List of all app
*/
export function useGetProductCollections(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product_collection/fetch_entity_list"],
        queryFn: () => axios.get<TypedProductCollection[]>(`/product_collection/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetProductCollection(collection_id: string) {
    return useQuery({
        queryKey: ['product_collection/fetch_entity'],
        queryFn: () => axios.get<TypedProductCollection>(`/product_collection/${collection_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useGetMutationProductCollection() {
    return useMutation({
        mutationKey: ['product_collection/fetch_entity'],
        mutationFn: (collection_id: string) => axios.get<TypedProductCollection>(`/product_collection/${collection_id}`).then((res) => res.data),
        retry: 1,
        // enabled: false,
    });
}


export function useUpdateProductCollection() {
    return useMutation({
        mutationKey: ['product_collection/update_entity'],
        mutationFn: ({ collection_id, ...rest }: TypedProductCollection) => axios.patch<any>(`/product_collection/${collection_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_collection/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['product_collection/fetch_entity'] });
        }
    });
}


export function useCreateProductCollection() {
    return useMutation({
        mutationKey: ['product_collection/create_entity'],
        mutationFn: (entity: TypedProductCollection) => axios.post<any>(`/product_collection`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_collection/fetch_entity_list'] });
        }
    });
}


export function useDeleteProductCollection() {
    return useMutation({
        mutationKey: ['product_collection/delete_entity'],
        mutationFn: (collection_id: string) => axios.delete<any>(`/product_collection/${collection_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_collection/fetch_entity_list'] });
        }
    });
}
export function useBatchReorder() {
    return useMutation({
        mutationKey: ['product_collection/batch_reorder'],
        mutationFn: (reorderData: any) => axios.patch<any>(`/product_collection/batch_reorder`, helpers.cleanEntity(reorderData))
    });
}