import { ActionList, Badge, Button, DataTable, Form, Icon, LegacyCard, Link, Loading, Popover, Text, TextField, Tooltip } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'config/store';
import orderDeliverType from '../../order/order.delivery.json';
import orderStatusJson from 'config/order.status.json';
import __, { ___ } from 'languages';
import NoteComponent from 'components/noteComponent';
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import FilterAdvance from '../../order/filter';
import { PrintIcon, ReceiptRefundIcon, XSmallIcon, StatusActiveIcon, AlertDiamondIcon, ViewIcon, LockIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
import { useBulkUpdateOrder, useMutateGetOrderDetail, useGetOrders, useUpdateOrder } from 'queries/orders.query';
import { useGetLogistics } from 'queries/logistic_service.query';
import { useUpdateorder_fulfillment } from 'queries/order_fulfillment.query';
export default function CampaignOrderList({
  campaign_id
}) {
  /**
   * Nếu bên khác truyền state sang thì có thể dùng luôn để filter ...
   */

  const {
    mutateAsync: updateOrderFulfillment
  } = useUpdateorder_fulfillment();
  const initialQuery = {
    query: '',
    page: 1,
    limit: 100,
    order_status: null,
    payment_status: null,
    customer_campaign: campaign_id,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);
  const {
    data: rootData,
    isLoading: loading,
    refetch: getEntities
  } = useGetOrders(mainQuery);
  const {
    mutateAsync: updateEntity,
    isPending: updating
  } = useUpdateOrder();
  const {
    mutateAsync: getOrder,
    data: entity
  } = useMutateGetOrderDetail();
  const {
    mutateAsync: bulkUpdateEntities
  } = useBulkUpdateOrder();
  const {
    data: logisticEntities
  } = useGetLogistics({
    sort: 'createdAt:desc',
    limit: 100,
    logistic_status: 1
  });
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (rootData) {
      let {
        body,
        totalItems
      } = rootData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [rootData]);
  const mergeEntityToMainStream = async entity => {
    let cloneDataInternal = entities?.map(el => {
      if (entity && el.order_id === entity.order_id) {
        return {
          ...el,
          ...entity
        };
      }
      return el;
    });
    setEntities(cloneDataInternal);
  };

  /**
   * Get one record and update to main Data...
   */
  const reloadOrderData = useCallback(async order_id => {
    await getOrder(order_id);
    // if ( orderData?.payload?.data ) {
    // mergeEntityToMainStream(orderData.payload.data);
    // }
  }, []);

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage: number, Limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: Limit
    });
  }, [mainQuery]);
  const reduceRequest = useCallback(mainQuery => {
    getEntities(mainQuery).catch(e => {});
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  function goToThisCustomer(customer_id: any) {
    history('/customer/view/' + customer_id);
  }
  function getOrderDeliveryMethod(_method: string | number) {
    return __(orderDeliverType[_method]) || <Badge></Badge>;
  }
  function getOrderStatusBadge(order_status: number): any {
    let name = orderStatusJson[order_status] || '';
    switch (order_status) {
      case 10:
      case 11:
      case 12:
      case 13:
        return <Badge tone="info" progress="partiallyComplete">
            {name}
          </Badge>;
      case 14:
        return <Badge tone="success" progress="complete">
            {name}
          </Badge>;
      case 15:
        return <Badge tone="attention" progress="complete">
            {name}
          </Badge>;
      case 16:
        return <Badge tone="critical">{name}</Badge>;
      case 19:
        return <Badge tone="critical">{name}</Badge>;
      default:
        return <Badge>Chờ xác nhận</Badge>;
    }
  }
  function getPaymentStatusBadge(status: number) {
    switch (status) {
      case 0:
        return <Badge></Badge>;
      case 1:
        return <Badge tone={'warning'} progress={'partiallyComplete'}>
            T.Toán một phần
          </Badge>;
      case 2:
        return <Badge tone={'success'} progress={'complete'}>
            Đã thanh toán
          </Badge>;
      case 3:
        return <Badge tone="critical" progress="incomplete">
            Chờ hoàn tiền
          </Badge>;
      case 4:
        return <Badge tone="critical" progress="incomplete">
            Hoàn tiền
          </Badge>;
      default:
        return <Badge></Badge>;
    }
  }

  /**!SECTION
   * ============================================================================================
   */

  /**
   * Khi update một record, chúng ta có thể update vào thẳng trong data, đừng gọi lại thêm kẻo tốn băng thông!
   */

  useEffect(() => {
    // if ( ! updateSuccess ) return;
    if (!entity) return;
    mergeEntityToMainStream(entity);
  }, [entity]);

  /** Mọi update đều vào đây ... */
  const quickUpdateOrder = useCallback(async (_v: any) => {
    await dispatch(updateEntity(_v));
  }, []);
  const quickUpdateOrderFulfillment = useCallback(async (_v: any) => {
    try {
      let userFulfillment = await updateOrderFulfillment(_v);
      reloadOrderData(userFulfillment.order_id);
    } catch (e) {
      console.error(e, 'ERROR_786875jkhbgk');
    }
  }, []);

  /**
   * THay đổi loại giao vận
   */
  const [showDeliveryType, setShowDeliveryType] = useState(null);
  const onClosePophoverOderCallback = useCallback((order_id: any) => {
    setShowDeliveryType(!order_id);
  }, []);
  const togglePophoverOrderCallback = useCallback((_s: any) => {
    setShowDeliveryType(showDeliveryType === _s ? false : _s);
  }, [showDeliveryType]);
  const savingDeliveryTypeCallback = useCallback((order_id: any, order_delivery_type: any) => {
    quickUpdateOrder({
      order_id: order_id,
      order_delivery_type: order_delivery_type
    });
    onClosePophoverOderCallback(order_id);
  }, []);

  /**
   * Thay đổi đơn vị vận chuyển
   * @param media
   * @returns
   */

  const [showLogisticService, setShowLogisticService] = useState(null);
  const onCloseLogisticServiceCallback = useCallback((order_id: any) => {
    setShowLogisticService(!order_id);
  }, []);
  const toggleLogisticServiceCallback = useCallback((_s: any) => {
    setShowLogisticService(showLogisticService === _s ? false : _s);
  }, [showLogisticService]);
  const savingLogisticServiceCallback = useCallback((order_id: any, logistic_id: any) => {
    quickUpdateOrderFulfillment({
      order_id: order_id,
      logistic_id: logistic_id
    });
    onCloseLogisticServiceCallback(order_id);
  }, []);

  /**!SECTION
   * Get all logistic service and memo it
   */

  const findLogistic = useCallback((keyword: string, order_id: any, current_logistic_id: any): any[] => {
    let matchLogistic = [];
    for (let logistic of logisticEntities?.body) {
      if (logistic.logistic_delivery_method.includes(keyword)) matchLogistic.push({
        content: logistic.logistic_name,
        onAction: () => savingLogisticServiceCallback(order_id, logistic.logistic_id),
        suffix: logistic.logistic_id === current_logistic_id && <Icon source={StatusActiveIcon} />,
        active: logistic.logistic_id === current_logistic_id
      });
    }
    return matchLogistic;
  }, [logisticEntities, savingLogisticServiceCallback]);

  /**
   * Thay đổi mã vận chuyển
   */

  const [currentFulfillmentCode, setCurrentFulfillmentCode] = useState('');
  const [showLogisticFulfillmentCode, setShowLogisticFulfillmentCode] = useState(null);
  const onCloseFulfillmentCallback = useCallback((order_id: any) => {
    setShowLogisticFulfillmentCode(!order_id);
  }, []);
  const toggleFulfillmentCallback = useCallback((_s: any, current_value: string) => {
    setShowLogisticFulfillmentCode(showLogisticFulfillmentCode === _s ? false : _s);
    setCurrentFulfillmentCode(current_value);
  }, [showLogisticFulfillmentCode]);
  const savingFulfillmentCallback = useCallback(async () => {
    quickUpdateOrderFulfillment({
      order_id: showLogisticFulfillmentCode,
      order_fulfillment_code: currentFulfillmentCode
    });
    onCloseFulfillmentCallback(showLogisticFulfillmentCode);
  }, [showLogisticFulfillmentCode, currentFulfillmentCode]);

  /**
   * Ghi chú đơn hàng ...
   */

  const [noteForOrder, setNoteForOrder] = useState('');
  const [showPophoverOrderNote, setShowPophoverOrderNote] = useState(null);
  const onClosePophoverOderNoteCallback = useCallback((customer_campaign_id: any) => {
    setShowPophoverOrderNote(!customer_campaign_id);
    setNoteForOrder('');
  }, []);
  const togglePophoverOrderNoteCallback = useCallback((_s: any, order_note: string) => {
    setShowPophoverOrderNote(showPophoverOrderNote === _s ? false : _s);
    setNoteForOrder(order_note);
  }, [showPophoverOrderNote]);
  const submitNoteOrderCallback = useCallback(async (value: string, order_id: any) => {
    await quickUpdateOrder({
      order_id: order_id,
      order_note: value
    });
    setShowPophoverOrderNote(null);
    setNoteForOrder('');
  }, []);

  /**
   * Action menu ...
   */
  const [showActionList, setShowActionList] = useState(null);
  const onCloseActionList = useCallback((order_id: any) => {
    setShowActionList(!order_id);
  }, []);
  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);

  /**
   * markAsChecked
   * @param order_pnr String
   * @param mode String
   */
  const markAsChecked = async (order_pnr: string, order_id: any, mode: string) => {
    setShowActionList(null);
    bulkUpdateEntities({
      order_pnr: order_pnr,
      mode: mode
    }).catch(e => {});
    reloadOrderData(order_id);
  };

  /**
   * Order status
   */

  const [showPopupForChangeOrderStatus, setShowPopupForChangeOrderStatus] = useState('');
  const changeOrderStatus = useCallback(async (order_id: any, new_status: number) => {
    setShowPopupForChangeOrderStatus('');
    await quickUpdateOrder({
      order_id: order_id,
      order_status: Number(new_status)
    });
  }, []);

  /**
   * END order Status
   */

  const renderItem = (orderData: any) => {
    const {
      order_id,
      order_pnr,
      order_status,
      order_note,
      order_total_price,
      order_fulfillment,
      order_delivery_type,
      order_checked,
      payment_status,
      user_id,
      user,
      customer_data,
      createdAt,
      createdBy,
      updatedAt
    } = orderData;
    let fulfillmentData = order_fulfillment || null;
    let order_fulfillment_error = fulfillmentData?.order_fulfillment_error;
    return [order_id, <Popover active={showActionList === order_id} activator={<Button variant="plain" disclosure={'down'} onClick={() => togglePophoverActionListCallback(order_id)}>
            {order_pnr}
          </Button>} autofocusTarget="first-node" onClose={() => onCloseActionList(order_id)}>
        <ActionList actionRole="menuitem" sections={[{
        title: 'Chung',
        items: [{
          content: 'Xem chi tiết',
          icon: ViewIcon,
          onAction: () => history('/order/view/' + order_id)
        }, {
          content: 'In Packing Slip',
          icon: PrintIcon,
          onAction: () => history(process.env.REACT_APP_BACKEND_URL + '/orders/packing_slip/print/' + order_id)
        }]
      }, {
        title: 'Đối soát',
        items: [{
          content: 'Hoàn thành đơn',
          icon: StatusActiveIcon,
          disabled: order_checked,
          onAction: () => markAsChecked(order_pnr, order_id, '14')
        }, {
          content: 'Hủy đơn',
          icon: XSmallIcon,
          disabled: order_checked,
          onAction: () => markAsChecked(order_pnr, order_id, '19')
        }, {
          content: 'Hoàn đơn',
          icon: ReceiptRefundIcon,
          disabled: order_checked,
          onAction: () => markAsChecked(order_pnr, order_id, '15')
        }]
      }]} />
      </Popover>, <div className="small-icon">
        {order_checked === 1 ? <Tooltip content="Đã đối soát">
            <Text as="span">
              <LockIcon />
            </Text>
          </Tooltip> : ''}
      </div>, createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm') : '-', order_status > 0 ? getOrderStatusBadge(order_status) : <Popover active={showPopupForChangeOrderStatus === order_id} activator={<>
              <Link onClick={() => setShowPopupForChangeOrderStatus(order_id)}>{getOrderStatusBadge(order_status)}</Link>
            </>} autofocusTarget="first-node" onClose={() => setShowPopupForChangeOrderStatus('')}>
          <ActionList actionRole="menuitem" items={[{
        content: 'Khách hủy đơn',
        onAction: () => changeOrderStatus(order_id, 1)
      }, {
        content: 'Khách đồng ý lên đơn',
        onAction: () => changeOrderStatus(order_id, 2)
      }]} />
        </Popover>, order_checked ? getOrderDeliveryMethod(order_delivery_type) : <Popover active={showDeliveryType === order_id} activator={<Link removeUnderline onClick={() => togglePophoverOrderCallback(order_id)}>
              {order_delivery_type ? getOrderDeliveryMethod(order_delivery_type) : <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </Link>} onClose={() => onClosePophoverOderCallback(order_id)} ariaHaspopup={false}>
          <ActionList actionRole="menuitem" items={Object.entries(orderDeliverType).map(([key, value]) => {
        return {
          content: __(value),
          onAction: () => savingDeliveryTypeCallback(order_id, key),
          suffix: String(order_delivery_type) === key && <Icon source={StatusActiveIcon} />,
          active: String(order_delivery_type) === key
        };
      })} />
        </Popover>, order_checked ? fulfillmentData?.logistic_service?.logistic_name || <>
            <Badge></Badge>
            <Badge></Badge>
          </> : <Popover active={showLogisticService === order_id} activator={<Link removeUnderline onClick={() => toggleLogisticServiceCallback(order_id)}>
              {fulfillmentData?.logistic_service ? fulfillmentData?.logistic_service?.logistic_name : <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </Link>} onClose={() => onCloseLogisticServiceCallback(order_id)} ariaHaspopup={false}>
          <ActionList actionRole="menuitem" sections={[{
        items: findLogistic(orderDeliverType[order_delivery_type], order_id, fulfillmentData?.logistic_service?.logistic_id)
      }]} />
        </Popover>, order_checked || order_status !== 2 ? fulfillmentData?.order_fulfillment_code || <>
            <Badge></Badge>
            <Badge></Badge>
          </> : <Popover active={showLogisticFulfillmentCode === order_id} activator={<Link removeUnderline onClick={() => toggleFulfillmentCallback(order_id, fulfillmentData?.order_fulfillment_code || '')}>
              {fulfillmentData?.order_fulfillment_code ? fulfillmentData?.order_fulfillment_code : <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </Link>} onClose={() => onCloseFulfillmentCallback(order_id)} ariaHaspopup={false} sectioned>
          <Form onSubmit={savingFulfillmentCallback}>
            <TextField label="Mã vận chuyển" value={currentFulfillmentCode} onChange={val => setCurrentFulfillmentCode(val)} autoComplete="off" />
          </Form>
        </Popover>, helpers.formatNumber(order_total_price, ','), getPaymentStatusBadge(payment_status), <div className="sale_order_note">
        <Popover active={showPophoverOrderNote === order_id} activator={<Link removeUnderline onClick={() => togglePophoverOrderNoteCallback(order_id, order_note)}>
              {order_note || <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </Link>} onClose={() => onClosePophoverOderNoteCallback(order_id)} ariaHaspopup={false} sectioned>
          <NoteComponent label="Ghi chú đơn hàng" default_string={noteForOrder} onSave={submitNoteOrderCallback} args={order_id} />
        </Popover>
      </div>, user_id ? <Link onClick={() => goToThisCustomer(user_id)}>
          {customer_data?.display_name || customer_data?.user_email || customer_data?.user_phonenumber || '---'}
        </Link> : <>
          <Badge></Badge>
          <Badge></Badge>
        </>];
  };
  const OrderList = <DataTable sortable={[]} defaultSortDirection="descending" initialSortColumnIndex={0} columnContentTypes={['numeric', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['ID', 'PNR', '', 'Ngày tạo', 'Tình trạng', 'Kho vận', 'Vận chuyển', 'D.vụ vận chuyển', 'Mã vận chuyển', 'Giá trị', 'Thanh toán', 'Ghi chú', 'Khách hàng']} rows={entities?.map(renderItem)} hideScrollIndicator
  // fixedFirstColumns={1}
  stickyHeader firstColumnMinWidth="100px" footerContent={___('Show page {display_page_number} of {total_record_number} results', {
    display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
    total_record_number: <Text as="strong">{totalItems}</Text>
  })} />;
  return <>
      {loading || updating ? <Loading /> : null}
      <LegacyCard>
        <FilterAdvance loading={loading} onCallback={filterCallback} />
        {OrderList}
      </LegacyCard>
      <br />
      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={Number(mainQuery?.page)} pageSize={mainQuery?.limit} onChange={onChangePageNumber} /> : null}
      <br />
      <br />
    </>;
}