import { TypedSms, getEntities, getSMSs, sendSMS } from 'store/sms.store.reducer';
import { useAppDispatch, useAppSelector } from 'config/store';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import 'media/css/SMS.scss';
import helpers from 'helpers/index';
import { Button, Form, Link, TextField } from '@shopify/polaris';
import { ChevronLeftIcon } from '@shopify/polaris-icons';
import __ from 'languages/index';
export default function SMSLog({
  show
}: {
  show: boolean;
}) {
  const entities = useAppSelector(state => state.sms.entities);
  const loading = useAppSelector(state => state.sms.loading);
  const dispatch = useAppDispatch();
  const loadData = useCallback(async () => {
    return await dispatch(getEntities({
      sort: 'createdAt:desc',
      limit: 15
    }));
  }, []);
  useEffect(() => {
    if (!show) return;
    loadData();
  }, [loadData, show]);
  const showSMSThreadListAgain = useCallback(() => {
    loadData();
    setShowDetailMode(false);
  }, []);
  const [showDetailMode, setShowDetailMode] = useState(false);
  const [smsEntity, setSmsEntity] = useState<TypedSms>();
  const loadSMSDetail = useCallback(async (thread_id: string | bigint) => {
    setShowDetailMode(true);
    dispatch(getSMSs({
      sms_thread_id: thread_id,
      sort: 'createdAt:asc',
      limit: 200
    })).unwrap().then(response => {
      let {
        data
      } = response;
      setSmsEntity(data);
    }).catch(e => {});
  }, []);
  const [smsText, setSmsText] = useState('');
  const [sendingSMS, setSendingSMS] = useState(false);
  const handleTextFieldChange = useCallback((value: string) => setSmsText(value), []);
  const sendSMSCallback = useCallback(async () => {
    setSendingSMS(true);
    let sms_thread_number = smsEntity?.sms_thread?.sms_thread_number;
    let sms_thread_id = smsEntity?.sms_thread?.sms_thread_id;
    dispatch(sendSMS({
      sms_thread_number: sms_thread_number,
      sms_content: smsText
    })).unwrap().then(e => {
      setSendingSMS(false);
      setSmsText('');
      loadSMSDetail(sms_thread_id);
    }).catch(e => {
      setSendingSMS(false);
    });
  }, [smsText, smsEntity]);
  useEffect(() => {
    setInterval(() => {
      const element = document.getElementById('sms_list');
      if (element) element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth'
      });
    }, 1000);
  }, []); // Ensure scroll only happens when ref changes

  return <>
      {loading && <div style={{
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: '2rem'
    }}>
          Loading...
        </div>}

      {!showDetailMode && <div id="sms_thread_wrap">
          <ul className="sms_inner">
            {entities?.map((el, index) => {
          return <li key={index}>
                  <Link onClick={() => loadSMSDetail(el.sms_thread_id)}>
                    <div className="wrap">
                      <div className="name">{el.sms_thread_number}</div>
                      <div className="datetime">{helpers.subtractTimeHistory(el.updatedAt)}</div>
                    </div>
                    <div className={`sms_short_detail ` + (el.sms_thread_read === 0 ? 'unread' : 'read')}> HAHAH HOO HOO </div>
                  </Link>
                </li>;
        })}
          </ul>
        </div>}

      {showDetailMode && <div id="sms_thread_wrap">
          <div id="sms_thread">
            <div className="sms_header">
              <Button variant="plain" icon={ChevronLeftIcon} onClick={showSMSThreadListAgain}>
                {smsEntity?.sms_thread?.sms_thread_number || '-'}
              </Button>
            </div>
            <div className="sms_body">
              <ul id="sms_list" className="direct-chat-messages">
                {smsEntity?.sms?.map((el, index) => {
              return <li key={`sms-` + index} id={`sms-${el.sms_id}`} className={`sms-mode ${el.sms_mode} direct-chat-msg`}>
                      <div className="direct-chat-text">{el.sms_content}</div>
                      <div className="direct-chat-timestamp">{helpers.subtractTimeHistory(el.createdAt)}</div>
                    </li>;
            })}
              </ul>
            </div>

            <div className="sms_footer">
              <Form onSubmit={sendSMSCallback}>
                <TextField label="SMS content" labelHidden placeholder="SMS có tính phí ..." value={smsText} showCharacterCount onChange={handleTextFieldChange} autoComplete="off" loading={sendingSMS} />
              </Form>
            </div>
          </div>
        </div>}
    </>;
}