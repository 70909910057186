import { Card, Text, Link, Page, Popover, DataTable, Button, ActionList } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "../../helpers";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, DuplicateIcon, UndoIcon, ClipboardCheckIcon } from "@shopify/polaris-icons";
import dateandtime from 'date-and-time';
import Pagination from "components/pagination";
import { clearError, getEntities, updateStatus, deleteEntity } from "store/email.outgoing.store.reducer";
import SimpleFilter from "components/simpleFilter";
import DeleteConfirm from "components/deleteConfirm";
export default function Webmail_outgoing() {
  const entities = useAppSelector(state => state.email_outgoing.entities);
  const loading = useAppSelector(state => state.email_outgoing.loading);
  const totalItems = useAppSelector(state => state.email_outgoing.totalItems);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const rootSearchPath = window.location.search;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: "",
    page: 1,
    limit: 50,
    sort: "createdAt:desc"
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  useEffect(() => {
    dispatch(clearError());
  }, []);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((page: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      ...{
        page,
        limit
      }
    });
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, "Email outgoing", "/webmail" + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    // console.log(_value, '_value');
    // return;
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  /**
   * email_sent_status
   * -1 mean cancel
   * 0 mean draft
   * 1 mean in schedule
   * 2 mean sent
   * 9 mean fail
   */

  function emailSentStatus(_emailStatus: string | number) {
    switch (_emailStatus) {
      case -1:
        return <Button variant="plain" disclosure>Hủy</Button>;
      case 0:
        return <Button variant="plain" disclosure>Bản nháp</Button>;
      case 1:
        return <Button variant="plain" disclosure>Chuẩn bị gửi</Button>;
      case 2:
        return <Button variant="plain" disabled>Đã gửi</Button>;
      case 9:
        return <Button variant="plain" disclosure>Gửi lỗi</Button>;
      default:
        return <Button variant="plain" disclosure>Bản nháp</Button>;
    }
  }

  /**
   * For button only ...
   */
  const [showPophoverStatus, setShowPophoverStatus] = useState(null);
  const togglePophoverStatusCallback = useCallback(async (id: any) => {
    setShowPophoverStatus(id);
  }, []);
  const onClosePophoverStatusCallback = useCallback(() => {
    setShowPophoverStatus(null);
  }, []);
  const changeCampaignStatus = useCallback(async (email_id: any, new_status: number) => {
    onClosePophoverStatusCallback();
    await dispatch(updateStatus({
      id: email_id,
      email_sent_status: new_status
    }));
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const current_email_id = useRef(null);
  const deleteThisEmail = useCallback((id: any) => {
    setShowDeleteModal(true);
    current_email_id.current = id;
  }, [current_email_id]);
  const deleteAfterConfirm = useCallback(e => {
    if (!e) return;
    dispatch(deleteEntity(current_email_id.current));
    setShowDeleteModal(false);
    current_email_id.current = null;
    reduceRequestMemo(mainQuery);
  }, [current_email_id, mainQuery]);
  const renderItem = (item: any) => {
    let {
      id,
      to_email,
      cc,
      bcc,
      subject,
      createdAt,
      email_outgoing_meta
    } = item;
    return [to_email, helpers.trimContentString(subject, 50), email_outgoing_meta?.email_sent_status === 2 ? emailSentStatus(2) : <Popover key={id} active={showPophoverStatus === id} activator={<Link onClick={() => togglePophoverStatusCallback(id)}>
                    {emailSentStatus(email_outgoing_meta?.email_sent_status)}
                  </Link>} onClose={() => onClosePophoverStatusCallback()} ariaHaspopup={false}>
                <ActionList actionRole="menuitem" items={[{
        content: 'Hủy gửi',
        icon: UndoIcon,
        disabled: email_outgoing_meta?.email_sent_status !== 1,
        onAction: () => changeCampaignStatus(id, -1)
      }, {
        content: 'Chuyển sang bản nháp',
        icon: DuplicateIcon,
        onAction: () => changeCampaignStatus(id, 0)
      }, {
        content: 'Lên lịch để gửi',
        icon: ClipboardCheckIcon,
        onAction: () => changeCampaignStatus(id, 1)
      }, {
        content: 'Xóa bỏ',
        icon: DeleteIcon,
        onAction: () => deleteThisEmail(id)
      }]} />
            </Popover>, dateandtime.format(new Date(Number(email_outgoing_meta?.email_sent_schedule || 0)), 'DD-MM-YYYY HH:mm'), dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm')];
  };
  return <>
        <DeleteConfirm title="Bạn có chắc muốn xóa Email này?" show={showDeleteModal} onClose={e => deleteAfterConfirm(e)} />
        <Page fullWidth title="Hòm thư đi" primaryAction={{
      content: 'Soạn email',
      onAction: () => history('/webmail/new')
    }}>
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'Đang gửi',
          value: '1',
          field: 'email_outgoing_meta.email_sent_status'
        }, {
          label: 'Đã gửi',
          value: '2',
          field: 'email_outgoing_meta.email_sent_status'
        }, {
          label: 'Bản nháp',
          value: '0',
          field: 'email_outgoing_meta.email_sent_status'
        }, {
          label: 'Đã hủy',
          value: '-1',
          field: 'email_outgoing_meta.email_sent_status'
        }, {
          label: 'Gửi lỗi',
          value: '9',
          field: 'email_outgoing_meta.email_sent_status'
        }]} sortField={[]} />
          <DataTable sortable={[false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={["text", "text", "text", "text", "text"]} headings={['Gửi đến', 'Subject', 'Tình trạng', "Lịch gửi", "Tạo lúc"]} rows={entities ? entities?.map(renderItem) : []} hideScrollIndicator hasZebraStripingOnData increasedTableDensity />

        </Card>
        
        <br />
            {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}

      </Page>
      <br /><br />

      </>;
}