import { Page } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import crownICON from 'media/images/crown.svg';
// import medalICON from 'media/medal.svg';
// import cupICON from 'media/cup.svg';

import { PlusIcon } from '@shopify/polaris-icons';
import AddAchievementModal from './achievement.new';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import SkeletonLoading from 'components/skeletonPageLoading';
import { TypedUserAchievement, useGetAchivements } from 'queries/user_archivement.query';
export default function AchievementList() {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => setFirstLoad(false), []);
  const [showModal, setShowModal] = useState(false);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 100,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    isLoading,
    refetch: getEntities
  } = useGetAchivements(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage: number, Limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: Limit
    });
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Achievement', '/achievement' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);
  const closeModal = useCallback(() => {
    setShowModal(false);
    getEntities();
  }, []);
  const ActualPage = <Page title="Huy chương và thành tích" subtitle="Dành cho tất cả mọi người" primaryAction={{
    content: 'Tạo mới',
    icon: PlusIcon,
    onAction: () => setShowModal(true)
  }}>
      {entities && <div className="user_achievement">
          {entities?.map((achivement: TypedUserAchievement, index: number) => {
        const {
          achievement_name,
          achievement_badge,
          achievement_id
        } = achivement;
        let i = achievement_badge ? process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + achievement_badge : crownICON;
        return <Link key={'dsfg_' + index} className={`badge orange user_achivement_element`} to={`/achievement/view/${achievement_id}`}>
                <div className="circle">
                  <img alt="" src={i} width="100%" height="100%" />
                </div>
                <div className="ribbon">{achievement_name}</div>
              </Link>;
      })}
        </div>}

      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePageNumber} /> : null}
    </Page>;
  return <>
      <AddAchievementModal open={showModal} onClose={closeModal} />
      {firstLoad ? <SkeletonLoading /> : ActualPage}
    </>;
}