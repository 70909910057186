import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice } from 'config/reducer.utils';
import helpers from 'helpers/index';

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

export interface TypedSms_thread {
  sms_thread_id?: string;
  sms_thread_number?: string;
  sms_thread_read?: number;
  sms_source?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface SMSElement {
  sms_id?: bigint;
  sms_content?: string;
  sms_thread_id?: bigint;
  sms_mode?: string;
  createdAt?: string;
}

export interface TypedSms {
  sms: SMSElement[];
  sms_thread?: TypedSms_thread;
}



const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as TypedSms_thread[],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'sms_thread';


export const getEntities = createAsyncThunk('sms_thread/fetch_entities', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  return await axios.get<any>(`${apiUrl}${EndURL}`);
});

export const deleteEntity = createAsyncThunk('sms_thread/delete_entity', async (sms_thread_id: bigint | string) => {
  return await axios.delete<any>(`${apiUrl}/${sms_thread_id}`);
});


export const getSMSs = createAsyncThunk('sms/fetch_entities', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  return await axios.get<any>(`sms${EndURL}`);
});

export const sendSMS = createAsyncThunk('sms/create_entities', async (object: any) => {
  return await axios.post<any>(`sms`, object);
});


// slice

export const Reducer_smslog = createEntitySlice({
  name: 'sms',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.errorMessage = null;
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })
  },
});

export const { clearError } = Reducer_smslog.actions;
// Reducer
export default Reducer_smslog.reducer;
