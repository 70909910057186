import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedProductWishlist {
    wishlist_id: bigint;
    user_id: bigint;
    product_id: bigint;
    variant_id: bigint | null;
    hash: string;
    createdAt: bigint;

    "user"?: {
        "user_avatar": string,
        "display_name": string,
        "user_id": string,
    },

    "product": {
        "product_id": string,
        "product_name": string,
        "product_slug": string,
        "product_status": string,
        "product_thumbnail_to_media": TypedMedia
    },
    "product_variant": {
        "variant_name": string,
        "variant_slug": string,
        "variant_status": string,
        "variant_thumbnail": string,
    }


}


type IQuery = TypedProductWishlist & IQueryParams;


/*
* List of all product_wishlist
*/
export function useGetProductWishlist(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product_wishlist/fetch_entity_list"],
        queryFn: () => axios.get<TypedProductWishlist[]>(`/product_wishlist${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}
