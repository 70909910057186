import { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { Page, Tabs, BlockStack } from '@shopify/polaris';
import { getEntity, updateEntity } from '../../store/customer.store.reducer';
import { StarFilledIcon, PlusIcon } from '@shopify/polaris-icons';
import CustomerNew from './customer.new';
import SkeletonLoading from 'components/skeletonPageLoading';
import CustomerGeneralDetail from './customer_detail/customer.general.information';
import CustomerOtherInformation from './customer_detail/customer.other.information';
import UserAchievement from 'components/user_achivement';
import UserActivity from 'components/user_activity';
import CustomerEditInformation from './customer_detail/customer.edit';
import ChangeCustomerPassword from './customer_detail/customer.password';
import CustomerLoginHistory from './customer_detail/customer.login.history';
import ActivityTracing from 'components/activityTracing';
import helpers from 'helpers';
import { Helmet } from 'react-helmet-async';
import UserFinance from './customer_detail/userFinance';
import CustomerAddressBook from './customer_detail/customer.address.book';
export default function CustomerView() {
  const entity = useAppSelector(state => state.customer.entity);
  const [loading, setLoading] = useState(true);

  /**
   * Get all orders of this customer
   */

  const dispatch = useAppDispatch();
  let {
    customer_id
  } = useParams();
  let {
    hash
  } = useLocation();
  const loadData = useCallback(async user_id => {
    setLoading(true);
    await dispatch(getEntity(user_id));
    await helpers.sleep(1000);
    setLoading(false);
  }, []);
  useEffect(() => {
    if (customer_id) {
      loadData(customer_id);
    }
  }, [customer_id]);

  /**
   * New model
   */

  const [newModelactive, setNewModelactive] = useState(false);
  const toggleThisCustomerIsVip = useCallback(async entity => {
    if (!entity) return;
    let newValue = !entity?.customer_to_user?.is_vip;
    dispatch(updateEntity({
      user_id: customer_id,
      is_vip: Number(newValue || 0)
    }));
  }, []);
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    window.location.hash = '#tab-' + selectedTabIndex;
  }, []);
  useEffect(() => {
    if (hash) {
      setTabselected(Number(String(hash || ' ').replace('#tab-', '')));
    } else {
      setTabselected(0);
    }
  }, [hash]);
  const tabs = [{
    id: 'tab_0',
    content: 'Thông tin chung',
    panelID: 'general'
  }, {
    id: 'tab_1',
    content: 'Thành tựu',
    panelID: 'achived'
  }, {
    id: 'tab_2',
    content: 'Tương tác',
    panelID: 'user_react'
  }, {
    id: 'tab_3',
    content: 'Trace',
    panelID: 'activity_trace'
  }, {
    id: 'tab_5',
    content: 'Chỉnh sửa tài khoản',
    panelID: 'edit_user_account'
  }, {
    id: 'tab_6',
    content: 'Thông tin thanh toán',
    panelID: 'user_payment_information'
  }, {
    id: 'tab_7',
    content: 'Mật khẩu',
    panelID: 'user_password'
  }, {
    id: 'tab_8',
    content: 'Lịch sử đăng nhập',
    panelID: 'user_login_history'
  }, {
    id: 'tab_9',
    content: 'Sổ địa chỉ',
    panelID: 'user_address_book'
  }];
  return <>
      <Helmet>
        <title>{entity?.display_name}</title>
      </Helmet>

      {loading ? <SkeletonLoading /> : entity ? <Page title={''} backAction={{
      content: 'Danh sách khách hàng',
      url: '/customer'
    }} secondaryActions={[{
      content: entity?.customer_to_user?.is_vip ? 'Bỏ khỏi VIP' : 'Đánh dấu VIP',
      icon: StarFilledIcon,
      onAction: () => {
        toggleThisCustomerIsVip(entity);
      }
    }, {
      content: 'Thêm mới',
      icon: PlusIcon,
      onAction: () => {
        setNewModelactive(true);
      }
    }]}>
          <BlockStack gap="400">
            <CustomerGeneralDetail customerData={entity} />

            <div className="custom_tabs" style={{
          margin: '0 -15px'
        }}>
              <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>
              <div style={{
            margin: '15px'
          }}>
                {tabs[tabselected].panelID === 'general' && <CustomerOtherInformation />}
                {tabs[tabselected].panelID === 'achived' && <UserAchievement user_id={entity?.user_id} showList />}
                {tabs[tabselected].panelID === 'user_react' && <UserActivity objectId={customer_id} title="Tương tác" />}
                {tabs[tabselected].panelID === 'activity_trace' && <ActivityTracing userID={customer_id} />}{' '}
                {tabs[tabselected].panelID === 'edit_user_account' && <CustomerEditInformation onClose={() => loadData(customer_id)} entity={entity} />}
                {tabs[tabselected].panelID === 'user_payment_information' && <UserFinance current_user_id={entity?.user_id} />}
                {tabs[tabselected].panelID === 'user_password' && <ChangeCustomerPassword user_data={entity} onClose={() => loadData(customer_id)} />}
                {tabs[tabselected].panelID === 'user_login_history' && <CustomerLoginHistory user_id={customer_id} />}
                {tabs[tabselected].panelID === 'user_address_book' && <CustomerAddressBook user_id={customer_id} />}
              </div>
            </div>
          </BlockStack>
        </Page> : null}

      <CustomerNew onClose={() => setNewModelactive(false)} show={newModelactive} />
    </>;
}