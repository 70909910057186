import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Divider, Grid, InlineGrid, InlineStack, Select, SkeletonDisplayText, Text, BlockStack } from '@shopify/polaris';
import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import helpers from '../../helpers';
import { clearError, getTop5, getGeneralAnalytics } from '../../store/analytics.store.reducer';
import { useAppDispatch, useAppSelector } from '../../config/store';
import DateRangePicker from '../../components/dateRangePicker';
export default function Analytics() {
  const top5people = useAppSelector(state => state.analytics.top5people);
  const loading = useAppSelector(state => state.analytics.loading);
  const dispatch = useAppDispatch();
  const [generalAnalyticsData, setGeneralAnalyticsData] = useState<any>({});
  const [loadingGeneralAnalytics, setLoadingGeneralAnalytics] = useState(false);
  useEffect(() => {
    setLoadingGeneralAnalytics(true);
    dispatch(getGeneralAnalytics()).unwrap().then(response => {
      let {
        data
      } = response;
      setGeneralAnalyticsData(data);
      setLoadingGeneralAnalytics(false);
    }).catch(e => {
      setLoadingGeneralAnalytics(false);
    });
  }, []);
  const totalSaleViaAllChanelOption: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {},
    grid: {
      left: 50,
      top: 50,
      right: 50,
      bottom: 50
    },
    xAxis: [{
      type: 'category',
      data: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
      axisPointer: {
        type: 'shadow'
      }
    }],
    yAxis: [{
      type: 'value',
      name: 'Tổng số khách',
      alignTicks: true,
      axisLabel: {
        formatter: '{value}'
      }
    }],
    series: [{
      name: 'Số khách',
      type: 'bar',
      // prettier-ignore
      data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
    }, {
      name: 'Số chuyển đổi',
      type: 'bar',
      // prettier-ignore
      data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3]
    }]
  };
  const SourceAddCustomer: ReactEChartsProps['option'] = {
    title: {
      text: 'Referer of a Website',
      subtext: 'ABC DEF hahaha',
      top: 0
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0',
      left: 'center'
    },
    series: [{
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [{
        value: 1048,
        name: 'Search Engine'
      }, {
        value: 735,
        name: 'Direct'
      }, {
        value: 580,
        name: 'Email'
      }, {
        value: 484,
        name: 'Union Ads'
      }, {
        value: 300,
        name: 'Video Ads'
      }]
    }]
  };
  const NightingaleChart: ReactEChartsProps['option'] = {
    title: {
      text: 'NightingaleChart',
      subtext: 'ABC DEF hahaha',
      top: 0
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0%',
      left: 'center',
      selectedMode: false
    },
    series: [{
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '70%'],
      // adjust the start angle
      startAngle: 180,
      label: {
        show: true,
        formatter(param) {
          // correct the percentage
          return param.name + ' (' + param.percent * 2 + '%)';
        }
      },
      data: [{
        value: 1048,
        name: 'Search Engine'
      }, {
        value: 735,
        name: 'Direct'
      }, {
        value: 580,
        name: 'Email'
      }, {
        value: 484,
        name: 'Union Ads'
      }, {
        value: 300,
        name: 'Video Ads'
      }, {
        // make an record to fill the bottom 50%
        value: 1048 + 735 + 580 + 484 + 300,
        itemStyle: {
          // stop the chart from rendering this piece
          color: 'none',
          decal: {
            symbol: 'none'
          }
        },
        label: {
          show: false
        }
      }]
    }]
  };
  return <div style={{
    padding: '25px'
  }}>
      <Text as="h1" variant="heading2xl">
        Dashboard
      </Text>
      <Text as="h3" variant="headingMd">
        Toàn cảnh việc kinh doanh của bạn
      </Text>
      <br />

      <InlineGrid gap="400" columns={{
      xs: 1,
      sm: 1,
      md: 1,
      lg: ['twoThirds', 'oneThird'],
      xl: ['twoThirds', 'oneThird']
    }}>
        <div className="dashboard_column_1_wrap">
          <div style={{
          padding: '15px'
        }}>
            <InlineStack wrap={false} gap={'200'} align="space-between" blockAlign="start">
              <InlineGrid columns={['oneThird', 'twoThirds']} alignItems="center">
                <img src={require('media/images/4l.png')} width={'50px'} alt="" />
                <BlockStack>
                  {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                      <Text as="h3" fontWeight="regular" variant="heading2xl">
                        {generalAnalyticsData?.newCustomerToday} khách mới
                      </Text>
                      <Text as="p">(bao gồm cả lead)</Text>
                    </>}
                </BlockStack>
              </InlineGrid>

              <InlineGrid columns={['oneThird', 'twoThirds']} alignItems="center">
                <img src={require('media/images/4l.png')} width={'50px'} alt="" />
                <BlockStack>
                  {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                      <Text as="h3" fontWeight="regular" variant="heading2xl">
                        {generalAnalyticsData?.orderCountToday} đơn mới
                      </Text>
                      <Text as="p">(bao gồm cả đơn nháp)</Text>
                    </>}
                </BlockStack>
              </InlineGrid>

              <InlineGrid columns={['oneThird', 'twoThirds']} alignItems="center">
                <img src={require('media/images/4l.png')} width={'50px'} alt="" />
                <BlockStack>
                  {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                      <Text as="h3" fontWeight="regular" variant="heading2xl">
                        {generalAnalyticsData?.newTransactionToday} giao dịch
                      </Text>
                      <Text as="p">(mọi giao dịch)</Text>
                    </>}
                </BlockStack>
              </InlineGrid>
            </InlineStack>
          </div>
          <br />

          <Divider />
          <div>
            <br />
            <br />
            <InlineStack wrap={false} align="space-between" blockAlign="center">
              <BlockStack>
                <Text as="h3" variant="headingLg">
                  Total sells
                </Text>
                <Text as="span">Payment received across all channels</Text>
              </BlockStack>
              <Select label="Date range" labelInline options={[{
              label: 'Today',
              value: 'today'
            }, {
              label: 'Yesterday',
              value: 'yesterday'
            }, {
              label: 'Last 7 days',
              value: 'lastWeek'
            }]} onChange={null} value={null} />
            </InlineStack>
            <br />
            <br />

            <ReactECharts option={totalSaleViaAllChanelOption} style={{
            minHeight: '400px'
          }} graphic={undefined} />
          </div>
        </div>

        <div className="dashboard_column_2_wrap">
          <div style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '1px 1px 1px #CCC'
        }}>
            <ReactECharts option={SourceAddCustomer} style={{
            minHeight: '350px',
            padding: '15px'
          }} />
          </div>

          <br />

          <div style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '1px 1px 1px #CCC'
        }}>
            <ReactECharts option={NightingaleChart} style={{
            minHeight: '300px',
            padding: '15px'
          }} />
          </div>
        </div>
      </InlineGrid>
    </div>;
}