import { Button, DatePicker, InlineStack, IndexFilters, IndexFiltersProps, Link, Select, useSetIndexFiltersMode, Text } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dateandtime from 'date-and-time';
import helpers from '../../helpers';
import orderStatusList from 'config/order.status.json';
import orderDeliveryStatusList from './order.delivery.json';
import { getEntity, updateEntity, deleteEntity } from 'store/user.meta.store.reducer';
import { useAppDispatch, useAppSelector } from 'config/store';
import useDidMountEffect from 'components/useDidMountEffect';

/**
 * Check if filter is true
 * @param range_filter
 * @returns
 */
function isRangeNumber(range_filter: string): boolean {
  return String(range_filter || ' ').match(/(gte|gt|lt|lte):[0-9]+/g) ? true : false;
}

/**
 *
 * @param range_filter query string, eg: field_name=gt:23&field_name=lt:99999999999999
 * @returns
 */
function extractRangeNumber(range_filter: string): string {
  try {
    let q = String(range_filter || ' ').split(':');
    let cmpare = q[0];
    let cmpare_value = q[1];
    if (['gt', 'gte', 'lt', 'lte'].indexOf(cmpare) < 0) {
      return;
    }
    if (!helpers.isNumeric(cmpare_value)) return;
    if (!helpers.isBigInt(cmpare_value)) return;
    return cmpare_value;
  } catch (e) {
    return null;
  }
}
function formatDateRange(createdAt: string) {
  let d = [];
  let dateRangeSource = helpers.commaToArray(createdAt);
  if (isRangeNumber(dateRangeSource[0])) d.push(extractRangeNumber(dateRangeSource[0]));
  if (isRangeNumber(dateRangeSource[1])) d.push(extractRangeNumber(dateRangeSource[1]));
  return d;
}
export default function OrdersFilter({
  onCallback,
  loading
}: {
  onCallback: (output: any) => void;
  loading: boolean;
}) {
  const _loading = useAppSelector(state => state.user_meta.loading);
  const updating = useAppSelector(state => state.user_meta.updating);
  const dispatch = useAppDispatch();

  /**
   * Load data
   */

  const getItem = useCallback(async (meta_key: string) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await dispatch(getEntity(meta_key));
  }, []);
  const removeItem = useCallback(async (meta_key: string) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await dispatch(deleteEntity(meta_key));
  }, []);
  const updateItem = useCallback(async (meta_key: string, meta_value: any) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await dispatch(updateEntity({
      meta_key: meta_key,
      meta_value: meta_value
    }));
  }, []);

  /**
   * Load data
   */

  const rootSearchPath = window.location.search;
  let mainQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const {
    query,
    order_status,
    payment_status,
    order_delivery_type,
    sort,
    createdAt
  } = mainQuery;
  const [sortSelected, setSortSelected] = useState<string[]>([helpers.colonToOldSort(sort) || 'createdAt, desc']);
  const [queryValue, setQueryValue] = useState(query || '');
  const [orderStatus, setOrderStatus] = useState(order_status || '');
  const [paymentStatus, setPaymentStatus] = useState(payment_status || '');
  const [orderDeliveryStatus, setOrderDeliveryStatus] = useState(order_delivery_type || '');
  const [selected, setSelected] = useState(0); // tab selected
  const [dateRanges, setDateRanges] = useState<any>(formatDateRange(createdAt));
  /**
   * Change tab, load new data from url down to filter
   */
  const reloadDataDownFromURL = (__: any) => {
    const {
      query,
      order_status,
      payment_status,
      order_delivery_type,
      createdAt
    } = __;
    setQueryValue(query || '');
    setOrderStatus(order_status || '');
    setPaymentStatus(payment_status || '');
    setOrderDeliveryStatus(order_delivery_type || '');
    setDateRanges(formatDateRange(createdAt));
  };

  /***************************************************************************
  /***************************************************************************
  /***************************************************************************
  * FILTERs
  */

  function getCurrentSetting(): string {
    const rootSearchPath = window.location.search;
    let mainQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
    return JSON.stringify(mainQuery);
  }

  /**
   * reduce loading,
   */

  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onCallback?.call(this, _value);
    }, 200);
  }, []);
  useDidMountEffect(() => {
    if (!sortSelected) return;
    let sortString = '';
    if (Array.isArray(sortSelected)) {
      sortString = sortSelected.join('');
    } else {
      sortString = sortSelected;
    }
    onChangeMainQueryCallback({
      sort: helpers.oldSortToColon(sortString)
    });
  }, [sortSelected]);

  /**
   * Hold array of all tab
   */
  const [itemStrings, setItemStrings] = useState(['ALL']);
  useEffect(() => {
    getItem('order_tabs').then((response: any) => {
      try {
        let x = response?.payload?.data;
        if (x.length > 0) {
          setItemStrings(x);
        }
      } catch (_e) {}
    }).catch(_ => {});
  }, []);

  // save name to
  useDidMountEffect(() => {
    let tabsToString = JSON.stringify(itemStrings);
    updateItem('order_tabs', tabsToString);
  }, [itemStrings]);

  /**
   * click change tab ...
   * @param index
   * @returns
   */
  const changeTabFilterAction = useCallback(async (index: number, name: string) => {
    if (index === 0) {
      reloadDataDownFromURL({});
      return onChangeMainQueryCallback(false);
    }
    onChangeMainQueryCallback(false);
    await helpers.sleep(100);
    getItem(`tab_order_index_` + name).then((response: any) => {
      let currentSettingAfterParse = response?.payload?.data;
      if (!currentSettingAfterParse) return;
      onChangeMainQueryCallback(currentSettingAfterParse);
      reloadDataDownFromURL(currentSettingAfterParse);
    }).catch(_ => {});
  }, []);
  const deleteView = (index: number, value: string) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
    removeItem(`tab_order_index_` + value);
  };
  const duplicateView = async (name: string) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    // clone setting
    updateItem(`tab_order_index_` + name, getCurrentSetting());
    return true;
  };
  const tabs: any[] = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {
      changeTabFilterAction(index, item);
    },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: index === 0 ? [] : [{
      type: 'rename',
      onAction: () => {},
      onPrimaryAction: async (value: string): Promise<boolean> => {
        const newItemsStrings = tabs.map((item, idx) => {
          if (idx === index) {
            return value;
          }
          return item.content;
        });
        setItemStrings(newItemsStrings);
        return true;
      }
    }, {
      type: 'duplicate',
      onPrimaryAction: async (value: string): Promise<boolean> => {
        duplicateView(value);
        return true;
      }
    }, {
      type: 'delete',
      onPrimaryAction: async (value: string) => {
        deleteView(index, value);
        return true;
      }
    }]
  }));
  const {
    mode,
    setMode
  } = useSetIndexFiltersMode();
  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value);
    onChangeMainQueryCallback({
      query: value,
      page: 1
    });
  }, []);

  /**
   * Xoa hoac huy form search ...
   */
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('');
    onChangeMainQueryCallback({
      query: '',
      page: 1
    });
  }, []);
  const sortOptions: any[] = [{
    label: 'Ngày tạo',
    value: 'createdAt, asc',
    directionLabel: 'Ascending'
  }, {
    label: 'Ngày tạo',
    value: 'createdAt, desc',
    directionLabel: 'Descending'
  }, {
    label: 'Giá trị đơn hàng',
    value: 'order_total_price, asc',
    directionLabel: 'Ascending'
  }, {
    label: 'Giá trị đơn hàng',
    value: 'order_total_price, desc',
    directionLabel: 'Descending'
  }];
  const paymentStatusList = ['Chưa thanh toán', 'Thanh toán một phần', 'Đã thanh toán', 'Chờ hoàn tiền', 'Đã hoàn tiền'];
  function disambiguateLabel(key: string, value: string | number | any): string {
    switch (key) {
      case 'keyword':
        return `Từ khóa: ${value}`;
      case 'order_status':
        // value = Number(value);
        return `Tình trạng đơn hàng: ${orderStatusList[value] || 'Tất cả'}`;
      case 'payment_status':
        value = Number(value);
        return `Tình trạng thanh toán: ${paymentStatusList[value]}`;
      case 'order_delivery_type':
        // value = Number(value);
        return `Vận chuyển: ${orderDeliveryStatusList[value] || 'Tất cả phương thức'}`;
      case 'daterange':
        let from = value[0] ? dateandtime.format(new Date(Number(value[0])), 'DD/MM/YYYY') : '-';
        let to = value[1] ? dateandtime.format(new Date(Number(value[1])), 'DD/MM/YYYY') : '-';
        return `Khoảng thời gian: từ ${from} đến: ${to}`;
      default:
        return value as string;
    }
  }
  const handleorderStatusChange = useCallback((value: string) => {
    setOrderStatus(value);
    onChangeMainQueryCallback({
      order_status: value,
      page: 1
    });
  }, []);
  const handlePaymentStatusChange = useCallback((value: string) => {
    setPaymentStatus(value);
    onChangeMainQueryCallback({
      payment_status: value,
      page: 1
    });
  }, []);
  const handleDeliveryStatusChange = useCallback((value: string) => {
    setOrderDeliveryStatus(value);
    onChangeMainQueryCallback({
      order_delivery_type: value,
      page: 1
    });
  }, []);
  const handleorderStatusRemove = useCallback(() => {
    setOrderStatus('');
    onChangeMainQueryCallback({
      order_status: '',
      page: 1
    });
  }, []);
  const handlePaymentStatusRemove = useCallback(() => {
    setPaymentStatus('');
    onChangeMainQueryCallback({
      payment_status: '',
      page: 1
    });
  }, []);
  const handleDeliveryStatusRemove = useCallback(() => {
    setOrderDeliveryStatus('');
    onChangeMainQueryCallback({
      order_delivery_type: '',
      page: 1
    });
  }, []);
  const handCalendarRemove = useCallback(() => {
    setDateRanges([]);
    onChangeMainQueryCallback({
      createdAt: '',
      page: 1
    });
  }, []);
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleorderStatusRemove();
    handlePaymentStatusRemove();
    handleDeliveryStatusRemove();
    handCalendarRemove();
    onChangeMainQueryCallback(false);
  }, [handleQueryValueRemove, handleorderStatusRemove, handlePaymentStatusRemove, handleDeliveryStatusRemove, handCalendarRemove, onChangeMainQueryCallback]);

  /**
   * Date time filling ...
   */

  // date range
  const [{
    month,
    year
  }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  });
  const [{
    startDate,
    endDate
  }, setDateRange] = useState({
    startDate: new Date().getTime(),
    endDate: new Date().getTime()
  });
  function handleMonthChange(month: number, year: number): any {
    setDate({
      month,
      year
    });
  }
  function handleCalendarChange({
    start,
    end
  }): void {
    let startDate = start.valueOf();
    let endDate = end.valueOf();
    setDateRange({
      startDate,
      endDate
    }); // for calendar
    setDateRanges([startDate, endDate]); // for saving ...
    onChangeMainQueryCallback({
      createdAt: `gte:${startDate},lte:${endDate}`,
      page: 1
    });
  }
  const [orderStatusFilterOption, setOrderStatusFilterOption] = useState([{
    label: 'Tất cả tình trạng',
    value: ''
  }]);
  useEffect(() => {
    let c = [];
    for (let index in orderStatusList) {
      let r = {
        label: orderStatusList[index],
        value: index
      };
      c.push(r);
    }
    setOrderStatusFilterOption([...orderStatusFilterOption, ...c]);
  }, []);
  const [deliveryStatusFilterOption, setDeliveryStatusFilterOption] = useState([{
    label: 'Tất cả tình trạng',
    value: ''
  }]);
  useEffect(() => {
    let c = [];
    for (let index in orderDeliveryStatusList) {
      let r = {
        label: orderDeliveryStatusList[index],
        value: index
      };
      c.push(r);
    }
    setDeliveryStatusFilterOption([...deliveryStatusFilterOption, ...c]);
  }, []);
  const filters = [{
    key: 'order_status',
    label: 'Tình trạng đơn hàng',
    filter: <Select label="Tình trạng đơn hàng: " labelInline value={orderStatus} onChange={handleorderStatusChange} options={orderStatusFilterOption} />,
    shortcut: true
  }, {
    key: 'payment_status',
    label: 'Tình trạng thanh toán',
    filter: <Select label="Tình trạng thanh toán" labelInline value={paymentStatus} onChange={handlePaymentStatusChange} options={[{
      label: 'Tất cả',
      value: ''
    }, {
      label: 'Chưa thanh toán',
      value: '0'
    }, {
      label: 'Thanh toán một phần',
      value: '1'
    }, {
      label: 'Đã thanh toán',
      value: '2'
    }, {
      label: 'Chờ hoàn tiền',
      value: '3'
    }, {
      label: 'Đã hoàn tiền',
      value: '4'
    }]} />,
    shortcut: true
  }, {
    key: 'order_delivery_type',
    label: 'Phương thức vận chuyển',
    filter: <Select label="Phương thức vận chuyển" labelInline value={orderDeliveryStatus} onChange={handleDeliveryStatusChange} options={deliveryStatusFilterOption} />,
    shortcut: true
  }, {
    key: 'daterange',
    label: 'Khoảng thời gian',
    filter: <>
          <DatePicker month={month} year={year} selected={{
        start: new Date(startDate),
        end: new Date(endDate)
      }} onMonthChange={handleMonthChange} onChange={handleCalendarChange} multiMonth={false} allowRange />
        </>,
    shortcut: true
  }];
  const appliedFilters: IndexFiltersProps['appliedFilters'] = [];

  // if (!helpers.isEmpty(orderStatus)) {
  appliedFilters.push({
    key: 'order_status',
    label: disambiguateLabel('order_status', orderStatus),
    onRemove: handleorderStatusRemove
  });
  // }

  if (!helpers.isEmpty(paymentStatus)) {
    const key = 'payment_status';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, paymentStatus),
      onRemove: handlePaymentStatusRemove
    });
  }

  // if (!helpers.isEmpty(orderDeliveryStatus)) {
  appliedFilters.push({
    key: 'order_delivery_type',
    label: disambiguateLabel('order_delivery_type', orderDeliveryStatus),
    onRemove: handleDeliveryStatusRemove
  });
  // }

  if (!helpers.isEmpty(dateRanges)) {
    const key = 'daterange';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, dateRanges),
      onRemove: handCalendarRemove
    });
  }

  /**
   * Lưu lại ...
   * @param value
   * @returns
   */
  const onCreateNewView = async (value: string) => {
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    updateItem(`tab_order_index_` + value, getCurrentSetting());
    return true;
  };
  const onHandleSave = useCallback(async () => {
    if (!itemStrings[selected]) return;
    updateItem(`tab_order_index_` + itemStrings[selected], getCurrentSetting());
    return true;
  }, [selected, itemStrings]);
  const primaryActionForFilterFields: IndexFiltersProps['primaryAction'] = selected === 0 ? {
    type: 'save-as',
    onAction: onCreateNewView,
    disabled: false,
    loading: false
  } : {
    type: 'save',
    onAction: onHandleSave,
    disabled: false,
    loading: false
  };

  /***************************************************************************
  /***************************************************************************
  /***************************************************************************
   * FILTERs
   */

  return <>
      <IndexFilters loading={loading || _loading || updating} sortOptions={sortOptions} sortSelected={sortSelected} queryValue={queryValue} queryPlaceholder="Tìm kiếm" onQueryChange={handleFiltersQueryChange} onQueryClear={handleQueryValueRemove} onSort={setSortSelected} primaryAction={primaryActionForFilterFields} cancelAction={{
      onAction: () => {},
      disabled: false,
      loading: false
    }} tabs={tabs} selected={selected} onSelect={setSelected} canCreateNewView={true} onCreateNewView={onCreateNewView} onClearAll={handleFiltersClearAll} mode={mode} setMode={setMode} filters={filters} appliedFilters={appliedFilters} />
      {mode === 'DEFAULT' ? <div className="Polaris-Filters__FiltersWrapper Polaris-Filters__FiltersWrapperWithAddButton" id="filterWrapper">
          <div className="Polaris-Filters__FiltersInner">
            {!helpers.isEmpty(queryValue) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('keyword', queryValue)}
              </span> : null}

            {!helpers.isEmpty(orderStatus) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('order_status', orderStatus)}
              </span> : null}
            {!helpers.isEmpty(paymentStatus) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('payment_status', paymentStatus)}
              </span> : null}

            {!helpers.isEmpty(orderDeliveryStatus) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('order_delivery_type', orderDeliveryStatus)}
              </span> : null}

            {!helpers.isEmpty(dateRanges) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('daterange', dateRanges)}
              </span> : null}

            {helpers.isEmpty(queryValue) && helpers.isEmpty(orderStatus) && helpers.isEmpty(paymentStatus) && helpers.isEmpty(orderDeliveryStatus) && helpers.isEmpty(dateRanges) ? <InlineStack blockAlign="center" align="start" wrap={false}>
                <Link removeUnderline onClick={() => setMode('FILTERING' as any)}>
                  {'   '}{' '}
                  <Text as="span" variant="bodySm" tone="subdued">
                    mở bộ lọc (hoặc bấm nút F)
                  </Text>
                </Link>
              </InlineStack> : <Button variant="plain" size="micro" onClick={handleFiltersClearAll}>
                xóa hết
              </Button>}
          </div>
        </div> : null}
    </>;
}