import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import affiliate_list from './affiliate.list';
import affiliate_view from './affiliate.view';
import affiliate_setup from './affiliate.setup';

/**
 *   Create index file for Comment
 */

export default function List_comment() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = affiliate_list;
      break;
    case 'view':
      ActualPage = affiliate_view;
      break;
    case 'setup':
      ActualPage = affiliate_setup;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}