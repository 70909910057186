import { useGetStock } from 'queries/stock.query';
import { useNavigate, useParams } from 'react-router-dom';
import { EmptyState, Page, Tabs } from '@shopify/polaris';
import SkeletonPageLoading2 from 'components/skeletonPageLoading2';
import { Helmet } from 'react-helmet-async';
import { lazy, Suspense } from 'react';
const StockLogisticSetting = lazy(() => import('./components/stock_logistic_setting'));
export default function StockDetail() {
  let {
    stock_id
  } = useParams();
  let history = useNavigate();
  const {
    error,
    data: entity,
    isLoading
  } = useGetStock(stock_id);
  return <>
      <Helmet>
        <title>Thông tin kho hàng</title>
      </Helmet>
      {error ? <EmptyState heading="Có gì đó không đúng!" action={{
      content: 'Quay lại danh sách',
      onAction: () => history('/stock')
    }} image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
          <p>Không tìm thấy dữ liệu bạn yêu cầu.</p>
        </EmptyState> : isLoading ? <SkeletonPageLoading2 /> : <Page subtitle={entity?.stock_code} title={`Kho hàng ` + entity?.stock_name} backAction={{
      content: 'Quay lại danh sách',
      onAction: () => history('/stock')
    }}>
          <Suspense fallback={<>Đang tải...</>}>
            <StockLogisticSetting stock_id={stock_id} />
          </Suspense>
        </Page>}
    </>;
}