import { BlockStack, Button, Card, Checkbox, Combobox, EmptySearchResult, Form, Grid, LegacyStack, Listbox, Tag, Text, TextField, AutoSelection, Link } from '@shopify/polaris';
import { SaveIcon } from '@shopify/polaris-icons';
import { notEmptyString, useField, useForm } from '@shopify/react-form';
import TextFieldRichText from 'components/TextFieldRichText';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useUpdateGame } from 'queries/game.query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
export default function SettingLuckyWGame({
  entity
}: {
  entity: any;
}) {
  const {
    mutateAsync: updateEntity
  } = useUpdateGame();
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    if (entity) {
      let {
        game_setting
      } = entity;
      setSettings(game_setting);
    }
  }, [entity]);
  const useFields = {
    game_status: useField<number>({
      value: entity?.game_status || 0,
      validates: []
    }),
    game_title: useField<string>({
      value: entity?.game_title ?? '',
      validates: []
    }),
    game_excerpt: useField<string>({
      value: entity?.game_excerpt ?? '',
      validates: []
    }),
    game_description: useField<string>({
      value: entity?.game_description ?? '',
      validates: []
    }),
    lucky_time_from: useField<string>({
      value: String(settings?.lucky_time_from ?? '0'),
      validates: [inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Số không hợp lệ';
      }]
    }),
    lucky_time_to: useField<string>({
      value: String(settings?.lucky_time_to ?? '0'),
      validates: [inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Số không hợp lệ';
      }]
    }),
    lucky_time_per_day: useField<string>({
      value: String(settings?.lucky_time_per_day ?? '1'),
      validates: [inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Số không hợp lệ';
        if (Number(inputVal) < 1) return 'Số phải lớn hơn 0';
      }]
    }),
    lucky_day: useField<string>({
      value: String(settings?.lucky_day ?? 'sunday'),
      validates: [notEmptyString('Bắt buộc điền cho trường này!')]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          id: entity?.id,
          game_status: values.game_status,
          game_excerpt: values.game_excerpt,
          game_description: values.game_description,
          game_setting: {
            lucky_time_from: values.lucky_time_from,
            lucky_time_to: values.lucky_time_to,
            lucky_time_per_day: values.lucky_time_per_day,
            lucky_day: values.lucky_day
          }
        });
        await helpers.sleep(2000);
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /** COMBO BOX */

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [suggestion, setSuggestion] = useState('');
  useEffect(() => {
    useFields.lucky_day.onChange(selectedTags.join(','));
  }, [selectedTags]);
  useEffect(() => {
    if (settings?.lucky_day) {
      setSelectedTags(settings?.lucky_day?.split(','));
    }
  }, [settings?.lucky_day]);
  const handleActiveOptionChange = useCallback((activeOption: string) => {
    const activeOptionIsAction = activeOption === value;
    if (!activeOptionIsAction && !selectedTags.includes(activeOption)) {
      setSuggestion(activeOption);
    } else {
      setSuggestion('');
    }
  }, [value, selectedTags]);
  const updateSelection = useCallback((selected: string) => {
    const nextSelectedTags = new Set([...selectedTags]);
    if (nextSelectedTags.has(selected)) {
      nextSelectedTags.delete(selected);
    } else {
      nextSelectedTags.add(selected);
    }
    setSelectedTags([...nextSelectedTags]);
    setValue('');
    setSuggestion('');
  }, [selectedTags]);
  const removeTag = useCallback((tag: string) => () => {
    updateSelection(tag);
  }, [updateSelection]);
  const getAllTags = useCallback(() => {
    const savedTags = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    return [...new Set([...savedTags, ...selectedTags].sort())];
  }, [selectedTags]);
  const formatOptionText = useCallback((option: string) => {
    const trimValue = value.trim().toLocaleLowerCase();
    const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);
    if (!value || matchIndex === -1) return option;
    const start = option.slice(0, matchIndex);
    const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
    const end = option.slice(matchIndex + trimValue.length, option.length);
    return <p>
          {start}
          <Text fontWeight="bold" as="span">
            {highlight}
          </Text>
          {end}
        </p>;
  }, [value]);
  const options = useMemo(() => {
    let list;
    const allTags = getAllTags();
    if (value) {
      list = allTags.filter(tag => tag.match(value));
    } else {
      list = allTags;
    }
    return [...list];
  }, [value, getAllTags]);
  const verticalContentMarkup = selectedTags.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedTags.map(tag => <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
            {tag}
          </Tag>)}
      </LegacyStack> : null;
  const optionMarkup = options.length > 0 ? options.map(option => {
    return <Listbox.Option key={option} value={option} selected={selectedTags.includes(option)} accessibilityLabel={option}>
              <Listbox.TextOption selected={selectedTags.includes(option)}>{formatOptionText(option)}</Listbox.TextOption>
            </Listbox.Option>;
  }) : null;
  const noResults = value && !getAllTags().includes(value);
  const actionMarkup = noResults ? <Listbox.Action value={value}>{`Add "${value}"`}</Listbox.Action> : null;
  const emptyStateMarkup = optionMarkup ? null : <EmptySearchResult title="" description={`No tags found matching "${value}"`} />;
  const listboxMarkup = optionMarkup || actionMarkup || emptyStateMarkup ? <Listbox autoSelection={AutoSelection.None} onSelect={updateSelection} onActiveOptionChange={handleActiveOptionChange}>
        {actionMarkup}
        {optionMarkup}
      </Listbox> : null;
  return <Form onSubmit={submit}>
      <Card>
        <BlockStack gap="400">
          <Checkbox label="Cho phép chạy game" helpText="Nếu tắt, toàn bộ hoạt động cũng dừng theo" checked={fields.game_status.value === 1} onChange={e => fields.game_status.onChange(Number(e))} />

          <TextField readOnly label="Tên của Game này" autoComplete="off" {...fields.game_title} />
          <TextField label="Mô tả ngắn" autoComplete="off" {...fields.game_excerpt} />

          <TextFieldRichText label={'Nội dung / hướng dẫn'} initialData={entity?.game_description} onCallback={html => fields.game_description.onChange(html)} />

          <Grid>
            <Grid.Cell columnSpan={{
            xs: 6,
            sm: 3,
            md: 3,
            lg: 6,
            xl: 6
          }}>
              <TextField label="Thời gian quay thưởng từ" suffix="(24 giờ)" type="number" autoComplete="off" {...fields.lucky_time_from} />
            </Grid.Cell>
            <Grid.Cell columnSpan={{
            xs: 6,
            sm: 3,
            md: 3,
            lg: 6,
            xl: 6
          }}>
              <TextField label="Đến mấy giờ?" suffix="(24 giờ)" autoComplete="off" type="number" {...fields.lucky_time_to} />
            </Grid.Cell>
          </Grid>

          <Text as="p" tone="subdued">
            Chỉ quay thưởng vào trong khoảng giờ này trong ngày.
          </Text>

          <TextField label="Số lần quay tối đa cho phép trong ngày:" autoComplete="off" type="number" {...fields.lucky_time_per_day} />

          <Text as="h2" variant="headingSm">
            Ngày quay thưởng
          </Text>

          <Combobox allowMultiple activator={<Combobox.TextField autoComplete="off" label="" labelHidden value={value} suggestion={suggestion} placeholder="Chọn ngày trong tuần" verticalContent={verticalContentMarkup} onChange={setValue} />}>
            {listboxMarkup}
          </Combobox>
        </BlockStack>
      </Card>

      <br />
      <Button onClick={submit} disabled={!dirty} loading={submitting} icon={SaveIcon}>
        Cập nhật
      </Button>
    </Form>;
}