import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import {TypedProject} from 'interface/project.interface';
import helpers from 'helpers/index';


/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as TypedProject[],
  entity: null as TypedProject,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  
  assignLoading: false,
  addAssigneeLoading:false,
  assignList: [] as any,
};

type TypedQuery = TypedProject & IQueryParams;


const apiUrl = 'project';

// Actions

export const getEntities = createAsyncThunk('project/fetch_entity_list', async (object: TypedQuery) => {
      const EndURL = helpers.buildEndUrl(object);
      const requestUrl = `${apiUrl}${EndURL}`;
      return await axios.get<any>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk('project/fetch_entity_single', async (project_id: string) => {
      return await axios.get<any>(`${apiUrl}/${project_id}`);
    },
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
    'project/delete_entity',
    async (file_name: string | number, thunkAPI) => {
      const requestUrl = `${apiUrl}/${file_name}`;
      return await axios.delete<any>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'project/create_entity',
    async (entity: TypedProject ) => {
      return await axios.post<any>(apiUrl, helpers.cleanEntity(entity));
    },
    { serializeError: serializeAxiosError }
);


export const updateEntity = createAsyncThunk(
    'project/update_entity',
    async (entity: TypedProject ) => {
      let project_id = String(entity.project_id);
      delete entity.project_id;
      return await axios.patch<any>(`${apiUrl}/${project_id}`, helpers.cleanEntity(entity));
    },
    { serializeError: serializeAxiosError }
);




/**
 * Assign
 */
export const getAssignList = createAsyncThunk('project/assign/fetch_entity_list', async (project_id: string | bigint) => {
    const requestUrl = `${apiUrl}/assign/${project_id}`;
    return await axios.get<any>(requestUrl);
  });
  
  // add
  export const addAssignee = createAsyncThunk('project/assign/create_entity', async (entity: {user_id: any, project_id: any}) => {
    return await axios.post<any>(`${apiUrl}/assign/`, helpers.cleanEntity(entity));
  });
  
  // deleteAssign
  export const deleteAssign = createAsyncThunk('project/assign/delete_entity', async (assign_id: string | bigint) => {
    return await axios.delete<any>(`${apiUrl}/assign/${assign_id}`);
  });
  
  /**
   * Delete Assign
   */

  

// slice

export const Reducer_System_Product = createEntitySlice({
  name: 'project',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder
    
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.totalItems = 0;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })
      
    
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
        state.entity = null;
      })
      
    
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(createEntity.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess = false;
      })

    
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })
    
      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })


      
      .addCase(getAssignList.fulfilled, (state, action) => {
        state.assignLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(getAssignList.pending, (state, action) => {
        state.assignLoading = true;
      })
      .addCase(getAssignList.rejected, (state, action) => {
        state.assignLoading = false;
        state.assignList = [];
        state.errorMessage = action.payload;
      })

      .addCase(addAssignee.fulfilled, (state, action) => {
        state.addAssigneeLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(addAssignee.pending, (state, action) => {
        state.addAssigneeLoading = true;
      })
      .addCase(addAssignee.rejected, (state, action) => {
        state.addAssigneeLoading = false;
        state.errorMessage = action.payload;
      })



  },
});

export const { clearError, reset } = Reducer_System_Product.actions;

// Reducer
export default Reducer_System_Product.reducer;
