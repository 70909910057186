import { Button, ChoiceList, InlineGrid, InlineStack, Link, Modal, Scrollable, Sheet, Text, BlockStack } from '@shopify/polaris';
import { useAppDispatch, useAppSelector } from 'config/store';
import { useCallback, useEffect, useState } from 'react';
import { getEntities } from 'store/customer_campaign.store.reducer';
import SearchFilter from 'components/quick_search';
import { createEntity as customerCampaignAddCustomerToList } from 'store/customer_campaign_relationship.store.reducer';
import { XIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useLocation, useParams } from 'react-router-dom';
export default function ModalQuickAddCustomerToCampaign({
  onClose
}) {
  const entities = useAppSelector(state => state.customer_campaign.entities);
  const updateSuccess = useAppSelector(state => state.customer_campaign_relationship.updateSuccess);
  const dispatch = useAppDispatch();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  let {
    customer_id
  } = useParams();
  const [mainQuery, setMainQuery] = useState({
    ...{
      query: '',
      page: 1,
      limit: 10,
      sort: 'createdAt:desc'
    },
    ...StringQuery
  });
  useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);
  const handleFiltersQueryChange = useCallback((value: string) => {
    setMainQuery({
      ...mainQuery,
      query: value ?? '',
      page: 1
    });
  }, [mainQuery]);

  /**
   * selectableCallback
   * @param user_id
   */
  function addCustomerToCampaign(campaign_id: any) {
    dispatch(customerCampaignAddCustomerToList({
      campaign_id: campaign_id,
      user_id: customer_id
    }));
  }
  useEffect(() => {
    if (updateSuccess === true) onClose();
  }, [updateSuccess]);
  return <>
      <Sheet open={true} onClose={onClose} accessibilityLabel="Thêm khách hàng vào chiến dịch">
        <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
          <div style={{
          alignItems: 'center',
          borderBottom: '1px solid #DFE3E8',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
          width: '100%'
        }}>
            <Text variant="headingMd" as="h2">
              Thêm khách hàng vào chiến dịch
            </Text>
            <Button accessibilityLabel="Cancel" icon={XIcon} onClick={onClose} variant="plain" />
          </div>
          <div style={{
          padding: '15px'
        }}>
            <SearchFilter queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
          </div>
          <Scrollable style={{
          padding: '1rem',
          height: '100%'
        }}>
            <BlockStack gap={'300'}>
              {entities && entities.length > 0 ? entities.map((element, index) => {
              return <InlineGrid key={'kjdshgkd' + index} gap={'200'} columns={['oneThird', 'oneHalf']} alignItems="start">
                        <Link onClick={() => addCustomerToCampaign(element.campaign_id)}>
                          <Text as="h2" variant="headingMd">
                            {element.campaign_name}
                          </Text>
                        </Link>
                        <BlockStack gap="0">
                          <Text as="h2" alignment="end" variant="headingMd">
                            {element.campaign_count} khách
                          </Text>
                          <Text as="p" alignment="end" tone="subdued">
                            {element?.user?.user_email}
                          </Text>
                        </BlockStack>
                      </InlineGrid>;
            }) : null}
            </BlockStack>
          </Scrollable>
          <div style={{
          alignItems: 'center',
          borderTop: '1px solid #DFE3E8',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
          width: '100%'
        }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="primary" onClick={onClose}>
              Done
            </Button>
          </div>
        </div>
      </Sheet>
    </>;
}