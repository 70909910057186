/**
 * Page
 */

import { Page, Layout, LegacyCard, SkeletonPage, SkeletonDisplayText, SkeletonBodyText, TextContainer } from '@shopify/polaris';
import { useEffect } from 'react';
import Theme404 from '../../layout/404';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/store';
import Parser from 'html-react-parser';
import dateandtime from 'date-and-time';
import { getEntity } from '../../store/comment.store.reducer';
export default function View_comment() {
  const data = useAppSelector(state => state.comment.entity);
  const loading = useAppSelector(state => state.comment.loading);
  const dispatch = useAppDispatch();
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */
  let {
    comment_slug
  } = useParams();
  useEffect(() => {
    dispatch(getEntity(comment_slug));
  }, [comment_slug, dispatch]);
  const loadingPageMarkup = <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <TextContainer>
              <SkeletonDisplayText size="medium" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>;
  const actual_page = data?.comment_id ? <Page title={data?.post_title} subtitle={`${data?.createdBy}, last update at ${dateandtime.format(new Date(data?.createdAt), 'DD/MM/YYYY HH:mm:ss')} `}>
      <Layout>
        <Layout.Section>
          <LegacyCard title={null} sectioned>
            {Parser(data ? data?.post_content : ' ')}
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page> : <Theme404 />;
  const pageMarkup = loading ? loadingPageMarkup : actual_page;
  return <div id="general_page">{pageMarkup}</div>;
}