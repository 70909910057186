import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};
const apiUrl = 'utils/backup';

// Actions

export const getEntities = createAsyncThunk('backup/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const createEntity = createAsyncThunk('backup/create_entity', async (a: any, thunkAPI) => {
  const result = await axios.post<any>(`${apiUrl}`);
  thunkAPI.dispatch(getEntities());
  return result;
}, {
  serializeError: serializeAxiosError
});
export const deleteEntity = createAsyncThunk('backup/delete_entity', async (file_name: string | number, thunkAPI) => {
  const requestUrl = `${apiUrl}/${file_name}`;
  const result = await axios.delete<any>(requestUrl);
  thunkAPI.dispatch(getEntities());
  return result;
}, {
  serializeError: serializeAxiosError
});

// slice

export const Reducer_System = createEntitySlice({
  name: 'backup',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getEntities.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.error.message;
    }).addCase(getEntities.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload.data;
    }).addCase(getEntities.pending, (state, action) => {
      state.loading = true;
      state.entities = [];
    }).addCase(createEntity.rejected, (state, action) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.error.message;
    }).addCase(createEntity.fulfilled, (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
    }).addCase(createEntity.pending, (state, action) => {
      state.updating = true;
      state.updateSuccess = false;
    }).addCase(deleteEntity.rejected, (state, action) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.error.message;
    }).addCase(deleteEntity.fulfilled, (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
    }).addCase(deleteEntity.pending, (state, action) => {
      state.updating = true;
      state.updateSuccess = false;
    });
  }
});
export const {
  clearError,
  reset
} = Reducer_System.actions;

// Reducer
export default Reducer_System.reducer;