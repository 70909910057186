import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'config/store';
import dateandtime from 'date-and-time';
import { getEntities as transactionsGetEntities, reset as resetTransactionData } from 'store/transaction_to_order.store.reducer';
import { DataTable, Text } from '@shopify/polaris';
import helpers from 'helpers/index';
export default function OrderTransaction({
  order_id
}: {
  order_id: string;
}) {
  /**
   * Danh sách giao dịch
   */
  const transactionsEntities = useAppSelector(state => state.transaction_to_order.entities);
  const dispatch = useAppDispatch();
  const queryTransactionList = useCallback(() => {
    dispatch(transactionsGetEntities(order_id));
  }, [order_id]);
  useEffect(() => {
    dispatch(resetTransactionData());
    queryTransactionList();
  }, []);

  /**
   * Tính tổng đã thanh toán ...
   */
  const totalPaid = useRef(0);
  useEffect(() => {
    if (!transactionsEntities) return;
    let paid = 0;
    for (let A of transactionsEntities) {
      paid += A.amount;
    }
    totalPaid.current = paid;
  }, [transactionsEntities]);
  return <>
      {transactionsEntities && transactionsEntities.length > 0 ? <DataTable hasZebraStripingOnData increasedTableDensity hideScrollIndicator truncate columnContentTypes={['text', 'text', 'text', 'text']} headings={['Giá trị', 'Phương thức', 'Loại giao dịch', 'Ngày thêm']} rows={transactionsEntities?.map(t => {
      const renderItem = t.transactions;
      return [helpers.formatNumber(renderItem.amount, ','), renderItem.transaction_type, renderItem.transaction_category, dateandtime.format(new Date(Number(renderItem.createdAt)), 'DD-MM-YYYY HH:mm')];
    })} /> : <Text as="p" tone="subdued">
          Chưa có giao dịch nào.
        </Text>}
    </>;
}