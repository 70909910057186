import { Avatar, Badge, Card, InlineStack, Link, Page, ResourceItem, IndexTable, Popover, Text, Button, ActionList, Toast } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleFilter from 'components/simpleFilter';
import { EditIcon, ConfettiIcon } from '@shopify/polaris-icons';
import AddAchievementModal from './achievement.new';
import AchievementDecorateModal from './achievement.decorate.modal';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import CustomerDetailPopup from 'components/customer.detail.popup';
import dateandtime from 'date-and-time';
import __ from 'languages/index';
import { TypeUserToAchievement, useGetAchivement, useGetAssignee, useRemoveAssignee } from 'queries/user_archivement.query';
export default function AchievementView() {
  // const entity = useAppSelector((state) => state.achievement.entity);
  // const _assignee = useAppSelector((state) => state.achievement.assignee);

  const {
    mutateAsync: getEntity,
    isPending: loading,
    data: entity
  } = useGetAchivement();
  const {
    mutateAsync: removeAssignee
  } = useRemoveAssignee();

  // const dispatch = useAppDispatch();
  const history = useNavigate();
  const [showDecorateModal, setShowDecorateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */

  let {
    achievement_id
  } = useParams();
  const loadData = useCallback(async Param => {
    await getEntity(Param);
  }, []);
  useEffect(() => {
    loadData(achievement_id);
  }, [achievement_id]);

  /**
   *
   */

  const initialQuery = {
    query: '',
    page: 1,
    limit: 10,
    achievement_id: achievement_id,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage: number, Limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: Limit
    });
  }, [mainQuery]);
  const {
    data: _assignee,
    refetch: getAssignee
  } = useGetAssignee(mainQuery);
  const [assignee, setAssignee] = useState([]);
  const [totalItems_assignee, setTotalItems_assignee] = useState(0);
  useEffect(() => {
    if (_assignee) {
      let {
        body,
        totalItems
      } = _assignee;
      setAssignee(body);
      setTotalItems_assignee(totalItems);
    }
  }, [_assignee]);
  const reduceRequest = useCallback(async mainQuery => {
    await getAssignee(mainQuery);
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  useEffect(() => {
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const closeModal = useCallback(() => {
    setShowModal(false);
    setShowDecorateModal(false);
    loadData(achievement_id);
    reduceRequestMemo(initialQuery);
  }, []);
  const _removeAssignee = useCallback(async (ID: string) => {
    await removeAssignee(ID);
    await helpers.sleep(1000);
    reduceRequestMemo(initialQuery);
  }, []);

  // show popup customer quick detail ...
  const [showCustomerModal, setShowCustomerModal] = useState(null);
  const showCustomerDetailPopup = useCallback((user_id: string | bigint) => {
    setShowCustomerModal(user_id);
  }, []);
  const closeCustomerModal = useCallback(() => {
    setShowCustomerModal(null);
  }, []);

  /**
   * ask to delete ...
   * using new modal ...
   */
  const [askDelete, setAskDelete] = useState<any>(null);
  const toastMarkup = askDelete && <Toast duration={10000} content={__('achievement_redeem')} action={{
    content: 'Undo',
    onAction: () => {
      setAskDelete(null);
      reduceRequestMemo(mainQuery);
    }
  }} onDismiss={() => {
    _removeAssignee(String(askDelete));
    setAskDelete(null);
  }} />;
  useEffect(() => {
    if (askDelete) {
      let A = assignee.filter(el => el.ID !== askDelete);
      setAssignee(A);
    }
  }, [askDelete]);
  const rowMarkup = assignee?.map((item: TypeUserToAchievement, index) => {
    const {
      createdAt,
      achievement_id,
      ID
    } = item;
    const {
      user_id,
      display_name,
      user_login,
      user_email,
      user_avatar,
      user_role
    } = item.user;
    let customerName = display_name || user_email || user_login;
    return <IndexTable.Row id={`` + ID} key={ID} position={index}>
        <IndexTable.Cell>
          <Link onClick={() => showCustomerDetailPopup(user_id)}>
            <InlineStack align="center" blockAlign="center" gap={'200'}>
              <Avatar customer source={user_avatar} size="md" name={customerName} />
              {customerName}
            </InlineStack>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{user_role}</IndexTable.Cell>
        <IndexTable.Cell>
          <Button variant="plain" onClick={() => setAskDelete(ID)}>
            thu hồi
          </Button>
        </IndexTable.Cell>
        <IndexTable.Cell>{dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss')}</IndexTable.Cell>
      </IndexTable.Row>;
  });
  return <>
      {toastMarkup}
      <AddAchievementModal open={showModal} entity={entity} onClose={closeModal} />
      <AchievementDecorateModal open={showDecorateModal} entity={entity} onClose={closeModal} achievement_id={achievement_id} />
      <CustomerDetailPopup onClose={closeCustomerModal} show={showCustomerModal} />

      <Page title={entity?.achievement_name} subtitle={entity?.achievement_description} titleMetadata={entity?.achievement_status === 1 ? <Badge tone="success">Active</Badge> : <Badge tone="warning">inActive</Badge>} backAction={{
      onAction: () => history('/achievement'),
      content: 'Back'
    }} secondaryActions={[{
      content: 'Trao tặng',
      icon: ConfettiIcon,
      onAction: () => setShowDecorateModal(true)
    }]} primaryAction={{
      content: 'Chỉnh sửa',
      icon: EditIcon,
      onAction: () => setShowModal(true)
    }}>
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
          <IndexTable resourceName={{
          singular: 'Huy chương',
          plural: 'Huy chương'
        }} itemCount={totalItems_assignee || 1} headings={[{
          title: 'Người nhận'
        }, {
          title: 'User role'
        }, {
          title: 'tool'
        }, {
          title: 'Ngày tạo'
        }]} loading={loading} selectable={false}>
            {assignee?.length > 0 && rowMarkup}
          </IndexTable>
        </Card>

        <br />

        {totalItems_assignee > mainQuery.limit ? <Pagination TotalRecord={totalItems_assignee} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePageNumber} /> : null}

        <br />
        <br />
      </Page>
    </>;
}