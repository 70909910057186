import {
  Action,
  ActionReducerMapBuilder,
  createSlice,
  SerializedError,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';


/**
 * For dispatch Error Return ...
 */
export interface SerializedErrorExtend extends SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  code?: string;
  path?: string;
  params?: any;
}

/**
 * Model for redux actions with pagination
 */

export type IQueryParams = {
  query?: string;
  page?: number;
  limit?: number;
  sort?: string;
  filter?: string;
  [propName: string]: any;
};

/**
 * Check if the async action type is rejected
 */
export function isRejectedAction(action: Action) {
  return action.type.endsWith('/rejected');
}

/**
 * Check if the async action type is pending
 */
export function isPendingAction(action: Action) {
  return action.type.endsWith('/pending');
}

/**
 * Check if the async action type is completed
 */
export function isFulfilledAction(action: Action) {
  return action.type.endsWith('/fulfilled');
}

const commonErrorProperties: any[] = ['name', 'message', 'stack', 'code', 'path', 'information', 'error', 'params'];

/**
 * serialize function used for async action errors,
 * since the default function from Redux Toolkit strips useful info from axios errors
 * * 
 */
export const serializeAxiosError = (value: any): AxiosError | SerializedErrorExtend => {
  if (typeof value === 'object' && value !== null) {
    if (value.isAxiosError) {
      return {
        name: value?.response?.data?.error || '',
        message: JSON.stringify(value.response.data.message),
        stack: '',
        code: value.response.data?.code || 500,
      };
    } else {
      const simpleError: SerializedError = {};
      for (const property of commonErrorProperties) {
        if (typeof value[property] !== 'undefined') {
          simpleError[property] = value[property];
        }
      }
      return simpleError as SerializedErrorExtend;
    }
  }
  return { message: String(value) };
};


export interface EntityState<T> {
  loading?: boolean;
  errorMessage?: string | null;
  entities?: ReadonlyArray<T>;
  entity?: T;
  updating?: boolean;
  totalItems?: number;
  updateSuccess?: boolean;
  [propName: string]: any;
}

/**
 * A wrapper on top of createSlice from Redux Toolkit to extract
 * common reducers and matchers used by entities
 */
export const createEntitySlice = <T, S extends EntityState<T>, Reducers extends SliceCaseReducers<S>>({
  name = '',
  initialState,
  reducers,
  extraReducers,
  skipRejectionHandling,
}: {
  name: string;
  initialState: S;
  reducers?: ValidateSliceCaseReducers<S, Reducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<S>) => void;
  skipRejectionHandling?: boolean;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      /**
       * Reset the entity state to initial state
       */
      reset() {
        return initialState;
      },
      ...reducers,
    },
    extraReducers(builder) {
      extraReducers && extraReducers(builder); // Ensure extraReducers is provided
      if (!skipRejectionHandling) {
        builder.addMatcher(isRejectedAction, (state, action) => {
          const newState = state as EntityState<T>; // Explicitly declare the type of state
          newState.loading = false;
          newState.updating = false; // Ensure 'updating' property exists on EntityState<T>
          newState.updateSuccess = false;
          newState.errorMessage = (action as any).error.message;
        });
      }
    }
  });
};