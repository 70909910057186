import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';

/**
 *   Reducer used for front-end, with comment.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using comment.model.ts
 */

const initialState = {
  loading: false,
  loadingLifeTimeAnalytics: false,
  errorMessage: null as any,
  myPerformance: [] as any,
  loadingMyPerformance: [] as any,
  top5people: [] as any,
  lifetimeAnalytics: [] as any,
  loadingstaffPerformance: false,
  staffPerformance: null,
  loadingstaffPerformanceLine: false,
  staffPerformanceLine: null,
  loadingProductPerformanceLine: false,
  productPerformanceLine: null,
  loadingGetCustomerGeneralAnalytics: false,
  customerGeneralAnalytics: [] as any
};
const apiUrl = 'analytics';

// Actions

/**
 * Lấy top 5 người dùng ... ghi công cho họ
 * Nhớ truyền vào thời gian ...
 * ví dụ: ?createdAt=gte:9988976868768,lt:6574876583743
 */

type TypeddateFilter = {
  createdAt?: string | number;
  [propName: string]: any;
};
export const getTop5 = createAsyncThunk('getTop5/fetch_entity_list', async (dateobject: TypeddateFilter) => {
  const EndURL = helpers.buildEndUrl(dateobject);
  const requestUrl = `${apiUrl}/top5${EndURL}`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const getMyPerformance = createAsyncThunk('getMyPerformance/fetch_entity_list', async (user_id: string) => {
  const requestUrl = `${apiUrl}/my_performance?user_id=${user_id}`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const getStaffPerformance = createAsyncThunk('getStaffPerformance/fetch_entity_list', async (dateobject: TypeddateFilter) => {
  const EndURL = helpers.buildEndUrl(dateobject);
  const requestUrl = `${apiUrl}/staff_performance${EndURL}`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const getStaffPerformanceLine = createAsyncThunk('getStaffPerformanceLine/fetch_entity_list', async (dateobject: TypeddateFilter) => {
  const EndURL = helpers.buildEndUrl(dateobject);
  const requestUrl = `${apiUrl}/staff_performance_line${EndURL}`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});

/**
 * Lấy thông tin cơ bản trong tháng ...
 */
export const getGeneralAnalytics = createAsyncThunk('generalAnalytics/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}/general`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});

/**
 * Lấy toàn bộ thông tin và báo cáo lifetime ...
 */
export const getLifetimeAnalytics = createAsyncThunk('generalAnalytics/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}/lifetime`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});

/**
 * Lấy thông tin cơ bản trong tháng ...
 */
export const getCustomerGeneralAnalytics = createAsyncThunk('getCustomerGeneralAnalytics/fetch_entity_list', async (dateobject: TypeddateFilter) => {
  const EndURL = helpers.buildEndUrl(dateobject);
  const requestUrl = `${apiUrl}/customers${EndURL}`;
  return await axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});

// slice

export const Reducer_Analytics = createEntitySlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addMatcher(isFulfilled(getTop5), (state, action) => {
      return {
        ...state,
        loading: false,
        top5people: action.payload.data
      };
    }).addMatcher(isPending(getTop5), state => {
      state.loading = true;
      state.top5people = null;
    }).addMatcher(isFulfilled(getCustomerGeneralAnalytics), (state, action) => {
      state.loadingGetCustomerGeneralAnalytics = false;
      state.customerGeneralAnalytics = action.payload.data;
    }).addMatcher(isPending(getCustomerGeneralAnalytics), state => {
      state.loadingGetCustomerGeneralAnalytics = true;
      state.customerGeneralAnalytics = [];
    }).addMatcher(isRejected(getCustomerGeneralAnalytics), state => {
      state.loadingGetCustomerGeneralAnalytics = false;
      state.customerGeneralAnalytics = [];
    }).addMatcher(isFulfilled(getLifetimeAnalytics), (state, action) => {
      state.loadingLifeTimeAnalytics = false;
      state.lifetimeAnalytics = action.payload.data;
    }).addMatcher(isPending(getLifetimeAnalytics), state => {
      state.loadingLifeTimeAnalytics = true;
      state.lifetimeAnalytics = null;
    }).addMatcher(isRejected(getLifetimeAnalytics), state => {
      state.loadingLifeTimeAnalytics = false;
      state.lifetimeAnalytics = null;
    }).addMatcher(isFulfilled(getStaffPerformance), (state, action) => {
      state.loadingstaffPerformance = false;
      state.staffPerformance = action.payload.data;
    }).addMatcher(isPending(getStaffPerformance), state => {
      state.loadingstaffPerformance = true;
      state.staffPerformance = [];
    }).addMatcher(isRejected(getStaffPerformance), state => {
      state.loadingstaffPerformance = false;
      state.staffPerformance = [];
    }).addMatcher(isFulfilled(getMyPerformance), (state, action) => {
      state.loadingMyPerformance = false;
      state.myPerformance = action.payload.data;
    }).addMatcher(isPending(getMyPerformance), state => {
      state.loadingMyPerformance = true;
      state.myPerformance = [];
    }).addMatcher(isRejected(getMyPerformance), state => {
      state.loadingMyPerformance = false;
      state.myPerformance = [];
    }).addMatcher(isFulfilled(getStaffPerformanceLine), (state, action) => {
      state.loadingstaffPerformanceLine = false;
      state.staffPerformanceLine = action.payload.data;
    }).addMatcher(isPending(getStaffPerformanceLine), state => {
      state.loadingstaffPerformanceLine = true;
      state.staffPerformanceLine = [];
    }).addMatcher(isRejected(getStaffPerformanceLine), state => {
      state.loadingstaffPerformanceLine = false;
      state.staffPerformanceLine = [];
    });
  }
});
export const {
  clearError
} = Reducer_Analytics.actions;

// Reducer
export default Reducer_Analytics.reducer;