import { DataTable, LegacyCard, Link, Page, Text } from '@shopify/polaris';
import Pagination from 'components/pagination';
import SimpleFilter from 'components/simpleFilter';
import helpers from 'helpers/index';
import __, { ___ } from 'languages/index';
import Template404 from 'layout/404';
import { useGetUserUsingWallet, useGetWallet } from 'queries/wallet.query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import dateandtime from 'date-and-time';
import CustomerDetailPopup from 'components/customer.detail.popup';
export default function WalletDetail() {
  let {
    wallet_unit
  } = useParams();
  const history = useNavigate();
  const {
    mutate: getEntity,
    data: walletData,
    isError,
    isSuccess
  } = useGetWallet();
  useEffect(() => {
    getEntity(wallet_unit);
  }, [wallet_unit]);
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc',
    wallet_type_id: wallet_unit
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    mutateAsync: getEntities,
    data: EntitiesData
  } = useGetUserUsingWallet(); // lấy danh sách tất tần tật

  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntites] = useState(null);
  useEffect(() => {
    if (EntitiesData) {
      let {
        body,
        totalItems
      } = EntitiesData;
      setEntites(body);
      setTotalItems(totalItems);
    }
  }, [EntitiesData]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  const reduceRequest = useCallback(mainQuery => {
    getEntities(mainQuery);
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Wallet Detail', `/wallet/${wallet_unit}` + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery, wallet_unit]);

  // show popup customer quick detail ...
  const [showCustomerModal, setShowCustomerModal] = useState(null);
  const showCustomerDetailPopup = useCallback((user_id: string | bigint) => {
    setShowCustomerModal(user_id);
  }, []);
  const closeCustomerModal = useCallback(() => {
    setShowCustomerModal(null);
  }, []);
  return <>
      <Helmet>
        <title>Chi tiết ví {walletData?.wallet_name ?? ''}</title>
      </Helmet>
      <CustomerDetailPopup onClose={closeCustomerModal} show={showCustomerModal} />
      {isError ? <Template404 /> : <Page title={walletData?.wallet_name} backAction={{
      content: 'Back to List',
      onAction: () => history('/wallet')
    }} subtitle={`Đơn vị tính ${walletData?.wallet_unit} - Ký hiệu ${walletData?.wallet_sign}`}>
          <LegacyCard>
            <SimpleFilter onCallback={filterCallback} options={[]} loading={false} sortField={[{
          label: 'Số tiền',
          field: 'balance'
        }]} />
            <DataTable columnContentTypes={['text', 'numeric', 'text']} headings={['User', 'Tổng', 'Ngày tạo']} rows={entities?.map((walletDetail, index) => {
          return [<Link removeUnderline onClick={() => showCustomerDetailPopup(walletDetail.user_id)}>
                      <Text as="span" fontWeight="bold">
                        {walletDetail?.user?.display_name}
                      </Text>{' '}
                      <Text as="span" tone="subdued" variant="bodyXs">
                        {walletDetail?.user?.user_phonenumber || walletDetail?.user?.user_email}
                      </Text>
                    </Link>, helpers.formatNumber(walletDetail?.balance), dateandtime.format(new Date(Number(walletDetail?.createdAt)), 'YYYY-MM-DD')];
        }) || []} hasZebraStripingOnData increasedTableDensity footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{entities?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
          </LegacyCard>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
        </Page>}
    </>;
}