import { Autocomplete, Icon, InlineError, InlineStack, Tag, Text } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import ProductBrandModalAddAndEdit from '../brands/brand.modal';
import { TypedProductBrand, useGetProductBrands } from 'queries/brand.query';
import helpers from 'helpers/index';
export default function QuickSearchProductBrand({
  label = '',
  onClose,
  errorMessage = '',
  current_brand_id
}: {
  label?: string;
  onClose: (val: string) => void;
  errorMessage?: string;
  current_brand_id: string[];
}) {
  const [initialQuery, setInitialQuery] = useState({
    limit: 5,
    query: '',
    sort: 'createdAt:desc'
  });
  const {
    refetch: fetchDataFromServerCallback,
    isLoading: searching,
    data: entities
  } = useGetProductBrands(initialQuery);

  /**
   *  list
   */

  const [productBrandList, setProductBrandList] = useState<TypedProductBrand[]>(null);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setProductBrandList(body);
    }
  }, [entities]);

  /**
   * Assign cho ai đóa
   */
  const [brandSelected, setBrandSelected] = useState<string[] | undefined>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [productCategoryListForQuickOption, setproductCategoryListForQuickOption] = useState<{
    label: string;
    value: string;
  }[]>([]);
  useEffect(() => {
    if (!current_brand_id) return;
    setBrandSelected(current_brand_id);
  }, [current_brand_id]);
  const callmebackCallback = useCallback(brandSelected => {
    onClose(brandSelected[0]);
  }, []);
  useEffect(() => {
    let innerBrandData = [];
    if (productBrandList) for (let brandData of productBrandList) {
      innerBrandData.push({
        value: brandData.brand_id,
        label: brandData.brand_name
      });
    }
    setproductCategoryListForQuickOption(innerBrandData);
  }, [productBrandList]);
  const updateTextThenDelayCallback = useMemo(() => helpers.debounce((_value: any) => updateTextThenDoitCallback?.call(this, _value), 500), []);
  const updateTextThenDoitCallback = useCallback((value: string) => {
    fetchDataFromServerCallback();
    return value;
  }, []);
  const updateText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    const cloneInitialQuery = {
      ...initialQuery,
      ...{
        query: value
      }
    };
    setInitialQuery(cloneInitialQuery);
    updateTextThenDelayCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    let selectedLabel = '';
    selected.map(selectedItem => {
      const matchedOption = productCategoryListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      selectedLabel = matchedOption.label;
      return matchedOption && matchedOption.label;
    });
    setBrandSelected(selected);
    //   setUserQuickSearchText(selectedValue[0] || '');
    setUserQuickSearchText(selectedLabel);
    callmebackCallback(selected);
  }, [productCategoryListForQuickOption]);

  /** ABC DEF GHI */

  const removeTag = useCallback((tag: string) => () => {
    const options = [...brandSelected];
    options.splice(options.indexOf(tag), 1);
    setBrandSelected(options);
    callmebackCallback(options);
  }, [brandSelected]);
  const [showQuickCreate, setShowQuickCreate] = useState(false);
  const verticalContentMarkup = brandSelected?.length > 0 ? <InlineStack align="start" blockAlign="center" gap="200">
        {brandSelected.map(option => {
      if (!option) return null;
      let l = productCategoryListForQuickOption.find(el => el.value === String(option))?.label;
      if (!l) return;
      return <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {l}
            </Tag>;
    })}
      </InlineStack> : <Text as="p" tone="subdued" variant="bodySm">
        Chưa có một nhãn hiệu nào ...
      </Text>;
  const textFieldSelectUser = <>
      <Autocomplete.TextField error={errorMessage ? true : false} onChange={updateText} label={label} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm kiếm" autoComplete="off" id="TextFieldSelectProductBrand" />
      <div style={{
      marginTop: '4px'
    }}>
        <InlineError message={errorMessage} fieldID="TextFieldSelectProductBrand" />
      </div>
    </>;
  return <>
      <ProductBrandModalAddAndEdit show={showQuickCreate} onClose={(category_id: any) => {
      setShowQuickCreate(false);
      if (category_id) {
        setBrandSelected([category_id]);
      }
    }} />

      <Autocomplete actionBefore={{
      accessibilityLabel: 'Tạo nhãn hiệu mới',
      content: 'Tạo một nhãn hiệu mới',
      ellipsis: true,
      helpText: 'Tạo nhãn hiệu mới và chỉnh sửa sau',
      icon: PlusCircleIcon,
      onAction: () => {
        setShowQuickCreate(!showQuickCreate);
      }
    }} allowMultiple={false} options={productCategoryListForQuickOption} selected={brandSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={searching} />

      <div>{verticalContentMarkup}</div>
    </>;
}