import { DataTable, EmptyState, Layout, Page, Link, Text, Badge, Card } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/store';
import emptyIMG from '../../media/images/empty.png';
import Pagination from '../../components/pagination';
import { getEntities } from '../../store/outgoing_webhook.store.reducer';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import OutgoingWebhookModal from './outgoing_webhook.create';
import SkeletonLoading from 'components/skeletonPageLoading';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
export default function General_outgoing_webhook() {
  const entity = useAppSelector(state => state.outgoing_webhook.entity);
  const entities = useAppSelector(state => state.outgoing_webhook.entities);
  const loading = useAppSelector(state => state.outgoing_webhook.loading);
  const totalItems = useAppSelector(state => state.outgoing_webhook.totalItems);
  const dispatch = useAppDispatch();
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc',
    webhook_method: ''
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);

  /**
   * Double .. must fixed
   */
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/outgoing_webhook' + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);
  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);
  const emptyData = <EmptyState heading="No webhook here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const renderItem = (outgoing_webhook: any) => {
    const {
      webhook_id,
      webhook_title,
      webhook_description,
      webhook_url,
      webhook_active,
      webhook_method,
      webhook_events,
      createdAt,
      createdBy,
      updatedAt,
      lastOpen
    } = outgoing_webhook;
    return [webhook_id, <Link key={webhook_id} onClick={() => history('view/' + webhook_id)}>
        <div style={{
        minWidth: '150px'
      }}>{webhook_title}</div>
      </Link>, webhook_active === 1 ? <Badge tone="success">Active</Badge> : <Badge tone="warning">Inactive</Badge>, webhook_method, decodeURIComponent(webhook_url), webhook_description, <time>{createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss') : '-'}</time>, <time>{updatedAt ? dateandtime.format(new Date(Number(updatedAt)), 'DD-MM-YYYY HH:mm:ss') : '-'}</time>];
  };
  const PagesList = totalItems > 0 ? <>
        <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['Id', 'Title', '', 'Method', 'Url', 'Description', 'Create At', 'Update At']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : emptyData;
  const Actual_page = <>
      <Page fullWidth title="Outgoing Webhook" subtitle="The Outgoing Webhook acts as a normal request like POST, PATCH from this site to an URL you config." primaryAction={{
      content: 'Create new',
      disabled: false,
      onAction: toggleNewActive
    }}>
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
              label: 'get',
              value: 'get',
              field: 'webhook_method'
            }, {
              label: 'post',
              value: 'post',
              field: 'webhook_method'
            }, {
              label: 'patch',
              value: 'patch',
              field: 'webhook_method'
            }, {
              label: 'delete',
              value: 'delete',
              field: 'webhook_method'
            }]} sortField={[]} />
              {PagesList}
            </Card>
            <br />
            {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
          </Layout.Section>
        </Layout>
      </Page>
      <OutgoingWebhookModal show={newModelactive} onClose={() => setNewModelactive(false)} />
    </>;
  return <>{entities === null ? <SkeletonLoading fullWidth /> : Actual_page}</>;
}