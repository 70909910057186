import { useEffect, useState } from "react";
import { Card, SkeletonBodyText, SkeletonDisplayText, Text, Tooltip } from "@shopify/polaris";
import { ReactECharts, ReactEChartsProps } from "components/React-ECharts";
import { useAppDispatch, useAppSelector } from "config/store";
export default function CampaignAnalytics() {
  // const customerCampaignSingleData = useAppSelector((state) => state.customer_campaign.entity);
  // const customerCampaignLoading = useAppSelector((state) => state.customer_campaign.loading);

  const a = useAppSelector(state => state.customer_campaign.analytics);
  const {
    analytics,
    best_sale
  } = a || [];
  const [dataDate, setDataDate] = useState([]);
  const [dataOrders, setDataOrders] = useState([]);
  const [dataCustomers, setDataCustomers] = useState([]);
  useEffect(() => {
    if (!analytics) return;
    let dataDate = [];
    let dataOrders = [];
    let dataCustomers = [];
    for (let x in analytics) {
      let result = analytics[x];
      let date = Object.keys(result)[0];
      let {
        orders,
        customers
      } = result[date];
      dataDate.push(date);
      dataOrders.push(orders);
      dataCustomers.push(customers);
    }
    setDataDate(dataDate);
    setDataOrders(dataOrders);
    setDataCustomers(dataCustomers);
  }, [analytics]);
  const totalSaleViaAllChanelOption: ReactEChartsProps["option"] = {
    tooltip: {
      trigger: 'axis'
    },
    textStyle: {
      fontFamily: "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
    },
    title: {
      show: true,
      text: 'Tỉ lệ chốt đơn',
      subtext: 'Tính trong 2 tuần gần nhất',
      top: 0
    },
    legend: {
      show: true,
      // orient: 'vertical',
      // right: 10,
      top: 0
    },
    grid: {
      backgroundColor: '#fff',
      containLabel: true,
      left: 30,
      top: 100,
      right: 15,
      bottom: 15
    },
    xAxis: [{
      type: 'category',
      data: dataDate || [],
      axisPointer: {
        type: 'shadow'
      },
      min: 0
    }],
    yAxis: [{
      type: 'value',
      name: 'Tổng số khách',
      alignTicks: true,
      axisLabel: {
        formatter: '{value}'
      },
      min: 0
    }],
    series: [{
      name: 'Số khách',
      type: 'bar',
      data: dataCustomers || []
    }, {
      name: 'Số chuyển đổi',
      type: 'bar',
      data: dataOrders || []
    }]
  };
  return <Card>
        {analytics === null ? <><SkeletonDisplayText size="large" /><br /><SkeletonBodyText /></> : <ReactECharts option={totalSaleViaAllChanelOption} settings={{
      notMerge: true,
      lazyUpdate: true
    }} style={{
      minHeight: '380px'
    }} />}
    </Card>;
}