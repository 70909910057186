import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import helpers from "helpers";
import { createEntitySlice, serializeAxiosError } from "../config/reducer.utils";
import { IOutgoing_webhook } from "../interface/outgoing_webhook.model";

/**
 *   Reducer used for front-end, with outgoing_webhook.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using outgoing_webhook.model.ts
 */

const initialState = {
  loading: false,
  errorMessage: null as any,
  loadingLog: false,
  totalLogItems: 0,
  entities: null,
  entity: null,
  entities_log: [],
  updating: false,
  totalItems: 0,
  updateSuccess: false
};
const apiUrl = "webhook_outgoing";

// Actions

export const getEntities = createAsyncThunk("webhook_outgoing/fetch_entity_list", async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});
export const getEntity = createAsyncThunk("webhook_outgoing/fetch_entity", async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.get<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});
export const createEntity = createAsyncThunk("webhook_outgoing/create_entity", async (entity: IOutgoing_webhook, thunkAPI) => {
  const result = await axios.post<IOutgoing_webhook>(`${apiUrl}`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});
export const updateEntity = createAsyncThunk("webhook_outgoing/update_entity", async (entity: IOutgoing_webhook, thunkAPI) => {
  let webhookID = String(entity.webhook_id);
  delete entity.webhook_id;
  const result = await axios.patch<IOutgoing_webhook>(`${apiUrl}/${webhookID}`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});
export const partialUpdateEntity = createAsyncThunk("webhook_outgoing/partial_update_entity", async (entity: IOutgoing_webhook, thunkAPI) => {
  let webhookID = String(entity.webhook_id);
  delete entity.webhook_id;
  const result = await axios.patch<IOutgoing_webhook>(`${apiUrl}/${webhookID}`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getEntities({}));
  return result;
}, {
  serializeError: serializeAxiosError
});
export const deleteEntity = createAsyncThunk("webhook_outgoing/delete_entity", async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.delete<any>(requestUrl);
}, {
  serializeError: serializeAxiosError
});

/**
 * Get log ...
 */
export const getOutgoingLog = createAsyncThunk('webhook_outgoing_log/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `webhook_outgoing_log/${object.outgoing_webhook_id}${EndURL}`;
  return axios.get<any>(requestUrl);
});

// slice

export const Reducer_Outgoing_webhook = createEntitySlice({
  name: "webhook_outgoing",
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getEntity.fulfilled, (state, action) => {
      state.loading = false;
      state.entity = action.payload.data;
    }).addCase(getEntity.rejected, (state, action) => {
      state.loading = false;
      state.entity = null;
    }).addCase(createEntity.rejected, (state, action) => {
      state.loading = false;
      state.entity = null;
      state.errorMessage = action.payload;
    }).addMatcher(isFulfilled(getEntities), (state, action) => {
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers["x-total-count"], 10)
      };
    }).addMatcher(isFulfilled(getOutgoingLog), (state, action) => {
      return {
        ...state,
        loadingLog: false,
        entities_log: action.payload.data,
        totalLogItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }).addMatcher(isPending(getOutgoingLog), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loadingLog = true;
    }).addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    }).addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = action.payload.data;
    }).addMatcher(isFulfilled(deleteEntity), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = null;
    }).addMatcher(isPending(deleteEntity), state => {
      state.updating = true;
      state.loading = true;
      state.updateSuccess = false;
      state.entity = null;
    }).addMatcher(isPending(getEntities, getEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loading = true;
    }).addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.updating = true;
    });
  }
});
export const {
  clearError,
  reset
} = Reducer_Outgoing_webhook.actions;

// Reducer
export default Reducer_Outgoing_webhook.reducer;