import { ActionList, Badge, Button, Card, DataTable, Form, Icon, InlineStack, Link, Loading, Page, Popover, Text, TextField, Tooltip } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import orderDeliverType from './order.delivery.json';
import orderStatusJson from 'config/order.status.json';
import __, { ___ } from 'languages';
import NoteComponent from '../../components/noteComponent';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import FilterAdvance from './filter';
import { ListBulletedIcon, PrintIcon, ReceiptRefundIcon, XCircleIcon, StatusActiveIcon, CheckSmallIcon, AlertDiamondIcon, ViewIcon, LockIcon, PlusIcon } from '@shopify/polaris-icons';
import BulkModal from './order.bulk.modal';
import Pagination from 'components/pagination';
import { TypedOrder, useBulkUpdateOrder, useCreateOrder, useGetOrders, useUpdateOrder } from 'queries/orders.query';
import { useGetLogistics } from 'queries/logistic_service.query';
import { useUpdateorder_fulfillment } from 'queries/order_fulfillment.query';
import { PAYMENT_STATUS_CONST } from 'constant/index';
import { Helmet } from 'react-helmet-async';
import { useNotification } from 'NotificationContext';
import { getBadgeOfPaymentMethod, getOrderDeliveryMethod, getOrderStatusBadge } from './order.component';
export default function OrderList() {
  const {
    mutateAsync: updateOrderFulfillment,
    isSuccess: updateFulfillmentSuccess
  } = useUpdateorder_fulfillment();
  const {
    mutateAsync: updateOrder,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateOrder();
  const {
    addNotification
  } = useNotification();
  useEffect(() => {
    if (!updateFulfillmentSuccess) return;
    addNotification('info', __('update_successfully'));
  }, [updateFulfillmentSuccess]);
  useEffect(() => {
    if (!updateSuccess) return;
    addNotification('info', __('update_successfully'));
  }, [updateSuccess]);
  const orderStatusArray = [];
  for (let orderStatus in orderStatusJson) {
    let orderStatusName = orderStatusJson[orderStatus];
    orderStatusArray.push({
      label: orderStatusName,
      value: orderStatus
    });
  }

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    order_status: null,
    payment_status: null,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
    // ...stateProp
  });
  const {
    data: rootData,
    isLoading: loading,
    refetch: getEntities
  } = useGetOrders(mainQuery);
  const {
    mutateAsync: createEntity,
    data: newCreateData
  } = useCreateOrder();
  const {
    mutateAsync: bulkUpdateEntities
  } = useBulkUpdateOrder();
  const history = useNavigate();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (rootData) {
      let {
        body,
        totalItems
      } = rootData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [rootData]);
  const [showBulkOrderAction, setShowBulkOrderAction] = useState(false);

  /**
   * create new Order
   */

  const createNewEmptyOrder = useCallback(() => {
    createEntity({}).then().catch(e => {});
  }, []);
  useEffect(() => {
    if (newCreateData) {
      history('/order/view/' + newCreateData.order_id);
    }
  }, [newCreateData]);

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage: number, Limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: Limit
    });
  }, [mainQuery]);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Order', '/order' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**!SECTION
   * ============================================================================================
   */

  const clearOrderFulfillmentError = useCallback(async (order_id: any) => {
    try {
      await updateOrderFulfillment({
        order_id: order_id,
        order_fulfillment_private_status: 10,
        order_fulfillment_error: ''
      });
    } catch (e) {}
  }, []);

  /**
   * THay đổi loại giao vận
   */
  const [showDeliveryType, setShowDeliveryType] = useState(null);
  const onClosePophoverOderCallback = useCallback((order_id: any) => {
    setShowDeliveryType(!order_id);
  }, []);
  const togglePophoverOrderCallback = useCallback((_s: any) => {
    setShowDeliveryType(showDeliveryType === _s ? false : _s);
  }, [showDeliveryType]);
  const savingDeliveryTypeCallback = useCallback(async (order_id: any, order_delivery_type: any) => {
    try {
      await updateOrder({
        order_id: order_id,
        order_delivery_type: order_delivery_type
      });
    } catch (e) {}
    onClosePophoverOderCallback(order_id);
  }, []);

  /**
   * Thay đổi đơn vị vận chuyển
   * @param media
   * @returns
   */

  const [showLogisticService, setShowLogisticService] = useState(null);
  const onCloseLogisticServiceCallback = useCallback((order_id: any) => {
    setShowLogisticService(!order_id);
  }, []);
  const toggleLogisticServiceCallback = useCallback((_s: any) => {
    setShowLogisticService(showLogisticService === _s ? false : _s);
  }, [showLogisticService]);
  const savingLogisticServiceCallback = useCallback((order_id: any, logistic_id: any) => {
    updateOrderFulfillment({
      order_id: order_id,
      logistic_id: logistic_id
    });
    onCloseLogisticServiceCallback(order_id);
  }, []);

  /**!SECTION
   * Get all logistic service and memo it
   */

  const {
    data: logisticEntities
  } = useGetLogistics({
    sort: 'createdAt:desc',
    limit: 100,
    logistic_status: 1
  });
  const findLogistic = useCallback((keyword: string, order_id: any, current_logistic_id: any): any[] => {
    let matchLogistic = [];
    if (logisticEntities && typeof logisticEntities.body !== 'undefined') for (let logistic of logisticEntities?.body) {
      if (logistic.logistic_delivery_method.includes(keyword)) matchLogistic.push({
        content: logistic.logistic_name,
        onAction: () => savingLogisticServiceCallback(order_id, logistic.logistic_id),
        suffix: logistic.logistic_id === current_logistic_id && <Icon source={CheckSmallIcon} />,
        active: logistic.logistic_id === current_logistic_id
      });
    }
    return matchLogistic;
  }, [logisticEntities, savingLogisticServiceCallback]);

  /**
   * Thay đổi mã vận chuyển
   */

  const [currentFulfillmentCode, setCurrentFulfillmentCode] = useState('');
  const [showLogisticFulfillmentCode, setShowLogisticFulfillmentCode] = useState(null);
  const onCloseFulfillmentCallback = useCallback((order_id: any) => {
    setShowLogisticFulfillmentCode(!order_id);
  }, []);
  const toggleFulfillmentCallback = useCallback((_s: any, current_value: string) => {
    setShowLogisticFulfillmentCode(showLogisticFulfillmentCode === _s ? false : _s);
    setCurrentFulfillmentCode(current_value);
  }, [showLogisticFulfillmentCode]);
  const savingFulfillmentCallback = useCallback(async () => {
    updateOrderFulfillment({
      order_id: showLogisticFulfillmentCode,
      order_fulfillment_code: currentFulfillmentCode
    });
    onCloseFulfillmentCallback(showLogisticFulfillmentCode);
  }, [showLogisticFulfillmentCode, currentFulfillmentCode]);

  /** Tình trạng thanh toán */

  const [showPaymentStatusPopup, setShowPaymentStatusPopup] = useState(null);
  const toggleShowPaymentStatusPopup = useCallback((_s: any) => {
    setShowPaymentStatusPopup(showPaymentStatusPopup === _s ? false : _s);
  }, [showPaymentStatusPopup]);
  const changePaymentStatusCallback = useCallback(async (order_id: string, new_status: number) => {
    try {
      await updateOrder({
        order_id: order_id,
        payment_status: Number(new_status)
      });
    } catch (e) {
      console.error(e, 'ERR_87687HJ');
    }
    setShowPaymentStatusPopup(null);
  }, []);

  /**
   * Ghi chú đơn hàng ...
   */

  const [noteForOrder, setNoteForOrder] = useState('');
  const [showPophoverOrderNote, setShowPophoverOrderNote] = useState(null);
  const onClosePophoverOderNoteCallback = useCallback((customer_campaign_id: any) => {
    setShowPophoverOrderNote(!customer_campaign_id);
    setNoteForOrder('');
  }, []);
  const togglePophoverOrderNoteCallback = useCallback((_s: any, order_note: string) => {
    setShowPophoverOrderNote(showPophoverOrderNote === _s ? false : _s);
    setNoteForOrder(order_note);
  }, [showPophoverOrderNote]);
  const submitNoteOrderCallback = useCallback(async (value: string, order_id: any) => {
    try {
      await updateOrder({
        order_id: order_id,
        order_note: value
      });
    } catch (e) {}
    setShowPophoverOrderNote(null);
    setNoteForOrder('');
  }, []);

  /**
   * Action menu ...
   */
  const [showActionList, setShowActionList] = useState(null);
  const onCloseActionList = useCallback((order_id: any) => {
    setShowActionList(!order_id);
  }, []);
  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);

  /**
   * markAsChecked
   * @param order_pnr String
   * @param mode String
   */
  const markAsChecked = async (order_pnr: string, order_id: any, mode: string) => {
    setShowActionList(null);
    await bulkUpdateEntities({
      order_pnr: order_pnr,
      mode: mode
    });
  };

  /**
   * Order status
   */

  const [showPopupForChangeOrderStatus, setShowPopupForChangeOrderStatus] = useState('');
  const changeOrderStatus = useCallback(async (order_id: any, new_status: number) => {
    try {
      await updateOrder({
        order_id: order_id,
        order_status: Number(new_status)
      });
      setShowPopupForChangeOrderStatus('');
    } catch (e) {}
  }, []);

  /**
   * END order Status
   */

  const renderItem = (orderDetail: TypedOrder) => {
    const {
      order_id,
      order_pnr,
      order_status,
      order_note,
      order_total_price,
      order_total_mustpay,
      order_fulfillment,
      order_delivery_type,
      order_checked,
      payment_type,
      payment_status,
      user_id,
      createdAt,
      customer_data
    } = orderDetail;
    let order_fulfillment_error = order_fulfillment?.order_fulfillment_error;
    return [order_id, <Popover active={showActionList === order_id} activator={<Button variant="plain" disclosure={'down'} onClick={() => togglePophoverActionListCallback(order_id)}>
            {order_pnr}
          </Button>} autofocusTarget="first-node" onClose={() => onCloseActionList(order_id)}>
        <ActionList actionRole="menuitem" sections={[{
        title: 'Chung',
        items: [{
          content: 'Xem chi tiết',
          icon: ViewIcon,
          onAction: () => history('/order/view/' + order_id)
        }, {
          content: 'In Packing Slip',
          icon: PrintIcon,
          onAction: () => history(process.env.REACT_APP_BACKEND_URL + '/orders/packing_slip/print/' + order_id)
        }]
      }, {
        title: 'Đối soát',
        items: [{
          content: 'Hoàn thành đơn',
          icon: StatusActiveIcon,
          disabled: order_checked === 1,
          onAction: () => markAsChecked(order_pnr, order_id, '14')
        }, {
          content: 'Hủy đơn',
          icon: XCircleIcon,
          disabled: order_checked === 1,
          onAction: () => markAsChecked(order_pnr, order_id, '19')
        }, {
          content: 'Hoàn đơn',
          icon: ReceiptRefundIcon,
          disabled: order_checked === 1,
          onAction: () => markAsChecked(order_pnr, order_id, '15')
        }]
      }]} />
      </Popover>, <div className="small-icon">
        {order_checked === 1 ? <Tooltip content="Đã đối soát">
            <Text as="span">
              <LockIcon />
            </Text>
          </Tooltip> : ''}
      </div>, createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm') : '-', order_checked ? getOrderStatusBadge(order_status) : <Popover active={showPopupForChangeOrderStatus === order_id} activator={<>
              <Link onClick={() => setShowPopupForChangeOrderStatus(order_id)}>{getOrderStatusBadge(order_status)}</Link>
            </>} autofocusTarget="first-node" onClose={() => setShowPopupForChangeOrderStatus('')}>
          <ActionList actionRole="menuitem" items={orderStatusArray.map((el, index) => {
        return {
          content: el.label,
          onAction: () => changeOrderStatus(order_id, el.value),
          suffix: String(order_status) === el.value && <Icon source={CheckSmallIcon} />
        };
      })} />
        </Popover>, order_checked ? getOrderDeliveryMethod(order_delivery_type) : <Popover active={showDeliveryType === order_id} activator={<Link removeUnderline onClick={() => togglePophoverOrderCallback(order_id)}>
              {order_delivery_type ? getOrderDeliveryMethod(order_delivery_type) : <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </Link>} onClose={() => onClosePophoverOderCallback(order_id)} ariaHaspopup={false}>
          <ActionList actionRole="menuitem" items={Object.entries(orderDeliverType).map(([key, value]) => {
        return {
          content: __(value),
          onAction: () => savingDeliveryTypeCallback(order_id, key),
          suffix: String(order_delivery_type) === key && <Icon source={CheckSmallIcon} />,
          active: String(order_delivery_type) === key
        };
      })} />
        </Popover>, <div style={{
      minWidth: '180px'
    }}>
        <InlineStack gap={'100'} align="start" blockAlign="center">
          {order_fulfillment_error && <Link onClick={() => clearOrderFulfillmentError(order_id)}>
              <span title={order_fulfillment_error}>
                <span className="Polaris-Text--root Polaris-Text--bodySm" style={{
              fontSize: '12px'
            }}>
                  <Icon source={AlertDiamondIcon} tone="critical" />
                </span>
              </span>
            </Link>}

          {order_checked ? order_fulfillment?.logistic_service?.logistic_name || <>
                <Badge></Badge>
                <Badge></Badge>
              </> : <Popover active={showLogisticService === order_id} activator={<Link removeUnderline onClick={() => toggleLogisticServiceCallback(order_id)}>
                  {order_fulfillment?.logistic_service ? order_fulfillment?.logistic_service?.logistic_name : <>
                      <Badge></Badge>
                      <Badge></Badge>
                    </>}
                </Link>} onClose={() => onCloseLogisticServiceCallback(order_id)} ariaHaspopup={false}>
              <ActionList actionRole="menuitem" sections={[{
            items: findLogistic(orderDeliverType[order_delivery_type], order_id, order_fulfillment?.logistic_service?.logistic_id)
          }]} />
            </Popover>}
        </InlineStack>
      </div>, order_checked || order_status !== 2 ? order_fulfillment?.order_fulfillment_code || <>
            <Badge></Badge>
            <Badge></Badge>
          </> : <Popover active={showLogisticFulfillmentCode === order_id} activator={<Link removeUnderline onClick={() => toggleFulfillmentCallback(order_id, order_fulfillment?.order_fulfillment_code || '')}>
              {order_fulfillment?.order_fulfillment_code ? order_fulfillment?.order_fulfillment_code : <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </Link>} onClose={() => onCloseFulfillmentCallback(order_id)} ariaHaspopup={false} sectioned>
          <Form onSubmit={savingFulfillmentCallback}>
            <TextField label="Mã vận chuyển" value={currentFulfillmentCode} onChange={val => setCurrentFulfillmentCode(val)} autoComplete="off" />
          </Form>
        </Popover>, helpers.formatNumber(order_total_mustpay, ','), payment_type, <Popover active={showPaymentStatusPopup === order_id} activator={<Link onClick={() => toggleShowPaymentStatusPopup(order_id)}>{getBadgeOfPaymentMethod(payment_status)}</Link>} onClose={() => toggleShowPaymentStatusPopup(order_id)} ariaHaspopup={false} sectioned>
        {/** PAYMENT .... */}
        <ActionList actionRole="menuitem" sections={[{
        items: PAYMENT_STATUS_CONST.map((el, index) => {
          return {
            content: __(el),
            onAction: () => changePaymentStatusCallback(order_id, index),
            suffix: payment_status === index && <Icon source={CheckSmallIcon} />
          };
        })
      }]} />
      </Popover>, <div className="sale_order_note">
        <Popover active={showPophoverOrderNote === order_id} activator={<Link removeUnderline onClick={() => togglePophoverOrderNoteCallback(order_id, order_note)}>
              {order_note || <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </Link>} onClose={() => onClosePophoverOderNoteCallback(order_id)} ariaHaspopup={false} sectioned>
          <NoteComponent label="Ghi chú đơn hàng" default_string={noteForOrder} onSave={submitNoteOrderCallback} args={order_id} />
        </Popover>
      </div>, user_id ? <Link onClick={() => history(`/customer/view/${user_id}`)}>
          {customer_data?.display_name || customer_data?.user_email || customer_data?.user_phonenumber || '---'}
        </Link> : <>
          <Badge></Badge>
          <Badge></Badge>
        </>];
  };
  const OrderList = <DataTable verticalAlign="middle" sortable={[]} defaultSortDirection="descending" initialSortColumnIndex={0} columnContentTypes={['numeric', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'text', 'text', 'text']} headings={['ID', 'PNR', '', 'Ngày tạo', 'Tình trạng', 'Vận chuyển', 'D.vụ vận chuyển', 'Mã vận chuyển', 'Giá trị', 'Cách thanh toán', 'Thanh toán', 'Ghi chú', 'Khách hàng']} rows={entities?.map(renderItem)} hideScrollIndicator
  // fixedFirstColumns={1}
  stickyHeader firstColumnMinWidth="100px" footerContent={___('Show page {display_page_number} of {total_record_number} results', {
    display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
    total_record_number: <Text as="strong">{totalItems}</Text>
  })} />;
  return <>
      <Helmet>
        <title>Danh sách đơn hàng</title>
      </Helmet>
      {showBulkOrderAction ? <BulkModal onClose={() => setShowBulkOrderAction(false)} /> : null}

      {loading || updating ? <Loading /> : null}
      <Page fullWidth title="Danh sách đơn hàng" subtitle="Quản lý và chỉnh sửa danh sách đơn hàng" compactTitle primaryAction={{
      content: 'Thêm đơn hàng',
      loading: updating,
      icon: PlusIcon,
      onAction: createNewEmptyOrder
    }} secondaryActions={[{
      content: 'Đối soát hàng loạt',
      icon: ListBulletedIcon,
      onAction: () => setShowBulkOrderAction(true)
    }]}>
        <Card padding="0">
          <FilterAdvance loading={loading} onCallback={filterCallback} />
          {OrderList}
        </Card>
        <br />
        {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={Number(mainQuery?.page)} pageSize={mainQuery?.limit} onChange={onChangePageNumber} /> : null}
      </Page>
      <br />
      <br />
    </>;
}