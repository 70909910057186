import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import helpers from 'helpers';

export const initialState = {
  loading: false,
  updating: false,
  entities: null as any[],
  errorMessage: null as any, // Errors returned from server side
  updateSuccess: false,
};

const apiUrl = 'user/user_social_profile';


export const addUserSocialProfile = createAsyncThunk(
  'user_social_profile/add_user_social_profile',
  async (entity: { social_name: string, social_profile_id: string, user_id: string }) => {
    return await axios.post<any>(`${apiUrl}/`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


export const deleteUserSocialProfile = createAsyncThunk(
  'user_social_profile/delete_user_social_profile',
  async (social__id: string) => {
    return await axios.delete<any>(`${apiUrl}/${social__id}`);
  },
  { serializeError: serializeAxiosError }
);



export const UserSocialProfile = createEntitySlice({
  name: 'user_social_profile',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    },
    reset: (state) => {
      return { ...state, ...initialState };
    }
  },

  extraReducers(builder) {
    builder

      .addCase(addUserSocialProfile.fulfilled, (state, action) => {
        state.updating = false;
        state.entities = action.payload.data;
      })
      .addCase(addUserSocialProfile.pending, (state, action) => {
        state.updating = true;
      })
      .addCase(addUserSocialProfile.rejected, (state, action) => {
        state.updating = false;
        state.errorMessage = action.payload;
      })

      .addCase(deleteUserSocialProfile.fulfilled, (state, action) => {
        state.updating = false;
        state.entities = action.payload.data;
      })
      .addCase(deleteUserSocialProfile.pending, (state, action) => {
        state.updating = true;
      })
      .addCase(deleteUserSocialProfile.rejected, (state, action) => {
        state.updating = false;
        state.errorMessage = action.payload;
      })


  },
});
export const { clearError, reset } = UserSocialProfile.actions;
// Reducer
export default UserSocialProfile.reducer;