import { Form, FormLayout, List, Modal, Text, TextField, BlockStack, Icon } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadFile from 'components/oneclick-upload-file';
import { useAppDispatch, useAppSelector } from 'config/store';
import QuickSearchEmailSetting from './quick_search_email_setting';
import { useCallback, useEffect, useState } from 'react';
import { createEntity } from 'store/email_template.store.reducer';
import { UploadIcon } from '@shopify/polaris-icons';
import __ from 'languages/index';
export default function EmailTemplatePopup({
  show,
  onClose
}: {
  show: boolean;
  onClose: Function;
}) {
  const loading = useAppSelector(state => state.email_template.loading);
  const updating = useAppSelector(state => state.email_template.updating);
  const updateSuccess = useAppSelector(state => state.email_template.updateSuccess);
  const deleting = useAppSelector(state => state.email_template.deleting);
  const deleteSuccess = useAppSelector(state => state.email_template.deleteSuccess);
  const dispatch = useAppDispatch();
  const [uploadError, setUploadError] = useState(null);
  // const [selectServerID, setSelectServerID] = useState(null);

  const closeMyModal = useCallback(() => {
    onClose?.call(this, null);
    resetForm();
  }, []);
  const useFields = {
    template_name: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống.'), lengthMoreThan(5, 'Tên mẫu quá ngắn.'), lengthLessThan(255, 'Tên mẫu quá dài.')]
    }),
    template_url: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống.')]
    })
  };
  const {
    fields,
    submit,
    reset: resetForm,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        dispatch(createEntity({
          template_name: values.template_name,
          template_url: values.template_url
          //   email_setting: selectServerID
        }));
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (deleteSuccess) closeMyModal();
  }, [deleteSuccess]);
  useEffect(() => {
    if (updateSuccess) {
      closeMyModal();
      resetForm();
      makeClean();
    }
  }, [updateSuccess]);
  return <Modal activator={null} open={show} onClose={closeMyModal} title="Add Email Template" primaryAction={{
    content: 'Thêm template',
    onAction: () => submit(),
    loading: updating
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeMyModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <TextField autoFocus label="Tên mẫu" autoComplete="off" {...fields.template_name} />

            {/* 
                <Text as="p">Tài khoản gửi email *</Text>
                <QuickSearchEmailSetting current_setting={null} onClose={(id) => setSelectServerID(id) } />
             */}

            <Text as="p" tone="success">
              {useFields.template_url.value}
            </Text>
            <br />
            <QuickUploadFile onSuccess={e => {
            useFields.template_url.onChange(e.media_filename);
          }} onError={e => setUploadError(e)} children={<div style={{
            border: '2px dashed #000'
          }}>
                  <BlockStack gap="500" align="center" inlineAlign="center">
                    <Icon source={UploadIcon} />
                    <Text as="span"> Tải mẫu lên</Text>
                  </BlockStack>
                </div>} acceptMimeType={['text/html', 'text/htm', 'text/plain']} />
            <Text as="p" tone="critical">
              {uploadError}
            </Text>

            <br />

            <BlockStack gap={'200'}>
              <Text as="h3" variant="headingSm">
                Template cho Email
              </Text>
              <Text as="p" tone="subdued" variant="bodySm">
                Thiết kế file html, chuyển sang đuôi file txt và tải lên tại đây.{' '}
              </Text>
              <Text as="p" tone="subdued" variant="bodySm">
                Trong template, có rất nhiều các placeholder được phép sử dụng như:
              </Text>
              <List type="bullet">
                <List.Item>
                  <code>{`{{site_name}}`}</code>: Tên công ty
                </List.Item>
                <List.Item>
                  <code>{`{{site_slogan}}`}</code>: Slogan
                </List.Item>
                <List.Item>
                  <code>{`{{site_main_address_1}}`}</code>: Địa chỉ công ty dòng 1
                </List.Item>
                <List.Item>
                  <code>{`{{site_main_address_2}}`}</code>: Địa chỉ công ty dòng 2
                </List.Item>
                <List.Item>
                  <code>{`{{site_main_address_district}}`}</code>: Địa chỉ công ty: Quận huyện
                </List.Item>
                <List.Item>
                  <code>{`{{site_main_address_city}}`}</code>: Địa chỉ công ty: Tỉnh / thành phố
                </List.Item>
                <List.Item>
                  <code>{`{{subject}}`}</code> là chủ đề email
                </List.Item>
                <List.Item>
                  <code>{`{{title}}`}</code> là tựa đề trong email
                </List.Item>
                <List.Item>
                  <code>{`{{content}}`}</code> là nội dung email
                </List.Item>
                <List.Item>
                  <code>{`{{name}}`}</code> là tên người nhận
                </List.Item>
                <List.Item>
                  <code>{`{{address}}`}</code> là email của người nhận
                </List.Item>
                <List.Item>
                  <code>{`{{email_id}}`}</code> là id của Email được lưu trữ trên hệ thống, giúp tracking dễ dàng
                </List.Item>
              </List>
            </BlockStack>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;
}