import { compiledBlock as _compiledBlock } from "million/react";
const TaskList_1 = _compiledBlock(_props => <slot>
              <div className="small-icon">
                  {_props.v0}
              </div>
  
              {_props.v1}
          </slot>, {
  name: "TaskList_1",
  portals: ["v0", "v1"]
});
const TaskList = function TaskList({
  project_id
}: {
  project_id: string | number;
}) {
  const entities = useAppSelector(state => state.task.entities);
  const loading = useAppSelector(state => state.task.loading);
  const errorMessage = useAppSelector(state => state.task.errorMessage);
  const updateSuccess = useAppSelector(state => state.task.updateSuccess);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getEntities({
      limit: 50,
      project_id: project_id,
      sort: 'createdAt:asc'
    }));
  }, [updateSuccess]);
  function task_status(task_status: number) {
    switch (task_status) {
      case 0:
        return <span className="default"></span>;
        break;
      case 1:
        return <span className="processing"></span>;
        break;
      case 2:
        return <span className="done"></span>;
        break;
      case 9:
        return <span className="cancel"></span>;
        break;
    }
  }
  const [showPopupForChangeTaskStatus, setShowPopupForChangeTaskStatus] = useState(null);
  const changeTaskStatus = useCallback((task_id: string, task_status: number) => {
    dispatch(updateEntity({
      task_id: task_id,
      task_status: String(task_status)
    }));
    setShowPopupForChangeTaskStatus(null);
  }, []);
  return /*@million jsx-skip*/<TaskList_1 v0={<Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                      <ClipboardCheckIcon /> Task
                  </Text>} v1={entities && entities.length > 0 ? <div className="task_list_wrap">
                        {entities?.map((renderItem, index) => {
      return <div className="task_list_template" key={index}>
                                        <div className="task_mark">
                                        <Popover active={showPopupForChangeTaskStatus === renderItem.task_id} activator={<>
                                            <Link onClick={() => setShowPopupForChangeTaskStatus(renderItem.task_id)}>
                                                {task_status(Number(renderItem.task_status))}
                                            </Link>
                                            </>} autofocusTarget="first-node" onClose={() => setShowPopupForChangeTaskStatus(null)}>
                                            <ActionList actionRole="menuitem" items={[{
              content: 'Đang triển khai',
              onAction: () => changeTaskStatus(renderItem.task_id, 1)
            }, {
              content: 'Hoàn thành',
              onAction: () => changeTaskStatus(renderItem.task_id, 2)
            }, {
              content: 'Hủy nhiệm vụ',
              onAction: () => changeTaskStatus(renderItem.task_id, 9)
            }]} />
                                        </Popover>
                                        </div>
                                        <div className="task_content">
                                            <div className="task_title">{renderItem.task_title}</div>
                                            
                                            <div className="task_description">{renderItem.task_description}</div>
                                            <div className="task_assign"><Link>{renderItem?.user?.display_name}</Link>, tạo lúc {dateandtime.format(new Date(Number(renderItem.createdAt)), 'DD-MM-YYYY')}  {renderItem.lastUpdated ? ' - cập nhật lúc ' + dateandtime.format(new Date(Number(renderItem.lastUpdated)), 'DD-MM-YYYY HH:mm') : null}</div>
                                        </div>
                                    </div>;
    })}
                        

                    </div> : <ExceptionList items={[{
    icon: AlertCircleIcon,
    description: 'Chưa có một task nào.'
  }]} />} />;
};
import { block as _block } from "million/react";
import React, { useCallback, useEffect, useState } from "react";
import { Text, Link, ExceptionList, Popover, ActionList } from "@shopify/polaris";
import { ClipboardCheckIcon, AlertCircleIcon } from '@shopify/polaris-icons';
import dateandtime from 'date-and-time';
import { useAppDispatch, useAppSelector } from "config/store";
import { getEntities, updateEntity } from "store/task.store.reducer";
export default TaskList;