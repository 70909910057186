import { Modal, Select, TextField, Text, Checkbox } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { useBulkUpdateOrder } from 'queries/orders.query';
export default function BulkModal({
  onClose
}) {
  const {
    mutateAsync: bulkUpdateEntities,
    data: bulkUpdateEntitiesResult,
    isPending: bulkUpdateEntitiesLoading
  } = useBulkUpdateOrder();
  const [selected, setSelected] = useState('14');
  const handleSelectChange = useCallback((value: string) => setSelected(value), []);

  /**
   * Tình trạng đơn hàng
   */
  const orderStatusOptions = [{
    label: 'Hoàn thành',
    value: '14'
  }, {
    label: 'Hoàn hàng',
    value: '15'
  }, {
    label: 'Hủy đơn',
    value: '19'
  }];
  const [pnrs, setPnrs] = useState('');
  const handleTextFieldChange = useCallback((newValue: string) => setPnrs(newValue), []);
  const onSubmit = useCallback(() => {
    let afterFormat = pnrs.split('\n').join(',');
    bulkUpdateEntities({
      order_pnr: afterFormat,
      mode: selected
    }).catch(e => {});
  }, [pnrs, selected]);

  // payment_status
  return <>
      <Modal open={true} onClose={onClose} title="Xử lý đơn hàng loạt" primaryAction={{
      content: 'Bắt đầu',
      loading: bulkUpdateEntitiesLoading,
      disabled: bulkUpdateEntitiesLoading,
      onAction: onSubmit
    }} secondaryActions={[{
      content: 'Close',
      onAction: onClose,
      disabled: bulkUpdateEntitiesLoading
    }]}>
        <Modal.Section>
          <TextField label="Mã PNR của Order" helpText="Phân cách mỗi mã bằng xuống dòng mới" value={pnrs} autoFocus onChange={handleTextFieldChange} multiline={8} autoComplete="off" />

          <br />

          <Select label="Chọn đối soát" labelInline options={orderStatusOptions} onChange={handleSelectChange} value={selected} />

          <br />

          {bulkUpdateEntitiesResult ? <div>
              <br />
              {bulkUpdateEntitiesResult.invalidPNR.length > 0 ? <Text as="p" tone="critical">
                  Các mã PNR lỗi cần kiểm tra lại: {bulkUpdateEntitiesResult.invalidPNR.join(', ')}
                </Text> : <>
                  <Text as="p">Thành công: {bulkUpdateEntitiesResult.success}</Text>
                  <Text as="p">Lỗi: {bulkUpdateEntitiesResult.fail}</Text>
                </>}
            </div> : null}
        </Modal.Section>
      </Modal>
    </>;
}