import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';
import { TypedDevice } from 'interface/device.model';


/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as TypedDevice[],
  entity: null as TypedDevice,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'device';

// Actions

export const getEntities = createAsyncThunk('device/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);


export const getEntity = createAsyncThunk('device/fetch_single_entity', async (device_uuid: string) => {
  const requestUrl = `${apiUrl}/${device_uuid}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);


export const assignUserToDeviceUUID = createAsyncThunk(
  'device/assignUserToDeviceUUID',
  async (entity: { user_id: string, device_uuid: string }) => {
    return await axios.post<any>(`${apiUrl}/assign`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'device/updateEntity',
  async (entity: { device_id: string, status: number }) => {
    let device_id = String(entity.device_id);
    delete entity.device_id;
    return await axios.patch<any>(`${apiUrl}/${device_id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);



export const deleteEntity = createAsyncThunk(
  'device/delete_entity',
  async (file_name: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${file_name}`;
    const result = await axios.delete<any>(requestUrl);
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_System_Device = createEntitySlice({
  name: 'device',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })


      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
        state.entity = null;
      })


      .addCase(updateEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.loading = true;
        state.entity = null;
      })


      .addCase(assignUserToDeviceUUID.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(assignUserToDeviceUUID.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(assignUserToDeviceUUID.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })

      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })
  },
});

export const { clearError, reset } = Reducer_System_Device.actions;

// Reducer
export default Reducer_System_Device.reducer;
