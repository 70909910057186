import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { memo } from 'react';
import 'media/css/starRating.scss';
const StarRatingComponent_2 = _compiledBlock(() => <div className="star_wrap">
          <span className="star checked"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_2"
});
const StarRatingComponent_3 = _compiledBlock(() => <div className="star_wrap">
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_3"
});
const StarRatingComponent_4 = _compiledBlock(() => <div className="star_wrap">
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_4"
});
const StarRatingComponent_5 = _compiledBlock(() => <div className="star_wrap">
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_5"
});
const StarRatingComponent_6 = _compiledBlock(() => <div className="star_wrap">
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
        </div>, {
  name: "StarRatingComponent_6"
});
const StarRatingComponent_1 = function StarRatingComponent({
  num
}: {
  num: number;
}) {
  switch (num) {
    case 1:
      return /*@million jsx-skip*/<StarRatingComponent_2 />;
    case 2:
      return /*@million jsx-skip*/<StarRatingComponent_3 />;
    case 3:
      return /*@million jsx-skip*/<StarRatingComponent_4 />;
    case 4:
      return /*@million jsx-skip*/<StarRatingComponent_5 />;
    case 5:
      return /*@million jsx-skip*/<StarRatingComponent_6 />;
  }
};
const StarRating = memo(StarRatingComponent_1);
export default StarRating;