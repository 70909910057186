import { Card, Text,
// IndexTable,
Link, Page,
// useIndexResourceState,
Thumbnail,
// LegacyCard,
DataTable,
// TableData,
InlineStack, Button } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import { DeleteIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
// import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
// import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import ProductCategoryModalAddAndEdit from './brand.modal';
import { useDeleteProductBrand, useGetProductBrands } from 'queries/brand.query';
import { TypedProductBrand } from 'queries/brand.query';
import DeleteConfirm from 'components/deleteConfirm';
export default function BrandList() {
  const [entities, setEntities] = useState<TypedProductBrand[] | null>(null);
  const [totalItems, setTotalItems] = useState(0);

  // const dispatch = useAppDispatch();
  // const history = useNavigate();

  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    isLoading: searching,
    data: productCategoryData
  } = useGetProductBrands(mainQuery);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductBrand();
  useEffect(() => {
    if (productCategoryData) {
      let {
        body,
        totalItems
      } = productCategoryData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [productCategoryData]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((page: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      ...{
        page,
        limit
      }
    });
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product Brand', '/product_brand' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const modalEntity = useRef(null);
  const clickToShowEditModal = useCallback((categoryData: TypedProductBrand) => {
    modalEntity.current = categoryData;
    setShowModal(true);
  }, []);

  // delete modal
  const current_delete_id = useRef('');
  const [openDeleteModal, setOpenDeleteModal] = useState<any>(null);
  const onDeleteAgree = useCallback(async () => {
    try {
      await deleteEntity(current_delete_id.current);
      setOpenDeleteModal(null);
    } catch (e) {}
  }, [current_delete_id.current]);
  return <>
      <Helmet>
        <title>Quản lý nhãn hiệu hàng hóa</title>
      </Helmet>

      <ProductCategoryModalAddAndEdit show={showModal} onClose={(el: any) => {
      modalEntity.current = null;
      setShowModal(false);
    }} entity={modalEntity.current} />

      <DeleteConfirm title="Xoá ứng dụng này?" content="Ứng dụng sau khi được xóa thành công, sẽ ngăn cản các website đăng nhập bằng SSO." show={!!openDeleteModal} onClose={(mode: boolean) => {
      mode === true ? onDeleteAgree() : setOpenDeleteModal(null);
    }} />

      <Page title="Nhãn hiệu hàng hóa" primaryAction={{
      content: 'Thêm nhãn hiệu',
      onAction: () => setShowModal(true)
    }}>
        <Card padding={'0'}>
          <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'numeric', 'numeric', 'numeric']} headings={['Tên nhãn hiệu', 'Mô tả', 'Sản phẩm', 'Ngày tạo', '-']} rows={entities?.length > 0 ? entities.map((ProductBrandData, index) => {
          return [<Link removeUnderline onClick={() => clickToShowEditModal(ProductBrandData)} key={index}>
                        {ProductBrandData.product_brand_to_media ? <InlineStack blockAlign="center" gap={'100'}>
                            <Thumbnail size="small" source={helpers.getMediaLink(ProductBrandData.product_brand_to_media ? ProductBrandData.product_brand_to_media?.media_thumbnail ? ProductBrandData.product_brand_to_media?.media_thumbnail['128x128'] : '' : undefined)} alt={''} />
                            <Text as="span">{ProductBrandData.brand_name}</Text>
                          </InlineStack> : <Text as="span">{ProductBrandData.brand_name}</Text>}
                      </Link>, <Text as="span">{helpers.trimMiddleString(ProductBrandData.brand_excerpt, 30, 10)}</Text>, ProductBrandData._count.product_to_brand || 0, dateandtime.format(new Date(Number(ProductBrandData.createdAt)), 'DD-MM-YYYY'), <Button tone="critical" variant="plain" icon={DeleteIcon} onClick={() => {
            setOpenDeleteModal(true);
            current_delete_id.current = ProductBrandData.brand_id || '';
          }}></Button>];
        }) : []} footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{entities?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </Card>
        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}