import { InlineStack, SkeletonDisplayText, Text } from '@shopify/polaris';
import CustomerBirthDayList from 'components/customer_birthday';
import UserBirthDayList from 'components/user_birthday';
import { useAppDispatch } from 'config/store';
import helpers from 'helpers/index';
import React, { useEffect, useState } from 'react';
import { getGeneralAnalytics } from 'store/analytics.store.reducer';
export default function ForLoggedInPeople() {
  const dispatch = useAppDispatch();
  const [generalAnalyticsData, setGeneralAnalyticsData] = useState<any>({});
  const [loadingGeneralAnalytics, setLoadingGeneralAnalytics] = useState(false);
  useEffect(() => {
    setLoadingGeneralAnalytics(true);
    dispatch(getGeneralAnalytics()).unwrap().then(response => {
      let {
        data
      } = response;
      setGeneralAnalyticsData(data);
      setLoadingGeneralAnalytics(false);
    }).catch(e => {
      setLoadingGeneralAnalytics(false);
    });
  }, []);
  const HomeAnalytics = () => {
    return <div className="home_analytics">
        <InlineStack gap="400" align="space-between" blockAlign="start">
          <div className="dd">
            <Text as="h2" variant="headingMd" fontWeight="bold">
              Hallu, mọi thứ hôm nay
            </Text>
            <div className="home_analytics_body">
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.newCustomerToday ?? 0)}</div>
                    <div className="des">Khách hàng mới</div>
                  </>}
              </div>
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.orderCountToday ?? 0)}</div>
                    <div className="des">Đơn hàng mới</div>
                  </>}
              </div>
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.newTransactionToday ?? 0)}</div>
                    <div className="des">Giao dịch mới</div>
                  </>}
              </div>
            </div>
          </div>
        </InlineStack>
      </div>;
  };
  return <>
      <HomeAnalytics />
      <CustomerBirthDayList customer_campaign={undefined} />
      <UserBirthDayList limit={100} />
    </>;
}