import { Page, Layout, FormLayout, TextField, Text, Form, ChoiceList, BlockStack, InlineGrid, Select, Divider, ContextualSaveBar, Card, Checkbox, Tag, InlineStack, Button, EmptyState, Tooltip, Link } from '@shopify/polaris';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProduct, useUpdateProduct, useCreateProduct } from 'queries/product.query';
import helpers from '../../helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { StatusActiveIcon, InfoIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchProductCategory from './quickSearchProductCategory';
import { Helmet } from 'react-helmet-async';
import __ from 'languages/index';
import { useNotification } from 'NotificationContext';
import ProductGallery from './ProductGallery';
import ProductVariants from './product_variant/list';
import TextFieldRichText from 'components/TextFieldRichText';
import { FormPrompt } from 'components/FormPrompt';
import DialogConfirm from 'components/dialogConfirm';
import QuickSearchProductBrand from './quickSearchProductBrand';
export default function ProductEdit() {
  let {
    product_id: current_product_id
  } = useParams();
  if (current_product_id === 'new') current_product_id = null;
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: getEntity,
    data: entity,
    isPending: loading
  } = useGetProduct();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateProduct();
  const {
    mutateAsync: createEntity,
    isPending: creating
  } = useCreateProduct();
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('update_successfully'));
  }, [updateSuccess]);

  // const dispatch = useAppDispatch();
  const history = useNavigate();
  useEffect(() => {
    if (current_product_id) getEntity(current_product_id);
  }, [current_product_id]);
  const useFields = {
    product_thumbnail: useField<string>({
      value: entity?.product_thumbnail ?? '',
      validates: []
    }),
    product_thumbnail_cache: useField<string>({
      value: entity?.product_thumbnail_to_media ? entity.product_thumbnail_to_media?.media_thumbnail['512x512'] : undefined,
      validates: []
    }),
    product_name: useField<string>({
      value: entity?.product_name ?? '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.'), inputValue => {
        if (inputValue.length < 2) {
          return 'Your title is too short, or it is empty.';
        }
      }]
    }),
    product_slug: useField<string>({
      value: entity?.product_slug,
      validates: [inputVal => {
        if (helpers.isUTF8(inputVal)) {
          return 'Không được chứa ký tự đặc biệt';
        }
      }]
    }),
    product_excerpt: useField<string>({
      value: entity?.product_excerpt ?? '',
      validates: [lengthLessThan(250, 'No more than 250 characters.')]
    }),
    product_description: useField<string>({
      value: entity?.product_description ?? '',
      validates: [lengthLessThan(65000, 'No more than 65000 characters.')]
    }),
    product_price: useField<string>({
      value: entity?.product_price ? String(entity?.product_price) : '',
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 1) {
          return 'product_price must be a valid number!';
        }
      }]
    }),
    product_original_price: useField<string>({
      value: entity?.product_original_price ? String(entity?.product_original_price) : '',
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 1) {
          return 'product_original_price must be a number!';
        }
      }]
    }),
    product_size_length: useField<string>({
      value: entity?.product_size_length ? String(entity?.product_size_length) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_length must be a number!';
        }
      }]
    }),
    product_size_width: useField<string>({
      value: entity?.product_size_width ? String(entity?.product_size_width) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_width must be a number!';
        }
      }]
    }),
    product_size_height: useField<string>({
      value: entity?.product_size_height ? String(entity?.product_size_height) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_height must be a number!';
        }
      }]
    }),
    product_size_weight: useField<string>({
      value: entity?.product_size_weight ? String(entity?.product_size_weight) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_weight must be a number!';
        }
      }]
    }),
    product_sku: useField<string>({
      value: entity?.product_sku ?? '',
      validates: [lengthLessThan(50, 'No more than 50 characters.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'UTF-8 can not be used here.';
        }
      }]
    }),
    product_barcode: useField<string>({
      value: entity?.product_barcode ?? '',
      validates: [lengthLessThan(48, 'No more than 48 characters.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'UTF-8 can not be used here.';
        }
      }]
    }),
    product_tags: useField<string>({
      value: entity?.product_tags ?? '',
      validates: [lengthLessThan(255, 'No more than 255 characters.')]
    }),
    product_type: useField<string>({
      value: entity?.product_type ?? 'normal',
      validates: []
    }),
    product_status: useField<string>({
      value: entity ? String(entity.product_status) : '0',
      validates: []
    }),
    product_category: useField<string[]>({
      value: entity ? entity?.product_to_category.map(el => el.category_id) : [],
      validates: [notEmpty('Trường này không được để trống'), inputVal => {
        if (helpers.isEmpty(inputVal)) return 'Trường này yêu cầu phải điền.';
      }]
    }),
    product_brand: useField<string>({
      value: entity ? entity?.product_to_brand?.product_brand?.brand_id : '',
      validates: []
    }),
    product_has_variants: useField<number>({
      value: entity?.product_has_variants ?? 0,
      validates: []
    }),
    product_badge: useField<string>({
      value: entity?.product_badge,
      validates: []
    })
  };
  useEffect(() => {
    // chế độ thêm mới, thì mình chỉnh product_slug
    if (!entity) useFields.product_slug.onChange(helpers.slugify(useFields.product_name.value));
  }, [useFields.product_name.value, entity]);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!current_product_id) {
          // create new
          let afterUpdate = await createEntity({
            product_name: values.product_name,
            product_slug: values.product_slug,
            product_excerpt: values.product_excerpt,
            product_description: values.product_description,
            product_thumbnail: values.product_thumbnail,
            product_price: helpers.parseNumeric(values.product_price),
            product_original_price: helpers.parseNumeric(values.product_original_price),
            product_sku: values.product_sku,
            product_barcode: values.product_barcode,
            product_badge: values.product_badge,
            product_tags: values.product_tags,
            product_type: String(values.product_type),
            product_status: Number(values.product_status),
            product_size_length: Number(values.product_size_length),
            product_size_width: Number(values.product_size_width),
            product_size_height: Number(values.product_size_height),
            product_size_weight: Number(values.product_size_weight),
            product_category: values.product_category,
            product_has_variants: Number(values.product_has_variants),
            product_media: [],
            product_brand: values.product_brand
          });
          history('/product/edit/' + afterUpdate?.data?.product_id);
        } else {
          await updateEntity({
            product_id: current_product_id,
            product_name: values.product_name,
            product_slug: values.product_slug,
            product_excerpt: values.product_excerpt,
            product_description: values.product_description,
            product_thumbnail: values.product_thumbnail,
            product_price: helpers.parseNumeric(values.product_price),
            product_original_price: helpers.parseNumeric(values.product_original_price),
            product_sku: values.product_sku,
            product_barcode: values.product_barcode,
            product_badge: values.product_badge,
            product_tags: values.product_tags,
            product_type: String(values.product_type),
            product_status: Number(values.product_status),
            product_size_length: Number(values.product_size_length),
            product_size_width: Number(values.product_size_width),
            product_size_height: Number(values.product_size_height),
            product_size_weight: Number(values.product_size_weight),
            product_has_variants: Number(values.product_has_variants),
            product_category: values.product_category,
            product_brand: values.product_brand
          });
        }
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const productTypeOption = useFields.product_type;
  const productStatusOption = useFields.product_status;
  const handUploadError = useCallback((err: any) => {
    addNotification('error', err?.message ?? 'unknown_error');
  }, []);
  const [showConfirmUnsavedForm, setShowConfirmUnsavedForm] = useState(false);
  const [productTagsValue, setProductTagsValue] = useState('');
  const removeProductTagCallback = useCallback((keywords: string) => {
    if (!useFields.product_tags.value) return;
    let OriginalKey = useFields.product_tags.value.split(',').filter(el => el !== keywords);
    useFields.product_tags.onChange(OriginalKey.join(','));
  }, [useFields.product_tags]);
  return <>
      <Helmet>
        <title>Chỉnh sửa sản phẩm</title>
      </Helmet>
      {dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
      onAction: submit,
      loading: updating,
      disabled: !dirty
    }} discardAction={{
      content: 'Hoàn tác',
      onAction: Userreset
    }} />}

      {loading ? <SkeletonLoading /> : <Page backAction={{
      content: 'Products',
      onAction: () => dirty ? setShowConfirmUnsavedForm(true) : history('/product')
    }} title={entity ? helpers.getTrimContent(entity.product_name, 50) : 'Thêm mới sản phẩm'} subtitle={entity ? entity.product_excerpt : ''} compactTitle>
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <FormLayout>
                  <Card>
                    <Text as="h2" variant="headingSm">
                      Thông tin chung
                    </Text>
                    <br />

                    <InlineGrid gap="200" columns={{
                  xs: '',
                  md: ['oneThird', 'twoThirds']
                }}>
                      <QuickUploadImage height={180} width={180} placeholder={helpers.getMediaLink(useFields.product_thumbnail_cache.value, 'https://placehold.co/512x512')} onSuccess={res => {
                    useFields.product_thumbnail.onChange(res.media_id);
                    useFields.product_thumbnail_cache.onChange(res.media_url);
                  }} onError={handUploadError} />
                      <div>
                        <BlockStack gap={'500'}>
                          <TextField autoComplete="off" autoFocus label="Tên sản phẩm" {...fields.product_name} />
                          <TextField autoComplete="off" label="Đường dẫn tĩnh" {...fields.product_slug} />

                          <TextField autoComplete="off" maxLength={250} label="Trích dẫn / mô tả ngắn" {...fields.product_excerpt} showCharacterCount={true} multiline={2} />
                        </BlockStack>
                      </div>
                    </InlineGrid>
                    <br />
                    <TextFieldRichText label="Nội dung"
                // maxLength={65000}
                initialData={entity?.product_description} onCallback={e => fields.product_description.onChange(e)} />
                  </Card>

                  <Card>
                    <Text as="h2" variant="headingSm">
                      Thư viện đa phương tiện
                    </Text>
                    <br />
                    {!current_product_id ? <EmptyState heading="Lưu bản nháp trước, sau đó sẽ thêm được các ảnh và video..." action={{
                  content: 'Lưu nháp ngay',
                  icon: StatusActiveIcon,
                  onAction: () => submit(),
                  loading: creating
                }} image={''}></EmptyState> : <ProductGallery product_id={current_product_id} />}
                  </Card>

                  <Card>
                    <Text as="h2" variant="headingSm">
                      Giá cả
                    </Text>
                    <br />

                    <InlineGrid gap={'400'} columns={{
                  xs: 1,
                  sm: 1,
                  md: 2,
                  lg: 2,
                  xl: 2
                }}>
                      <TextField autoComplete="off" label="Giá bán gợi ý" value={helpers.formatNumberWithCommas(useFields.product_price.value)} onChange={e => useFields.product_price.onChange(e)} error={useFields.product_price.error} />
                      <TextField autoComplete="off" label="Giá gốc" suffix={<Tooltip content={<>
                                Nhập giá trị cao hơn để hiển thị mức giá giảm. <br />
                                Ứng dụng và web sẽ hiển thị giá này có <del>gạch giữa</del>.
                              </>}>
                            <Button icon={QuestionCircleIcon} variant="plain"></Button>
                          </Tooltip>} value={helpers.formatNumberWithCommas(useFields.product_original_price.value)} onChange={e => useFields.product_original_price.onChange(e)} error={useFields.product_original_price.error} />
                    </InlineGrid>
                  </Card>

                  <Card>
                    <Text as="h2" variant="headingSm">
                      Kho vận
                    </Text>
                    <br />

                    <InlineGrid columns={{
                  xs: 1,
                  md: 2
                }} gap="400">
                      <TextField autoComplete="off" suffix={<Tooltip content={<>Đây không phải là số lượng trong kho. Đây là mã quản lý kho, hãy giữ nó đơn giản.</>}>
                            <Button icon={QuestionCircleIcon} variant="plain"></Button>
                          </Tooltip>} label="SKU (Đơn vị lưu kho)" {...fields.product_sku} />
                      <TextField autoComplete="off" maxLength={48} suffix={<Tooltip content="Từ 8 tới tối đa 48 chữ số cho mã vạch.">
                            <Button icon={QuestionCircleIcon} variant="plain"></Button>
                          </Tooltip>} label="Mã vạch (ISBN, UPC, GTIN, v.v.)" {...fields.product_barcode} />
                    </InlineGrid>

                    <br />
                    <ChoiceList title=" " choices={[{
                  label: 'Đây là phẩm dịch vụ',
                  value: 'service',
                  helpText: 'Các sản phẩm dịch vụ không có số lượng hàng.'
                }, {
                  label: 'Đây là sản phẩm vật lý',
                  value: 'normal',
                  helpText: 'Sản phẩm vật lý có số lượng, có kích thước đóng gói và phí vận chuyển.'
                }]} selected={[productTypeOption.value]} onChange={([value]) => productTypeOption.onChange(value)} />
                    <br />

                    {productTypeOption.value === 'normal' && <>
                        <Divider />
                        <br />
                        <br />
                        <Text as="h4" variant="headingSm">
                          Kích thước và khối lượng
                        </Text>
                        <InlineGrid gap={'300'} columns={{
                    xs: 1,
                    sm: 1,
                    md: 3,
                    lg: 3,
                    xl: 3
                  }}>
                          <TextField label="Dài" {...fields.product_size_length} placeholder="cm" type="integer" autoComplete="off" />
                          <TextField label="Rộng" {...fields.product_size_width} placeholder="cm" type="integer" autoComplete="off" />
                          <TextField label="Cao" {...fields.product_size_height} placeholder="cm" type="integer" autoComplete="off" />
                        </InlineGrid>
                        <br />
                        <Text as="p" tone="subdued">
                          Đơn vị tính của Dài x Rộng x Cao là Centimet.
                        </Text>
                        <br />
                        <InlineGrid gap={'300'} columns={{
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2
                  }}>
                          <TextField autoComplete="off" label="Trọng lượng vận chuyển" {...fields.product_size_weight} />
                          <Select disabled options={['oz', 'g', 'kg', 'lb']} value={'kg'} onChange={null} label={'Đơn vị tính'} />
                        </InlineGrid>
                        <Text as="p" tone="subdued">
                          Đơn vị tính là Kilogram cho tất cả các sản phẩm.
                        </Text>
                      </>}
                  </Card>

                  <Card>
                    <BlockStack gap="400">
                      <Checkbox label={'Sản phẩm này có biến thể?'} checked={fields.product_has_variants.value === 1} onChange={val => fields.product_has_variants.onChange(Number(val))} />
                      {fields.product_has_variants.value === 1 && <ProductVariants current_product_id={current_product_id} />}
                      {entity?.product_price_range && <Text as="p">Giá từ {entity?.product_price_range} đ</Text>}
                    </BlockStack>
                  </Card>
                </FormLayout>
              </Form>
              <br />
              <br />
            </Layout.Section>

            <Layout.Section variant="oneThird">
              <Card padding={'400'}>
                <BlockStack gap="100">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Tình trạng sản phẩm
                  </Text>

                  <ChoiceList title="" choices={[{
                label: 'Bản nháp',
                value: '0',
                helpText: 'Lưu nháp, chỉ admin mới có quyền xem và sửa. Và nó không xuất hiện trong danh sách bán.'
              }, {
                label: 'Đang bán',
                value: '1',
                helpText: 'Tất cả mọi người đều xem được và thêm được vào đơn hàng.'
              }]} selected={[productStatusOption.value]} onChange={([value]) => productStatusOption.onChange(value)} />
                </BlockStack>
              </Card>

              <br />
              <Card padding={'400'}>
                <BlockStack gap="100">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    * Chuyên mục
                  </Text>
                  <Suspense fallback={<Text as="p">Đang tải...</Text>}>
                    <QuickSearchProductCategory current_category_id={useFields.product_category.value} errorMessage={useFields.product_category.error} onClose={category_ids => {
                  useFields.product_category.onChange(category_ids);
                }} />
                  </Suspense>
                </BlockStack>
              </Card>

              <br />
              <Card padding={'400'}>
                <BlockStack gap="100">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Nhãn hiệu
                  </Text>
                  <Suspense fallback={<Text as="p">Đang tải...</Text>}>
                    <QuickSearchProductBrand current_brand_id={[useFields.product_brand.value]} onClose={brand_id => {
                  useFields.product_brand.onChange(brand_id);
                }} />
                  </Suspense>
                </BlockStack>
              </Card>

              <br />
              <Card padding={'400'}>
                <BlockStack gap="200">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Từ khóa
                  </Text>
                  <InlineStack gap="200">
                    {useFields.product_tags.value === '' && <Text as="p" variant="bodyXs" tone="subdued">
                        Chưa có một từ khóa nào
                      </Text>}
                    {useFields.product_tags.value?.split(',').map((el, index) => {
                  if (!el) return undefined;
                  return <Tag onRemove={() => removeProductTagCallback(el)} key={`A_` + index}>
                          {el}
                        </Tag>;
                })}
                  </InlineStack>
                  <Form onSubmit={() => {
                let old = useFields.product_tags.value;
                let _new = old + ',' + productTagsValue;
                let _newA = helpers.uniqueArray(_new.split(','));
                useFields.product_tags.onChange(_newA.join(','));
                setProductTagsValue('');
              }}>
                    <TextField label="" placeholder="Gõ từ khóa và bấm Enter" autoComplete="off" value={productTagsValue} onChange={val => setProductTagsValue(val)} />
                  </Form>
                </BlockStack>
              </Card>
              <br />

              <Card padding={'400'}>
                <BlockStack gap="100">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Hiển thị biểu tượng
                  </Text>

                  <Text as="p" variant="bodyXs" tone="subdued">
                    Các biểu tượng hiển thị trong danh sách sản phẩm và chi tiết sản phẩm, các biểu tượng này phụ thuộc vào giao diện.
                  </Text>
                  <br />

                  <ChoiceList allowMultiple title="" choices={[{
                label: 'Biểu tượng bán chạy',
                value: 'best_sellers'
              }, {
                label: 'Biểu tượng có khuyến mại',
                value: 'discount'
              }]} selected={useFields.product_badge.value?.split(',') ?? []} onChange={e => useFields.product_badge.onChange(e.join(','))} />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>

          <br />
          <br />
          <br />
          <br />
        </Page>}

      <FormPrompt hasUnsavedChanges={dirty} />

      <DialogConfirm tone="critical" show={showConfirmUnsavedForm} onClose={e => {
      e && history('/product');
      setShowConfirmUnsavedForm(false);
    }} title={'Rời khỏi trang mà chưa lưu thay đổi?'} content={'Rời khỏi trang này sẽ xóa tất cả các thay đổi chưa được lưu. Bấm hủy bỏ để tiếp tục chỉnh sửa.'} />
    </>;
}