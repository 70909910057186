import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import { useAppDispatch, useAppSelector } from 'config/store';
import { getStaffPerformanceLine } from "../../store/analytics.store.reducer";
import { useEffect, useState } from 'react';
// import helpers from "../../helpers";

export default function StaffLineChart({
  defaultDate
}) {
  const staffPerformanceLine = useAppSelector(state => state.analytics.staffPerformanceLine);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getStaffPerformanceLine({
      createdAt: defaultDate
    }));
  }, [defaultDate]);
  const [drawBottomLine, setDrawBottomLine] = useState<any>([]);
  const [drawMiddleLine, setDrawMiddleLine] = useState<any>([]);
  useEffect(() => {
    if (staffPerformanceLine === null) return;
    const {
      users,
      date
    } = staffPerformanceLine;
    let usrline: any[] = [];
    if (Array.isArray(users)) for (let n of users) {
      usrline.push({
        data: n.data,
        type: 'line',
        name: n.name || 'UnKnown',
        smooth: false
        //   itemStyle: {normal: {areaStyle: {type: 'default'}}},
        // lineStyle: {
        //     color: helpers.getRandomElementInArray(colors)
        // }
      });
    }
    setDrawBottomLine(date);
    setDrawMiddleLine(usrline);
  }, [staffPerformanceLine]);
  const lineChart: ReactEChartsProps["option"] = {
    title: {
      text: 'Số đơn hàng',
      left: 0
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: drawBottomLine
    },
    yAxis: {
      type: 'value'
    },
    series: drawMiddleLine
  };
  return <ReactECharts option={lineChart} settings={{
    notMerge: true
  }} style={{
    minHeight: 400
  }} />;
}