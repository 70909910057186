

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";




export interface TypedProduct_variant {
    variant_id?: string;
    product_id?: string;
    variant_thumbnail?: string;
    variant_name?: string;
    variant_slug?: string; // read only
    variant_excerpt?: string;
    variant_sku?: string;
    variant_barcode?: string;
    variant_price?: number;
    variant_original_price?: number;
    variant_status?: number;
    hash?: string; // read only ...
    createdAt?: string;

    variant_thumbnail_to_media?: TypedMedia | null
}


type IQuery = TypedProduct_variant & IQueryParams;



/*
* List of all product_variant
*/
export function useGetProductVariants(product_id: string) {
    return useQuery({
        queryKey: ["product_variant/fetch_entity_list"],
        queryFn: () => axios.get<TypedProduct_variant[]>(`/product_variant/${product_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/fetch_entity'],
        mutationFn: (product_variant_id: string) => axios.get<TypedProduct_variant>(`/product_variant/view/${product_variant_id}`).then((res) => res.data),
        retry: 1,
    });
}


export function useUpdateProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/update_entity'],
        mutationFn: ({ variant_id, ...rest }: TypedProduct_variant) => axios.patch<any>(`product_variant/${variant_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}


export function useCreateProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/create_entity'],
        mutationFn: (entity: TypedProduct_variant) => axios.post<any>(`/product_variant`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}


export function useDeleteProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`product_variant/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}



/** Tự động tạo hàng loạt variant cho nó nhanh ... */
export function useBulkCreateProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/bulk_create_entity'],
        mutationFn: (product_id: string) => axios.post<any>(`product_variant/bulk_create/${product_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}



