import { Card, Text, Page, Popover, BlockStack, LegacyCard, Layout, Form, TextField, FormLayout, Box, DatePicker, Icon, Loading, List, ContextualSaveBar } from '@shopify/polaris';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { CalendarIcon } from '@shopify/polaris-icons';
import { createEntity } from 'store/email.outgoing.store.reducer';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickSearchEmailTemplate from './webmail_search_email_template';
import QuickSearchEmailSetting from 'entities/email_setting/quick_search_email_setting';
import TextFieldRichText from 'components/TextFieldRichText';
import __ from 'languages/index';
export default function ComposerEmail() {
  const updating = useAppSelector(state => state.email_outgoing.updating);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const useFields = {
    to_email: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống')]
    }),
    cc: useField<string>({
      value: '',
      validates: []
    }),
    bcc: useField<string>({
      value: '',
      validates: []
    }),
    subject: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống'), lengthLessThan(150, 'Chủ đề quá dài!'), lengthMoreThan(2, 'Chủ đề quá ngắn!')]
    }),
    title: useField<string>({
      value: '',
      validates: []
    }),
    content: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống')]
    }),
    email_template: useField<string>({
      value: '',
      validates: []
    }),
    email_setting: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống')]
    }),
    email_sent_schedule: useField<string>({
      value: '',
      validates: []
    }),
    email_sent_status: useField<string>({
      value: '1',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await dispatch(createEntity({
          subject: values.subject,
          title: values.title,
          content: values.content,
          email_template: values.email_template,
          email_setting: values.email_setting,
          to_email: values.to_email,
          cc: values.cc,
          bcc: values.bcc,
          email_sent_schedule: new Date(values.email_sent_schedule).getTime(),
          email_sent_status: Number(values.email_sent_status)
        }));
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  const [selectedDates, setSelectedDates] = useState(new Date());
  const [{
    month,
    year
  }, setDate] = useState({
    month: selectedDates.getMonth(),
    year: selectedDates.getFullYear()
  });
  const handleMonthChange = useCallback((_month, _year) => setDate({
    month: _month,
    year: _year
  }), []);
  const [showDatePopup, setShowDatePopup] = useState(false);
  const dateSelectedFormatForTextField = useRef(new Date().toISOString().slice(0, 10));
  const handleDateSelection = ({
    end: newSelectedDate
  }) => {
    setSelectedDates(newSelectedDate);
    setShowDatePopup(false);
    /** Bug: show wrong date, because of local time ... */
    let nDate = new Date(newSelectedDate);
    nDate.setDate(nDate.getDate() + 1);
    dateSelectedFormatForTextField.current = nDate.toISOString().slice(0, 10);
    useFields.email_sent_schedule.onChange(dateSelectedFormatForTextField.current);
  };
  const setStatus = useCallback(async (_newStatus: number) => {
    useFields.email_sent_status.onChange(`${_newStatus}`);
    await helpers.sleep(1000);
  }, []);
  /** Save draft */
  const saveDraft = useCallback(async () => {
    await setStatus(0);
    submit();
  }, [submit]);
  const sendNow = useCallback(async () => {
    await setStatus(1);
    submit();
  }, [submit]);
  const contextualSaveBar = dirty && <ContextualSaveBar message="Chưa lưu bản nháp" saveAction={{
    content: 'Gửi ngay',
    loading: updating,
    onAction: () => sendNow()
  }} discardAction={{
    content: 'Lưu nháp',
    disabled: updating,
    onAction: () => saveDraft()
  }} />;
  return <>
      {updating && <Loading />}
      {contextualSaveBar}

      <Page title="Tạo Email mới" backAction={{
      content: 'Back',
      onAction: () => history(-1)
    }}>
        <Layout>
          <Layout.Section>
            <LegacyCard title="Nội dung email" sectioned>
              <Form onSubmit={submit}>
                <FormLayout>
                  <TextField label="Đến" autoComplete="off" placeholder="Hiển <jamvietdotcom@gmail.com>" {...fields.to_email} />

                  <TextField label="CC" autoComplete="off" {...fields.cc} />

                  <TextField label="BCC" autoComplete="off" {...fields.bcc} />

                  <TextField label="Chủ đề" requiredIndicator autoComplete="off" {...fields.subject} />

                  <TextField label="Tựa đề trong email (tùy chọn)" autoComplete="off" {...fields.title} />

                  <TextFieldRichText label={'Nội dung'} initialData={''} onCallback={content => fields.content.onChange(content)} />
                </FormLayout>
              </Form>
            </LegacyCard>
          </Layout.Section>

          <Layout.Section variant="oneThird">
            <LegacyCard sectioned>
              <BlockStack inlineAlign="start" align="start" gap="400">
                <Text as="h3" variant="headingSm">
                  Chọn mẫu để gửi
                </Text>
                <Box minWidth="276px">
                  <QuickSearchEmailTemplate onClose={email_template_id => useFields.email_template.onChange(email_template_id)} />
                </Box>

                <Text as="h3" variant="headingSm">
                  Chọn tài khoản gửi
                </Text>
                <Box minWidth="276px">
                  <QuickSearchEmailSetting onClose={email_setting_id => useFields.email_setting.onChange(email_setting_id)} error={useFields.email_setting.error} />
                </Box>

                <Text as="h3" variant="headingSm">
                  Lên lịch
                </Text>
                <Box minWidth="276px">
                  <Popover active={showDatePopup} autofocusTarget="none" preferredAlignment="left" fullWidth preferInputActivator={false} preferredPosition="below" preventCloseOnChildOverlayClick onClose={() => setShowDatePopup(false)} activator={<TextField role="combobox" label={'Start date'} labelHidden prefix={<Icon source={CalendarIcon} />} value={dateSelectedFormatForTextField.current} onFocus={() => setShowDatePopup(true)} onChange={() => {}} autoComplete="off" />}>
                    <Card>
                      <DatePicker month={month} year={year} onChange={handleDateSelection} onMonthChange={handleMonthChange} selected={selectedDates} />
                    </Card>
                  </Popover>
                </Box>
              </BlockStack>
            </LegacyCard>

            <br />
            <Text as="p">Lưu ý về các placeholder được chấp nhận trong tựa đề và nội dung email:</Text>
            <br />
            <Text as="p" tone="subdued" variant="bodyMd">
              Trong template, có rất nhiều các placeholder được phép sử dụng như:
            </Text>
            <br />
            <List type="bullet">
              <List.Item>
                <code>{`{{site_name}}`}</code>: Tên công ty
              </List.Item>
              <List.Item>
                <code>{`{{site_slogan}}`}</code>: Slogan
              </List.Item>
              <List.Item>
                <code>{`{{site_main_address_1}}`}</code>: Địa chỉ công ty dòng 1
              </List.Item>
              <List.Item>
                <code>{`{{site_main_address_2}}`}</code>: Địa chỉ công ty dòng 2
              </List.Item>
              <List.Item>
                <code>{`{{site_main_address_district}}`}</code>: Địa chỉ công ty: Quận huyện
              </List.Item>
              <List.Item>
                <code>{`{{site_main_address_city}}`}</code>: Địa chỉ công ty: Tỉnh / thành phố
              </List.Item>
              <List.Item>
                <code>{`{{subject}}`}</code> là chủ đề email
              </List.Item>
              <List.Item>
                <code>{`{{title}}`}</code> là tựa đề trong email
              </List.Item>
              <List.Item>
                <code>{`{{content}}`}</code> là nội dung email
              </List.Item>
              <List.Item>
                <code>{`{{name}}`}</code> là tên người nhận
              </List.Item>
              <List.Item>
                <code>{`{{address}}`}</code> là email của người nhận
              </List.Item>
              <List.Item>
                <code>{`{{email_id}}`}</code> là id của Email được lưu trữ trên hệ thống, giúp tracking dễ dàng
              </List.Item>
            </List>
          </Layout.Section>
        </Layout>
      </Page>
      <br />
      <br />
    </>;
}