import { EmptyState, Form, FormLayout, IndexTable, Layout, Modal, Page, TextField, useIndexResourceState, Card } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'config/store';
import emptyIMG from 'media/images/empty.png';
import Pagination from 'components/pagination';
import DeleteConfirm from 'components/deleteConfirm';
import { clearError, deleteEntity, getEntities, updateEntity } from 'store/comment.store.reducer';
import helpers from '../../helpers';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import SkeletonLoading from 'components/skeletonPageLoading';
import SimpleFilter from 'components/simpleFilter';
export default function CommentList() {
  const entities = useAppSelector(state => state.comment.entities);
  const loading = useAppSelector(state => state.comment.loading);
  const totalItems = useAppSelector(state => state.comment.totalItems);
  const updating = useAppSelector(state => state.comment.updating);
  const updateSuccess = useAppSelector(state => state.comment.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search: searchParam
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(searchParam);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 25,
    comment_type: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (searchParam !== buildURLSearch) history('/comment' + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);
  const [showEditModel, setShowEditModel] = useState(false);
  const closeModal = useCallback(() => {
    setShowEditModel(active => !active);
  }, [showEditModel]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  /**
   * Delete comment
   */

  const [openModal, setOpenModal] = useState(false);
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(entities, {
    resourceIDResolver
  });
  function resourceIDResolver(comment_data: any) {
    return comment_data.comment_id ?? null;
  }
  function _deleteEntityAction() {
    setOpenModal(true);
  }

  /**
   * Delete modal ...
   */
  const onModalAgree = useCallback(() => {
    dispatch(deleteEntity(selectedResources.join(',')));
    setOpenModal(false);
    // @ts-ignore
    handleSelectionChange('all', false); // <~~ This will trigger the recalculation
  }, [selectedResources]);
  useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [updateSuccess]);
  const publishCommentStatus = useCallback(() => {
    dispatch(updateEntity({
      comment_id: selectedResources.join(','),
      comment_status: 'publish'
    }));
  }, [selectedResources]);
  const deleteCommentStatus = useCallback(() => {
    dispatch(updateEntity({
      comment_id: selectedResources.join(','),
      comment_status: 'delete'
    }));
  }, [selectedResources]);
  const spamCommentStatus = useCallback(() => {
    dispatch(updateEntity({
      comment_id: selectedResources.join(','),
      comment_status: 'spam'
    }));
  }, [selectedResources]);

  /**
   * must use callback function ...
   */
  const [commentDetail, setCommentDetail] = useState<any>(null);
  const getCommentDetail = useCallback((comment_data: any) => {
    setCommentDetail(comment_data);
    setShowEditModel(true);
  }, []);
  const {
    fields,
    dirty,
    submit,
    submitting
  } = useForm({
    fields: {
      comment_title: useField<string>({
        value: commentDetail?.comment_title ?? '',
        validates: [notEmpty('Title is required!'), lengthMoreThan(5, 'Title must be more than 5 characters'), lengthLessThan(200, 'No more than 200 characters.')]
      }),
      comment_content: useField<string>({
        value: commentDetail?.comment_content ?? '',
        validates: [notEmpty('Content is required!'), lengthMoreThan(5, 'Content must be more than 5 characters'), lengthLessThan(200, 'No more than 200 characters.')]
      })
    },
    async onSubmit(values) {
      try {
        dispatch(updateEntity({
          comment_id: commentDetail.comment_id,
          comment_title: values.comment_title,
          comment_content: values.comment_content
        }));
        setShowEditModel(false);
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? 'Lỗi không xác định, vui lòng thử lại sau.';
        const field = e?.response?.data?.errorKey ?? 'base';
        return {
          status: 'fail',
          errors: [{
            field,
            message
          }]
        };
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * read out commentDetail
   */
  const detailModal = <Modal open={showEditModel} onClose={closeModal} title="Edit comment" primaryAction={{
    content: 'Save',
    disabled: !dirty,
    loading: submitting,
    onAction: submit
  }} secondaryActions={[{
    content: 'Close',
    onAction: closeModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <TextField autoFocus autoComplete="off" requiredIndicator label="Title" {...fields.comment_title} />
            <TextField autoComplete="off" requiredIndicator multiline={4} label="Content" {...fields.comment_content} />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;

  /**
   *  Index table
   */
  function IndexTableComment() {
    const resourceName = {
      singular: 'comment',
      plural: 'comments'
    };
    const promotedBulkActions = [{
      content: 'Delete comment',
      onAction: _deleteEntityAction
    }];
    const bulkActions = [{
      content: 'Publish',
      onAction: () => {
        publishCommentStatus();
      }
    }, {
      content: 'Spam',
      onAction: () => {
        spamCommentStatus();
      }
    }, {
      content: 'Delete',
      onAction: () => {
        deleteCommentStatus();
      }
    }];
    const rowMarkup = entities?.map((props, index) => {
      const {
        comment_id,
        user,
        comment_title,
        comment_content,
        object_id,
        comment_type,
        comment_parent,
        comment_status,
        comment_point
      } = props;
      return <IndexTable.Row id={comment_id} key={comment_id} selected={selectedResources.includes(comment_id)} position={index}>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {user.display_name}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_title}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_content}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {object_id}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_type}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_parent}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_status}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_point}
            </div>
          </IndexTable.Cell>
        </IndexTable.Row>;
    });
    return <IndexTable loading={loading} resourceName={resourceName} itemCount={entities.length} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} bulkActions={bulkActions} promotedBulkActions={promotedBulkActions} headings={[{
      title: 'User'
    }, {
      title: 'Title'
    }, {
      title: 'Content'
    }, {
      title: 'Object id'
    }, {
      title: 'Type'
    }, {
      title: 'Comment parent'
    }, {
      title: 'Status'
    }, {
      title: 'Point'
    }]}>
        {rowMarkup}
      </IndexTable>;
  }
  const emptyData = <EmptyState heading="No comment to show here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const PagesList = totalItems > 0 ? <>
        <IndexTableComment />
      </> : emptyData;
  const Actual_page = <Page fullWidth title="Comment &amp; Review">
      {detailModal}
      <Layout>
        <Layout.Section>
          <Card padding="0">
            <SimpleFilter loading={loading} options={[]} onCallback={filterCallback} sortField={[]} />
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Layout.Section>
      </Layout>
    </Page>;

  /**
   * Delete modal
   * selectedResources <------ Toan bo ID checked o day = array
   */
  const commentArray = [selectedResources];
  commentArray.join(',');
  return <>
      <DeleteConfirm title="Xoá comment?" show={openModal} onClose={(mode: boolean) => {
      mode === true ? onModalAgree() : setOpenModal(false);
    }} />
      {entities === null ? <SkeletonLoading /> : Actual_page}
    </>;
}