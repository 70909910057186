import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'config/store';
import { Avatar, InlineStack, Link, Modal, ResourceItem, ResourceList, SkeletonTabs, Text, Tooltip } from '@shopify/polaris';
import { clearError, getCustomerBirthday } from 'store/customer.store.reducer';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
const ShowModalCustomerBirthday = forwardRef<any>(function ShowModalCustomerBirthdayList(props, ref: any) {
  let {
    list
  } = ref.current;
  const [isOpen, setIsOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen(_ => !_)
  }), []);
  return <Modal activator={null} open={isOpen} onClose={() => ref.current.close()} title="Danh sách khách hàng sinh nhật gần đây" secondaryActions={[{
    content: 'Đóng',
    onAction: () => ref.current.close()
  }]}>
      <ResourceList resourceName={{
      singular: 'customer',
      plural: 'customers'
    }} items={list} renderItem={customerData => {
      const {
        user_id,
        display_name,
        user_avatar,
        user_birthday
      } = customerData;
      return <ResourceItem id={user_id} url={null} media={<Avatar size="md" name={display_name} source={user_avatar} initials={display_name.charAt(0)} />} shortcutActions={[{
        content: 'Xem thông tin khách',
        url: '/customer/view/' + user_id
      }]} name={display_name}>
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                {display_name}
              </Text>
              <div>
                <Text as="p">
                  {helpers.getAge(new Date(user_birthday), ' tuổi')}
                  {' - '} Ngày sinh {dateandtime.format(new Date(user_birthday), 'DD/MM/YYYY')}
                </Text>
              </div>
            </ResourceItem>;
    }} />
    </Modal>;
});
export default function CustomerBirthDayList({
  customer_campaign
}: {
  customer_campaign: any;
}) {
  const loading = useAppSelector(state => state.customer.customerBirthdayListLoading);
  const entities = useAppSelector(state => state.customer.customerBirthdayList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearError());
    dispatch(getCustomerBirthday({
      limit: 100,
      customer_campaign: customer_campaign ?? undefined
    }));
  }, []);
  const modalBirthdayRef = useRef<{
    toggle: Function;
    list: any;
  }>({
    toggle: () => {},
    list: []
  });
  useEffect(() => {
    if (entities) modalBirthdayRef.current.list = entities;
  }, [entities]);
  return <>
      <ShowModalCustomerBirthday ref={modalBirthdayRef} />
      <br />
      <Text as="h3" variant="headingMd">
        Sinh nhật của khách hàng
      </Text>
      <br />
      {loading ? <SkeletonTabs /> : Array.isArray(entities) && entities.length > 0 ? <InlineStack align="start" blockAlign="center" gap={'200'} key={'ddd'}>
          {entities.map((ctm, index) => {
        if (index > 5) return null;
        if (index === 5) {
          return <Tooltip width="wide" content={<>
                      <Text as="p">Còn {entities.length - 4} người khác, click để xem thêm.</Text>
                    </>}>
                  <Link onClick={() => modalBirthdayRef.current.toggle()}>
                    <Avatar name="Other" initials="+" />
                  </Link>
                </Tooltip>;
        }
        return <Tooltip width="wide" key={index + '_hsh'} content={<>
                    <Text as="p" fontWeight="bold">
                      {ctm.display_name}
                    </Text>
                    <Text as="p">{helpers.getAge(new Date(ctm?.user_birthday), ' tuổi')}</Text>
                    <Text as="p">Ngày sinh {dateandtime.format(new Date(ctm?.user_birthday), 'DD/MM/YYYY')}</Text>
                  </>}>
                <Link url={'/customer/view/' + ctm.user_id}>
                  <Avatar source={ctm.user_avatar} name={ctm.display_name} initials={ctm.display_name.charAt(0)} />
                </Link>
              </Tooltip>;
      })}
        </InlineStack> : <Text as="p">Chưa có ai có sinh nhật vào dịp này.</Text>}
    </>;
}