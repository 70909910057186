import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';


export type TypeEmailSetting = {
  id?: string,
  setting_type?: string,
  server_name?: string,
  server_address?: string,
  server_port?: string,
  server_username?: string,
  server_password?: string,
  server_secure_protocol?: string,
  server_interval?: string,
  server_checked?: string,
  server_status?: number,
  createdAt?: string,
}

type TQuery = TypeEmailSetting & IQueryParams

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as TypeEmailSetting[],
  entity: null as TypeEmailSetting,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleting: false,
  deleteSuccess: false,
};

const apiUrl = 'email_setting';

// Actions

export const getEntities = createAsyncThunk('email_setting/fetch_entity_list', async (object: TQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);
export const getEntity = createAsyncThunk('email_setting/fetch_entity', async (id: any) => {
  const requestUrl = `${apiUrl}/${id}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);


export const updateEntity = createAsyncThunk(
  'email_setting/update_entity',
  async (entity: TypeEmailSetting) => {
    let { id, ...rest } = entity;
    return await axios.patch<any>(`${apiUrl}/${id}`, helpers.cleanEntity(rest));
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'email_setting/create_entity',
  async (entity: TypeEmailSetting) => {
    return await axios.post<any>(`${apiUrl}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'email_setting/delete_entity',
  async (id: string) => {
    return await axios.delete<any>(`${apiUrl}/${id}`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_System_EmailSetting = createEntitySlice({
  name: 'email_setting',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.totalItems = 0;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
        state.entity = null;
      })

      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.totalItems = 0;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })

      .addCase(createEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(createEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })

      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })

      .addCase(deleteEntity.rejected, (state, action) => {
        state.deleting = false;
        state.deleteSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.deleting = false;
        state.deleteSuccess = true;
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.deleting = true;
        state.deleteSuccess = false;
      })
  },
});

export const { clearError, reset } = Reducer_System_EmailSetting.actions;

// Reducer
export default Reducer_System_EmailSetting.reducer;
