import { Button, LegacyCard, DataTable, EmptyState, Form, Text, Layout, List, Loading, Modal, Page, PageActions, Toast, DescriptionList, Badge, Link, ExceptionList, Card } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { clearError, getEntity, getIncomingLog, updateEntity, resetToken, deleteEntity } from '../../store/incoming_webhook.store.reducer';
import SkeletonLoading from 'components/skeletonPageLoading';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import DeleteConfirm from 'components/deleteConfirm';
import helpers from 'helpers';
import { AlertTriangleIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
export default function ViewIncomingWebhook() {
  const entity = useAppSelector(state => state.incoming_webhook.entity);
  const entities_log = useAppSelector(state => state.incoming_webhook.entities_log);
  const updating = useAppSelector(state => state.incoming_webhook.updating);
  const loading = useAppSelector(state => state.incoming_webhook.loading);
  const loadingLog = useAppSelector(state => state.incoming_webhook.loadingLog);
  const totalLogItems = useAppSelector(state => state.incoming_webhook.totalLogItems);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  let {
    incoming_webhook_slug,
    search
  } = useParams();
  useEffect(() => {
    if (incoming_webhook_slug) dispatch(getEntity(incoming_webhook_slug));
  }, [incoming_webhook_slug]);
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const [mainQuery, setMainQuery] = useState({
    ...{
      query: '',
      page: 1,
      limit: 20,
      incoming_webhook_id: incoming_webhook_slug,
      sort: 'createdAt:desc'
    },
    ...StringQuery
  });
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  function _deleteEntityAction() {
    setOpenModal(true);
  }
  function onModalAgree() {
    dispatch(deleteEntity(incoming_webhook_slug));
    setOpenModal(false);
  }

  //Set generateToken = api_access_token
  useEffect(() => {
    if (entity) {
      dispatch(getIncomingLog(mainQuery));
    }
  }, [entity, mainQuery]);
  function convertToRow(entity: any) {
    return [entity.log_ip, entity.log_method, dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY HH:mm:ss')];
  }
  const resetTokenCallback = useCallback(() => {
    dispatch(resetToken(entity?.api_id));
  }, [entity]);
  const Actual_page = (entity || !incoming_webhook_slug) && <>
      <Page title="Incoming Webhook" titleMetadata={entity?.default_webhook === 1 && <Badge tone="attention">Default</Badge>} subtitle="Danh sách log của một Incoming webhook" backAction={{
      content: 'Incoming_webhook list',
      url: '/incoming_webhook'
    }}>
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <DataTable columnContentTypes={['text', 'text', 'text']} headings={['IP', 'Method', 'Date']} rows={entities_log?.map(convertToRow)} />
            </Card>
            {totalLogItems > mainQuery.limit ? <Pagination TotalRecord={totalLogItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
            <br />
            <br />
            <br />
          </Layout.Section>

          <Layout.Section variant="oneThird">
            <LegacyCard>
              <LegacyCard.Section title="Incomming Webhook details">
                <Text fontWeight="bold" as="p">
                  {entity.api_name}
                </Text>
                <p>{Parser(entity.api_description || 'No description!')}</p>

                <DescriptionList items={[{
                term: 'Method',
                description: String(entity.api_access_method).toUpperCase()
              }, {
                term: 'Role',
                description: entity.api_role
              }, {
                term: 'Request time',
                description: entity.api_count_request
              }, {
                term: 'Create At',
                description: dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY HH:mm:ss')
              }, {
                term: 'Update At',
                description: entity.updatedAt ? dateandtime.format(new Date(Number(entity.updatedAt)), 'DD-MM-YYYY HH:mm:ss') : <>
                          {' '}
                          <Badge></Badge> <Badge></Badge>{' '}
                        </>
              }]} />

                <br />
                <Text as="p" fontWeight="bold">
                  Access link
                </Text>
                <Text as="p" fontWeight="regular">
                  <code>{`${process.env.REACT_APP_BACKEND_URL || '{your_backend_address}'}/webhook_incoming/${entity.api_slug}/${entity.api_access_token}/`}</code>
                </Text>
                <br />
                <Text as="p" fontWeight="bold">
                  Hook Express
                </Text>
                <Text as="p" fontWeight="regular">
                  {`To handle this incoming request, [add_action] to [pre_incoming_webhook_${entity.api_slug}]`}
                </Text>
              </LegacyCard.Section>

              <LegacyCard.Section>
                {entity?.default_webhook === 1 ? <>
                    <Text as="p" tone="critical">
                      Bạn không thể xóa một webhook mặc định.
                    </Text>
                  </> : <>
                    <Button variant="primary" tone="critical" onClick={() => _deleteEntityAction()}>
                      DELETE
                    </Button>
                  </>}

                <br />
                <br />
                <ExceptionList items={[{
                icon: AlertTriangleIcon,
                description: <Text as="p">
                          Trong trường hợp bạn cần đổi key truy cập do cần bảo mật,{' '}
                          <Button variant="plain" loading={updating} onClick={resetTokenCallback}>
                            click vào đây
                          </Button>{' '}
                          để đổi. Mọi truy vấn tới webhook cũ sẽ không thể truy cập.
                        </Text>
              }]} />
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </Page>

      <br />
      <br />
    </>;
  return <>
      <DeleteConfirm title="Bạn muốn xóa webhook này?" show={openModal} onClose={mode => {
      mode === true ? onModalAgree() : setOpenModal(false);
    }} />

      {updating || loading || loadingLog ? <Loading /> : null}
      {loading ? <SkeletonLoading /> : Actual_page}
    </>;
}