import { Form, InlineGrid, Modal, TextField, Text, Banner, Checkbox, BlockStack, Divider } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useGetProductVariant, useUpdateProductVariant } from 'queries/product_variant.query';
import React, { useState, useEffect, useCallback } from 'react';
export default function ProductVariantModalEdit({
  show = false,
  onClose = null,
  variant_id = null
}: {
  show: boolean;
  onClose: () => void;
  variant_id: string;
}) {
  const [internalError, setInternalError] = useState('');
  const [open, setOpen] = useState(false);
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess,
    isPending
  } = useUpdateProductVariant();
  const {
    data: entity,
    mutateAsync: getEntity
  } = useGetProductVariant();
  useEffect(() => {
    if (show) {
      if (variant_id) getEntity(variant_id);
      setOpen(true);
    }
  }, [show, variant_id]);
  const onCloseCallback = useCallback(() => {
    onClose();
    setOpen(false);
  }, []);
  useEffect(() => {
    onCloseCallback();
  }, [updateSuccess]);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    variant_thumbnail: useField<string>({
      value: entity?.variant_thumbnail_to_media ? entity.variant_thumbnail_to_media?.media_id : undefined,
      validates: []
    }),
    variant_thumbnail_cache: useField<string>({
      value: entity?.variant_thumbnail_to_media ? entity.variant_thumbnail_to_media?.media_thumbnail['256x169'] : '',
      validates: []
    }),
    variant_name: useField<string>({
      value: entity?.variant_name || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 50 ký tự.')]
    }),
    variant_price: useField<string>({
      value: String(entity?.variant_price ?? 0),
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 1) {
          return 'variant_price must be a number!';
        }
      }]
    }),
    variant_original_price: useField<string>({
      value: String(entity?.variant_original_price ?? 0),
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 1) {
          return 'variant_original_price must be a number!';
        }
      }]
    }),
    variant_excerpt: useField<string>({
      value: entity?.variant_excerpt || '',
      validates: [lengthLessThan(250, 'Không được dài hơn 250 ký tự.')]
    }),
    variant_sku: useField<string>({
      value: entity?.variant_sku || '',
      validates: [lengthLessThan(50, 'Không được dài hơn 50 ký tự.')]
    }),
    variant_barcode: useField<string>({
      value: entity?.variant_barcode || '',
      validates: [lengthLessThan(48, 'Không được dài hơn 48 ký tự.')]
    }),
    variant_status: useField<number>({
      value: entity?.variant_status ?? 0,
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          variant_id: entity?.variant_id,
          variant_name: values.variant_name,
          variant_excerpt: values.variant_excerpt,
          variant_thumbnail: values.variant_thumbnail,
          variant_price: helpers.parseNumeric(values.variant_price),
          variant_original_price: helpers.parseNumeric(values.variant_original_price),
          variant_barcode: values.variant_barcode,
          variant_sku: values.variant_sku,
          variant_status: values.variant_status
        });
        reset();
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={'Cập nhật biến thể sản phẩm'} primaryAction={{
    content: __('update_label_button'),
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        {internalError && <>
            <Banner tone="critical" onDismiss={() => setInternalError(null)}>
              {internalError}
            </Banner>
            <br />
          </>}

        <Form onSubmit={submit}>
          <BlockStack gap="200">
            <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
              <div className="d1">
                <QuickUploadImage height={160} onSuccess={e => {
                useFields.variant_thumbnail.onChange(e.media_id);
                useFields.variant_thumbnail_cache.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={helpers.getMediaLink(useFields.variant_thumbnail_cache.value)} />
                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </div>
              <BlockStack gap="400">
                <TextField label="Tên biến thể" autoComplete="off" {...fields.variant_name} />
                <TextField label="Giá bán" autoComplete="off" value={helpers.formatNumberWithCommas(useFields.variant_price.value)} onChange={e => useFields.variant_price.onChange(e)} error={useFields.variant_price.error} />
                <TextField label="Giá gốc" autoComplete="off" value={helpers.formatNumberWithCommas(useFields.variant_original_price.value)} onChange={e => useFields.variant_original_price.onChange(e)} error={useFields.variant_original_price.error} />
              </BlockStack>
            </InlineGrid>

            <BlockStack gap="400">
              <TextField label="SKU (Đơn vị lưu kho)" autoComplete="off" {...fields.variant_sku} />
              <TextField label="Mã vạch (ISBN, UPC, GTIN, v.v.)" autoComplete="off" {...fields.variant_barcode} />
              <TextField label="Mô tả ngắn" autoComplete="off" multiline={2} maxLength={250} showCharacterCount {...fields.variant_excerpt} />
            </BlockStack>

            <br />
            <Divider />
            <br />
            <Checkbox label={'Cho phép bán biến thể này'} checked={fields.variant_status.value === 1} onChange={val => fields.variant_status.onChange(Number(val))} helpText={'Các đơn hàng đã lên sẽ không bị ảnh hưởng bởi việc tắt này.'} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}