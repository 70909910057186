import { Form, InlineGrid, InlineStack, Modal, TextField, Text, Select, Banner, Checkbox, BlockStack } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import TextFieldRichText from 'components/TextFieldRichText';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import { useNotification } from 'NotificationContext';
import { TypedProductBrand, useCreateProductBrand, useUpdateProductBrand } from 'queries/brand.query';
import React, { useState, useEffect, useCallback } from 'react';
export default function BrandModal({
  show = false,
  onClose = null,
  entity = null
}: {
  show: boolean;
  entity?: TypedProductBrand;
  onClose?: (current_brand_id: bigint | null) => void;
}) {
  const [internalError, setInternalError] = useState('');
  const [open, setOpen] = useState(false);
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: updateEntity,
    isSuccess: updatedSuccess
  } = useUpdateProductBrand();
  const {
    mutateAsync: createEntity,
    isSuccess: createdSuccess
  } = useCreateProductBrand();

  // const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) setOpen(true);
  }, [show]);
  useEffect(() => {
    if (updatedSuccess) {
      onCloseCallback();
      addNotification('info', 'Đã cập nhật nhãn hàng');
    }
  }, [updatedSuccess]);
  useEffect(() => {
    if (createdSuccess) {
      onCloseCallback();
      addNotification('info', 'Đã tạo nhãn hàng thành công');
    }
  }, [createdSuccess]);
  const onCloseCallback = useCallback((brand_id?: bigint) => {
    onClose(brand_id);
    setOpen(false);
  }, []);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    brand_thumbnail: useField<string>({
      value: entity?.brand_thumbnail || '',
      validates: []
    }),
    brand_thumbnail_cache: useField<string>({
      value: entity?.product_brand_to_media ? entity.product_brand_to_media.media_thumbnail ? entity.product_brand_to_media.media_thumbnail['256x169'] : null : undefined,
      validates: []
    }),
    brand_name: useField<string>({
      value: entity?.brand_name || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.')]
    }),
    brand_excerpt: useField<string>({
      value: entity?.brand_excerpt || '',
      validates: [lengthLessThan(255, 'Không được dài hơn 255 ký tự.')]
    }),
    brand_description: useField<string>({
      value: entity?.brand_description || '',
      validates: [lengthLessThan(65000, 'Không được dài hơn 65000 ký tự.')]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        let addProductCategory: Promise<any>;
        if (entity) {
          addProductCategory = await updateEntity({
            brand_id: entity?.brand_id,
            brand_name: values.brand_name,
            brand_excerpt: values.brand_excerpt,
            brand_description: values.brand_description,
            brand_thumbnail: values.brand_thumbnail
          });
        } else {
          addProductCategory = await createEntity({
            brand_name: values.brand_name,
            brand_excerpt: values.brand_excerpt,
            brand_description: values.brand_description,
            brand_thumbnail: values.brand_thumbnail
          });
        }
        let {
          data
        } = await addProductCategory;
        reset();
        onCloseCallback(data.brand_id);
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={entity ? 'Cập nhật nhãn hiệu sản phẩm' : 'Thêm nhãn hiệu sản phẩm'} primaryAction={{
    content: entity ? __('update_label_button') : 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        {internalError && <>
            <Banner tone="critical" onDismiss={() => setInternalError(null)}>
              {internalError}
            </Banner>
            <br />
          </>}

        <Form onSubmit={submit}>
          <BlockStack gap="200">
            <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
              <div className="d1">
                <QuickUploadImage height={150} onSuccess={e => {
                useFields.brand_thumbnail.onChange(e.media_id);
                useFields.brand_thumbnail_cache.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={__helpers.getMediaLink(useFields.brand_thumbnail_cache.value)} />
                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </div>
              <BlockStack gap="500">
                <TextField label="Tên nhãn hiệu" autoComplete="off" requiredIndicator {...fields.brand_name} />
                <TextField label="Mô tả ngắn" autoComplete="off" multiline={3} maxLength={255} showCharacterCount requiredIndicator {...fields.brand_excerpt} />
              </BlockStack>
            </InlineGrid>

            <TextFieldRichText label={'Nội dung'} initialData={entity?.brand_description} onCallback={content => useFields.brand_description.onChange(content)} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}