import { ActionList, Card, DataTable, Link, Page, Popover } from '@shopify/polaris';
import Pagination from 'components/pagination';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from 'helpers/index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getEntities, deleteEntity } from 'store/email_template.store.reducer';
import dateandtime from 'date-and-time';
import EmailTemplatePopup from './email_template_popup';
import { PlusIcon } from '@shopify/polaris-icons';
export default function EmailTemplate() {
  const entities = useAppSelector(state => state.email_template.entities);
  const deleteSuccess = useAppSelector(state => state.email_template.deleteSuccess);
  const totalItems = useAppSelector(state => state.email_template.totalItems);
  const dispatch = useAppDispatch();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    setting_type: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePageNumber = useCallback(numPage => {
    setMainQuery({
      ...mainQuery,
      ...{
        page: numPage
      }
    });
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Email template', '/email_template' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  useEffect(() => {
    if (deleteSuccess) {
      reduceRequestMemo(mainQuery);
    }
  }, [deleteSuccess, mainQuery]);
  const [popActive, setPopActive] = useState(null);
  const deleteEmailTemplate = useCallback(id => {
    dispatch(deleteEntity(id));
    setPopActive(null);
  }, []);
  const renderItem = (itemData: any) => {
    if (!itemData) return;
    const {
      id,
      template_name,
      template_url,
      createdAt,
      createdBy
    } = itemData;
    return [id, <Popover active={popActive === id} activator={<Link onClick={() => setPopActive(id)}>{template_name}</Link>} autofocusTarget="first-node" onClose={() => setPopActive(null)}>
        <ActionList actionRole="menuitem" items={[{
        content: 'Xem thử',
        onAction: () => window.location.href = process.env.REACT_APP_BACKEND_URL + '/email_template/preview/' + id
      }, {
        content: 'Xóa',
        onAction: () => deleteEmailTemplate(id)
      }]} />
      </Popover>, template_url, dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY')];
  };
  const [showIncomingEmailPopup, setShowIncomingEmailPopup] = useState(false);
  return <>
      {<EmailTemplatePopup show={showIncomingEmailPopup} onClose={() => {
      setShowIncomingEmailPopup(false);
      reduceRequestMemo(mainQuery);
    }} />}
      <Page narrowWidth title="Email template" secondaryActions={[{
      content: 'Thêm template',
      icon: PlusIcon,
      onAction: () => setShowIncomingEmailPopup(true)
    }]}>
        <Card padding={'0'}>
          <DataTable columnContentTypes={['text', 'text', 'text', 'text']} headings={['ID', 'Name', 'File', 'Date']} rows={entities?.map(renderItem) || []} hideScrollIndicator hasZebraStripingOnData increasedTableDensity />
        </Card>
      </Page>

      <br />
      {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePageNumber} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
    </>;
}