import { useEffect, useState } from "react";
import helpers from "helpers/index";
import { Link, Text } from "@shopify/polaris";
type dataElement = {
  name: string;
  value: number;
  percent?: number;
};
type TypedProgressbar = {
  title: string;
  unit: string;
  data: dataElement[];
  size?: 'normal' | 'medium' | 'big';
};
export default function Progressbar(props: TypedProgressbar) {
  const {
    title,
    data,
    size,
    unit
  } = props;
  let sizeCSS = size ?? 'normal';
  const [showData, setShowData] = useState(null);
  if (helpers.isEmpty(data)) return;
  let newData = Array.from(data);

  /**
   * Sort it ...
   */
  newData.sort((a, b) => {
    return b.value - a.value;
  });

  /** total */
  let totalValue = 0;
  for (let d of newData) {
    totalValue += d.value;
  }
  ;
  /** percent */
  for (let d of newData) {
    d.percent = d.value / totalValue * 100;
  }
  /**
   * Others ... 
   */
  if (newData.length > 5) {
    let shadowCopy = Array.from(newData);
    let reserveData = shadowCopy.slice(5, newData.length);
    newData.splice(5, newData.length);
    let otherPercent = 0;
    let otherValue = 0;
    for (let n of reserveData) {
      otherPercent += n.percent;
      otherValue += n.value;
    }
    newData.push({
      name: 'Others',
      value: otherValue,
      percent: otherPercent
    });
  }
  const colors = ['#3178c6', '#f1e05a', '#89e051', '#701516', '#384d54', '#438eff', '#4F5D95', '#878787', '#563d7c'];
  return <>
            <div className="BorderGrid-row">
              <div className="BorderGrid-cell">

                <Text as="h4" variant="headingMd">{title}</Text>
                <br />

                    <div className="mb-2">
                      <span data-view-component="true" className={`Progress progress_size_${sizeCSS}`}>
                        {newData?.map((el, index) => {
              return <span key={index + '__bar_progress'} style={{
                backgroundColor: colors[index],
                width: `${el.percent}%`
              }} itemProp="keywords" aria-label={el.name} title={`${el.value}`} data-view-component="true" className={`Progress-item color-bg-success-emphasis ${showData === index ? 'z' : ''}`}>
                              <span className="m">
                                {el.name} {Math.ceil(el.percent)}% ({el.value}{' '}{unit}) 
                              </span>
                            </span>;
            })}
                      </span>
                    </div>

                    <ul className="list-style-none">
                      {newData?.map((el, index) => {
            return <li className="d-inline" key={index + '_ex_progress_A'}>
                              <Link url="" removeUnderline onClick={() => setShowData(index)}>
                                  <svg style={{
                  color: colors[index]
                }} aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" className="octicon octicon-dot-fill mr-2">
                                      <path d="M8 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z"></path>
                                  </svg>
                                <span className="color-fg-default" style={{
                  marginRight: 8,
                  fontWeight: '600'
                }}>{el.name}</span>
                                <Text as="span" tone="subdued">{Math.floor(el.percent)}%</Text>
                              </Link>
                            </li>;
          })}
                    </ul>

              </div>
            </div>
        </>;
}