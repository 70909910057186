import { DataTable, EmptyState, Layout, Page, Text, Badge, Card } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import emptyIMG from '../../media/images/empty.png';
import SkeletonLoading from 'components/skeletonPageLoading';
import NotificationNew from './notification.new';
import { PlusIcon } from '@shopify/polaris-icons';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
import { useGetNotifications } from 'queries/notification.query';
import { Helmet } from 'react-helmet-async';
export default function NotificationList() {
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) window.history.replaceState(null, 'Notification', '/notification' + buildURLSearch);
    loadData();
  }, [mainQuery, search]);
  const {
    data,
    isLoading: loading,
    refetch: loadData,
    isFetched
  } = useGetNotifications(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    if (!isFetched) return;
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [isFetched, data]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * END QUERY NOTIFICATION DETAIL
   */

  const renderItem = (notification: any, index: number) => {
    const {
      notification_id,
      notification_title,
      notification_content,
      notification_channel,
      notification_user,
      createdAt,
      notification_meta
    } = notification;
    let _notification_meta = notification_meta[0]?.notification_meta_status;
    let status_text = _notification_meta === 0 ? 'Draft' : _notification_meta === 1 ? 'Sent' : 'Fail';
    let status_text_badge: any = _notification_meta === 0 ? 'info' : _notification_meta === 1 ? 'success' : 'critical';
    let notification_schedule = notification_meta[0]?.notification_meta_schedule ?? false;
    return [notification_id, <Text as="p">{notification_title}</Text>, <Badge size="small" tone={status_text_badge}>
        {status_text}
      </Badge>, notification_user || notification_channel, <time key={index + '11'}>
        {notification_schedule ? dateandtime.format(new Date(Number(notification_schedule)), 'DD-MM-YYYY HH:mm:ss') : '-'}
      </time>, <time key={index + '1122'}>{createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss') : '-'}</time>];
  };
  const PagesList = entities && entities.length > 0 ? <>
        <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']} headings={['ID', 'Content', 'Status', 'Send To', 'Schedule', 'Create at']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : <EmptyState heading="No notification here!" image={emptyIMG}>
        <p>Oh! There is no record here! Try remove filter or add a new record!</p>
      </EmptyState>;
  const Actual_page = <>
      <Page title="Quản lý thông báo" secondaryActions={[{
      content: 'Thêm thông báo',
      icon: PlusIcon,
      onAction: () => setUploadModelactive(true)
    }]}>
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />

              {PagesList}
            </Card>
            <br />
            {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery.limit)} activeCurrentPage={Number(mainQuery.page)} /> : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>;
  const [uploadModelactive, setUploadModelactive] = useState(false);
  const toggleUpdateActive = useCallback(() => setUploadModelactive(active => !active), []);
  return <>
      <Helmet>
        <title>Quản lý thông báo</title>
      </Helmet>
      {entities === null ? <SkeletonLoading /> : Actual_page}

      <NotificationNew showModal={uploadModelactive} closeModal={toggleUpdateActive} />
    </>;
}